<div class="intro-wrapper" fxFlex="100" fxLayout="column" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
  <div class="intro-wrapper-inner mt-5" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
    <div fxFlex="100" fxLayout="column" *ngIf="Microclear">
      <div fxFlex="100" class="mt-5">
        <img class="mt-3" src="../../../../assets/images/MicroClear-LogoB.png" width="226" height="48" alt="First slide">
      </div>
      <div fxFlex="100">
        <ul class="intro-pros" fxLayout="row" fxLayoutAlign="center center">
          <li>{{'Global' | translate}}</li>
          <li class="intro-dot"></li>
          <li>{{'Flexible' | translate}}</li>
          <li class="intro-dot"></li>
          <li>{{'Intelligent' | translate}}</li>
        </ul>
      </div>
      <div fxFlex="100" fxLayoutAlign="center center">
        <h2 class="logointro-content" fxFlex="55">
          {{'leftcontent' | translate}}
        </h2>
      </div>
      <div fxFlex="100" fxLayoutAlign="center center">
        <h3>
          <a class="sign-in-link" (click)="goToIDPServerLogin()">{{'Signin' | translate }}</a>
          <a class="sign-up-link" [routerLink]="'/registration/selection'" routerLinkActive="active">{{'SignUp' | translate }}</a>
        </h3>
      </div>
      <div fxFlex="100" fxLayoutAlign="center center">
        <p>{{'Useyourindividualororganizationalaccountcredentials' | translate }}</p>
      </div>
    </div>
    <div fxFlex="100" style="background:#000;" *ngIf="this.Tariff" class="mr-5 my-5">
      <mat-tab-group #tabGroup fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="space-around center" class="tariff-duty-estimator"
                     style="opacity:0.6; width:590px;cursor:default">
        <mat-tab label="{{'TARIFF AND POLICY SEARCH'| translate}}">
          <div fxLayout="column wrap">
            <form [formGroup]="tariffPolicyDtls" class="width556" novalidate>
              <div fxLayout="column wrap">
                <div fxLayout="row wrap" fxLayoutAlign="start center" class="labelAlign">
                  <div fxFlex="100" fxFlex.gt-xs="50" class="p-2 ps-rel mr-4 labelAlignAuto">
                    <mcn-autocomplete [apiUrl]="this.appConfig.anonymousTariffItemApiUrl"
                                      [formGroup]="tariffPolicyDtls" ngDefaultControl
                                      [formControl]="tariffPolicyDtls.controls.hsCode"
                                      optionKey="tariffCode" optionValue="tariffCode" additionalOptionValue="description"
                                      isShowAdditionalOptionValue="true"
                                      placeholder="{{'Select HS Code'| translate}}">
                    </mcn-autocomplete>
                  </div>
                  <div fxFlex="100" fxFlex.gt-sm="33" class="p-2 ps-rel">
                    <p class="preview-content font-bold"><span>{{'Select Procedure'| translate}}</span></p>
                    <mcn-radio-group [formGroup]="tariffPolicyDtls" class="astric-star" controlName="radio" [optionsData]="radioData" optionKey="radioID" optionValue="radioName" ngDefaultControl></mcn-radio-group>
                    <mcn-error-messages [control]="tariffPolicyDtls.controls.radio" customErrorMsg="{{ 'Procedure' | translate }}"></mcn-error-messages>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <div fxFlex="100" fxFlex.gt-xs="50" class="p-2 ps-rel labelAlignAuto">
                    <mcn-autocomplete [formGroup]="tariffPolicyDtls"
                                      [formControl]="tariffPolicyDtls.controls.country"
                                      ngDefaultControl
                                      [apiUrl]="this.appConfig.anonymousCountryListApiUrl"
                                      optionValue="countryName"
                                      optionKey="countryName"
                                      placeholder="{{'Country/Region'| translate}}">
                    </mcn-autocomplete>
                  </div>
                </div>
              </div>
              <div class="orange-grey-theme mt-2" fxLayoutAlign="end end">
                <div class="button-container ps-rel" fxLayout="row">
                  <mcn-button type="button" text="Search" cssClass="btn-custom-primary" (click)="searchTariffResults()"></mcn-button>
                  <mcn-button type="button" text="Cancel" cssClass="btn-custom-secondary-filter" class="ml-3" (click)="goToHome()"></mcn-button>
                </div>
              </div>
            </form>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div fxFlex="100" style="background:#000;" *ngIf="Duty" class="mr-10 my-5">
      <mat-tab-group #tabGroup fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="space-around center" class="tariff-search"
                     style="opacity:0.6; width:840px;cursor:default">
        <mat-tab label="{{'SIMPLIFIED DUTY AND TAX ESTIMATOR'| translate}}">
          <div fxLayout="column wrap">
            <form [formGroup]="dutyTaxEstimatorDtls" style="width:800px" novalidate>
              <div fxLayout="column wrap">
                <div fxLayout="row wrap" fxLayoutAlign="start center" class="labelAlign">
                  <div fxFlex="100" fxFlex.gt-sm="33.33" class="p-2 ps-rel">
                    <p class="preview-content font-bold"><span>{{'Select Procedure'| translate}}</span></p>
                    <mcn-radio-group style="text-align:left"
                        [formGroup]="dutyTaxEstimatorDtls" controlName="radio" [optionsData]="radioData" optionKey="radioID"
                        optionValue="radioName" ngDefaultControl
                        (OnChange)="onChangeProcedureType($event)"></mcn-radio-group>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel labelAlignAuto">
                    <mcn-autocomplete [apiUrl]="this.appConfig.anonymousTariffItemApiUrl"
                                      [formGroup]="dutyTaxEstimatorDtls" ngDefaultControl
                                      [formControl]="dutyTaxEstimatorDtls.controls.hsCode"
                                      optionKey="tariffCode" optionValue="tariffCode" additionalOptionValue="description"
                                      isShowAdditionalOptionValue="true"
                                      placeholder="{{'Select HS Code'| translate}}"
                                      class="astric-star"
                                      (OnChange)="getHSCodeDesc($event)">
                    </mcn-autocomplete>
                    <mcn-error-messages [control]="dutyTaxEstimatorDtls.controls.hsCode" customErrorMsg="{{'Select HS Code' | translate }}"></mcn-error-messages>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-input [formGroup]="dutyTaxEstimatorDtls"
                      controlName="itemDesc"
                      placeholder="{{'Item Description'| translate}}"
                      [readonly]="true" [disabled]="true">
                    </mcn-input>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center" class="labelAlign">
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-input
                      [formGroup]="dutyTaxEstimatorDtls"
                      controlName="cif" placeholder="{{ labelciffob | translate}}" class="astric-star"></mcn-input>
                      <mcn-error-messages [control]="dutyTaxEstimatorDtls.controls.cif" customErrorMsg="{{ labelciffob | translate }}"></mcn-error-messages>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-input
                      [formGroup]="dutyTaxEstimatorDtls"
                      controlName="quantity" placeholder="{{ 'Quantity (In UOMs)' | translate}}" class="astric-star"></mcn-input>
                      <mcn-error-messages [control]="dutyTaxEstimatorDtls.controls.quantity" customErrorMsg="{{ 'Quantity (In UOMs)' | translate }}"></mcn-error-messages>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-select [formGroup]="dutyTaxEstimatorDtls" controlName="specifications" placeholder="{{'Add Specifications'| translate}}"></mcn-select>
                  </div>
                </div>
              </div>
              <div class="orange-grey-theme mt-2" fxLayoutAlign="end end">
                <div class="button-container ps-rel" fxLayout="row">
                  <mcn-button type="button" text="Calculate Duty and Taxes" cssClass="btn-custom-primary" (click)="dutyTaxCharges()"></mcn-button>
                  <mcn-button type="button" text="Cancel" cssClass="btn-custom-secondary-filter" (click)="goToHome()"></mcn-button>
                  <mcn-button type="button" text="Clear" cssClass="btn-custom-secondary-filter" (click)="clear()"></mcn-button>
                </div>
              </div>
            </form>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div fxFlex="90" style="width:90%; bottom:0px;">
    <div fxFlex="100" fxLayout="row wrap" fxLayout.xs="row wrap" fxLayoutAlign="end end">
      <div fxFlex="9">
        <i class="home-left-arrow"></i>
      </div>
      <div fxFlex="11" fxFlex.xs="50" fxLayoutAlign="center center" fxLayout="column" (click)="DutyEstimator()">
        <i class="duty-tax-estimator-icon m-2"></i>
        <p>{{'DutyAndTaxEstimator' | translate }}</p>
      </div>
      <div fxFlex="11" fxFlex.xs="50" fxLayoutAlign="center center" fxLayout="column" (click)="TariffPolicy()">
        <i class="tariff-policy-icon m-2"></i>
        <p>{{'TariffAndPolicySearch' | translate }}</p>
      </div>
      <div fxFlex="9" fxFlex.xs="50" fxLayoutAlign="center center" fxLayout="column">
        <i class="multi-languages-icon m-2"></i>
        <p>{{'MultiLanguages' | translate }}</p>
      </div>
      <div fxFlex="11" fxFlex.xs="50" fxLayoutAlign="center center" fxLayout="column">
        <i class="hormonized-sys-icon m-2"></i>
        <p>{{'HarmonizedSystems' | translate }}</p>
      </div>
      <div fxFlex="9" fxFlex.xs="50" fxLayoutAlign="center center" fxLayout="column">
        <i class="multi-currencys-icon m-2"></i>
        <p>{{'MultiCurrencies' | translate }}</p>
      </div>
      <div fxFlex="9" fxFlex.xs="50" fxLayoutAlign="center center" fxLayout="column">
        <i class="flexible-workflows-icon m-2"></i>
        <p>{{'FlexibleWorkflows' | translate }}</p>
      </div>
      <div fxFlex="13" fxFlex.xs="50" fxLayoutAlign="center center" fxLayout="column">
        <i class="config-businesrules-icon m-2"></i>
        <p>{{'ConfigurableBusinessRules' | translate }}</p>
      </div>
      <div fxFlex="9" fxFlex.xs="100" fxLayoutAlign="end start">
        <i class="home-right-arrow"></i>
      </div>
    </div>
  </div>
</div>
