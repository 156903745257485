import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RmsConfig } from '../../../../mcnConfig/RmsConfig';
import { ToasterService } from '../../../../toastrService/toasterService';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

export interface MyData {
  outliers: any;
}

@Injectable()
export class NVDBOutliersSharedService {
  constructor(private http: HttpClient, private toastrService: ToasterService,
    private appConfig: RmsConfig) {
  }

  private handleError() {
    return this.toastrService.Error('Internal Server Error.Please Try Again Later.');
  }

  getRMSMLData(): Observable<any> {
    return this.http.get(this.appConfig.AIRMSChartApiUrl)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }
  
  sharingData: MyData = {
    outliers: []
  };
}
