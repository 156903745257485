<div [ngClass]="{'arb': (this.webStorageService.get('lang_code') === 'Arb')}">
  <div fxLayout="row wrap">
    <div fxLayout="column wrap" fxFlex="100">
      <h2 class="main-title"><span>{{'Search Result'| translate}}</span></h2>
    </div>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="tariffSearchForm" novalidate>
      <expansion-panels-container class="custom-expansion-panel-container">
        <expansion-panel>
          <expansion-panel-title>
            {{'HS Code'| translate}}
          </expansion-panel-title>
          <expansion-panel-content>
            <section class="table-section responsive-material-table mt-0">
              <mat-table #table [dataSource]="dataSource">
                <ng-container cdkColumnDef="hsCode">
                  <mat-header-cell *cdkHeaderCellDef fxFlex="30">
                    {{'HS Code'| translate}}
                  </mat-header-cell>
                  <mat-cell *cdkCellDef="let row" fxFlex="30">
                    <span class="mobile-cont-text">{{'HS Code'| translate}}</span>
                    <span> {{row.hsCode}} </span>
                  </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="desc">
                  <mat-header-cell *cdkHeaderCellDef fxFlex="37">{{'Description'| translate}}</mat-header-cell>
                  <mat-cell *cdkCellDef="let row" fxFlex="37">
                    <span class="mobile-cont-text">{{'Description'| translate}}</span>
                    <span> {{row.desc}} </span>
                  </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="uom">
                  <mat-header-cell *cdkHeaderCellDef fxFlex="25">{{'UOM(s)'| translate}}</mat-header-cell>
                  <mat-cell *cdkCellDef="let row" fxFlex="25">
                    <span class="mobile-cont-text">{{'UOM(s)'| translate}}</span>
                    <span> {{row.uom}} </span>
                  </mat-cell>
                </ng-container>
                <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </section>
          </expansion-panel-content>
        </expansion-panel>
        <expansion-panel>
          <expansion-panel-title>
            {{'Tariff Regulations'| translate}}
          </expansion-panel-title>
          <expansion-panel-content>
            <section class="table-section responsive-material-table mt-0">
              <mat-table #table [dataSource]="dataSource5">
                <ng-container cdkColumnDef="duty">
                  <mat-header-cell *cdkHeaderCellDef fxFlex="40">{{'Applicable Duty(s)'| translate}}</mat-header-cell>
                  <mat-cell *cdkCellDef="let row" fxFlex="40">
                    <span class="mobile-cont-text">{{'Duty: '| translate}}</span>
                    <span> {{row.duty}} </span>
                  </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="startDate">
                  <mat-header-cell *cdkHeaderCellDef fxFlex="15">{{'Start Date'| translate}}</mat-header-cell>
                  <mat-cell *cdkCellDef="let row" fxFlex="15">
                    <span class="mobile-cont-text">{{'Start Date'| translate}}</span>
                    <span> {{row.startDate | date}} </span>
                  </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="endDate">
                  <mat-header-cell *cdkHeaderCellDef fxFlex="15">{{'End Date'| translate}}</mat-header-cell>
                  <mat-cell *cdkCellDef="let row" fxFlex="15">
                    <span class="mobile-cont-text">{{'End Date'| translate}}</span>
                    <span *ngIf="row.endDate !== null"> {{ row.endDate | date}} </span>
                    <span *ngIf="row.endDate === null"> {{ 'Infinite Date' | translate}} </span>
                  </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="rate">
                  <mat-header-cell *cdkHeaderCellDef fxFlex="30"></mat-header-cell>
                  <mat-cell *cdkCellDef="let row" fxFlex="30">
                    <div fxFlex="100" fxFlex.gt-sm="10" class="p-2 mt-2">
                      <a href="javascript:void(0)">
                        <span (click)="OpenDutyRate(row)" class="preview-content"
                          style="color:#006ee6">{{'DutyRates' | translate}}</span>
                      </a>
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-header-row *cdkHeaderRowDef="displayedColumns5"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: displayedColumns5;"></mat-row>
              </mat-table>
            </section>
          </expansion-panel-content>
        </expansion-panel>
        <!-- <expansion-panel>
          <expansion-panel-title>
            {{'Exemption Schemes'| translate}}
          </expansion-panel-title>
          <expansion-panel-content>
            <div fxLayout="column wrap">
              <div fxLayout="row wrap" class="mb-2">
                <h2 class="main-title"><span>{{'Exemption and Concession Regimes'| translate}}</span></h2>
              </div>
              <section class="table-section responsive-material-table mt-0">
                <mat-table #table [dataSource]="dataSource1">
                  <ng-container cdkColumnDef="exemptionRequestNumber">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="40">{{'Exemption Number'| translate}}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="40">
                      <span class="mobile-cont-text">{{'Exemption Number'| translate}}</span>
                      <span> {{row.exemptionRequestNumber}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="exemptionCategoryInfoId">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="20">{{'Exemptions & Concessions'| translate}}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="20">
                      <span class="mobile-cont-text">{{'Exemptions & Concessions'| translate}}</span>
                      <span> {{row.exemptionCategoryInfoId}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="direction">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="20">{{'Duty Type'| translate}}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="20">
                      <span class="mobile-cont-text">{{'Duty Type'| translate}}</span>
                      <span> {{ row.direction === "1" ? 'Import' : 'Export' }} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="approvedExemptionRate">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="20">{{'Applicable Duty Rate'| translate}}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="20">
                      <span class="mobile-cont-text">{{'Applicable Duty Rate'| translate}}</span>
                      <span> {{ row.approvedExemptionRate === '' ? 'NA' : row.approvedExemptionRate }} </span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="displayedColumns1"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: displayedColumns1;"></mat-row>
                </mat-table>
              </section>
            </div>
            <div fxLayout="column wrap" class="mt-2">
              <div fxLayout="row wrap" class="mb-2">
                <h2 class="main-title"><span>{{'Quotas'| translate}}</span></h2>
              </div>
              <section class="table-section responsive-material-table mt-0">
                <mat-table #table [dataSource]="dataSource2">
                  <ng-container cdkColumnDef="direction">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="33.33">{{'Regime'| translate}}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="33.33">
                      <span class="mobile-cont-text">{{'Regime'| translate}}</span>
                      <span> {{row.direction}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="quotaName">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="33.33">{{'Quota Name'| translate}}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="33.33">
                      <span class="mobile-cont-text">{{'Quota Name'| translate}}</span>
                      <span> {{row.quotaName}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="quotaRefCode">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="33.33">{{'Quotas & Concessions '| translate}}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="33.33">
                      <span class="mobile-cont-text">{{'Quotas & Concessions '| translate}}</span>
                      <span> {{row.quotaRefCode}} </span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="displayedColumns2"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: displayedColumns2;"></mat-row>
                </mat-table>
              </section>
            </div>
          </expansion-panel-content>
        </expansion-panel> -->
        <!-- <expansion-panel>
          <expansion-panel-title>
            {{'Policy Regulations'| translate}}
          </expansion-panel-title>
          <expansion-panel-content>
            <div fxLayout="column wrap">
              <div fxLayout="row wrap" class="mb-2">
                <h2 class="main-title"><span>{{'Non-Tariff Regulations'| translate}}</span></h2>
              </div>
              <section class="table-section responsive-material-table mt-0">
                <mat-table #table [dataSource]="dataSource3" multiTemplateDataRows>
                  <ng-container cdkColumnDef="title">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="45">{{'Prohibition Name'| translate}}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="45">
                      <span class="mobile-cont-text">{{'Prohibition Name'| translate}}</span>
                      <span> {{row.title}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="referenceNumber">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="45">{{'Reference to Regulation'| translate}}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="45">
                      <span class="mobile-cont-text">{{'Reference to Regulation'| translate}}</span>
                      <span> {{row.referenceNumber}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="expand">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="10"></mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="10">
                        <span *ngIf="!row.isExpanded" class="signs">+</span>
                        <span *ngIf="row.isExpanded" class="signs">-</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="expandedDetail">
                    <mat-cell class="cellColor" [attr.colspan]="displayedColumns3.length"
                        *cdkCellDef="let detail; let i = dataIndex">
                        <div class="innerTariffPolicyTable">
                            <mat-table #table [dataSource]="dataSourceSecondTable[i]" multiTemplateDataRows>

                                <ng-container cdkColumnDef="name">
                                    <mat-header-cell *cdkHeaderCellDef fxFlex="50"> Restriction </mat-header-cell>
                                    <mat-cell *cdkCellDef="let row" fxFlex="50"> {{row.name}} </mat-cell>
                                </ng-container>

                                <ng-container cdkColumnDef="agency">
                                    <mat-header-cell *cdkHeaderCellDef fxFlex="50"> Authority </mat-header-cell>
                                    <mat-cell *cdkCellDef="let row" fxFlex="50"> {{row.agency}} </mat-cell>
                                </ng-container>

                                <mat-header-row *cdkHeaderRowDef="displayedColumnsSecondTable"></mat-header-row>
                                <mat-row *cdkRowDef="let row; columns: displayedColumnsSecondTable;" matRipple class="element-row"></mat-row>
                            </mat-table>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="displayedColumns3"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: displayedColumns3; let rInd = dataIndex"
                    class="element-row" [class.expanded]="row.isExpanded" (click)="expandCollapse(row, rInd)">
                </mat-row>
                <mat-row *cdkRowDef="let row; columns: ['expandedDetail'];"
                      [@detailExpand]="row.isExpanded === true ? 'expanded' : 'collapsed'" style="overflow: hidden">
                </mat-row>
                </mat-table>
              </section>
            </div>
          </expansion-panel-content>
        </expansion-panel> -->
      </expansion-panels-container>
    </form>
  </div>
</div>
<button matDialogClose class="dialog-close">
  <i class="icon-close"></i>
</button>


