<div class="container mt-3 nopl-pr" fxLayout="column" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
  <div fxLayoutAlign="center center" fxLayout="column" fxFlex="90" class="riskFreq-flex">
    <div fxFlex.gt-sm="100" fxFlex.lt-sm="100" class="mb-4 mt-3 overflow-hidden">
      <div class="rndm-scltn-cnfg-list width-100 bg-clr-white risk-indicator-arrows-p">
        <form [formGroup]="nvdbHistoricalDataFltrForm" novalidate>
          <div fxLayout="row wrap">
            <div fxFlex="32" class="ps-rel">
              <mcn-select placeholder="{{ 'ModeofTransport' | translate }}" class="fx-selectBox"
                [formGroup]="nvdbHistoricalDataFltrForm" controlName="modeOfTransport"
                [apiUrl]="this.rmsConfig.getMcnTypes" optionKey="typeId" optionValue="name"
                [moduleName]="this.moduleNameDeclaration" optionParameterKey="typeName"
                [optionParameterValue]="this.modeOfTransport">
              </mcn-select>
            </div>
            <div fxFlex="2"></div>
            <div fxFlex="32" class="ps-rel">
              <mcn-autocomplete ngDefaultControl placeholder="{{'ItemHSCode' | translate}}"
                [formGroup]="nvdbHistoricalDataFltrForm" [formControl]="nvdbHistoricalDataFltrForm.controls.itemHScode"
                [apiUrl]="this.rmsConfig.autoTariffItems" optionKey="tariffCode" optionValue="tariffCode">
              </mcn-autocomplete>
            </div>
            <div fxFlex="2"></div>
            <div fxFlex="32" class="ps-rel">
              <mcn-select placeholder="{{ 'DeclarationType' | translate }}" class="fx-selectBox"
                [formGroup]="nvdbHistoricalDataFltrForm" controlName="declarationType"
                [apiUrl]="this.rmsConfig.getMcnTypes" optionKey="typeId" optionValue="name"
                [moduleName]="this.ModuleNametariffmngt" optionParameterKey="typeName"
                [optionParameterValue]="this.declarationType">
              </mcn-select>
            </div>
          </div>
          <div fxLayout="row wrap">
            <div fxFlex="32" class="ps-rel">
              <mcn-input [formGroup]="nvdbHistoricalDataFltrForm"
                         controlName="importerName"
                         placeholder="{{ 'ImporterName' | translate }}">
              </mcn-input>
            </div>
            <div fxFlex="2"></div>
            <div fxFlex="32" class="ps-rel">
              <mcn-select placeholder="{{ 'ValuationMethod' | translate }}" class="fx-selectBox"
                [formGroup]="nvdbHistoricalDataFltrForm" controlName="valuationMethod"
                [apiUrl]="this.rmsConfig.getMcnTypes" optionKey="typeId" optionValue="name"
                [moduleName]="this.moduleNameDeclaration" optionParameterKey="typeName"
                [optionParameterValue]="this.valuationMethod">
              </mcn-select>
            </div>
            <div fxFlex="2"></div>
            <div fxFlex="32" class="ps-rel">
              <mcn-input [formGroup]="nvdbHistoricalDataFltrForm" controlName="supplierName"
                placeholder="{{ 'SupplierName' | translate }}">
              </mcn-input>
            </div>
          </div>
          <div fxLayout="row wrap">
            <div fxFlex="32" class="ps-rel">
              <mcn-autocomplete ngDefaultControl placeholder="{{'CountryofOrigin' | translate}}"
                [formGroup]="nvdbHistoricalDataFltrForm"
                [formControl]="nvdbHistoricalDataFltrForm.controls.countryOfOrigin"
                [apiUrl]="this.rmsConfig.countryApiUrl" optionKey="countryName" optionValue="countryName">
              </mcn-autocomplete>
            </div>
            <div fxFlex="2"></div>
            <div fxFlex="32" class="ps-rel">
              <mcn-autocomplete ngDefaultControl placeholder="{{'DestinationCountry' | translate}}"
                [formGroup]="nvdbHistoricalDataFltrForm"
                [formControl]="nvdbHistoricalDataFltrForm.controls.desitinationCountry"
                [apiUrl]="this.rmsConfig.countryApiUrl" optionKey="countryName" optionValue="countryName">
              </mcn-autocomplete>
            </div>
            <div fxFlex="2"></div>
            <div fxFlex="32" class="ps-rel">
              <mcn-date [formGroup]="nvdbHistoricalDataFltrForm" controlName="fromdate"
                placeholder="{{'FromDate' | translate}}" (OnChange)="checkDateConditions($event)">
              </mcn-date>
              <div *ngIf="this.dateValidation != null" class="mcn-error">{{ this.dateValidation | translate }}</div>
              <mcn-error-messages customErrorMsg="" [control]="nvdbHistoricalDataFltrForm.controls.fromdate">
              </mcn-error-messages>
            </div>
          </div>
          <div fxLayout="row wrap">
            <div fxFlex="32" class="ps-rel">
              <mcn-date [formGroup]="nvdbHistoricalDataFltrForm" controlName="todate"
                placeholder="{{'ToDate' | translate}}" (OnChange)="checkDateConditions($event)">
              </mcn-date>
              <mcn-error-messages customErrorMsg="" [control]="nvdbHistoricalDataFltrForm.controls.todate">
              </mcn-error-messages>
            </div>
          </div>
        </form>
        <div class="form-footer px-2">
          <div class="button-container">
            <mcn-button type="button" cssClass="btn-custom-primary" text="{{'Search' | translate}}" class="ml-3"
              (click)="searchNVDBHistoricalDataLstFilter()">
            </mcn-button>
            <div fxFlex="2"></div>
            <mcn-button type="button" cssClass="btn-custom-primary" text="{{'Reset' | translate}}" class="ml-3"
              (click)="resetNVDBHistoricalDataLstFilter()">
            </mcn-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="common-status-border">
    <div>
      <div fxLayout="column"  fxLayoutAlign="center center"  *ngIf="this.nvdbHistoricalDataList?.length=='0'">
        <img src="../../../../../../assets/images/No-Records-Found.png" alt="No Records" />
        <span class="no-records-found">{{'NoRecordsFound' | translate}}</span>
      </div>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let details of nvdbHistoricalDataList;trackBy:trackById" [ngClass]="['activated']"
          class="user-individual-card p-1 my-3 border" fxLayout="column" class="inspection-report-item my-2 ml-1 mr-1">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div fxLayout="column wrap" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center"
                fxLayoutAlign="center start" class="first-row m-0 py-2" fxFlex="100">
                <div fxFlex="auto" fxLayout="column wrap" fxLayout.sm="row" fxLayout.gt-sm="row"
                  fxLayoutAlign.gt-sm="start center" fxLayoutAlign="center start">
                  <div fxFlex.xs="100" fxFlex.sm="50" fxFlex.gt-sm="15" class="p-2">
                    <h6 class="preview-content font-bold req-item-anchor">{{details.submittedDate | date}}</h6>
                    <p class="preview-label">{{ 'SubmittedDate' | translate }}</p>
                  </div>
                  <div fxFlex.xs="100" fxFlex.sm="50" fxFlex.gt-sm="15" class="p-2">
                    <h6 class="preview-content font-bold">{{details.tariffCode}}</h6>
                    <p class="preview-label">{{'ItemHSCode' | translate}}</p>
                  </div>
                  <div fxFlex.xs="100" fxFlex.sm="50" fxFlex.gt-sm="20" class="p-2">
                    <h6 class="preview-content font-bold">{{details.description}}</h6>
                    <p class="preview-label">{{ 'Description' | translate }}</p>
                  </div>
                  <div fxFlex.xs="100" fxFlex.sm="50" fxFlex.gt-sm="20" class="p-2">
                    <h6 class="preview-content font-bold">{{details.countryName}}</h6>
                    <p class="preview-label">{{ 'CountryOfOrigin' | translate }}</p>
                  </div>
                  <div fxFlex.xs="100" fxFlex.sm="50" fxFlex.gt-sm="20" class="p-2">
                    <h6 class="preview-content font-bold">{{details.exportCountry}}</h6>
                    <p class="preview-label">{{ 'DestinationCountry' | translate }}</p>
                  </div>
                  <div fxFlex.xs="100" fxFlex.sm="50" fxFlex.gt-sm="10" class="p-2">
                    <ul fxLayout="row" class="main-title-rndm-scltn-cnfg-list">
                      <li class="mr-20">
                        <i class="icon-apply-tarrif" appCustomTooltip title="{{ 'ViewAppliedTariff' | translate }}"
                          (click)="ViewTarrrifDetails(details.declarationId)"></i>
                      </li>
                      <li class="edit">
                        <i class="icon-outer-layer" appCustomTooltip title="{{ 'ViewOutlier' | translate }}"
                          (click)="viewOutlierDetails(details.declarationId,details.tariffCode)"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="column wrap">
            <div fxLayout="row">
              <div fxFlex="15">
                <h6 class="preview-content font-semi-bold">{{details.quantity}}</h6>
                <p class="preview-label">{{ 'Quantity' | translate }}</p>
              </div>
              <div fxFlex="15">
                <h6 class="preview-content font-semi-bold">{{details.uom}}</h6>
                <p class="preview-label">{{ 'UOM' | translate }}</p>
              </div>
              <div fxFlex="20">
                <h6 class="preview-content font-semi-bold">{{details.value}}</h6>
                <p class="preview-label">{{ 'AssessedValue' | translate }}</p>
              </div>
              <div fxFlex="20">
                <h6 class="preview-content font-semi-bold">{{details.perUnit}}</h6>
                <p class="preview-label">{{ 'ValueUnit' | translate }}</p>
              </div>
              <div fxFlex="30">
                <h6 class="preview-content font-semi-bold">{{details.valuationMethods}}</h6>
                <p class="preview-label">{{ 'ValuationMethod' | translate }}</p>
              </div>
            </div>
            <div fxLayout="row" class="mt-2">
              <div fxFlex="15">
                <h6 class="preview-content font-semi-bold">{{details.importerName}}</h6>
                <p class="preview-label">{{ 'Importer' | translate }}</p>
              </div>
              <div fxFlex="15">
                <h6 class="preview-content font-semi-bold">{{details.purposeofUsage}}</h6>
                <p class="preview-label">{{ 'PurposeOfUsage' | translate }}</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>

<router-outlet></router-outlet>