<div class="container pt-4"  [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
  <div fxLayout="column" fxFlex class="card-grey-top-border manifest-info-wrapper mb-4 mb-lg-0">
    <div fxLayout="row wrap" class="border-bottom">
      <div fxLayout="column wrap" fxFlex="100">
        <h2 class="main-title"><span> {{'MessagingSystem' | translate}}</span></h2>
      </div>
    </div>
    <div class="container user-registration-wrapper-wizard nopl-pr" fxLayout="column">
      <div fxLayout="column" fxFlex="90">
        <div fxFlex.gt-sm="100" fxFlex.lt-sm="100" class="mb-4 mt-3">
          <div fxLayout="row wrap" class="mt-4">
            <div fxLayout="row wrap" fxFlex="100">
              <i class="icon-gear mr-2"></i>
              <h2 class="main-title"><span>{{'ActionConfigurationSettings' | translate}}</span></h2>
            </div>
          </div>
          <div fxLayout="column">
            <form [formGroup]="this.msgConfigForm" novalidate (ngSubmit)="submit($event,this.msgConfigForm.value)">
              <div fxLayout="column wrap">
                <div fxLayout="row wrap">
                  <div fxFlex="100" class="p-2 ps-rel">
                    <mcn-radio-group [optionsData]="this.msgConfigOptions" [formGroup]="actionConfigForm" optionKey="typeId" optionValue="name" (OnChange)="getConfigOption($event)"
                                     controlName="configOption"></mcn-radio-group>
                  </div>
                </div>
                <div fxLayout="row wrap" class="mt-2">
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-select class="astric-star" placeholder="{{'Module' | translate }}" [formGroup]="this.msgConfigForm" optionKey="dataSourceSchema"
                                optionValue="dataSourceSchema" [isList]="true" [isInbox]="true" controlName="moduleName" (OnChange)="getModule($event)" [apiUrl]="this.appConfig.getAllModulesApiUrl"></mcn-select>
                    <mcn-error-messages [control]=" this.msgConfigForm.controls.moduleName"></mcn-error-messages>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-select class="astric-star" placeholder="{{'Other' | translate }}" [formGroup]="this.msgConfigForm" optionKey="dataProfileId"
                                optionValue="name" controlName="profileId" [isInbox]="true" [isCascading]="this.profileCascading" optionParameterKey="dataSourceSchema" [optionParameterValue]="this.moduleName" [isList]="true"
                                (OnChange)="getProfile($event)" [apiUrl]="this.profileUrl"></mcn-select>
                    <mcn-error-messages [control]=" this.msgConfigForm.controls.profileId"></mcn-error-messages>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel" *ngIf="this.isClaimBased">
                    <mcn-select class="astric-star" placeholder="{{'ClaimType' | translate }}" [formGroup]="this.msgConfigForm" optionKey="transitionId"
                                optionValue="transitionClaim" [isList]="true" [isInbox]="true" [isCascading]="this.claimCascading" optionParameterKey="dataProfileId" [optionParameterValue]="this.dataProfileId"
                                (OnChange)="getClaims($event)" controlName="claimId" [apiUrl]="this.claimTypeUrl"></mcn-select>
                    <mcn-error-messages [control]="this.msgConfigForm.controls.claimId"></mcn-error-messages>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel" *ngIf="this.isStateBased">
                    <mcn-select class="astric-star" placeholder="{{'StateName' | translate }}" [formGroup]="this.msgConfigForm" optionKey="workflowStateId"
                                optionValue="stateName" [isList]="true" [isInbox]="true" [isCascading]="this.claimCascading" optionParameterKey="dataProfileId" [optionParameterValue]="this.dataProfileId"
                                (OnChange)="getClaims($event)" controlName="stateId" [apiUrl]="this.claimTypeUrl"></mcn-select>
                    <mcn-error-messages [control]="this.msgConfigForm.controls.stateId"></mcn-error-messages>
                  </div>
                </div>
                <div fxLayout="row wrap">
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel  mb-3" *ngIf="!this.isUserBased">
                    <mcn-multiSelect cssClass="multi-astric-star" placeholder="{{'Role' | translate }}" [formGroup]="this.roleConfigForm" optionKey="id"
                                     optionValue="name" [isList]="true" [isCascading]="this.isCascading" [selectedValue]="this.selectedRoles" [isInbox]="true" optionParameterKey="dataProfileId" [optionParameterValue]="this.dataProfileId"
                                     controlName="roles" [apiUrl]="this.roleUrl"></mcn-multiSelect>
                    <mcn-error-messages cssClass="mcn-multi-select-error" [control]="this.roleConfigForm.controls.roles"></mcn-error-messages>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-textarea class="astric-star" placeholder="{{'MessageSubject' | translate }}" controlName="msgSubject" [formGroup]="this.msgConfigForm"></mcn-textarea>
                    <mcn-error-messages [control]="this.msgConfigForm.controls.msgSubject"></mcn-error-messages>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-textarea class="astric-star" placeholder="{{'MessageBody' | translate }}" controlName="msgBody" [formGroup]="this.msgConfigForm"></mcn-textarea>
                    <mcn-error-messages [control]="this.msgConfigForm.controls.msgBody"></mcn-error-messages>
                  </div>
                </div>

                <div fxLayout="row wrap" fxFlex="100" fxFlex.gt-xs="50" class="p-2 position-relative">
                  <p class="mb-1 mt-2"><span>{{'MessageType' | translate }} <span class="star-icon">*</span></span></p>
                  <mcn-radio-group [optionsData]="this.msgType" [formGroup]="this.msgConfigForm" optionKey="typeId" optionValue="name" controlName="msgType"></mcn-radio-group>
                  <mcn-error-messages [control]=" this.msgConfigForm.controls.msgType"></mcn-error-messages>
                </div>
                <div fxLayout="row wrap" class="p-2 mt-2" fxFlex="60" fxLayoutAlign="start center">
                  <!--<mcn-checkbox *ngIf="!this.isUserBased" [formGroup]="this.msgConfigForm" ngDefaultControl controlName="isPortBased" text="{{'IsLocationBased' | translate }}"></mcn-checkbox>-->
                  <mcn-checkbox *ngIf="!this.isUserBased" class="ml-2" [formGroup]="this.msgConfigForm" ngDefaultControl controlName="isCustomBased" text="{{'IsCustomStationBased' | translate }}"></mcn-checkbox>
                  <mcn-checkbox [formGroup]="this.msgConfigForm" class="ml-2" ngDefaultControl controlName="isActive" text="{{'IsActivate' | translate }}"></mcn-checkbox>
                </div>
                <div class="orange-grey-theme" fxLayoutAlign="end end">
                  <div class="button-container ps-rel">
                    <mcn-button type="submit" text="{{'Create' | translate}}" cssClass="btn-custom-primary" *ngIf="!this.isEdit"></mcn-button>
                    <mcn-button type="submit" text="{{'Save' | translate}}" cssClass="btn-custom-primary" *ngIf="this.isEdit"></mcn-button>
                    <mcn-button type="button" text="{{'Cancel' | translate}}" cssClass="btn-custom-secondary" class="ml-3" (OnClick)="cancel()"></mcn-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div>
            <mat-expansion-panel class="user-individual-card p-1 my-3" (opened)="getConfigList('')" (closed)="clearConfigList()">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div fxLayout="row wrap" class="mt-2">
                    <div fxLayout="row wrap" fxFlex="100">
                      <i class="icon-list-view mr-2"></i>
                      <h2 class="main-title"><span>{{'ActionConfigurationList' | translate }}</span></h2>
                    </div>
                  </div>
                </mat-panel-title>
                <mat-panel-description>
                  <mat-icon class="accordion-arrow"></mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div fxLayout="column wrap">
                <form [formGroup]="actionConfigForm" novalidate>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-select class="astric-star" placeholder="{{'Module' | translate }}" [formGroup]="actionConfigForm" optionKey="dataSourceSchema"
                                optionValue="dataSourceSchema" [isList]="true" [isInbox]="true" controlName="modName" (OnChange)="getConfigList($event)" [apiUrl]="this.appConfig.getAllModulesApiUrl"></mcn-select>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-button type="button" text="{{'Refresh' | translate }}" cssClass="btn-custom-primary" (OnClick)="getConfigList('')"></mcn-button>
                  </div>
                </form>
              </div>
              <section class="table-section responsive-material-table">
                <mat-table #table [dataSource]="dataSource">
                  <ng-container cdkColumnDef="moduleName">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="15">{{'ModuleName' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="15">
                      <span class="mobile-cont-text">{{'ModuleName' | translate }}</span>
                      <span style="word-wrap: break-word;" class="preview-content"> {{row.moduleName}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="profileName">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="15">{{'ProfileName' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="15">
                      <span class="mobile-cont-text">{{'ProfileName' | translate }}</span>
                      <span style="word-wrap: break-word;" class="preview-content"> {{row.profile}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="actionType">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="25">{{'ActionType' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="25">
                      <span class="mobile-cont-text">{{'ActionType' | translate }}</span>
                      <span style="word-wrap: break-word;" class="preview-content"> {{row.claim}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="role">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="30">{{'Roles' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="30">
                      <span class="mobile-cont-text">{{'Roles' | translate }}</span>
                      <span style="word-wrap: break-word;"> {{row.roles}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="status">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="10">{{'Status' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="10">
                      <span class="mobile-cont-text">{{'Status' | translate }}</span>
                      <span style="word-wrap: break-word;" class="preview-content"> {{row.isActive === true ? "Active" : "Inactive"}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="icons">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="5">{{'Action' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="4">
                      <i class="icon-edit" (click)="editMsgConfig(row.msgConfigId)" appCustomTooltip title="{{'Edit' | translate }}"></i>
                      <i class="icon-delete" (click)="deleteMsgConfig(row.msgConfigId)" appCustomTooltip title="{{'Delete' | translate }}"></i>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
              </section>
              <mcn-pagination [totalCount]="this.pageCount" (OnChange)="getMessageConfigList($event)"></mcn-pagination>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
