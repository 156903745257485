import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { ValidationService } from './ValidationService';

@Component({
  selector: 'mcn-error-messages',
  template: `<span class="mcn-error" *ngIf="errorMessage !== null"> {{errorMessage | translate}}</span>`
})

export class McnErrorComponent {
  @Input() control: FormControl;
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() customErrorMsg = '';
  constructor() { }

  get errorMessage() {
    if (this.control) {
      const errors: ValidationErrors = this.control.errors;
      for (const propertyName in errors) {
        if (errors.hasOwnProperty(propertyName) && this.control.touched) {
          return ValidationService.getValidatorErrorMessage(propertyName, errors[propertyName]) || errors[propertyName];
        }
      }
    }
    return null;
  }
}
