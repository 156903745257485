import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'mcn-radio-group',
  template: `<div [formGroup]="formGroup">
        <mat-radio-group formControlName="{{controlName}}" [disabled]="disabled">
                <mat-radio-button *ngFor="let option of optionsData;
                let i=index" id="radio_{{i}}{{optionalId}}_{{controlName | removeSpace}}"
                [matTooltip]="option[optionSelectedValue]"
                      (click)="_onChange($event,option[optionKey])" style="margin:5px" value="{{option[optionKey]}}"
                      [ngClass]="{'side-bar-event-enable': !this.disabled ,'side-bar-event-disable': this.disabled }">
                     <a *ngIf="isIcons" href="javascript:void(0)" fxLayout="row" fxLayoutAlign="center center">
                          <i class="cursor-pointer {{option[optionValue]}}"></i>
                     </a>
                     <span *ngIf="!isIcons">{{option[optionValue] | translate }}</span>
                </mat-radio-button>
        </mat-radio-group>
    </div>`
})
export class McnRadioGroupComponent {
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() optionsData: any;
  @Input() optionKey: any;
  @Input() optionValue: any;
  @Input() optionSelectedValue: any;
  @Input() public optionParameterKey: any = null;
  @Input() public optionParameterValue: any = null;
  @Input() cssClass: string;
  @Input() disabled: boolean;
  @Input() selectedValue: string;
  @Input() optionalId = '';
  @Input() isIcons = false;

  @Output() OnChange = new EventEmitter();
  private _control: AbstractControl;
  public user_data: any[];
  public selectedKey: any;

  ngOnInit(): void {
    const self: McnRadioGroupComponent = this;
    if (!isNullOrUndefined(this.formGroup) && !isNullOrUndefined(this.controlName)) {
      self._control = this.formGroup.controls[self.controlName];
      if (self._control) {
        if (self.disabled) {
          self._control.disable();
        }
      }
      if (self._control.value !== '' && self._control.value !== undefined
        && self._control.value !== null) {
        this.formGroup.controls[this.controlName].setValue(self._control.value.toString());
      }
      if (this.selectedValue && (self._control.value === '' || self._control.value === undefined || self._control.value === null) && this.optionsData != undefined && this.optionsData.length > 0) {
        const result = this.optionsData.find(x => x[this.optionValue] === this.selectedValue);
        if (!isNullOrUndefined(result)) {
          this.formGroup.controls[this.controlName].setValue(result[this.optionKey].toString());
        }
      }
      if (self._control.value && !isNullOrUndefined(this.optionsData) && this.optionsData.length > 0) {
        const result = this.optionsData.find((x) => x[this.optionKey] === self._control.value);
        if (!isNullOrUndefined(result)) {
          this.formGroup.controls[this.controlName].setValue(result[this.optionKey].toString());
          this.OnChange.emit({ event: event, options: result });
        }
      }
    }
  }

  _onChange(event: any, value: any): void {
    if (this.OnChange != null) {
      const option: any = this.optionsData.find(x => x[this.optionKey] === value);
      this.formGroup.controls[this.controlName].setValue(option[this.optionKey].toString());
      this.OnChange.emit({ event: event, options: option });
    }
  }

  ngOnChanges(): void {
    const self: McnRadioGroupComponent = this;
    if (!isNullOrUndefined(this.formGroup) && !isNullOrUndefined(this.controlName)) {
      self._control = this.formGroup.controls[this.controlName];
      if (self._control) {
        if (!this.disabled) {
          self._control.enable();
        }
        if (this.disabled) {
          self._control.disable();
        }
      }
      if (this.selectedValue && this.optionsData != undefined && this.optionsData.length > 0) {
        const response = this.optionsData.find(x => x[this.optionValue] === this.selectedValue);
        if (!isNullOrUndefined(response)) {
          this.formGroup.controls[this.controlName].setValue(response[this.optionKey].toString());
          this.OnChange.emit({ event: event, options: response });
        }
      }
      if (self._control.value && this.optionsData != undefined && this.optionsData.length > 0) {
        const result = this.optionsData.find((x) => x[this.optionKey] === self._control.value);
        if (!isNullOrUndefined(result)) {
          this.formGroup.controls[this.controlName].setValue(result[this.optionKey].toString());
          this.OnChange.emit({ event: event, options: result });
        }
      }
    }
  }
}
