<div fxLayout="column Wrap" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayout="row Wrap">
        <div fxLayout="column Wrap" fxFlex="100" fxLayoutAlign="center center">
            <div fxLayout="row" class="flex-risk-head">
                <h2 fxFlex="90" class="main-title main-title-risk-frequency pb-0">
                    <span>
                        {{ (this.parentForm === 'C' ? 'NVDBCommoditySpecifications' :'NVDBVehicleSpecifications') | translate}}
                    </span>
                </h2>
                <h6 fxFlex="10" class="preview-content font-semi-bold">
                </h6>
            </div>
            <div><i class="star-heading-ul"></i></div>
        </div>
    </div>
    <div fxLayout="column">
        <form [formGroup]="" novalidate>
            <div mat-dialog-content>
                <div fxLayout="row Wrap" class="mt-3">
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-autocomplete ngDefaultControl placeholder="{{'SpecificationCode' | translate}}"
                            [formGroup]="this.nvdbSpecServiceDocument?.dataProfile.profileForm"
                            [formControl]="nvdbSpecServiceDocument?.dataProfile.profileForm.controls.specificationId"
                            [apiUrl]="this.rmsConfig.autoSpecification" optionKey="specificationId" optionValue="code"
                            Class="astric-star" [optionsData]="specificationData" [isList]="true"
                            (OnChange)="getSpecification($event)" (OnLeave)="onLeave($event)">
                        </mcn-autocomplete>
                        <mcn-error-messages customErrorMsg=""
                            [control]="this.nvdbSpecServiceDocument?.dataProfile.profileForm.controls.specificationId">
                        </mcn-error-messages>
                    </div>
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-date [formGroup]="this.nvdbSpecServiceDocument?.dataProfile.profileForm"
                            placeholder="{{'StartDate' | translate}}" controlName="startDate">
                        </mcn-date>
                        <mcn-error-messages customErrorMsg=""
                            [control]="this.nvdbSpecServiceDocument?.dataProfile.profileForm.controls.startDate">
                        </mcn-error-messages>
                    </div>
                </div>
                <div fxLayout="row Wrap" class="mt-3">
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-textarea placeholder="{{'Caption' | translate}}" [isFormGroup]="false"
                            [value]="this.caption" [disabled]="true">
                        </mcn-textarea>
                    </div>
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-date [formGroup]="this.nvdbSpecServiceDocument?.dataProfile.profileForm"
                            placeholder="{{'EndDate' | translate}}" controlName="endDate">
                        </mcn-date>
                        <mcn-error-messages customErrorMsg=""
                            [control]="this.nvdbSpecServiceDocument?.dataProfile.profileForm.controls.endDate">
                        </mcn-error-messages>
                    </div>
                </div>
                <div fxLayout="row Wrap" class="mt-3">
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-input placeholder="{{'SpecificationType' | translate}}" [isFormGroup]="false"
                            [value]="this.specificationType" [disabled]="true">
                        </mcn-input>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="form-footer px-2">
        <div class="button-container">
            <mcn-button-list class="mr-0 fxbtn-gap" (OnClick)="submitNVDBCommoditySpecification($event)"
                [allowedActions]="this.nvdbSpecServiceDocument.dataProfile.actionService.allowedActions"
                [formGroup]="this.nvdbSpecServiceDocument.dataProfile.profileForm">
            </mcn-button-list>
            <mcn-button type="button" (OnClick)="cancel()" cssClass="btn-custom-secondary"
                text="{{'Cancel' | translate}}" class="ml-3">
            </mcn-button>
        </div>
    </div>
</div>
<button matDialogClose class="dialog-close">
    <i class="icon-close"></i>
</button>