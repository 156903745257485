import { Component, OnInit } from '@angular/core';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { NVDBAppliedTariffDataModel } from '../../models/NVDBAppliedTariffDataModel';
import { NVDBHistoricalDataService } from '../../services/nvdbHistoricalData/NVDBHistoricalDataService';
import { SafeUnsubscriber } from '../../../../../app/common/SafeUnsubscriber';
import { ConstantNumber } from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';
import { StorageService } from '../../../../../app/storageService/storageService';

@Component({
  selector: 'nvdb-tariff-history',
  templateUrl: './NvdbAppliedTarrifHistoryComponent.html',
})

export class NvdbAppliedTarrifHistoryComponent extends SafeUnsubscriber implements OnInit {
  public modified: boolean;
  public newFrequecy: boolean;
  public nvdbAppliedTariffDataList: NVDBAppliedTariffDataModel[];
  public nvdbAppliedTariffServiceDocument: any = [];
  private nvdbAppliedTariffData: any;
  public pageCount: number;
  public nvdbAppliedTariffInfo: any;

  constructor(
    private nvdbHistoricalDataService: NVDBHistoricalDataService,
    public webStorage: StorageService,
    public appConfig: McnConfig) {
    super();
  }

  ngOnInit() {
    this.nvdbAppliedTariffServiceDocument = this.nvdbHistoricalDataService.nvdbAppliedTariffServiceDocument;
    this.nvdbAppliedTariffDataList = this.nvdbHistoricalDataService.nvdbAppliedTariffServiceDocument.dataProfile.dataList;
    this.nvdbAppliedTariffInfo = this.nvdbAppliedTariffServiceDocument.dataProfile.dataModel;
    this.nvdbAppliedTariffData = this.nvdbHistoricalDataService.nvdbAppliedTariffServiceDocument;
    if (this.nvdbAppliedTariffData.totalCount > ConstantNumber.IsZero) {
      this.pageCount = this.nvdbAppliedTariffData.totalCount;
    }
  }



}
