<div fxLayout="column" mat-dialog-content class="p-0">
  <div fxLayout="column" fxLayoutAlign="center center" class="state {{ state }} p-4">
    <i class="{{icon}}"></i>
    <h1 class="font-14 text-center">  {{ title }} !</h1>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between center">
    <div>
      <div class="alert-body-content p-4">
        {{ Message }}
      </div>
    </div>
  </div>

  <div *ngIf="this.isButtons" class="button-container pb-4 pt-2" fxLayout="row" fxLayoutAlign="center center">
    <mcn-button type="submit" text="{{ 'OK' | translate }}" cssClass="btn-custom-primary"
              (OnClick)="close(true)"></mcn-button>
    <mcn-button type="button" text="{{ 'Cancel' | translate }}" cssClass="btn-custom-secondary"
                (OnClick)="close(false)"></mcn-button>
  </div>
</div>
<button matDialogClose class="dialog-close">
  <i class="icon-close"></i>
</button>

<!-- Deactive Confirmation Modal Ended -->
