import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { InspectionJourneyDetailsModel } from '../journeyDetails/InspectionJourneyDetailsModel';
import { InvoiceItemModel } from './invoiceItemDetails/InvoiceItemModel';
import { InvoiceRefService } from './InvoiceRefservice';
import { InvoiceModel } from './InvoiceModel';

@Injectable()
export class InvoiceItemOpenResolver implements Resolve<ServiceDocument<InvoiceItemModel>> {
    constructor(private service: InvoiceRefService) { }

    public resolve(): Observable<ServiceDocument<InvoiceItemModel>> {
        return this.service.openInvoiceItem();
    }
}

@Injectable()
export class InvoiceItemListResolver implements Resolve<ServiceDocument<InvoiceItemModel>> {
    constructor(private service: InvoiceRefService) { }
    public resolve(): Observable<ServiceDocument<InvoiceItemModel>> {
        return this.service.listInvoiceItems();
    }
}

@Injectable()
export class InvoiceRefOpenResolver implements Resolve<any> {
    constructor(private service: InvoiceRefService) { }
    public resolve(): Observable<any> {
        return this.service.openRefDetails();
    }
}

@Injectable()
export class JourneyDetailstListResolver implements Resolve<ServiceDocument<InspectionJourneyDetailsModel>> {
    constructor(private service: InvoiceRefService) { }

    public resolve(): Observable<ServiceDocument<InspectionJourneyDetailsModel>> {
        return this.service.listJourneyDetails();
    }
}

@Injectable()
export class BillOfLadingRefDetailsResolver implements Resolve<any> {
    constructor(private service: InvoiceRefService) { }

    public resolve(): Observable<any> {
        return this.service.getBillOfLadingReferenceDetails();
    }
}

@Injectable()
export class InvoiceRefDetailsResolver implements Resolve<any> {
    constructor(private service: InvoiceRefService) { }

    public resolve(): Observable<ServiceDocument<InvoiceModel>> {
        return this.service.openInvoiceReferenceDetails();
    }
}
