import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { McnConfig } from '../../../mcnConfig/McnConfig';


@Injectable()
export class MsgConfigService {

  constructor(
    private http: HttpClient,
    private appConfig: McnConfig) { }


  list(modulename: any = '', pageIndex: any = 1, recordPerPage: any = this.appConfig.appData.pageCount): Observable<any> {
    const httpParams = new HttpParams().set('modulename', (modulename === '' || modulename === null) ? '' : modulename.toString())
      .set('pageIndex', pageIndex.toString()).set('recordPerPage', recordPerPage.toString());
    return this.http.post(this.appConfig.listMessageConfiguration, null, { params: httpParams })
      .pipe(map((response) => response));
  }

  new(): Observable<any> {
    return this.http.get(this.appConfig.newMessageConfiguration)
      .pipe(map((response) => response));
  }

  open(id: number, isDelete: any = false): Observable<any> {
    return this.http.get(this.appConfig.openMessageConfiguration + '?id=' + id, +'&&isDelete=' + isDelete)
      .pipe(map((response) => response));
  }

  checkClaim(claim: any, msgType: any): Observable<any> {
    return this.http.get(this.appConfig.checkClaim + '?claim=' + claim + '&&messageType=' + msgType)
      .pipe(map((response) => response));
  }

  delete(): Observable<any> {
    return this.http.get(this.appConfig.deleteMessage)
      .pipe(map((response) => response));
  }

  submit(response: any): Observable<any> {
    return this.http.post(this.appConfig.submitMessageConfiguration, response)
      .pipe(map((response) => response));
  }

  submitNotification(response: any): Observable<any> {
    return this.http.post(this.appConfig.submitNotificationConfig, response)
      .pipe(map((response) => response));
  }

  listNotification(pageIndex: any = 1, recordPerPage: any = this.appConfig.appData.pageCount): Observable<any> {
    return this.http.get(this.appConfig.listNotificationConfig + '?pageIndex=' + pageIndex + '&recordPerPage=' + recordPerPage)
      .pipe(map((response) => response));
  }

  openNotification(id: number): Observable<any> {
    return this.http.get(this.appConfig.openNotificationConfig + '?id=' + id)
      .pipe(map((response) => response));
  }
}
