import { CommonService, FxContext } from '@agility/frameworkcore';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { StorageService } from '../../../../storageService/storageService';
import { LoginService } from '../../LoginService';
import { PasswordManagementService } from '../PasswordManagementService';
import { ChangePassword } from './changePassword';
import { ValidationService } from '../../../../validations/ValidationService';
import { AccountMessages } from '../../../constant/accountConstant';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { SafeUnsubscriber } from '../../../../common/SafeUnsubscriber';

@Component({
  selector: 'app-changePassword',
  templateUrl: './changePasswordComponent.html',
})

export class ChangePasswordComponent extends SafeUnsubscriber implements OnInit {
  public changePasswordForm: FormGroup;
  public changePasswordFormSubmitted: boolean;
  public changePassword: ChangePassword = new ChangePassword();
  public activeClass: boolean;

  public notSame = false;
  public usersCount: any;
  public usersError = false;
  @ViewChild('confirmPassword') public cnfrmPasswordVar: any;
  @ViewChild('newPassword') public passwordVar: any;
  public bar0: string;
  public bar1: string;
  public bar2: string;
  public bar3: string;
  public bar4: string;

  public force = 0;
  public regex = /[$-/:-?{-~!"^_`\[\]]/g;
  public lowerLetters = false;
  public upperLetters = false;
  public numbers = false;
  public symbols = false;
  public flags = [];
  public passedMatches = 0;
  public colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
  public selectedValue: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public appConfig: McnConfig,
    private fxContext: FxContext,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private passwordManagementService: PasswordManagementService,
    private loginService: LoginService,
    private validationService: ValidationService,
    private commonService: CommonService,
    public storageService: StorageService,
  ) {
    super();
  }

  public measureStrength(p) {
    this.force = 0;
    this.lowerLetters = /[a-z]+/.test(p);
    this.upperLetters = /[A-Z]+/.test(p);
    this.numbers = /[0-9]+/.test(p);
    this.symbols = this.regex.test(p);
    this.flags = [this.lowerLetters, this.upperLetters, this.numbers, this.symbols];

    this.passedMatches = 0;

    for (let i = 0; i < this.flags.length; i++) {
      this.passedMatches += this.flags[i] === true ? 1 : 0;
    }
    this.force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    this.force += this.passedMatches * 10;

    // penality (short password)

    this.force = (p.length <= 6) ? Math.min(this.force, 10) : this.force;

    // penality (poor variety of characters)

    this.force = (this.passedMatches === 1) ? Math.min(this.force, 10) : this.force;
    this.force = (this.passedMatches === 2) ? Math.min(this.force, 20) : this.force;
    this.force = (this.passedMatches === 3) ? Math.min(this.force, 40) : this.force;

    return this.force;
  }

  public getColor(s) {
    let idx = 0;
    if (s <= 10) {
      idx = 0;
    } else if (s <= 20) {
      idx = 1;
    } else if (s <= 30) {
      idx = 2;
    } else if (s <= 40) {
      idx = 3;
    } else {
      idx = 4;
    }
    return {
      idx: idx + 1,
      col: this.colors[idx],
    };
  }

  public setBarColors(count, col) {
    for (let n = 0; n < count; n++) {
      this['bar' + n] = col;
    }
  }

  ngOnInit() {
    this.changePassword.emailId = (this.storageService.get('eMailId'));

    this.changePasswordForm = this.fb.group({
      emailId: [null, [Validators.required as any, this.validationService.EmailValidation(), Validators.pattern]],
      currentPassword: [null, [Validators.required as any]],
      newPassword: [null, [Validators.required as any, this.validationService.PasswordValidation(8, 0)]],
      confirmPassword: [null, [Validators.required as any]],
    });
    this.changePasswordForm.controls.emailId.setValue(this.fxContext.userProfile.userName);

    this.changePasswordForm.valueChanges.subscribe((data) => {
      this.setBarColors(5, '#DDD');
      if (data.newPassword) {
        const c = this.getColor(this.measureStrength(data.newPassword));
        this.setBarColors(c.idx, c.col);
      }
    });
  }

  public cnfrmPasskey(val: string) {
    if (val !== '') {
      if (val !== this.changePasswordForm.controls.newPassword.value) {
        this.notSame = true;
      } else {
        this.notSame = false;
      }
    } else {
      this.notSame = false;
    }
  }

  public emailKeyPress() {
    this.usersError = false;
  }

  public validateChangePassword(model: ChangePassword, isValid: boolean) {
    if (this.changePasswordForm.valid) {
      this.passwordManagementService.SubmitChangePassword(model).subscribe
        ((response) => {
          event.preventDefault();
          this.dialogRef.close();
          this.logOut();
        });
    } else {
      Object.keys(this.changePasswordForm.controls).forEach(field => {
        const control = this.changePasswordForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  public cancel() {
    event.preventDefault();
    this.dialogRef.close();
  }

  public logOut() {
    this.activeClass = !this.activeClass;
    this.loginService.logout().subscribe(
      (response) => {
        const result = response;
        if (result) {
          this.fxContext.IsAuthenticated = false;
        }
        // tslint:disable-next-line: comment-format
        //this.commonService.showAlert('Successfully updated..', '', 4500);
        this.commonService.showAlert(this.appConfig.getHardCodeMSg(AccountMessages.SuccessfullyUpdated), '', 4500);
        this.router.navigate(['Account/Login']);
      },
    );
  }
}
