import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TraderProfileService } from './TraderProfileService';
import { RiskIdentificationAndAnalysisSharedService } from '../../services/sharedServices/RiskIdentificationAndAnalysisSharedService';
import { StorageService } from '../../../../storageService/storageService';

@Injectable()
export class WhoTheTraderIsResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
    private webStorage: StorageService) {
    const traderId = this.webStorage.get('traderId');
    if (traderId != null) {
      this.riskIdentificationAndAnalysisSharedService.sharingData.traderId = traderId;
    }
  }
  public resolve() {
    return this.traderProfileService.whoTheTraderIs(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class KeySuppliersBuyersResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,) {
  }
  public resolve() {
    return this.traderProfileService.keySuppliersBuyers(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class LocalPurchasesResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.localPurchases(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class CostOfExemptionResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.costOfExemption(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class QuotasByCbraResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.quotasByCbra(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class BusinessVolumeResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.businessVolume(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class ComplianceHistoryResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.complianceHistory(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class GoodsConfiscatedResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.goodsConfiscated(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class AssocPartiesResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.assocParties(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class TraderOrgsListResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,) {
  }
  public resolve() {
    return this.traderProfileService.listOrgs();
  }
}

/////////////////////// Charts /////////////////////////////////

@Injectable()
export class ImportsBarChartResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.importsBarChart(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class ExportsBarChartResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.exportsBarChart(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class ImportsLineChartResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.importsLineChart(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}

@Injectable()
export class ExportsLineChartResolver implements Resolve<any> {
  constructor(
    private traderProfileService: TraderProfileService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
  }
  public resolve() {
    return this.traderProfileService.exportsLineChart(this.riskIdentificationAndAnalysisSharedService.sharingData.traderId);
  }
}
