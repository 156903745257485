import { NgModule } from '@angular/core';
import { GoogleChart } from './dashboard/angular2-google-chart.directive';
import { userDashboardComponent } from './dashboard/userDashboard/userDashboardComponent';
import { stackedChartComponent } from './dashboard/userDashboard/stackedChart/stackedChartComponent';
import { pieChartComponent } from './dashboard/userDashboard/pieChart/pieChartComponent';
import { guageChartComponent } from './dashboard/userDashboard/guageChart/guageChartComponent';
import { horizontalBarChartComponent } from './dashboard/userDashboard/horizontalBarChart/horizontalBarChartComponent';
import { lineChartComponent } from './dashboard/userDashboard/lineChart/lineChartComponent';
import { mapChartComponent } from './dashboard/userDashboard/mapChart/mapChartComponent';
import { areaChartComponent } from './dashboard/userDashboard/areaChart/areaChartComponent';
import { bubbleChartComponent } from './dashboard/userDashboard/bubbleBarChart/bubbleChartComponent';
import { candlestickChartComponent } from './dashboard/userDashboard/candleStickChart/candlestickChartComponent';
import { scatterChartComponent } from './dashboard/userDashboard/scatterBarChart/scatterChartComponent';
import { TrackingDashboardComponent } from './dashboard/trackingDashborad/TrackingDashboardComponent';

@NgModule({
    declarations: [
        GoogleChart
    ],
    exports: [
        GoogleChart
    ]
})

export class McnChartModule {
}
