<div class="container pt-4" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}" fxLayoutAlign="center center">
  <div fxFlex="85" class="card-grey-top-border manifest-info-wrapper mb-4 mb-lg-0">
    <div fxLayout="row wrap" class="border-bottom">
      <div fxLayout="column wrap" fxFlex="100">
        <h2 class="main-title"><span> {{'NotificationConfiguration' | translate}}</span></h2>
      </div>
    </div>
    <div class="container user-registration-wrapper nopl-pr" fxLayout="column">
      <div fxLayout="column" fxFlex="90">
        <div fxFlex.gt-sm="100" fxFlex.lt-sm="100" class="mb-4 mt-3 overflow-hidden">
          <div fxLayout="column">
            <form [formGroup]="this.actionConfigForm" novalidate (ngSubmit)="submit($event)">
              <div fxLayout="column wrap">
                <div fxLayout="row wrap">
                  <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 ps-rel">
                    <mcn-select class="astric-star" placeholder="{{'Role' | translate }}" [formGroup]="this.actionConfigForm" optionKey="id"
                                optionValue="name" [isList]="true" [isInbox]="true"
                                controlName="roleId" [apiUrl]="this.appConfig.getModuleRolesApiUrl"></mcn-select>
                    <mcn-error-messages [control]="this.actionConfigForm.controls.roleId"></mcn-error-messages>
                  </div>
                </div>
                <div fxLayout="row wrap" class="ml-2">
                  <p class="mb-1 mt-2"><span>{{'ActionType' | translate }} <span class="star-icon">*</span></span></p>
                  <div fxFlex="100" class="p-2 ps-rel">
                    <mcn-checkbox [formGroup]="this.actionConfigForm" ngDefaultControl controlName="isRoleBased" text="{{'RoleBased' | translate }}"></mcn-checkbox>
                    <mcn-checkbox [formGroup]="this.actionConfigForm" class="ml-2" ngDefaultControl controlName="isOrganizationBased" text="{{'OrganizationBased' | translate }}"></mcn-checkbox>
                    <mcn-checkbox [formGroup]="this.actionConfigForm" class="ml-2" ngDefaultControl controlName="isEconomicBased" text="{{'IsCustomStationBased' | translate }}"></mcn-checkbox>
                  </div>
                </div>
                <div class="orange-grey-theme" fxLayoutAlign="end end">
                  <div class="button-container ps-rel">
                    <mcn-button type="submit" text="{{'Create' | translate}}" cssClass="btn-custom-primary" *ngIf="!this.isEdit"></mcn-button>
                    <mcn-button type="submit" text="{{'Save' | translate}}" cssClass="btn-custom-primary" *ngIf="this.isEdit"></mcn-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div>
            <mat-expansion-panel class="user-individual-card p-1 my-3" (opened)="getConfigList($event)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div fxLayout="row wrap" class="mt-2">
                    <div fxLayout="row wrap" fxFlex="100">
                      <i class="icon-list-view mr-2"></i>
                      <h2 class="main-title"><span>{{'NotificationCofigList' | translate }}</span></h2>
                    </div>
                  </div>
                </mat-panel-title>
                <mat-panel-description>
                  <mat-icon class="accordion-arrow"></mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <section class="table-section responsive-material-table">
                <mat-table #table [dataSource]="dataSource">
                  <ng-container cdkColumnDef="role">
                    <mat-header-cell *cdkHeaderCellDef>{{'Role' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'Role' | translate }}</span>
                      <span style="word-wrap: break-word;"> {{row.roleName}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="roleBased">
                    <mat-header-cell *cdkHeaderCellDef>{{'RoleBased' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'RoleBased' | translate }}</span>
                      <span style="word-wrap: break-word;" class="preview-content"> {{row.isRoleBased === true ? "Yes" : "No"}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="organizationBased">
                    <mat-header-cell *cdkHeaderCellDef>{{'OrganizationBased' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'OrganizationBased' | translate }}</span>
                      <span style="word-wrap: break-word;" class="preview-content"> {{row.isOrganizationBased === true ? "Yes" : "No"}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="isCustomStationBased">
                    <mat-header-cell *cdkHeaderCellDef>{{'IsCustomStationBased' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'IsCustomStationBased' | translate }}</span>
                      <span style="word-wrap: break-word;" class="preview-content"> {{row.isEconomicBased === true ? "Yes" : "No"}} </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="icons">
                    <mat-header-cell *cdkHeaderCellDef>{{'Action' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <i class="icon-edit" (click)="editMsgConfig(row.notificationRoleId)" appCustomTooltip title="{{'Edit' | translate }}"></i>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
              </section>
              <mcn-pagination [totalCount]="this.pageCount" (OnChange)="getMessageConfigList($event)"></mcn-pagination>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
