<div fxLayout="column wrap" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}" >
  <div fxLayout="column wrap">
    <div fxLayout="row wrap">
      <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="center center">
        <h2 class="main-title pb-0"><span>{{'UploadForm'| translate}}</span></h2>
      </div>
    </div>
  </div>
  <div fxLayout="column">
    <div class="mode-of-transport">
      <form [formGroup]="upldCrewEffectsDocs" novalidate>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="pt-2 position-relative">
          <div class="p-2">
            <mcn-fileUpload labelName="{{'FileUpload' | translate }}" [formGroup]="upldCrewEffectsDocs"
                            actionLabelName="{{'Browse' | translate }}" [isExcelUpload]="true" (OnChange)="fileChange($event)">
            </mcn-fileUpload>
          </div>
          <div fxFlex="100" class="p-2">
            <mcn-input [formGroup]="upldCrewEffectsDocs" ngDefaultControl controlName="filePath"
                       placeholder="{{'ExcelFile' | translate}}" class="astric-star"></mcn-input>
            <mcn-error-messages [control]="upldCrewEffectsDocs.controls.filePath"
                                class="error customstyle" customErrorMsg="Excel File is "></mcn-error-messages>
          </div>
        </div>
        <div class="orange-grey-theme">
          <div class="button-container">
            <mcn-button type="button" text="Upload" (click)="uploadExcelFile()" cssClass="btn-custom-primary"></mcn-button>
            <button type="button" mat-raised-button matDialogClose class="btn-custom-secondary" (click)="Close()">
              {{'Cancel'| translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<button matDialogClose class="dialog-close">
  <i class="icon-close"></i>
</button>






