import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/AppModule';
import { AppSettings } from './app/app.settings';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}
export async function http<T>(request: RequestInfo): Promise<T> {
  const response = await fetch(request);
  const body = await response.json();
  return body;
}

(async () => {
  let buildTimestamp = new Date().getTime() + '';
  if (environment.production) {
    buildTimestamp = '09:15:00';
  }
  const response = await http<AppSettings>(
    (environment.production
      ? './assets/config/app-settings.json?t='
      : './assets/config/app-settings.dev.json?t=') + buildTimestamp
  );
  environment.appSettings = {
    ...new AppSettings(),
    ...response,
  };
  if (localStorage && localStorage.getItem('lang_code')) {
    environment.appSettings.defaultI18nLang = localStorage.getItem('lang_code') + '';
  }
  platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)     
    .catch((err) => console.error(err));
})(); 