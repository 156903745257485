﻿import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeSpace' })

export class RemoveSpace implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.replace(/\s/g, '');
    }
  }
}
