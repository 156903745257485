import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { WorkflowAction } from '@agility/frameworkcore';

@Component({
  selector: 'mcn-button-list',
  template: `<button *ngFor="let action of allowedActions" id="btn_{{action.actionName | removeSpace}}" type="button" class="matbutton btn-custom-primary {{cssClass}}" mat-button
                (click)="_setAction(action.transitionClaim,action.actionName)"> <img [src]="action.image" *ngIf="action.image">
                <span *ngIf="!action.image">{{action.actionName|translate}}</span></button>`
})
export class McnButtonListComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() allowedActions: WorkflowAction[];
  @Input() actionId: string;
  @Input() actionName: string;
  @Input() cssClass: string;
  @Output() OnClick = new EventEmitter<any>();
  private _showAction: boolean;
  private _actionName: string;
  private _self: McnButtonListComponent = this;

  ngOnInit(): void {
    const _self = this;
    for (let index = 0; index < _self.allowedActions.length; index++) {
      if (_self.allowedActions[index].actionId === _self.actionId) {
        _self._showAction = true;
        _self._actionName = (this.actionName != null && this.actionName != undefined) ? this.actionName : _self.allowedActions[index].actionName;
      }
    }
  }

  private _setAction(id: string, actionName: string) {
    const _self = this;
    if (_self.formGroup != null && _self.formGroup != undefined) {
      _self.formGroup.controls['currentActionId'].setValue(id);
      _self.formGroup.addControl('actionName', new FormControl());
      _self.formGroup.controls['actionName'].setValue(actionName);

    }
    _self.OnClick.emit(id);
  }
}
