import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { StorageService } from '../../../../storageService/storageService';
import { PasswordManagementService } from '../PasswordManagementService';
import { ForgotPWD } from './forgotpwd';
import { ValidationService } from '../../../../validations/ValidationService';
import { ToasterService } from '../../../../toastrService/toasterService';
import { SafeUnsubscriber } from '../../../../common/SafeUnsubscriber';

@Component({
  selector: 'app-forgot-pwd',
  templateUrl: './forgotPwdComponent.html',
})

export class ForgotPWDComponent extends SafeUnsubscriber implements OnInit {
  public forgotPWDForm: FormGroup;
  public forgotPWDFormSubmitted: boolean;
  public forgotPWD: ForgotPWD = new ForgotPWD();
  public emailId: any;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ForgotPWDComponent>,
    private passwordManagementService: PasswordManagementService,
    public storageService: StorageService,
    private toasterService: ToasterService,
    private validationService: ValidationService,
  ) {
    super();
  }
  ngOnInit() {
    this.forgotPWDForm = this.fb.group({
      emailId: [this.forgotPWD.emailId, [Validators.required, this.validationService.EmailValidation()]],
    });
  }

  public validateForgotPWD(model: ForgotPWD, isValid: boolean) {
    if (isValid) {
      this.passwordManagementService.SendForgotPasswordLink(model.emailId).subscribe(
        (response) => {
          if (response === true) {
            this.toasterService.Success('');
            this.dialogRef.close();
          } else {
            this.toasterService.Error('');
          }
        });
    } else {
      Object.keys(this.forgotPWDForm.controls).map(
        (controlName) => this.forgotPWDForm.controls[controlName]).forEach(
          (control) => { control.markAsTouched(); },
        );
    }
  }
  public cancel() {
    this.dialogRef.close();
  }
}
