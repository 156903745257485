import { Injectable } from '@angular/core';

export interface MyData {
  invoiceId: number;
  declarationId: number;
  invoiceNumber: number;
  editStatus: boolean;
  consigneeId: number;
  declarationTypeId: number;
  status: string;
  declarationPermitId: number;
  customStationId?: number;
  declarationCategory: number;
  referenceDeclarationId: number;
  declarationBillTypeId: number;
  functionalUnitId: number;
  invoiceAmount: number;
  expression: string;
  aliasName: string;
  passengerAssessmentId: number;
  declarationNumber: string;
  declarationDate: any;
  drDeclarationId: number;
  isFUHistory: boolean;
  identityNumber: string;
  transactionType: string;
  sampleRequestId: number;
  billOfLadingId: number;
  sampleRequestNumber: string;
  suretyTransactionId: number;
  sampleRequest: string;
  organizationId: number;
  item: boolean;
  assessmentId: number;
  inspectionInstructionId: number;
  inspectionId: number;
  motorVehiclePriceDataId: number;
  assessmentRequestId: number;
  ownercsId: number;
  isShowDocumnetReview: boolean;
  declarationType: string;
}
@Injectable()
export class AssessmentSharedService {
  sharingData: MyData = {
    invoiceId: 0, declarationId: 0, invoiceNumber: 0, assessmentRequestId: 0, editStatus: false,
    consigneeId: 0, declarationTypeId: 0, billOfLadingId: 0, status: null,
    declarationPermitId: 0, customStationId: 0, declarationCategory: 0, referenceDeclarationId: 0,
    declarationBillTypeId: 0, functionalUnitId: 0,
    invoiceAmount: 0, aliasName: '', expression: '', passengerAssessmentId: 0, declarationNumber: '',
    declarationDate: null, drDeclarationId: 0,
    isFUHistory: false, identityNumber: null, transactionType: '', organizationId: 0,
    inspectionInstructionId: 0, inspectionId: 0,
    sampleRequestId: 0, suretyTransactionId: 0, sampleRequestNumber: '', sampleRequest: '',
    item: false, assessmentId: 0,
    motorVehiclePriceDataId: 0, ownercsId: 0, isShowDocumnetReview: false, declarationType: ''
  };
  constructor() {

    // empty
  }
}

export enum DeclarationConstants {
}
