export enum Ruling {
  ValuationAppeal = 'Appeal For Valuation',
  ClassificationAppeal = 'Appeal For Classification'
}

export enum RulingTypes {
  Classification = 'C',
  Valuation = 'V',
  Ruling = 'R',
  C = 'C',
  R = 'R',
  V = 'V'
}

export enum CommonTypes {
  TARIFFVEHICLETYPES = 'TARIFFVEHICLETYPES',
  VALUATIONMETHOD = 'VALUATIONMETHOD',
  ClassificationAppealModel = 'ClassificationAppealModel',
  ValuationAppealModel = 'ValuationAppealModel',
  Classification = 'Classification',
  Valuation = 'Valuation'
}

export enum StepNames {
  AssignToOfficer = 'AssignToOfficer',
  GenerateBill = 'GenerateBill',
  Closed = 'Closed',
}

export enum PaymentSourceTypes {
  RulingsFee = 'BILLREFERENCETYPES_RUL',
  RulingsSampleAnalysisFee = 'BILLREFERENCETYPES_RULSAMANAFEE'
}

export enum Models {
  ListRulingModel = 'ListRulingModel',
  InvoiceItemModel = 'InvoiceItemModel'
}

export enum ModuleNames {
  Tariffruling = 'Tariffruling',
  Common = 'Common',
  Declaration = 'Declaration'
}

export enum RoleNames {
  Importer = 'Importer',
  Trader = 'Trader',
  CRSECRETARIAT = 'CRSECRETARIAT',
  VALUATIONDESKOFFICER = 'VALUATIONDESKOFFICER',
  CLASSIFICATIONDESKOFFICER = 'CLASSIFICATIONDESKOFFICER',
  DGCUSTOMS = 'DGCUSTOMS',
  LABORATORY = 'LABORATORY',
  IMPORTERN = 'IMPORTER'
}

export enum StateNames {
  Start = 'Start',
  Created = 'Created',
  CreatedToSubmitRulings = 'CreatedToSubmitRulings',
  ModifiedToSubmitRulings = 'ModifiedToSubmitRulings',
  Assign = 'Assign',
  Assigned = 'Assigned',
  ApprovedToCertificateGeneration = 'ApprovedToCertificateGeneration',
  SentToLab = 'Sent to Lab',
  LabReportSubmitted = 'Lab Report Submitted',
  Acknowledged = 'Acknowledged',
  Approved = 'Approved',
  Modified = 'Modified',
  Submitted = 'Submitted',
  Closed = 'Closed',
  Issued = 'Issued',
  Withdrawn = 'Withdrawn',
  CRSReturnedForCorrection = 'CRS Returned For Correction',
  ReturnedForCorrection = 'Returned For Correction',
  SenttoLabToLabReportSubmitted = 'SenttoLabToLabReportSubmitted',
  CertificateGenerated = 'Certificate Generated',
  CreatedToSubmittedClassificationAppeal = 'CreatedToSubmittedClassificationAppeal',
  ModifiedToSubmittedClassificationAppeal = 'ModifiedToSubmittedClassificationAppeal',
  SubmittedToCRSReturnedForCorrectionRulings = 'SubmittedToCRSReturnedForCorrectionRulings',
  SubmittedToAcknowledgedRulings = 'SubmittedToAcknowledgedRulings',
  CrsReturnedForCorrection = 'CRS Returned For Correction',
  CreatedToSubmittedValuationAppeal = 'CreatedToSubmittedValuationAppeal',
  ModifiedToSubmittedValuationAppeal = 'ModifiedToSubmittedValuationAppeal',
  CreatedToModifiedValuationAppeal = 'CreatedToModifiedValuationAppeal',
  SaveModifiedValuationAppeal = 'SaveModifiedValuationAppeal',
  SubmittedToReturnedForCorrectionValuationAppeal = 'SubmittedToReturnedForCorrectionValuationAppeal',
  AcknowledgedToWithdrawRulings = 'AcknowledgedToWithdrawRulings',
  AssignedToWithdrawnRulings = 'AssignedToWithdrawnRulings',
  CRSReturnedForCorrectionToWithdrawnRulings = 'CRSReturnedForCorrectionToWithdrawnRulings',
  DGReturnedForCorrectionToWithdrawnRulings = 'DGReturnedForCorrectionToWithdrawnRulings',
  DraftReportSubmittedToWithdrawnRulings = 'DraftReportSubmittedToWithdrawnRulings',
  LabReportSubmittedToWithdrawnRulings = 'LabReportSubmittedToWithdrawnRulings',
  SentToLabToWithdrawnRulings = 'SentToLabToWithdrawnRulings',
  SubmittedToWithdrawnRulings = 'SubmittedToWithdrawnRulings',
  Save = 'Save',
  AcknowledgedToAssignRuling = 'AcknowledgedToAssignRuling',
  ReportSubmittedToAssign = 'ReportSubmittedToAssign',
  LabReportSubmittedToAssigned = 'LabReportSubmittedToAssigned',
  CRSReturnedForCorrectionToSubmittedRuling = 'CRSReturnedForCorrectionToSubmittedRuling',
  SubmittedToReturnedForCorrection = 'SubmittedToReturnedForCorrection',
  DraftReportSubmittedToDGReturnedForCorrection = 'DraftReportSubmittedToDGReturnedForCorrection',
  AcknowledgedToSenttoLab = 'AcknowledgedToSenttoLab',
  DraftReportSubmitted = 'Draft Report Submitted',
  SenttoLab = 'Sent to Lab',
  ApprovedToCerGenClassificationAppeal = 'ApprovedToCertificateGeneratedClassificationAppeal',

}

export enum RulingMessages {
  UploadSuccess = 'UploadSuccess',
  SubmitReportMessage = 'SubmitReportMessage',
  DeleteDocsMsg = 'DeleteDocsMsg',
  FilterMsg = 'CommonFilterMessage',
  ExpertBillCollected = 'ExpertBillCollected',
  RulingBillCollected = 'RulingBillCollected',
  GenerateBillMsg = 'GenerateBillMsg',
  Delete = 'delete',
  WarnIcon = 'warn-icon',
  DeleteDocumentMsg = 'DeleteDocumentMsg',
  DeleteTitle = 'Delete',
  Error = 'Error',
  UploadDocsErrorMsg = 'UploadDocsErrorMsg',
  SampleRequiredErrorMsg = 'SampleRequiredErrorMsg',
  AppealItemMsg = 'AppealItemMsg',
  AppealInvoiceItemMsg = 'AppealInvoiceItemMsg',
  remarks = 'Remarks should be mandatory',
  itemcheckbox = 'itemcheckbox',
  acceptdisclaimer = 'acceptdisclaimer',
  acceptFormDeclaration = 'acceptFormDeclaration',
  valuationMsg = 'ValuationMsg',
  associateMsg = 'AssociateMsg',
  AcknowledgeMsg = 'AcknowledgeMsg',
  SelValuationdeletedsuccess = 'selected Valuation Appeal deleted successfully.',
  InterlServerError = 'Internal Server Error.Please Try Again Later.',
  DelSucessRulingApp = 'selected Ruling Application deleted successfully.',
  DeleteClassificationAppeal = 'DeleteClassificationAppeal',
  Confirm = 'Confirm',
  ConfirmDelete = 'ConfirmDelete',
  DeleteRulingApplication = 'DeleteRulingApplication',
  ApplicationNumber = ' Application Number ?',
  DeleteValuationAppeal = 'DeleteValuationAppeal',
  RequestNumber = 'Request Number ?',
  rulings = 'rulings',
  Rulings = 'Rulings',
  appeal = 'appeal',
  Appeal = 'Appeal'
}

export enum ValuationAppealCategory {
  ADVANCERULINGSVALUATION = 'APPEALCATEGORYFORVALUATION_ADVRULVAL',
  DECLARATION = 'APPEALCATEGORYFORVALUATION_DEC',
  MOTORVEHICLEVALUATIONAPPLICATION = 'APPEALCATEGORYFORVALUATION_MOTVEHVALAPP',
}

export enum TariffRulingUploadDocConstants {
  ClassificationAppeal = 'ClassificationAppeal',
  ValuationAppeal = 'ValuationAppeal',
  Ruling = 'Ruling'
}

export enum ProfileNames {
  ClassificationAppealModel = 'ClassificationAppealModel'
}

export enum EventName {
  clickEvent = 'click'
}

export enum CategoryType {
  AdvanceRulingClassificaton = 'ADVANCE RULINGS CLASSIFICATION',
  DECLARATION = 'DECLARATION'
}

export enum AlertMessages {
  delete = 'delete',
  WarnIcon = 'warn-icon'
}


