import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginHomeComponent } from './LoginHomeComponent';
import { LoginComponent } from './LoginComponent';
import { ForgotPasswordActivationComponent } from './passwordManagement/forgotPasswordActivation/forgotPasswordActivationComponent';
import { McnControlsModule } from '../../mcnControls/McnControlsModule';
import { ActivatedSuccessComponent } from '../activationProcess/activatedSuccess/ActivatedSuccessComponent';
import { ActivationFailComponent } from '../activationProcess/activationFail/ActivationFailComponent';
import { ProfileDetailsComponent } from '../registration/profile/ProfileDetailsComponent';

const accountRoutes: Routes = [
  {
    path: 'Account',
    data: {
      title: 'Microclear',
    },
    children: [
      { path: 'ForgotPassword', component: ForgotPasswordActivationComponent, data: { title: 'Forgot Password' } },
      { path: 'Login', component: LoginComponent, data: { title: 'Login' } },
      { path: 'LoginHome', component: LoginHomeComponent, data: { title: 'Login' } },
      { path: 'activated-success', component: ActivatedSuccessComponent, data: { title: 'ActivationSuccess' } },
      { path: 'activated-fail', component: ActivationFailComponent, data: { title: 'ActivationFail' } },
      { path: 'user-profile', component: ProfileDetailsComponent, data: { title: 'MyAccount', } },
    ],
  },

];

@NgModule({
  imports: [
    RouterModule.forChild(accountRoutes),
    McnControlsModule,
  ]
})
export class LoginRoutingModule { }
