import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { ServiceDocument } from "@agility/frameworkcore";
import { McnConfig } from "../../../mcnConfig/McnConfig";
import { PermitConfig } from "../../../mcnConfig/PermitConfig";
import { CommonMessages } from "../../../common/Constants";
import { ToasterService } from "../../../toastrService/toasterService";
import { StorageService } from "../../../storageService/storageService";
import { SupportingDocModel } from "../../models/supportingDocModels/SupportingDocModel";
import { catchError, map } from "rxjs/operators";
import { PermitSharedService } from "../../sharedService/PermitSharedService";

@Injectable()
export class SupportingDocService {
  public serviceDocument: ServiceDocument<SupportingDocModel> =
    new ServiceDocument<SupportingDocModel>();
  public headersDeclService = new HttpHeaders({
    "Content-Type": "application/json",
  });
  constructor(
    private http: HttpClient,
    public storageService: StorageService,
    private toastrService: ToasterService,
    public permitSharedService: PermitSharedService,
    public appConfig: McnConfig,
    public permitConfig: PermitConfig
  ) {}

  public handleError() {
    return this.toastrService.Error(
      this.appConfig.getHardCodeMSg(
        CommonMessages.InternalServerErrorPleaseTryAgainLater
      )
    );
  }

  public newSupportingDoc(): Observable<ServiceDocument<SupportingDocModel>> {
    return this.serviceDocument.new(this.permitConfig.newSupportingDoc).pipe(
      map((response) => response),
      catchError(() =>
        throwError({
          msg: this.handleError(),
          ngNavigationCancelingError: true,
        })
      )
    );
  }

  public openSupportingDoc(
    id: number
  ): Observable<ServiceDocument<SupportingDocModel>> {
    return this.serviceDocument
      .open(
        this.permitConfig.openSupportingDoc,
        new HttpParams().set("id", id.toString())
      )
      .pipe(
        map((response) => response),
        catchError(() =>
          throwError({
            msg: this.handleError(),
            ngNavigationCancelingError: true,
          })
        )
      );
  }

  public submitSupportingDocData(): Observable<any> {
    return this.serviceDocument
      .submit(this.permitConfig.submitSupportingDoc)
      .pipe(
        map((response) => response),
        catchError(() =>
          throwError({
            msg: this.handleError(),
            ngNavigationCancelingError: true,
          })
        )
      );
  }

  public listSupportingDoc(
    expression: string = "",
    aliasName: string = "",
    pageIndex: any = 1,
    recordPerPage: any = this.appConfig.appData.pageCount,
    orderIndex: any = 1,
    isDescending: any = true
  ): Observable<ServiceDocument<SupportingDocModel>> {
    const supportingDocListModel = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending,
    };
    return this.serviceDocument
      .list(this.permitConfig.listSupportingDoc, supportingDocListModel)
      .pipe(
        map((response) => response),
        catchError(() =>
          throwError({
            msg: this.handleError(),
            ngNavigationCancelingError: true,
          })
        )
      );
  }

  public deleteSupportingDoc(id: any): Observable<any> {
    return this.http
      .post(this.permitConfig.deleteSupportingDoc, id, {
        headers: this.headersDeclService,
      })
      .pipe(
        map((response) => response),
        catchError(() =>
          throwError({
            msg: this.handleError(),
            ngNavigationCancelingError: true,
          })
        )
      );
  }

  public checkSupportingDoc(
    documentCode: string,
    documentId?: number
  ): Observable<any> {
    documentId = documentId === undefined ? 0 : documentId;
    return this.http
      .get(
        this.permitConfig.checkSupportingDoc +
          "?documentCode=" +
          documentCode +
          "&documentId=" +
          documentId
      )
      .map((response) => response)
      .pipe(
        map((response) => response),
        catchError((res) =>
          throwError({
            msg: this.handleError(),
            ngNavigationCancelingError: true,
          })
        )
      );
  }

  public filterPermitMstSuppDocsData(
    expression: any,
    aliasName: any,
    pageIndex: any = 1,
    recordPerPage: any = this.appConfig.appData.associatePageCount,
    associatedDocIds: any = [],
    applicableFor?: any,
    orderIndex: any = 1,
    isDescending: any = true
  ): Observable<ServiceDocument<SupportingDocModel>> {
    const associationProcessModel = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      id: this.permitSharedService.sharingData.permitConfigurationId,
      associatedIds: associatedDocIds,
      applicableFor: applicableFor === true ? 1 : 2,
      orderIndex: orderIndex,
      isDescending: isDescending,
    };
    return this.serviceDocument
      .list(
        this.permitConfig.getPermitMasterSupportingData,
        associationProcessModel
      )
      .pipe(
        map((response) => response),
        catchError(() =>
          throwError({
            msg: this.handleError(),
            ngNavigationCancelingError: true,
          })
        )
      );
  }
}
