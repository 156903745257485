<div class="container" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
  <div fxLayout="column" mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="center center">
      <h3>{{ returnOrRejectTitle | translate }}</h3>
      <i class="star-heading-ul"></i>
    </div>
    <div fxLayout="column">
      <form [formGroup]="returnForCorrectionForm">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <div fxFlex="50" fxFlex.gt-xs="50" class="astric-star p-2 position-relative">
            <mcn-select optionKey="typeId" optionValue="name" [formGroup]="returnForCorrectionForm"
                        controlName="reasonId" [apiUrl]="this.reasonTypeUrl"
                        placeholder="{{ 'Reason' | translate }}" class="fx-selectBox astric-star"
                        optionParameterKey="typeName" [optionParameterValue]="this.reason"
                        [moduleName]="this.moduleName">
            </mcn-select>
            <mcn-error-messages [control]="returnForCorrectionForm.controls.reasonId"
                                customErrorMsg="{{'ReasonIs'| translate}}"></mcn-error-messages>
          </div>
          <div fxFlex="50" fxFlex.gt.xs="50" class="astric-star p-2 position-relative">
            <mcn-textarea ngDefaultControl placeholder="{{ 'Remarks'  | translate }}"
                          controlName="remarks" [formGroup]="returnForCorrectionForm"></mcn-textarea>
            <mcn-error-messages [control]="returnForCorrectionForm.controls.remarks"
                                customErrorMsg="{{'RemarksIs'| translate}}"></mcn-error-messages>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="form-footer position-relative" fxLayout="row wrap" fxLayoutAlign="end center">
    <mcn-button type="submit" (click)="submit()" cssClass="btn-custom-primary" style="margin-right:1.4rem"
                text="{{ 'Submit' | translate }}">
    </mcn-button>
    <mcn-button type="button" cssClass="btn-custom-secondary" text="{{ 'Cancel' | translate }}"
                (click)="cancelCorrection()">
    </mcn-button>
  </div>
</div>
<button matDialogClose class="dialog-close">
  <i class="icon-close"></i>
</button>
<!-- Cancel Journey Ends -->
