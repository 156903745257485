import { Injectable, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class StorageService implements OnInit {
  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit() {
  }

  set(key: any, value: any) {
    this.localStorageService.store(key, value);
  }

  get(key: any) {
    return this.localStorageService.retrieve(key);
  }

  remove(key: any) {
    this.localStorageService.clear(key);
  }

  removeAll() {
    this.localStorageService.clear();
  }
}
