import { ProfileModel } from '@agility/frameworkcore';

export class JourneyInfo extends ProfileModel {
  public journeyId: number;
  public journeyType: string;
  public journeyNumber: string;
  public carrierType?: number;
  public carrierId?: number;
  public agentId?: number;
  public captainName: string;
  public shipName: string;
  public shipCallNo: string;
  public vesselId?: number;
  public originPortId?: number;
  public arrivalPortId?: number;
  public lastPortOfCallId?: number;
  public nextPortOfCallId?: number;
  public entryCustomsStationId?: number;
  public exitCustomsStationId?: number;
  public dateOfShipment?: Date;
  public expectedArrivalDate?: Date;
  public expectedDepartureDate?: Date;
  public arrivalDate?: Date;
  public remarks: string;
  public cancellationReasonId?: number;
  public berth: string;
  public noOfContainersManifested?: number;
  public noOfContainersLanded?: number;
  public noOfContainersLoaded?: number;
  public refJourneyId?: number;
  public berthingDateAndTime?: Date;
  public arrivalBerth: string;
  public arrivalDraught?: number;
  public shipClearanceRefNo: string;
  public actualBerth: string;
  public portofDischargeId?: number;
  public entryPointId: string;
  public locationTypeId?: number;
  public assignedTo?: number;
  public assignedBy: string;
  public assignedFromStateId: string;
  public lastPortOfCallATA?: Date;
  public lastPortOfCallATD?: Date;
  public lastPortOfCallVoyageNo: string;
  public lastPortOfCallISPS: string;
  public lastPortOfCallPurposeOfCall?: number;
  public countryOfResidenceOfSO?: number;
  public voyageNo: string;
  public departureVoyageNo: string;
  public draughtPortSideForward?: number;
  public draughtPortSideAft?: number;
  public draughtStarboardForward?: number;
  public berthAlongSide: string;
  public locationName: string;
  public cargoOperation: string;
  public importedContainerCount?: number;
  public inboundServiceLane: string;
  public outboundServiceLane: string;
  public importedGenCargoType?: number;
  public exportGenCargoWeight?: number;
  public exportGenCargoWeightUOM?: number;
  public cargoLoadingRemarks: string;
  public cargoDischargeRemarks: string;
  public freshWaterReq: string;
  public freshWaterReqQuantity?: number;
  public freshWaterReqQuantityUOM?: number;
  public portOpCode: string;
  public portLedgerACNO: string;
  public draughtStarboardAFT?: number;
  public isSimplifiedCargo: string;
  public assignedToUId?: number;
  public assignedByUId?: number;
  public assignedDate?: Date;
  public customStationId?: number;
  public submittedDate?: Date;
  public draughtPortSideAftUOM?: number;
  public draughtPortSideForwardUOM?: number;
  public draughtStarboardAFTUOM?: number;
  public draughtStarboardForwardUOM?: number;
  public arrivalDraughtUOM?: number;
  public tugBoatVesselId?: number;
  public tugBoatVesselSCN: string;
  public ownerCSId?: number;
  public ownerLocId?: number;
  public agentname: string;
  public assignedToName: string;
  public originPort?: any;
  public arrivalPort?: any;
  public lastPortOfCall?: any;
  public nextPortOfCall?: any;
  public entryCustomsStation?: any;
  public exitCustomsStation?: any;
  public vessel?: any;
  public volumeUnit?: any;
  public bargeDraught?: any;
  public countryofSO?: any;
  public cargoWeightUOM?: any;
  public manifestModel: any;
  public importJourneyNumber: string;
  public flightNumber: string;
  public routeNumber: string;
  public railRouteNo: string;
  public carrierName: string;
  public airFlightNumber: string;
  public customStationNumber: string;
  public carrierTypeName: string;
  public workFlowStatus: string;
  public purposeOfCall: number;
  public portDetails: any;
  public departureDate: Date;
  public driverName: string;
  public carrierDriverId?: number;
  public vehiclePlateNumber: string;
  public carrierVehicleId?: number;
}

export class TransportMode {
  public typeId: number;
  public name: string;
  public className: string;
}

export enum Constants {
  Start = 'Start',
  Created = 'Created',
  Modified = 'Modified',
  Cancelled = 'Cancelled',
  Submitted = 'Submitted',
  Submit = 'Submit',
  CreatedToCancelledJourney = 'CreatedToCancelledJourney',
  SubmittedToCancellationRequestedJourney = 'SubmittedToCancellationRequestedJourney',
  CancelJourneymsg = 'Cancel Journey',
  CancelConfirmationmsg = 'Are you sure you want to cancel this Journey ?',
  CreatedToSubmittedJourney = 'CreatedToSubmittedJourney',
  SubmittedToArrivedJourney = 'SubmittedToArrivedJourney',
  ReturnForCorrectionSubmittedJourney = 'ReturnForCorrectionSubmittedJourney',
  CancellationRequestedToCancelledJourney = 'CancellationRequestedToCancelledJourney',
  Approved = 'Approved',
  SubmittedToAwaitingAcceptanceJourney = 'SubmittedToAwaitingAcceptanceJourney',
  CancellationRequestedToSubmittedJourney = 'CancellationRequestedToSubmittedJourney',
  CancellationRequestedToReturnedForCorrectionJourney = 'CancellationRequestedToReturnedForCorrectionJourney',
  CancellationRequestedToRejectedJourney = 'CancellationRequestedToRejectedJourney',
  AwaitingAcceptance = 'Awaiting Acceptance',
  ReturnedForCorrectionToSubmittedJourney = 'ReturnedForCorrectionToSubmittedJourney',
  VesselReadytoSailToDepartedJourney = 'VesselReadytoSailToDepartedJourney',
  JourneyInfoSuccessFullyDeparted = 'JourneyInfoSuccessFullyDeparted',
  ArrivedToApprovedToUnloadJourney = 'ArrivedToApprovedToUnloadJourney',
  AwaitingforApproval = 'Awaiting for Approval',
  Reject = 'Reject',
  Accept = 'Accept',
  ShippingAgent = 'ShippingAgent',
  Air = 2692,
  Sea = 2693,
  Land = 2694,
  Rail = 2696,
  JourneyTypeImport = 'I',
  JourneyTypeExport = 'E',
  Import = 'Import',
  Export = 'Export',
  ArriveJourney = 'Arrive Journey',
  DepartJourney = 'Depart Journey',
  VesselReadytoSail = 'Vessel Ready to Sail',
  // PurposeOfCallData
  Normal = 'N',
  NormalValue = '1-Normal',
  Heighten = 'H',
  HeightenValue = '2-Heightened',
  Exceptional = 'E',
  ExceptionalValue = '3-Exceptional',
  ReAssignJourney = 'ReAssign Journey',

  Ship_Icon = 'ship-icon',
  Plain_Icon = 'plane-icon',
  TrainIcon = 'declare-train-icon',
  PlainIcon = 'declare-plane-icon',
  ShipIcon = 'declare-ship-icon',
  TruckIcon = 'declare-truck-icon',

  CarrierType = 'CarrierType',
  JourneyType = 'JourneyType',
  AgentData = 'agentData',
  AgentList = 'agentList',
  AssociatedAgentData = 'associatedAgentData',
  Journey = 'Journey',
  OptionalValue = '--',
  airMode = 'AIR',
  railMode = 'RAIL',
  seaMode = 'SEA',
  landMode = 'LAND',
  AwaitingforApprovalToApprovedManifest = 'AwaitingforApprovalToApprovedManifest',
  AwaitingforApprovalToApprovedSuppManifest = 'AwaitingforApprovalToApprovedSuppManifest',
  Edit = 'Edit'
}
