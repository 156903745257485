import { Component, OnInit } from '@angular/core';
import { StorageService } from '../storageService/storageService';
@Component({
    selector: 'app-footer',
    templateUrl: './FooterComponent.html',
})

export class FooterComponent implements OnInit {
    constructor(public webStorageService: StorageService){}
    public ngOnInit() {
    }
}
