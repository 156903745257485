import { DataSource } from '@angular/cdk/table';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ColorCode, IndexValue, TraderCommodity } from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';
import { StorageService } from '../../../../../app/storageService/storageService';
import { SafeUnsubscriber } from '../../../../common/SafeUnsubscriber';

export interface IData { }
export class DataTableDataSource extends DataSource<any> {
  constructor(private data: IData[]) {
    super();
  }
  public connect(): Observable<IData[]> {
    return of(this.data);
  }
  public disconnect() { }
}

@Component({
  selector: 'app-360-profile-info',
  templateUrl: './Commodity360DegreeProfileComponent.html',
})

export class Commodity360DegreeProfileComponent extends SafeUnsubscriber implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    public webStorage: StorageService,
  ) {
    super();
  }

  public hsCode: string;
  public _tariffDescription: any;
  private _rulingsAssociated: any[];
  public rulingsAssDataSource: DataTableDataSource | null;
  public rulingsAssDisplayedColumns = [TraderCommodity.ClassificationCount, TraderCommodity.ValuationCount, TraderCommodity.TariffCode];
  private _keyTradersImport: any[];
  public keyTradersImportDataSource: DataTableDataSource | null;
  public keyTradersImportDisplayedColumns = [TraderCommodity.Importer, TraderCommodity.Value];
  private _keyTradersExport: any[];
  public keyTradersExportDataSource: DataTableDataSource | null;
  public keyTradersExportDisplayedColumns = [TraderCommodity.Exporter, TraderCommodity.Value];
  private _keyProducers: any[];
  public keyProducersDataSource: DataTableDataSource | null;
  public keyProducersDisplayedColumns = [TraderCommodity.LocalManufactureName, TraderCommodity.Value];
  private _keySectors: any[];
  public keySectorsDataSource: DataTableDataSource | null;
  public keySectorsDisplayedColumns = [TraderCommodity.Sectors, TraderCommodity.Import, TraderCommodity.Export, TraderCommodity.LocalMfg];
  private _appliedTariffs: any[];
  public appliedTariffDataSource: DataTableDataSource | null;
  public appliedTariffDisplayedColumns = [TraderCommodity.ImportProcedure, TraderCommodity.Value, TraderCommodity.FTAExemption];
  private _lpco: any[];
  public lpcoDataSource: DataTableDataSource | null;
  public lpcoDisplayedColumns = [TraderCommodity.License, TraderCommodity.Permit, TraderCommodity.Certificate, TraderCommodity.Others];
  public _importTariffRates: any = [[TraderCommodity.Year, TraderCommodity.TariffRate, { role: 'style' }]];
  public _exportTariffRates: any = [[TraderCommodity.Year, TraderCommodity.TariffRate, { role: 'style' }]];
  public _trendsImport: any = [[TraderCommodity.Year, TraderCommodity.Sale, { role: 'style' }]];
  public _trendsExport: any = [[TraderCommodity.Year, TraderCommodity.Sale, { role: 'style' }]];
  public _keySources: any = [[TraderCommodity.label, TraderCommodity.value, TraderCommodity.imPercent]];
  public _keyDestinations: any = [[TraderCommodity.label, TraderCommodity.value, TraderCommodity.exPercent]];
  public _keyImportProcedure: any = [[TraderCommodity.label, TraderCommodity.value, TraderCommodity.imPercent]];
  public _keyImportProcedureOther: any = [[TraderCommodity.label, TraderCommodity.value, TraderCommodity.exPercent]];
  public yearRecords: any = [];
  public maxYear: number;
  private flag = true;
  public tariffItemId: any;
  public tariffItemDetails: any;

  public tariffRates_Line_ChartOptions = {
    title: '',
    hAxis: { title: TraderCommodity.Year, titleTextStyle: { color: ColorCode.ColorCode333 }, format: ColorCode.format, },
    vAxis: { title: TraderCommodity.TariffRate },
    pointSize: IndexValue.IsFive,
    legend: 'none'
  };

  public trends_Line_ChartOptions = {
    title: '',
    hAxis: { title: TraderCommodity.Year, titleTextStyle: { color: ColorCode.ColorCode333 }, format: ColorCode.format },
    vAxis: { title: TraderCommodity.Sale },
    pointSize: IndexValue.IsFive,
    legend: 'none'
  };

  public pie_ChartOptions = {
    title: '',
    width: IndexValue.IsSixHundred,
    height: IndexValue.IsFourHundred
  };

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this._tariffDescription = res.tariffDescriptionResolver;
      this._rulingsAssociated = res.rulingsAssociatedResolver;
      this._keyTradersImport = res.keyTradersImportResolver;
      this._keyTradersExport = res.keyTradersExportResolver;
      this._keyProducers = res.keyProducersResolver;
      this._keySectors = res.keySectorsResolver;
      this._appliedTariffs = res.appliedTariffsResolver;
      this._lpco = res.lpcoResolver;

      ////////////// charts ////////////////
      let chartItems: any = res.importTariffRates;
      this.setToTariffRateChart(chartItems, TraderCommodity.importTariffRates);

      chartItems = res.exportTariffRates;
      this.setToTariffRateChart(chartItems, TraderCommodity.exportTariffRates);

      chartItems = res.trendsImport;
      this.setToTrendsChart(chartItems, TraderCommodity.trendsImport);

      chartItems = res.trendsExport;
      this.setToTrendsChart(chartItems, TraderCommodity.trendsExport);

      chartItems = res.keySources;
      this.setToPieChart(chartItems, TraderCommodity.keySources);

      chartItems = res.keyDestinations;
      this.setToPieChart(chartItems, TraderCommodity.keyDestinations);

      chartItems = res.keyImportProcedure;
      this.setToPieChart(chartItems, TraderCommodity.keyImportProcedure);

      chartItems = res.keyImportProcedureOther;
      this.setToPieChart(chartItems, TraderCommodity.keyImportProcedureOther);

      this.setToDataSource();
    });
  }

  public setToTariffRateChart(chartItems: any, destionationItems: string) {
    chartItems.forEach((response) => {
      const item = [response.year, response.rate, ColorCode.ColorCode0099];
      if (destionationItems === TraderCommodity.importTariffRates) {
        this._importTariffRates.push(item);
      } else if (destionationItems === TraderCommodity.exportTariffRates) {
        this._exportTariffRates.push(item);
      }
      this.yearRecords.push(response.year);
    });
    this.maxYear = Math.max(...this.yearRecords) + IndexValue.IsOne;
    this.getYearProblem(destionationItems);
  }

  public setToTrendsChart(chartItems: any, destionationItems: string) {
    chartItems.forEach((response) => {
      const item = [response.year, response.sale, ColorCode.ColorCode0099];
      if (destionationItems === TraderCommodity.trendsImport) {
        this._trendsImport.push(item);
      } else if (destionationItems === TraderCommodity.trendsExport) {
        this._trendsExport.push(item);
      }
      this.yearRecords.push(response.year);
    });
    this.maxYear = Math.max(...this.yearRecords) + IndexValue.IsOne;
    this.getYearProblem(destionationItems);
  }

  public setToPieChart(chartItems: any, destionationItems: string) {
    chartItems.forEach((response) => {
      let item;
      if (destionationItems === TraderCommodity.keySources || destionationItems === TraderCommodity.keyImportProcedure) {
        item = [response.label, response.value, response.imPercent];
      } else if (destionationItems === TraderCommodity.keyDestinations || destionationItems === TraderCommodity.keyImportProcedureOther) {
        item = [response.label, response.value, response.exPercent];
      }
      if (destionationItems === TraderCommodity.keySources) {
        this._keySources.push(item);
      } else if (destionationItems === TraderCommodity.keyDestinations) {
        this._keyDestinations.push(item);
      } else if (destionationItems === TraderCommodity.keyImportProcedure) {
        this._keyImportProcedure.push(item);
      } else if (destionationItems === TraderCommodity.keyImportProcedureOther) {
        this._keyImportProcedureOther.push(item);
      }
    });
  }

  public getYearProblem(destionationItems: string) {
    let count: number;
    if (destionationItems === TraderCommodity.importTariffRates) {
      count = this._importTariffRates.length;
      if (count < IndexValue.IsTwelve) {
        this.setYearProblem(destionationItems, count);
      }
    } else if (destionationItems === TraderCommodity.exportTariffRates) {
      count = this._exportTariffRates.length;
      if (count < IndexValue.IsTwelve) {
        this.setYearProblem(destionationItems, count);
      }

    } else if (destionationItems === TraderCommodity.trendsImport) {
      count = this._trendsImport.length;
      if (count < IndexValue.IsEight) {
        this.setYearProblem(destionationItems, count);
      }
    } else if (destionationItems === TraderCommodity.trendsExport) {
      count = this._trendsExport.length;
      if (count < IndexValue.IsEight) {
        this.setYearProblem(destionationItems, count);
      }
    }
  }

  public setYearProblem(destionationItems: string, count: number) {
    let totalCount;
    if (destionationItems === TraderCommodity.importTariffRates || destionationItems === TraderCommodity.exportTariffRates) {
      totalCount = IndexValue.IsSix;
    } else {
      totalCount = IndexValue.IsThree;
    }

    for (let i = count; i < totalCount; i++) {
      if (destionationItems === TraderCommodity.importTariffRates) {
        this._importTariffRates.push([this.maxYear, this.flag ? IndexValue.IsEleven : IndexValue.IsTwelve,
        ColorCode.ColorCode0099]);  // Temp Fix
      } else if (destionationItems === TraderCommodity.exportTariffRates) {
        this._exportTariffRates.push([this.maxYear, this.flag ? IndexValue.IsEleven : IndexValue.IsTwelve,
        ColorCode.ColorCode0099]); // Temp Fix
      } else if (destionationItems === TraderCommodity.trendsImport) {
        this._trendsImport.push([this.maxYear, this.flag ? IndexValue.IsEleven : IndexValue.IsTwelve, ColorCode.ColorCode0099]);
      } else if (destionationItems === TraderCommodity.trendsExport) {
        this._trendsExport.push([this.maxYear, IndexValue.IsZero, this.flag ? IndexValue.IsEleven :
          IndexValue.IsTwelve, ColorCode.ColorCode0099]);
      }
      this.maxYear += IndexValue.IsOne;
      this.flag = !this.flag;
    }
  }

  private setToDataSource() {
    this.rulingsAssDataSource = new DataTableDataSource(this._rulingsAssociated);
    this.keyTradersImportDataSource = new DataTableDataSource(this._keyTradersImport);
    this.keyTradersExportDataSource = new DataTableDataSource(this._keyTradersExport);
    this.keyProducersDataSource = new DataTableDataSource(this._keyProducers);
    this.keySectorsDataSource = new DataTableDataSource(this._keySectors);
    this.appliedTariffDataSource = new DataTableDataSource(this._appliedTariffs);
    this.lpcoDataSource = new DataTableDataSource(this._lpco);
  }
}

