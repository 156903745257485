
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { ShipEffectService } from '../../../services/ShipEffectService';
import { FxContext, ServiceDocument } from '@agility/frameworkcore';
import { StorageService } from '../../../../storageService/storageService';
import { SignalRService } from '../../../../signalR/signalR.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ShipEffectsDetailsModel } from '../../../models/ShipEffectDetailsModel';
import { SafeUnsubscriber } from '../../../../common/SafeUnsubscriber';

export interface Data { }

export class ExampleDatabase {
  /** Stream that emits whenever the data has been modified. */
  public agentsList: any[] = [
    { docType: 'XLSX', docRefNum: 'A12D440', fileName: 'Exchange Rate', expiryDate: '12 Feb 2018' },
  ];

  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get data(): any[] { return this.dataChange.value; }

  constructor() {
    this.dataChange.next(this.agentsList);
  }
}

export class ExampleDataSource extends DataSource<any> {
  constructor(private data: Data[]) {
    super();
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Data[]> {
    return Observable.of(this.data);
  }
  disconnect() { }
}

@Component({
  selector: 'app-upld-crew-effects',
  templateUrl: './UpldCrewEffectsComponent.html',
})
export class UpldCrewEffectsComponent extends SafeUnsubscriber implements OnInit {
  public upldCrewEffectsDocs: FormGroup;
  public uploaddocuments: any[] = [];
  private upLoadDocsUserId: any;
  public uploadDocsDataSource: any;
  public uploadDoctypes: any[] = [];
  private shipEffectServiceDocument: ServiceDocument<ShipEffectsDetailsModel>;

  constructor(private fb: FormBuilder,
    private shipEffectService: ShipEffectService,
    private fxContext: FxContext,
    public storageService: StorageService,
    private signalRService: SignalRService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<UpldCrewEffectsComponent>) {
    super();
    this.uploadDoctypes = [
      { key: '1', value: 'Others' }
    ];
  }


  ngOnInit() {
    this.upldCrewEffectsDocs = this.fb.group({
      filePath: ['', [<any>Validators.required]],
      file: [''],
    });

    this.uploadDocsDataSource = new ExampleDataSource(this.uploaddocuments);
    this.fxContext.userProfile = JSON.parse(this.storageService.get('userProfile').toString());
    this.upLoadDocsUserId = this.fxContext.userProfile.userName;
    this.shipEffectServiceDocument = this.shipEffectService.mcserviceDocument;
    this.signalRService.startConnection();
    this.signalRService.ShowServerNotification(this.upLoadDocsUserId);
  }

  public Close() {
    this.dialogRef.close();
  }

  public uploadExcelFile() {
    const shipCrewEffect = new ShipEffectsDetailsModel();
    shipCrewEffect.excelDocuments = this.uploaddocuments;
    if (this.upldCrewEffectsDocs.valid) {
      this.shipEffectService.submitExcelforShipCrewEffect(shipCrewEffect).subscribe((response) => {
        this.dialog.closeAll();
      });
    } else {
      Object.keys(this.upldCrewEffectsDocs.controls).map(
        controlName => this.upldCrewEffectsDocs.controls[controlName]).forEach(
          control => { control.markAsTouched(); }
        );
    }
  }

  public fileChange(event: any) {
    const docdata = {
      'base64String': event.base64String,
      'fileName': event.fileName,
      'docId': 1,
      'docType': '',
      'expiryDate': new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate(),
      'docRefNo': '',
    };
    this.uploaddocuments = [];
    this.uploaddocuments.push(docdata);
    this.upldCrewEffectsDocs.controls.filePath.setValue(docdata.fileName);
  }
}


