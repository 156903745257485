/* tslint:disable: max-line-length */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { StorageService } from '../storageService/storageService';

// tslint:disable-next-line: class-name
export interface myData {
  pageCount: number;
  associatePageCount: number;
  associateLessPageCount: number;
  sessionTimeout: number;
  localeJsonFile: any;
}

@Injectable()
export class RmsConfig implements OnInit {

  // Transaction History Methods
  getRiskProfileRemarksHistoryData: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/GetRiskProfileRemarksHistoryData';

  // MDM
  // localcountryApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Country/GetLocalCountry';
  // stateApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/State/GetAllState?filterValue=';
  // cityApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/City/GetCity?filterValue=';
  // postalApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/PostalCode/GetPostalCode?filterValue=';
  // customStationApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CustomStation/GetCustomStation?filterValue=';
  // bankApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Bank/GetBank?filterValue=';
  // bankBranchApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/BankBranch/GetBankBranch?filterValue=';
  // containerISOCodeApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/ContainerIsoCode/GetContainerISOCode?filterValue=';
  // currencyApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Currency/GetCurrency?filterValue=';
  // fTAManagementApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/FtaManagement/GetFTAManagements?filterValue=';
  // locationApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Location/GetLocation?filterValue=';
  // organizationApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Organization/GetOrganization?filterValue=';
  // postingOrganizationApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Organization/GetPostingOrganizations?filterValue=';
  // sTACodesApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/StaCodeType/GetSTACodeTypes?filterValue=';
  // sTACodeCategoriesApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/StaCodeType/GetSTACodeCategories?filterValue=';
  // storageLocationApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/StorageLocation/GetStorageLocation?filterValue=';
  // telePhoneCountryCodeApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TelePhoneCountryCode/GetTelePhoneCountryCode?filterValue=';
  // getTelePhoneCountryCodeId: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TelePhoneCountryCode/GetTelePhoneCountryCodeId/';
  // getTelePhoneCountryCode: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TelePhoneCountryCode/GetTelePhoneCountryCode';
  // vehicleBrandApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/VehicleBrand/GetVehicleBrand?filterValue=';
  // volumeUnitApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/VolumeUnit/GetVolumeUnit?filterValue=';
  // weightUnitApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WeightUnit/GetWeightUnit?filterValue=';
  // brandModelApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Brandmodel/GetVehicleBrand?filterValue=';
  // regOrganizationUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Organization/GetRegOrganization?filterValue=';
  // regOGAOrganizationUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Organization/GetRegCustomAndOGAOrganization?filterValue=';
  // regionsApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Region/GetRegions?filterValue=';
  // cascadingPortLocationApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Location/GetDestinationLocationByOrigin?filterValue=';
  // journeyLocationApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Location/GetModeLocation?locationNameFilter=';
  // getPortsfilter: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Location/GetPortsfilter';
  // getOrgPostings: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Posting/GetOrgPostings';
  // getGovernmenttPostingfilter: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Posting/GetGovernmenttPostingfilter';
  // getCustomPostingfilter: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Posting/GetCustomPostingfilter';
  // getCSfilter: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CustomStation/GetCSfilter';
  // countryApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Country/GetCountry?filterValue=';
  // quantityUnitApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/QuantityUnit/GetQuentityUnit?filterValue=';
  // getMcnTypes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/McnTypes/GetModuleType';

  // Mdm Domain Data
  quantityUnitApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/MdmDomainData/GetQuantityUnit?filterValue=';
  getMcnTypes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/MdmDomainData/GetModuleType';

  // Declaration
  viewDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RmsDeclaration/GetDeclarationData';
  getInvoiceDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RmsDeclaration/GetInvoiceData';
  viewInvoiceItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RmsDeclaration/GetInvoiceItemData';
  viewExemptionsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RmsDeclaration/GetExemptionList';

  getDeclarationStatus: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RmsDeclaration/GetAllStatusByDeclarationId';
  getJourneyStatus: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RmsDeclaration/GetAllStatusByJourneyId';


  // Journey
  viewJourney: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RmsCargo/GetJourneyData';
  viewManifestList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RmsCargo/GetJourneyManifestListData';
  viewContainerList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RmsCargo/GetJourneyContainerListData';
  viewManifestDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RmsCargo/GetManifestDetailsData';
  viewContainerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RmsCargo/GetJourneyContainerDetailsData';
  // Autocompletes
  autoRiskRegisterCodes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetRiskRegisterCodes?filterValue=';
  autoSRAS: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetSRASTitles?filterValue=';
  autoComplianceCategorizations: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetComplianceCategorizations?filterValue=';
  autoDepartment: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetDepartment?filterValue=';
  autoLocations: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetLocations?filterValue=';
  autoVessels: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetVessels?filterValue=';
  autoTariffItems: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetTariffItems?filterValue=';
  autoSpecification: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetSpecification?filterValue=';
  autoVehicleBrands: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetVehicleBrands?filterValue=';
  autoVehicleModels: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetVehicleModels?filterValue=';
  autoWatchlist: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetWatchlist?filterValue=';
  autoDataSource: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetRuleBuilderSource?filterValue=';
  autoDataSourceMember: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetRuleBuilderSourceMember?filterValue=';
  autoDataSourceMemberCount: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetRuleBuilderSourceMemberCount?filterValue=';
  countryApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Autocompletes/GetCountry?filterValue=';

  // RMS Types and domain data
  getRmsTypes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RmsTypes/GetRmsModuleType';
  newRmsTypes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RmsTypes/NewRmsTypes';
  submitRmsTypes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RmsTypes/SubmitRmsTypes';
  listRmsTypes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RmsTypes/ListRmsTypes';
  openRmsTypes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RmsTypes/OpenRmsTypes';
  deleteRmsSubTypes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RmsTypes/DeleteRmsSubTypes';
  ddGetLikelihoods: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/DomainData/GetLikelihoodsDomainData';
  ddGetConsequences: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/DomainData/GetConsequencesDomainData';
  ddGetDEEntities: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/DomainData/GetDataElementEntities';
  ddGetDEFields: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/DomainData/GetDataElementFields';
  ddGetOperatorTypes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/DomainData/GetOperatorTypes';
  ddAiRisks: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/DomainData/GetAIRisksConfigurationDomainData';


  // Risks
  newRisks: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Risks/NewRisks';
  listRisks: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Risks/ListRisks';
  openRisks: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Risks/OpenRisks';
  deleteRisks: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Risks/DeleteRisks';
  submitRisks: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Risks/SubmitRisks';
  riskTransactionHistoryList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Risks/RisksHistoryList';

  listRiskInstructions: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RIRiskInstructions/ListRiskInstructions';
  submitRiskInstructions: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RIRiskInstructions/SubmitRiskInstructions';
  filterRiskInstructions: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RIRiskInstructions/RiskInstructionsFilterList';
  listRiskRegisterRisks: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Risks/ListRiskRegisterRisks';
  openRiskSummary: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Risks/OpenRiskSummary';
  listRisksHistory: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Risks/ListRisksHistory';
  getRisksHistoryDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Risks/GetRisksHistoryDetails';


  // RiskRules
  newRiskRules: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskRules/NewRiskRules';
  submitRiskRules: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskRules/SubmitRiskRules';
  openRiskRules: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskRules/OpenRiskRules';
  insertFps: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskRules/InsertRiskRuleFivepntSum';
  insertSD: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskRules/InsertRiskRuleStandardDevi';

  // RiskProfiles
  newRiskProfiles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/NewRiskProfiles';
  listRiskProfiles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/ListRiskProfiles';
  openRiskProfiles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/OpenRiskProfiles';
  deleteRiskProfiles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/DeleteRiskProfiles';
  submitRiskProfiles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/SubmitRiskProfiles';
  RiskProfileWFStatesApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/GetRiskProfileWorkflowStates';
  getAssociatedRiskList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/GetAssociatedRiskList';
  getAssociatedCustomStationsList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/GetAssociatedCustomStationsList';
  getAssociatedPortsList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/GetAssociatedPortsList';
  listAssociatedRisks: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RPRisks/ListRPRisk';
  submitRisk: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RPRisks/SubmitRPRisk';
  filterRisks: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RPRisks/RPRiskFilterList';
  listAssociatedCustomStation: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RPCustomsStations/ListRPCustomsStation';
  submitriskCustomStation: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RPCustomsStations/SubmitRPCustomsStation';
  filterCustomStation: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RPCustomsStations/RPCustomsStationFilterList';
  listAssociatedPort: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RPPorts/ListRPPorts';
  submitPort: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RPPorts/SubmitRPPorts';
  filterPort: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RPPorts/RPPortsFilterList';
  chkforAlert: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/ChkForAlert';
  rmsRiskRecreation: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/RMSRiskReCreation';
  listRiskRegisterRiskProfiles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/ListRiskRegisterRiskProfiles';
  listRPReviewHistory: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/ListRPReviewHistory';
  openRPReviewHistory: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/OpenRPReviewHistory';
  listRiskProfileTestResults: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/GetRiskProfileTestResults';
  getRiskProfileRevenueOutput: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/GetRiskProfileRevenueOutput';
  getcloneRiskProfileApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/GetCloneRiskProfile';
  riskProfileHistoryList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/RiskProfileHistoryList';
  listRiskAnalysis: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/ListRiskAnalysis';

  // RisksRegister
  newRisksRegister: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/NewRisksRegister';
  listRisksRegister: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/ListRisksRegister';
  openRisksRegister: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/OpenRisksRegister';
  deleteRisksRegister: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/DeleteRisksRegister';
  submitRisksRegister: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/SubmitRisksRegister';
  risksRegisterWFStatesApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/GetRisksRegisterWorkflowStates';
  listRisksRegisterHistory: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/ListRisksRegisterHistory';
  getRisksRegisterHistory: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/GetRisksRegisterHistoryDetails';
  viewTransactionHistory: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/ViewTransactionHistory';

  // RiskIndicators (API = SRAS)
  newRiskIndicators: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/SRAS/NewSRAS';
  listRiskIndicators: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/SRAS/ListSRAS';
  openRiskIndicators: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/SRAS/OpenSRAS';
  deleteRiskIndicators: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/SRAS/DeleteSRAS';
  submitRiskIndicators: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/SRAS/SubmitSRAS';
  rilWFStatesApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/SRAS/GetRILWorkflowStates';
  downloadRiskIndicatorTemplateFile: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/SRAS/DownloadRiskIndicatorTemplateFile';

  // RiskFrequency (API = Likelihoods)
  newRiskFrequency: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Likelihoods/NewLikelihoods';
  listRiskFrequency: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Likelihoods/ListLikelihoods';
  openRiskFrequency: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Likelihoods/OpenLikelihoods';
  deleteRiskFrequency: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Likelihoods/DeleteLikelihoods';
  submitRiskFrequency: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Likelihoods/SubmitLikelihoods';

  // RiskImpact (API = Consequences)
  newRiskImpact: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Consequences/NewConsequences';
  listRiskImpact: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Consequences/ListConsequences';
  openRiskImpact: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Consequences/OpenConsequences';
  deleteRiskImpact: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Consequences/DeleteConsequences';
  submitRiskImpact: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Consequences/SubmitConsequences';

  // RiskAssessmentCriteriaMetrics (API = RiskValues)
  newRACM: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskValues/NewRiskValues';
  listRACM: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskValues/ListRiskValues';
  openRACM: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskValues/OpenRiskValues';
  deleteRACM: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskValues/DeleteRiskValues';
  submitRACM: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskValues/SubmitRiskValues';
  racmWFStatesApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskValues/GetRACMWorkflowStates';
  viewAllMetricsApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskValues/ViewAllMetricsValues';

  // TargetDecisions
  newTargetDecisions: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetDecisions/NewTargetDecisions';
  listTargetDecisions: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetDecisions/ListTargetDecisions';
  openTargetDecisions: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetDecisions/OpenTargetDecisions';
  deleteTargetDecisions: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetDecisions/DeleteTargetDecisions';
  submitTargetDecisions: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetDecisions/SubmitTargetDecisions';
  trgtDsnWFStatesApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetDecisions/GetTrgtDsnWorkflowStates';

  // WatchLists
  newWatchLists: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/NewWatchLists';
  listWatchLists: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/ListWatchLists';
  openWatchLists: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/OpenWatchLists';
  deleteWatchLists: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/DeleteWatchLists';
  submitWatchLists: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/SubmitWatchLists';
  listWLAssociatedPort: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLPorts/ListWLPorts';
  submitWLPort: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLPorts/SubmitWLPorts';
  filterWLPort: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLPorts/WLPortsFilterList';
  getAssociatedWLPortsList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLPortsList';
  watchListTemplateFileDownload: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/DownloadWatchListTemplateFile';
  submitExcelForWatchLists: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/WatchListExcelUpload';

  listWLAssociatedHSCode: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHSCodes/ListWLHSCodes';
  submitWLHSCode: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHSCodes/SubmitWLHSCodes';
  filterWLHSCode: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHSCodes/WLHSCodesFilterList';
  getAssociatedWLHSCodesList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLHSCodesList';

  listWLAssociatedChapter: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLChapter/ListWLChapters';
  submitWLChapter: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLChapter/SubmitWLChapters';
  filterWLChapter: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLChapter/WLChaptersFilterList';
  getAssociatedWLChapterList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLChaptersList';

  listWLAssociatedHeading: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHeading/ListWLHeadings';
  submitWLHeading: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHeading/SubmitWLHeadings';
  filterWLHeading: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHeading/WLHeadingsFilterList';
  getAssociatedWLHeadingList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLHeadingsList';

  listWLAssociatedSubHeading: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLSubHeading/ListWLSubHeadings';
  submitWLSubHeading: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLSubHeading/SubmitWLSubHeadings';
  filterWLSubHeading: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLSubHeading/WLSubHeadingsFilterList';
  getAssociatedWLSubHeadingList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLSubHeadingsList';

  listWLAssociatedHS5Detail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHS5Detail/ListWLHS5Details';
  submitWLHS5Detail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHS5Detail/SubmitWLHS5Details';
  filterWLHS5Detail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHS5Detail/WLHS5DetailsFilterList';
  getAssociatedWLHS5DetailList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLHS5DetailsList';

  listWLAssociatedHS7Detail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHS7Detail/ListWLHS7Details';
  submitWLHS7Detail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHS7Detail/SubmitWLHS7Details';
  filterWLHS7Detail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHS7Detail/WLHS7DetailsFilterList';
  getAssociatedWLHS7DetailList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLHS7DetailsList';

  listWLAssociatedHS8Detail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHS8Detail/ListWLHS8Details';
  submitWLHS8Detail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHS8Detail/SubmitWLHS8Details';
  filterWLHS8Detail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLHS8Detail/WLHS8DetailsFilterList';
  getAssociatedWLHS8DetailList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLHS8DetailsList';

  listWLAssociatedOrgDetail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLOrganization/ListOrganizationDetails';
  submitWLOrgDetail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLOrganization/SubmitOrganizationDetails';
  filterWLOrgDetail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLOrganization/OrganizationDetailsFilterList';
  getAssociatedWLOrgDetailList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLOrgDetailsList';

  listWLAssociatedCountryDetail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLCountry/ListCountryDetails';
  submitWLCountryDetail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLCountry/SubmitCountryDetails';
  filterWLCountryDetail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLCountry/CountryDetailsFilterList';
  getAssociatedWLCountryDetailList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLCountryDetailsList';

  listWLAssociatedRouteDetail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLRoute/ListRouteDetails';
  submitWLRouteDetail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLRoute/SubmitRouteDetails';
  filterWLRouteDetail: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLRoute/RouteDetailsFilterList';
  getAssociatedWLRouteDetailList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLRouteDetailsList';
  getWatchListEntity: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetWatchListEntity';

  listWLAssociatedPassenger: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLPassengers/ListWLPassengers';
  submitWLPassenger: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLPassengers/SubmitWLPassengers';
  filterWLPassenger: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLPassengers/WLPassengersFilterList';
  getAssociatedWLPassengerList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLPassengersList';

  // Passport Details Assocaition
  listWLAssociatedPassportDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLPassportDetails/ListWLPassportDetails';
  submitWLPassportDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLPassportDetails/SubmitWLPassportDetails';
  filterWLPassportDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WLPassportDetails/WLPassportDetailsFilterList';
  getAssociatedWLPassportDetailsList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchLists/GetAssociatedWLPassportDetailsList';

  // CumulativeScoreConfiguration
  newCumulativeScoreConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CumulativeScoreConfiguration/NewCumulativeScoreConfiguration';
  listCumulativeScoreConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CumulativeScoreConfiguration/ListCumulativeScoreConfiguration';
  openCumulativeScoreConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CumulativeScoreConfiguration/OpenCumulativeScoreConfiguration';
  deleteCumulativeScoreConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl +
    '/api/CumulativeScoreConfiguration/DeleteCumulativeScoreConfiguration';
  submitCumulativeScoreConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl +
    '/api/CumulativeScoreConfiguration/SubmitCumulativeScoreConfiguration';

  // WatchListConfiguration (API = DynamicWatchList)
  openWatchListConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchListConfiguration/OpenWatchListConfiguration';
  submitWatchListConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/WatchListConfiguration/SubmitWatchListConfiguration';

  // ComplianceConfigurationCompound (API = ComplianceScoreConfigurations)
  newComplianceConfigurationCompound: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl +
    '/api/ComplianceScoreConfigurations/NewComplianceScoreConfigurations';
  openComplianceConfigurationCompound: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl +
    '/api/ComplianceScoreConfigurations/OpenComplianceScoreConfigurations';
  submitComplianceConfigurationCompound: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl +
    '/api/ComplianceScoreConfigurations/SubmitComplianceScoreConfigurations';
  getModel2ComplianceCategorizations: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl +
    '/api/ComplianceScoreConfigurations/GetModel2ComplianceCategorizations';

  // ComplianceConfigurationErrorAndRevenue (API = ComplianceScoreConfig)
  openComplianceConfigurationErrorAndRevenue: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl +
    '/api/ComplianceConfigurationErrorAndRevenue/OpenComplianceConfigurationErrorAndRevenue';
  submitComplianceConfigurationErrorAndRevenue: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl +
    '/api/ComplianceConfigurationErrorAndRevenue/SubmitComplianceConfigurationErrorAndRevenue';

  // TraderComplianceCategorizations (API = ComplianceCategorizations)
  newComplianceCategorizations: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/ComplianceCategorizations/NewComplianceCategorizations';
  listComplianceCategorizations: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/ComplianceCategorizations/ListComplianceCategorizations';
  openComplianceCategorizations: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/ComplianceCategorizations/OpenComplianceCategorizations';
  deleteComplianceCategorizations: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/ComplianceCategorizations/DeleteComplianceCategorizations';
  submitComplianceCategorizations: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/ComplianceCategorizations/SubmitComplianceCategorizations';
  listComplianceTraderScoreHistory: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/ComplianceCategorizations/ListComplianceTraderScoreHistory';

  // ComplianceConfiguration (API = ComplianceScorePreferences)
  listComplianceConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/ComplianceConfiguration/ListComplianceConfiguration';
  openComplianceConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/ComplianceConfiguration/OpenComplianceConfiguration';
  submitComplianceConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/ComplianceConfiguration/SubmitComplianceConfiguration';

  // NVDBHistoricalData
  listNVDBHistoricalData: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBHistoricalData/ListNVDBHistoricalData';
  openNVDBAppliedTraiffData: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBHistoricalData/OpenNVDBAppliedTariffDetails';
  openViewOutlierData: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBHistoricalData/OpenViewOutlier';


  viewNVDBHistoricalData: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBHistoricalData/ViewNVDBHistoricalData';
  viewNVDBOutlierMagnitude: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBHistoricalData/ViewNVDBOutlierMagnitude';
  viewNVDBOutlierLOF: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBHistoricalData/ViewNVDBOutlierLOF';

  // NVDBMarketCommodity
  newNVDBMarketCommodity: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/NewNVDBMarketCommodity';
  listNVDBMarketCommodity: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/ListNVDBMarketCommodity';
  openNVDBMarketCommodity: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/OpenNVDBMarketCommodity';
  deleteNVDBMarketCommodity: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/DeleteNVDBMarketCommodity';
  submitNVDBMarketCommodity: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/SubmitNVDBMarketCommodity';

  nvdbCommodityWFStatesApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/GetNVDBCommodityWorkflowStates';

  newNVDBMarketCommoditySpecifications: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/NewNVDBMarketCommoditySpecifications';
  listNVDBMarketCommoditySpecifications: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/ListNVDBMarketCommoditySpecifications';
  openNVDBMarketCommoditySpecifications: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/OpenNVDBMarketCommoditySpecifications';
  deleteNVDBMarketCommoditySpecifications: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/DeleteNVDBMarketCommoditySpecifications';
  submitNVDBMarketCommoditySpecifications: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketCommodity/SubmitNVDBMarketCommoditySpecifications';


  // NVDBMarketVehicles
  newNVDBMarketVehicles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketVehicles/NewNVDBMarketVehicles';
  listNVDBMarketVehicles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketVehicles/ListNVDBMarketVehicles';
  openNVDBMarketVehicles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketVehicles/OpenNVDBMarketVehicles';
  deleteNVDBMarketVehicles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketVehicles/DeleteNVDBMarketVehicles';
  submitNVDBMarketVehicles: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketVehicles/SubmitNVDBMarketVehicles';
  newNVDBMarketVehiclesSpecifications: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketVehicles/NewNVDBMarketVehiclesSpecifications';
  listNVDBMarketVehiclesSpecifications: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketVehicles/ListNVDBMarketVehiclesSpecifications';
  openNVDBMarketVehiclesSpecifications: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketVehicles/OpenNVDBMarketVehiclesSpecifications';
  deleteNVDBMarketVehiclesSpecifications: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketVehicles/DeleteNVDBMarketVehiclesSpecifications';
  submitNVDBMarketVehiclesSpecifications: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/NVDBMarketVehicles/SubmitNVDBMarketVehiclesSpecifications';

  // Routes
  newRoutes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Routes/NewRoutes';
  listRoutes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Routes/ListRoutes';
  openRoutes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Routes/OpenRoutes';
  deleteRoutes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Routes/DeleteRoutes';
  submitRoutes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Routes/SubmitRoutes';
  newRouteDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Routes/NewRouteDetails';
  listRouteDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Routes/ListRouteDetails';
  deleteRouteDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Routes/DeleteRouteDetails';
  submitRouteDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Routes/SubmitRouteDetails';
  routesWFStatesApiUrl: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/Routes/GetRoutesWorkflowStates';

  // RMSPreferences
  openRMSPreferences: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RMSPreferences/OpenRMSPreferences';
  submitRMSPreferences: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RMSPreferences/SubmitRMSPreferences';

  // RuleBuilderPreferences
  newDataElementEntities: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RuleBuilderPreferences/NewDataElementEntities';
  listDataElementEntities: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RuleBuilderPreferences/ListDataElementEntities';
  openDataElementEntities: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RuleBuilderPreferences/OpenDataElementEntities';
  submitDataElementEntities: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RuleBuilderPreferences/SubmitDataElementEntities';
  newDataElementFields: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RuleBuilderPreferences/NewDataElementFields';
  listDataElementFields: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RuleBuilderPreferences/ListDataElementFields';
  openDataElementFields: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RuleBuilderPreferences/OpenDataElementFields';
  submitDataElementFields: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RuleBuilderPreferences/SubmitDataElementFields';
  submitAssoDEFields: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RuleBuilderPreferences/SubmitAssocDataElementFields';

  // RandomConfiguration (API = RandomConfiguration)
  newRandomConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RandomConfiguration/NewRandomConfiguration';
  openRandomConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RandomConfiguration/OpenRandomConfiguration';
  submitRandomConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RandomConfiguration/SubmitRandomConfiguration';

  // WatchListConfiguration(API = DynamicWatchList)
  newDynamicWatchList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/DynamicWatchList/NewDynamicWatchList';
  openDynamicWatchList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/DynamicWatchList/OpenDynamicWatchList';
  submitDynamicWatchList: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/DynamicWatchList/SubmitDynamicWatchList';

  // Targeting and Monitoring
  listTargandMontoring: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetingandMonitoring/ListTargetingandMonitoring';
  listTariffItemLine: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetingandMonitoring/ListTariffItemLine';

  // TargetingDecisions
  newTargetingDecision: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetingDecision/NewTargetingDecision';
  submitTargetingDecision: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetingDecision/SubmitTargetingDecision';
  openTargetingDecision: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetingDecision/OpenTargetingDecision';
  listTargetingDecision: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TargetingDecision/ListTargetingDecision';


  // AI Risk Configuration
  newRiskConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskConfiguration/NewRiskConfiguration';
  listRiskConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskConfiguration/ListRiskConfiguration';
  openRiskConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskConfiguration/OpenRiskConfiguration';
  submitRiskConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskConfiguration/SubmitRiskConfiguration';
  deleteRiskConfiguration: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskConfiguration/DeleteRiskConfiguration';

  // Traders Profile 360 Degree
  importsBarChart: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/ImportsBarChart?id=';
  exportsBarChart: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/ExportsBarChart?id=';
  importsLineChart: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/ImportsLineChart?id=';
  exportsLineChart: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/ExportsLineChart?id=';
  whoTheTraderIs: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/WhoTheTraderIs?id=';
  keySuppliersBuyers: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/KeySuppliersBuyers?id=';
  localPurchases: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/LocalPurchases?id=';
  costOfExemption: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/CostOfExemption?id=';
  quotasByCbra: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/QuotasByCbra?id=';
  businessVolume: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/BusinessVolume?id=';
  complianceHistory: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/ComplianceHistory?id=';
  goodsConfiscated: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/GoodsConfiscated?id=';
  assocParties: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/AssocParties?id=';
  listTraderProfileOrgs: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/TradersProfile360/TraderOrgsList';

  // Commodity Profile 360 Degree
  tariffDescription: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/TariffDescription?id=';
  importTariffRates: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/ImportTariffRates?id=';
  exportTariffRates: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/ExportTariffRates?id=';
  rulingsAssociated: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/RulingsAssociated?id=';
  trendsImport: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/TrendsImport?id=';
  trendsExport: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/TrendsExport?id=';
  keySources: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/KeySources?id=';
  keyDestinations: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/KeyDestinations?id=';
  keyTradersImport: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/KeyTradersImport?id=';
  keyTradersExport: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/KeyTradersExport?id=';
  keyProducers: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/KeyProducers?id=';
  keySectors: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/KeySectors?id=';
  appliedTariffs: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/AppliedTariffs?id=';
  keyImportProcedure: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/KeyImportProcedure?id=';
  keyImportProcedureOther: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/KeyImportProcedureOther?id=';
  lpco: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/Lpco?id=';
  listCommodityProfileHsCodes: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/CommodityProfile360/CommodityTariffList';

  // Vessel passenger targeting
  vpTargeting: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/VPTargeting/GetVPTargeting';

  // Confusion Matrix
  listConfustionMatrix: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/ListConfusionMatrix';

  // Confusion Matrix Details
  listConfustionMatrixDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskProfiles/ListConfusionMatrixDetails';

  // Risk Response Details
  getMcnRiskResponseDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskDashboard/GetRiskResponseDetails';
  getMcnRiskResultDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskDashboard/GetTriggeredRiskResults';
  getMcnViewRmsOutlierResult: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RiskDashboard/ViewRmsOutlierResult';

  getRiskResponseDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/GetRiskResponseDetails';
  getRiskResultDetails: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/GetTriggeredRiskResults';

  // OPM Reports
  listReportCategories: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/OPMReports/ListReportCategories';


  // Cumulative,FivePointSummary,StandardDeviation Results
  getCumulativeData: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/GetCumulativeResult';
  getFivePointSummaryData: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/GetFivePointSummaryResult';
  getStandardDevData: any = environment.appSettings.apiEndpoints.rmsApiBaseUrl + '/api/RisksRegister/GetStandardDevResult';

  // AI Outlier Analysis
  isMagnitudeOutlierApiUrl = 'http://10.138.108.55:11133/outliers/3926.9099/2.4480/4.000/2006-01-01/2006-01-30';
  isLOFOutlierApiUrl = 'http://10.138.108.55:11132/outliers/IM00033966/7287.95.04/AUSTRALIA/70.539/35/100';
  AIRMSChartApiUrl = 'http://10.138.108.33:5022/rms-ml-data';

  constructor(
    private http: HttpClient,
    private storageService: StorageService) { }
  ngOnInit() {
  }

  public getCascadingRMSTypes(moduleName: any, value: any): Observable<any> {
    const api = this.getRmsTypes.split('?');
    return this.http.get(api[0] + '?moduleName=' + moduleName + '&typeName=' + value);
  }

  public getCascadingDeEntities(): Observable<any> {
    const api = this.ddGetDEEntities.split('?');
    return this.http.get(api[0]);
  }

  public getCascadingDeEntityFields(deEntityId: any): Observable<any> {
    const api = this.ddGetDEFields.split('?');
    return this.http.get(api[0] + '?filterValue=' + deEntityId);
  }

  public getCascadingDeEntityOperators(dataType: any): Observable<any> {
    const api = this.ddGetOperatorTypes.split('?');
    return this.http.get(api[0] + '?filterValue=' + dataType);
  }

  public getCascadingWatchlistSources(): Observable<any> {
    return this.http.get(this.ddGetDEEntities);
  }

  public getCascadingTypes(moduleName: any, value: any): Observable<any> {
    const api = this.getMcnTypes.split('?');
    return this.http.get(api[0] + '?moduleName=' + moduleName + '&typeName=' + value);
  }

}
