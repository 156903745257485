import { NgModule } from '@angular/core';
import { McnControlsModule } from '../../mcnControls/McnControlsModule';
import { ChatInputComponent } from '../../account/chatBot/chatInput/ChatInputComponent';
import { ChatMsgComponent } from '../../account/chatBot/chatMessage/ChatMsgComponent';
import { ChatWindowComponent } from '../../account/chatBot/chatWindow/ChatWindowComponent';
import { ChatFooterComponent } from '../../account/chatBot/chatFooter/ChatFooterComponent';
import { ChatBotDataService } from '../../account/chatBot/services/ChatBotDataService';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
@NgModule({
    imports: [
        McnControlsModule,
        FormsModule,
        BrowserModule,
        CommonModule,
        AgmCoreModule
    ],
    declarations: [
        ChatInputComponent,
        ChatMsgComponent,
        ChatWindowComponent,
        ChatFooterComponent
    ],
    exports: [
        ChatInputComponent,
        ChatMsgComponent,
        ChatWindowComponent,
        ChatFooterComponent
    ],
    providers: [ChatBotDataService]
})
export class chartBotModule { }
