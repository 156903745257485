export enum AccountingConstant {
  DeleteTitle = 'Delete',
  DeleteRevenueSourceConfig = 'Delete Revenue Source Cofiguration',
  DeleteRevenuAccount = 'Delete Revenue Account',
  Delete = 'delete',
  WarnIcon = 'warn-icon',
  OnlinePayment = 2462,
  SystemPayment = 2463,
  CustomsCounter = 2461,
  RevenueAccount = 2394,
  CreateNewRevenueGroup = 'Create New Revenue Group',
  DeactivateRevenueGroup = 'Deactivate Revenue Group',
  DeactivateAccountingConfiguration = 'Deactivate Accounting Configuration',
  DeactivateCollectionAccount = 'Deactivate Collection Account',
  DeactivateRefundAccount = 'Deactivate Refund Account',
  Bill = 'Bill',
  AccountingConfiguration = 'AccountingConfigurationModel',
  CollectionAccount = 'CollectionAccountModel',
  RevenueAccountM = 'RevenueAccountModel',
  RevenueSourceConfiguration = 'RevenueSourceConfigurationModel',
  RevenueSourceGroup = 'RevenueSourceGroupModel',
  DebitAccount = 'DebitAccountModel',
  RefundPayment = 'RefundPaymentModel'
}

export enum StateNames {
  Activated = 'Activated',
  Deactivated = 'Deactivated',
  Start = 'Start',
  Save = 'Save',
  Modified = 'Modified'
}

export enum ActionName {
  Activate = 'Activate',
  Deactivate = 'Deactivate'
}

export enum ViewType {
  Grid = 'grid',
  List = 'list'
}
export enum TypeName {
  OnlinePayment = 'ONLINE PAYMENT',
  System = 'SYSTEM',
  OnlinePaymentTxt = 'Online Payment',
  SystemPaymentTxt = 'System Payment',
  CustomsCounter = 'Customs Counter'
}

export enum ActionNames {
  ActivateModifiedRevenueGroup = 'ActivateModifiedRevenueGroup',
  StartToCreatedRevenueGroup = 'StartToCreatedRevenueGroup',
  CreatedToActivatedRevenueGroup = 'CreatedToActivatedRevenueGroup',
  ModifiedToActivatedRevenueGroup = 'ModifiedToActivatedRevenueGroup',
  ModifiedToActivatedRevenueSourceConfiguration = 'ModifiedToActivatedRevenueSourceConfiguration',
  CreatedToActivatedRevenueAccount = 'CreatedToActivatedRevenueAccount',
  ModifiedToActivatedRevenueAccount = 'ModifiedToActivatedRevenueAccount',
  ActivatedToDeactivatedCollectionAccount = 'ActivatedToDeactivatedCollectionAccount',
  ActivatedToDeactivatedRefundAccount = 'ActivatedToDeactivatedRefundAccount',
  ActivatedToDeactivatedAccountingConfiguration = 'ActivatedToDeactivatedAccountingConfiguration',
  DeactivatedToActivatedRevenueAccounts = 'DeactivatedToActivatedRevenueAccounts',
  DeactivatedToActivatedRevenueSourceConfiguration = 'DeactivatedToActivatedRevenueSourceConfiguration',
  ActivatedToDeactivatedRevenueGroup = 'ActivatedToDeactivatedRevenueGroup',
  CreatedToActivatedRevenueSourceConfiguration = 'CreatedToActivatedRevenueSourceConfiguration',
  DeactivatedToModifiedRevenueSourceConfiguration = 'DeactivatedToModifiedRevenueSourceConfiguration',
  ActivatedToDeactivatedRevenueSourceConfiguration = 'ActivatedToDeactivatedRevenueSourceConfiguration',
  ActivatedToDeactivatedRevenueAccount = 'ActivatedToDeactivatedRevenueAccount'
}

export enum Messages {
  AssocRevenueSourceMandatory = 'AssocRevenueSourceMandatory',
  AssocRevenueTransactionManadtory = 'AssociationOfRevenueSourceTransactionTypeIsManadatory',
  RevenueSourceConfigurationModel = 'RevenueSourceConfigurationModel',
  AreYouSureYouWantToDeleteThisAccountingConfiguration = 'AreYouSureYouWantToDeleteThisAccountingConfiguration',
  AreYouSureYouWantToDeleteThisCollectionAccount = 'AreYouSureYouWantToDeleteThisCollectionAccount',
  AreYouSureYouWantToDeleteThisRefundAccount = 'AreYouSureYouWantToDeleteThisRefundAccount',
  AreYouSureYouWantToDeleteThisRefundPaymentAccount = 'AreYouSureYouWantToDeleteThisRefundPaymentAccount',
  AreYouSureYouWantToDeleteThisPaymentChannel = 'AreYouSureYouWantToDeleteThisPaymentChannel',
  DeleteRevenueGroupMandatoryMsg = 'AreYouSureYouWantToDeleteThisRevenueGroup',
  DeleteRevenueSourceConfigMandatoryMsg = 'AreYouSureYouWantToDeleteThisRevenueSourceConfiguration',
  DeleteRevenueAccountMandatoryMsg = 'AreYouSureYouWantToDeleteThisRevenueAccount',
  AccountCannotBeDeactivatedAsTheTransactionReferenceExists = 'AccountCannotBeDeactivatedAsTheTransactionReferenceExists',
  InternalServerErrorPleaseTryAgainLater = 'InternalServerErrorPleaseTryAgainLater',
  AtleastOneRecordInMandatory = 'AtleastOneRecordInMandatory',
  CannotbeCreatedActivatedPassengerRefundAccountalreadyexist = 'CannotbeCreatedActivatedPassengerRefundAccountalreadyexist',
  CannotbeCreatedActivatedAccountingConfigurationForPaymentChannelalreadyexist =
  'CannotbeCreatedActivatedAccountingConfigurationForPaymentChannelalreadyexist',
  associatedRevenueAccountList = 'associatedRevenueAccountListWarnig',
  associatedRevenueAccountSucessInfo = 'associatedRevenueAccountSucessInfo',
  CreateCollectionAccountWarning = 'CreateCollectionAccountWarning',
  AccountingConfigurationDetailsSuccessInfo = 'AccountingConfigurationDetailsSuccessInfo',
  AccountingConfigurationDetailsModifiedInfo = 'AccountingConfigurationDetailsModifiedInfo',
  CommonFilterMessage = 'CommonFilterMessage'
}
