import { ProfileModel } from '@agility/frameworkcore';

export class TariffitemModel extends ProfileModel {
  public tariffItemId: number;
  public subHeadingId?: number;
  public tariffCode: string;
  public name: string;
  public description: string;
  public startDate?: Date;
  public endDate?: Date;
  public localDescription: string;
  public tariffLineType?: number;
  public year?: number;
  public isTRQ?: boolean;
  public uoM1?: number;
  public dashes: string;
  public infiniteDate?: boolean;
  public hs8DetailId: number;
  public uoM2?: number;
  public uoM3?: number;
  public notes: string;
  public code1?: number;
  public code2?: number;
  public code3?: number;
  public note: string;
  public uoM1Name: string;
  public uoM2Name: string;
  public uoM3Name: string;
  public subheadingEndDate?: Date;
}
