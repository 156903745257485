import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-profile-modal',
  templateUrl: './ProfileModalComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileModalComponent implements OnInit {
  public profileModalInfoForm: FormGroup;
  public profileModalInfoSubmitted: boolean;
  public isEditView: boolean;

  constructor(private _fb: FormBuilder,
    public dialogRef: MatDialogRef<ProfileModalComponent>) {
  }

  // Profile Dialog close
  public closeProfileDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.profileModalInfoForm = this._fb.group({
      fullName: ['', [Validators.required as any, Validators.minLength(5) as any]],
      loginID: ['', [Validators.required as any, Validators.pattern('^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9' +
        '-]+)*(\.[a-z]{2,4})$') as any]],
      primaryMailID: ['', [Validators.required as any, Validators.pattern('^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9' +
        '-]+)*(\.[a-z]{2,4})$') as any]],
      alternateMailID: ['', [Validators.required as any, Validators.pattern('^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9' +
        '-]+)*(\.[a-z]{2,4})$') as any]],
      nationality: ['', [Validators.required as any]],
      idType: ['', [Validators.required as any]],
      idNo: ['', [Validators.required as any]],
      jpnName: ['', [Validators.required as any]],
      gender: ['', [Validators.required as any]],
      designation: ['', [Validators.required as any]],
      departmentName: ['', [Validators.required as any, Validators.minLength(5) as any]],
      customStation: ['', [Validators.required as any]],
      mcountryCode: ['', [Validators.required as any]],
      mobileNumber: ['', [Validators.required as any, Validators.pattern('^[+[0-9]]{12}$') as any]],
      t1countryCode: ['', [Validators.required as any]],
      tele1Input: ['', [Validators.required as any, Validators.pattern('^[+[0-9]]{12}$') as any]],
      t2countryCode: ['', [Validators.required as any]],
      tele2Input: ['', [Validators.required as any, Validators.pattern('^[+[0-9]]{12}$') as any]],
      fcountryCode: ['', [Validators.required as any]],
      faxInput: ['', [Validators.required as any, Validators.pattern('^[+[0-9]]{12}$') as any]],
    });
  }

  public validateProfileModalInfoForm(isValid: boolean) {
    this.profileModalInfoSubmitted = true; // set form submit to true
  }

  public editProfile(): void {
    this.isEditView = true;
  }

  public saveProfile(): void {
    this.isEditView = false;
  }

  public valueChangeEvent(event: any, inputType: any) {
    if (inputType === 'nationality') {
      (this.profileModalInfoForm.controls.nationality).patchValue(event);
    }
    if (inputType === 'idType') {
      (this.profileModalInfoForm.controls.idType).patchValue(event);
    }
    if (inputType === 'customStation') {
      (this.profileModalInfoForm.controls.customStation).patchValue(event);
    }
    if (inputType === 'mcountryCode') {
      (this.profileModalInfoForm.controls.mobileNo).patchValue(event);
    }
    if (inputType === 't1countryCode') {
      (this.profileModalInfoForm.controls.telephoneNumber1).patchValue(event);
    }
    if (inputType === 't2countryCode') {
      (this.profileModalInfoForm.controls.telephoneNumber2).patchValue(event);
    }
    if (inputType === 'fcountryCode') {
      (this.profileModalInfoForm.controls.faxNumber).patchValue(event);
    }
  }
}

