import { ProfileModel } from '@agility/frameworkcore';

export class QuantityUnitModel extends ProfileModel {

  public quantityUnitId: number;

  public unitName: string;

  public unitCode?: string;

  public description: string;

  public status: boolean;

}
