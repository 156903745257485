import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-chart',
  templateUrl: './mapChartComponent.html',
})
export class mapChartComponent implements OnInit {
  @Input() public optionsData: any;

  public map_ChartOptions = {
  };
  ngOnInit() {
  }

  constructor() {

  }
}
