import { ProfileModel } from '@agility/frameworkcore';
import { VolumeUnitModel } from './VolumeUnitModel';
import { WeightUnitModel } from './WeightUnitModel';
import { QuantityUnitModel } from './QuantityUnitModel';

export class TariffitemModel extends ProfileModel {
  public tariffItemId: number;
  public subHeadingId: number;
  public tariffCode: string;
  public name: string;
  public description: string;
  public startDate: Date;
  public endDate: Date;
  public localDescription: string;
  public tariffLineType: number;
  public year: number;
  public isTRQ: boolean;
  public uoM1: number;
  public volumeUnit: VolumeUnitModel;
  public weightUnit: WeightUnitModel;
  public quantityUnit: QuantityUnitModel;
  public dashes: string;
  public hs8DetailId: number;
  public uoM2: number;
  public volumeUnitUOM2: VolumeUnitModel;
  public uoM3: number;
  public volumeUnitUOM3: VolumeUnitModel;
  public hs7DetailId: number;
  public hs5DetailId: number;
  public code1: number;
  public code2: number;
  public code3: number;
}
