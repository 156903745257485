<div class="container mt-3 nopl-pr" fxLayout="column" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayoutAlign="center center" fxLayout="column" fxFlex="90" class="riskFreq-flex">
        <div fxFlex.gt-sm="100" fxFlex.lt-sm="100" class="mb-4 mt-3 overflow-hidden">
            <div class="common-status-border">
                <div class="filter-tab-rgt-section">
                    <div class="filter-container singleicon-filter-container">
                        <div class="new-filter-menu box-shadow-card" [ngClass]="{'open': filterMenuOpened}">
                            <div class="filters-heading" fxLayout="row" fxLayoutAlign="space-between center">
                                <h4>{{'SortFilter' | translate}}</h4>
                                <div class="button-grp">
                                    <a href="javascript:void(0)" mat-raised-button
                                        class="btn-custom-primary-filter mr-2"
                                        (click)="filter()">{{'SEARCH' | translate}}
                                    </a>
                                    <a href="javascript:void(0)" mat-raised-button class="btn-custom-secondary-filter"
                                        (click)="resetFilter()">{{'RESET'|translate}}
                                    </a>
                                </div>
                            </div>
                            <div class="filter-forms border-bottom" *ngIf="filterMenuOpened">
                                <h5>{{'SortBy' | translate }}</h5>
                                <div fxLayout="row wrap" fxLayoutAlign="start">
                                    <div class="sorting-lable" fxLayoutAlign="start center"
                                        [ngClass]="{'sortactive': this.selectedIndex === 2}">
                                        <span class="ml-2 mr-2">{{'TariffCode'| translate}}</span>
                                        <i class="icon-sort-asc" title="{{'Ascending' | translate }}"
                                            [ngClass]="{'asc-active': (this.selectedIndex === 2 && this.selectedAsc === true)}"
                                            (click)="sort($event,2,false)">
                                        </i>
                                        <i class="icon-sort-dec" title="{{'Descending' | translate }}"
                                            [ngClass]="{'dec-active': (this.selectedIndex === 2 && this.selectedDec === true)}"
                                            (click)="sort($event,2,true)">
                                        </i>
                                    </div>
                                    <div class="sorting-lable" fxLayoutAlign="start center"
                                        [ngClass]="{'sortactive': this.selectedIndex === 3}">
                                        <span class="ml-2 mr-2">{{'TariffDescription'| translate}}</span>
                                        <i class="icon-sort-asc" title="{{'Ascending' | translate }}"
                                            [ngClass]="{'asc-active': (this.selectedIndex === 3 && this.selectedAsc === true)}"
                                            (click)="sort($event,3,false)">
                                        </i>
                                        <i class="icon-sort-dec" title="{{'Descending' | translate }}"
                                            [ngClass]="{'dec-active': (this.selectedIndex === 3 && this.selectedDec === true)}"
                                            (click)="sort($event,3,true)">
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-forms" *ngIf="filterMenuOpened">
                                <form [formGroup]="cmmdtyProfileFltrForm" novalidate>
                                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                                        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" class="p-2">
                                            <mcn-input [formGroup]="cmmdtyProfileFltrForm"
                                                placeholder="{{'TariffCode'| translate}}" controlName="tariffCode">
                                            </mcn-input>
                                        </div>
                                    </div>
                                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                                        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" class="p-2">
                                            <mcn-input [formGroup]="cmmdtyProfileFltrForm"
                                                placeholder="TariffDescription"
                                                controlName="{{'TariffDescription'| translate}}">
                                            </mcn-input>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-card class="p-3 ml-3 my-3 user-individual-card user-individual-card-mr"
                    *ngFor="let details of hsList" [ngClass]="['activated']">
                    <div fxLayout="column wrap" class="ml-5">
                        <div fxLayout="row">
                            <div fxFlex="40" class="mt-2">
                                <h6 class="preview-content font-semi-bold">{{details.tariffCode}}</h6>
                                <p class="preview-label">{{'TariffCode'| translate}}</p>
                            </div>
                            <div fxFlex="50" class="mt-2">
                                <h6 class="preview-content font-semi-bold">{{details.description}}</h6>
                                <p class="preview-label">{{'TariffDescription'| translate}}</p>
                            </div>
                            <div fxFlex.xs="10" fxFlex.sm="50" fxFlex.gt-sm="5" class="p-2">
                                <img src="../../../../assets/images/360_Degree_Icon.png" class="cur-pointer"
                                    (click)="openCommodityProfile(details.tariffCode)" title="{{'Commodity360°Profile' | translate}}" />
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mcn-pagination [totalCount]="this.pageCount" (OnChange)="doPagination($event)">
                </mcn-pagination>
            </div>
        </div>
    </div>
</div>

<router-outlet></router-outlet>