import { ClassStmt } from '@angular/compiler';

export class FormData {
    public DocTypeVal: string;
    public idDetails: {
        idType: string;
        idNumber: string;
    };
    public uploadedDocs: {
        docType: string;
        docname: string;
        category: string;
        file: any;
    };
    public IndividualFile: File[] = [];
    public bankDetails: {
        bankId: string;
        bankName: string;
        branch: string;
        accountNo: string;
        ibanCode: string;
    };
    public customStationId: string;
    public registeredCSId: string;
    public OrguploadedDocs: {
        docType: string;
        category: string;
        file: string;
    };
}

export class AccountInfo {
    public emailId: string;
    public password: string;
    public confirmPassword: string;
    public altEmailId: string;
}

export class PersonalInfo {
    public firstName: string;
    public lastName: string;
    public isMaster: boolean;
    public emailId: any;
    public gender: number;
    public IdType: string;
    public IdNumber: string;
    public DocTypeVal: string;
    public idDetails: {
        idType: string;
        idNumber: string;
    };
    public uploadedDocs: {
        docType: string;
        docname: string;
        category: string;
        file: any;
    };
}

export interface IaddressInfo {
    country: string;
    state: string;
    city: string;
    countryname: string;
    statename: string;
    cityname: string;
    pin: string;
    pincode: string;
    addressLine1: string;
    addressLine2: string;
    mobCode: number;
    mobNumber: string;
    primaryTelCode: number;
    primaryTelNumber: string;
    altCode: number;
    altNumber: string;
    faxCode: number;
    faxNumber: string;
    mobTeleCodeValue: string;
    primaryTeleCodeValue: string;
    altrTeleCodeValue: string;
    faxTeleCodeValue: string;
}

export interface IotherInfo {
    bankDetails: {
        bankId: string;
        bankName: string;
        branch: string;
        accountNo: string;
        ibanCode: string;
    };
    customStationId: string[];
    registeredCSId: string;
    registeredCSName: string;
}

export interface IorganizationInfo {
    regAuthority: any;
    businessEntity: any;
    organizationName: any;
    businessEntityId: string;
    regAuthorityName: string;
    brn: string;
    gstNumber: string;
    doctypval: string;
    OrguploadedDocs: {
        docType: string;
        category: string;
        file: string;
    };
}

export interface IbankDetails {
    bankId: string;
    bankName: string;
    branch: string;
    accountNo: string;
    ibanCode: string;
    bankLogo: string;
}

export interface IportDetails {
    locationId: number;
    name: string;
    portType: string;
    unCode: string;
    isDefault: boolean;
}

export interface IcustomStationDetails {
    customStationId: number;
    stationName: string;
    stationCode: string;
    state: string;
    isDefault: string;
}

export interface IstakeHolderDetails {
    postingId: number;
    postingName: string;
    isDefault: boolean;
}

export class stakeHolderList {
    postingId: number;
    postingName: string;
    isDefault: boolean;
}
export interface IprofileDetails {
    postingId: number;
    postingName: string;
    isDefault: boolean;
}

export interface IdocData {
    docType: string;
    docId: string;
    file: File;
}

export class CheckUserModel {
    public emailId: string;
}

export interface IfileData {
    docType: string;
    docId: string;
    fileName: string;
    base64String: string;
    expiryDate: Date;
    docRefNo: any;
}

export interface IregistrationAuthority {
    name: string;
}

export interface IbusinessAuthority {
    name: string;
}

export interface IstackHolderType {
    businessStackHolder: string;
}
