import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';

@Component({
  selector: 'mcn-textarea',
  template: `<div [formGroup]="formGroup" *ngIf="isFormGroup">
       <mat-form-field>
          <textarea matInput id="txt_{{optionalId}}{{placeholder | removeSpace}}" [disabled]="disabled" placeholder="{{placeholder}}" formControlName="{{controlName}}" [required]="required" [readonly]="readonly" maxLength="{{maxlength}}" minLength="{{minlength}}"></textarea>
       </mat-form-field>
    </div>
    <div  *ngIf="!isFormGroup">
       <mat-form-field>
          <textarea matInput id="txt_{{optionalId}}{{placeholder | removeSpace}}" [value]="value" [disabled]="disabled" placeholder="{{placeholder}}"  [required]="required" [readonly]="readonly"  maxLength="{{maxlength}}" minLength="{{minlength}}"></textarea>
       </mat-form-field>
    </div>`
})

export class McnTextareaComponent {
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() readonly = false;
  @Input() isFormGroup = true;
  @Input() disabled = false;
  @Input() value = '';
  @Input() optionalId = '';
  @Input() maxlength = 512;
  @Input() minlength: number;
  @Output() OnChange = new EventEmitter();
  private control: AbstractControl;
  constructor(private ref: ChangeDetectorRef, private http: HttpClient) {

  }

  ngOnInit(): void {
    if (this.isFormGroup) {
      const self: McnTextareaComponent = this;
      self.control = self.formGroup.controls[self.controlName];
      if (self.control) {
        if (self.disabled) {
          self.control.disable();
        }
      }
    }
  }
  ngOnChanges(): void {
    const self: McnTextareaComponent = this;
    if (this.isFormGroup) {
      self.control = self.formGroup.controls[self.controlName];
      if (self.control) {
        if (!self.disabled) {
          self.control.enable();
        }
        if (self.disabled) {
          self.control.disable();
        }
      }
    }
  }
}
