export enum Status {
  Created = 'Created',
  Modified = 'Modified',
  Activated = 'Activated',
  Deactivated = 'Deactivated'
}

export enum MDMDataProfiles {
  Bank = 'BankModel',
  BankBranch = 'BankBranchModel',
  BankDataProfile = 'Bank',
  BankBranchDataProfile = 'BankBranch',
  CityDataProfile = 'City',
  CountryDataProfile = 'Country',
  CurrencyDataProfile = 'Currency',
  StorageLocationData = 'StorageLocation',
  LocationData = 'Location',
  TelePhoneCountryCodedata = 'TelePhoneCountryCode',
  PostalCodeData = 'PostalCode',
  VehicleBrandData = 'VehicleBrand',
  VolumeUnitData = 'VolumeUnit'

}

export enum MdmMessages {
  CommonFilterMessage = 'CommonFilterMessage',
  CountrySaveSuccessMessage = 'CountrySaveSuccessMessage',
  CountrySaveErrorMessage = 'CountrySaveErrorMessage',
  WeightUnitSaveSuccessMessage = 'WeightUnitSaveSuccessMessage',
  WeightUnitSaveErrorMessage = 'WeightUnitSaveErrorMessage',
  CustomStationSaveSuccessMessage = 'CustomStationSaveSuccessMessage',
  CustomStationSaveErrorMessage = 'CustomStationSaveErrorMessage',
  QuantityUnitSaveSuccessMessage = 'QuantityUnitSaveSuccessMessage',
  QuantityUnitSaveErrorMessage = 'QuantityUnitSaveErrorMessage',
  McnTypesSaveSuccessMessage = 'McnTypesSaveSuccessMessage',
  McnTypesSaveErrorMessage = 'McnTypesSaveErrorMessage',
  CustomPreferencesSaveSuccessMessage = 'CustomPreferencesSaveSuccessMessage',
  CustomPreferencesSaveErrorMessage = 'CustomPreferencesSaveErrorMessage',
}
