import { Component, OnInit, Inject } from '@angular/core';
import { StorageService } from '../../../storageService/storageService';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataSource } from '@angular/cdk/table';
import { Observable, BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { ViewDutyRateComponent } from './ViewDutyRateComponent';

export class ExampleDatabase {
  /** Stream that emits whenever the data has been modified. */
  public list: any[] = [
    { hsCode: '', desc: '', uom: '' }
  ];

  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get data(): any[] { return this.dataChange.value; }

  constructor() {
    this.dataChange.next(this.list);
  }
}

export class ExampleDataSource extends DataSource<any> {
  constructor(private _exampleDatabase: ExampleDatabase) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return this._exampleDatabase.dataChange;
  }

  disconnect() { }
}

export class ExampleDatabase1 {
  /** Stream that emits whenever the data has been modified. */
  public list: any[] = [
    { exemptionRequestNumber: '', exemptionCategoryInfoId: '', direction: '', approvedExemptionRate: '' }
  ];

  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get data(): any[] { return this.dataChange.value; }

  constructor() {
    this.dataChange.next(this.list);
  }
}

export class ExampleDataSource1 extends DataSource<any> {
  constructor(private _exampleDatabase: ExampleDatabase1) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return this._exampleDatabase.dataChange;
  }

  disconnect() { }
}

export class ExampleDatabase2 {
  /** Stream that emits whenever the data has been modified. */
  public list: any[] = [
    { quotaName: '', quotaRefCode: '', direction: '' }
  ];

  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get data(): any[] { return this.dataChange.value; }

  constructor() {
    this.dataChange.next(this.list);
  }
}

export class ExampleDataSource2 extends DataSource<any> {
  constructor(private _exampleDatabase: ExampleDatabase2) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return this._exampleDatabase.dataChange;
  }

  disconnect() { }
}

export class ExampleDatabase3 {
  /** Stream that emits whenever the data has been modified. */
  public list: any[] = [
    { title: '', referenceNumber: '', isExpanded: '', conditionalProhibitionAgency: [] }
  ];

  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get data(): any[] { return this.dataChange.value; }

  constructor() {
    this.dataChange.next(this.list);
  }
}

export class ExampleDataSource3 extends DataSource<any> {
  constructor(private _exampleDatabase: ExampleDatabase3) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return this._exampleDatabase.dataChange;
  }

  disconnect() { }
}

export class ExampleDatabase4 {
  /** Stream that emits whenever the data has been modified. */
  public list: any[] = [
    { restrictions: '', agency: '', refReg: '' }
  ];

  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get data(): any[] { return this.dataChange.value; }

  constructor() {
    this.dataChange.next(this.list);
  }
}

export class ExampleDataSource4 extends DataSource<any> {
  constructor(private _exampleDatabase: ExampleDatabase4) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return this._exampleDatabase.dataChange;
  }

  disconnect() { }
}


export class ExampleDatabase5 {
  /** Stream that emits whenever the data has been modified. */
  public list: any[] = [
    { duty: '', startDate: '', endDate: '', tariffItemDutyId: 0 }
  ];

  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get data(): any[] { return this.dataChange.value; }

  constructor() {
    this.dataChange.next(this.list);
  }
}

export class ExampleDataSource5 extends DataSource<any> {
  constructor(private _exampleDatabase: ExampleDatabase5) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return this._exampleDatabase.dataChange;
  }

  disconnect() { }
}


@Component({
  selector: 'app-tariff-search',
  templateUrl: './TariffSearchComponent.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TariffSearchComponent implements OnInit {
  public tariffSearchForm: FormGroup;

  displayedColumns = ['hsCode', 'desc', 'uom'];
  exampleDatabase = new ExampleDatabase();
  dataSource: ExampleDataSource | null;

  displayedColumns1 = ['exemptionRequestNumber', 'exemptionCategoryInfoId', 'direction', 'approvedExemptionRate'];
  exampleDatabase1 = new ExampleDatabase1();
  dataSource1: ExampleDataSource1 | null;

  displayedColumns2 = ['direction', 'quotaName', 'quotaRefCode'];
  exampleDatabase2 = new ExampleDatabase2();
  dataSource2: ExampleDataSource2 | null;

  displayedColumns3 = ['title', 'referenceNumber', 'expand'];
  displayedColumnsSecondTable = ['name', 'agency'];
  exampleDatabase3 = new ExampleDatabase3();
  dataSource3: ExampleDataSource3 | null;
  dataSourceSecondTable = [];

  displayedColumns4 = ['restrictions', 'agency', 'refReg'];
  exampleDatabase4 = new ExampleDatabase4();
  dataSource4: ExampleDataSource4 | null;

  displayedColumns5 = ['duty', 'startDate', 'endDate', 'rate'];
  exampleDatabase5 = new ExampleDatabase5();
  dataSource5: ExampleDataSource5 | null;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public webStorageService: StorageService,
    private http: HttpClient,
    private appConfig: McnConfig
  ) {
    if (data.tariffListData) {
      this.exampleDatabase.list = data.tariffListData;
      this.exampleDatabase.dataChange.next(this.exampleDatabase.list);
    }
    if (data.dutyData) {
      this.exampleDatabase5.list = data.dutyData;
      this.exampleDatabase5.dataChange.next(this.exampleDatabase5.list);
    }
    if (data.exemptionData) {
      this.exampleDatabase1.list = data.exemptionData;
      this.exampleDatabase1.dataChange.next(this.exampleDatabase1.list);
    }
    if (data.quotaData) {
      this.exampleDatabase2.list = data.quotaData;
      this.exampleDatabase2.dataChange.next(this.exampleDatabase2.list);
    }
    if (data.prohibitionData) {
      this.exampleDatabase3.list = data.prohibitionData;
      this.exampleDatabase3.dataChange.next(this.exampleDatabase3.list);
    }
  }

  expandCollapse(row: { isExpanded: boolean; conditionalProhibitionAgency: ConditionalProhibitionAgency[] }, index: string | number) {
    if (row.isExpanded === true) {
      row.isExpanded = false;
    } else {
      row.isExpanded = true;
    }
    this.dataSourceSecondTable[index] = new MatTableDataSource<ConditionalProhibitionAgency>();
    this.dataSourceSecondTable[index].data = row.conditionalProhibitionAgency;
  }

  ngOnInit() {
    this.tariffSearchForm = this.fb.group({
      cif: [''],
      customsDuty: [''],
      tax: [''],
      exciseDuty: [''],
      duties: ['']
    });
    this.dataSource = new ExampleDataSource(this.exampleDatabase);
    this.dataSource1 = new ExampleDataSource1(this.exampleDatabase1);
    this.dataSource2 = new ExampleDataSource2(this.exampleDatabase2);
    this.dataSource3 = new ExampleDataSource3(this.exampleDatabase3);
    this.dataSource4 = new ExampleDataSource4(this.exampleDatabase4);
    this.dataSource5 = new ExampleDataSource5(this.exampleDatabase5);
  }

  public OpenDutyRate(event: any) {
    this.getTariffItemDutyRateData(event.tariffItemDutyRateId).subscribe((data) => {
      this.dialog.open(ViewDutyRateComponent, {
        panelClass: 'custom-modal-container',
        data: {
          dutyRateData: data
        }
      });
    });
  }

  public getTariffItemDutyRateData(tariffItemId: any) {
    return this.http.get(this.appConfig.anonymousViewDutyRateApiUrl + tariffItemId);
  }
}

export class ConditionalProhibitionAgency {
  name: string;
  agency: string;
}
