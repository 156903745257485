import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { NVDBMarketCommodityModel } from '../../models/NVDBMarketCommodityModel';
import { NVDBMarketCommodityService } from '../../services/nvdbMarketCommodity/NVDBMarketCommodityService';

@Injectable()
export class NVDBMarketCommodityListResolver implements Resolve<ServiceDocument<NVDBMarketCommodityModel>> {
  constructor(private service: NVDBMarketCommodityService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<NVDBMarketCommodityModel>> {
    return this.service.listNVDBMarketCommodity('', '');
  }
}

@Injectable()
export class NVDBMarketCommodityOpenResolver implements Resolve<ServiceDocument<NVDBMarketCommodityModel>> {
  constructor(private service: NVDBMarketCommodityService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<NVDBMarketCommodityModel>> {
    return this.service.openNVDBMarketCommodity();
  }
}
