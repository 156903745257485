import { Component, OnInit } from '@angular/core';
import { SafeUnsubscriber } from '../../../../../common/SafeUnsubscriber';
import { StorageService } from '../../../../../storageService/storageService';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-invest-dtls-popup',
  templateUrl: './InvestDtlsPopupComponent.html',
})
export class InvestDtlsPopupComponent extends SafeUnsubscriber implements OnInit {
  constructor(private fb: FormBuilder,
    public webStorageService: StorageService) {
    super();
  }
  ngOnInit() { }
}

