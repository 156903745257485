import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { NVDBMarketVehiclesModel } from '../../models/NVDBMarketVehiclesModel';
import { NVDBMarketVehiclesService } from '../../services/nvdbMarketVehicles/NVDBMarketVehiclesService';

@Injectable()
export class NVDBMarketVehiclesListResolver implements Resolve<ServiceDocument<NVDBMarketVehiclesModel>> {
  constructor(private service: NVDBMarketVehiclesService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<NVDBMarketVehiclesModel>> {
    return this.service.listNVDBMarketVehicles('', '');
  }
}

@Injectable()
export class NVDBMarketVehiclesOpenResolver implements Resolve<ServiceDocument<NVDBMarketVehiclesModel>> {
  constructor(private service: NVDBMarketVehiclesService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<NVDBMarketVehiclesModel>> {
    return this.service.openNVDBMarketVehicles();
  }
}
