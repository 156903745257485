import { ServiceDocument } from '@agility/frameworkcore';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, map } from 'rxjs/operators';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { ToasterService } from '../../toastrService/toasterService';
import { ManifestBalancingListModel } from './models/ManifestBalancingListModel';
import { VesselInPortViewModel } from './models/VesselInPortViewModel';

@Injectable()
export class ManifestBalancingService {
  public manifestBalancingServiceDocument: ServiceDocument<ManifestBalancingListModel> =
    new ServiceDocument<ManifestBalancingListModel>();

  public vesselInPortViewModelServiceDocument: ServiceDocument<VesselInPortViewModel> =
    new ServiceDocument<VesselInPortViewModel>();

  constructor(
    private http: HttpClient, 
    private toastrService: ToasterService, 
    private mcnConfig: McnConfig) {}

   public listManifestBalancing(
    expression: string,
    aliasName: string,
    pageIndex: number = 1,
    recordPerPage: number = this.mcnConfig.appData.pageCount,
    orderIndex: any = 1,
    isDescending: boolean = true): Observable<ServiceDocument<ManifestBalancingListModel>> {

    const filterModel = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending
    };

    return this.manifestBalancingServiceDocument.list(
      this.mcnConfig.getLocationsForMB, filterModel).pipe(map((response) => response), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
    })));
  }

  public viewMB(id: number): Observable<ServiceDocument<ManifestBalancingListModel>> {
    return this.manifestBalancingServiceDocument.open(this.mcnConfig.viewMB, new HttpParams().set('id', id.toString()))
      .pipe(map((response) => response), catchError(
        (res) => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public openCargoCompositionChart(id: number): Observable<ServiceDocument<VesselInPortViewModel>> {
    return this.vesselInPortViewModelServiceDocument.open(this.mcnConfig.openCargoCompositionChart, new HttpParams().set('id', id.toString()))    
      .pipe(map((response) => response), catchError(
        (res) => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  
  private handleError() {
    return this.toastrService.Error('Internal Server Error.Please Try Again Later.');
  }  
}