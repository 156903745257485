<div fxLayout="column wrap" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
  <div fxLayout="column wrap">
    <div fxLayout="row wrap">
      <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="center center">
        <h2 class="main-title pb-0"><span>{{'UploadForm'| translate}}</span></h2>
        <i class="star-heading-ul"></i>
      </div>
    </div>
  </div>
  <div fxLayout="column">
    <div class="mode-of-transport">
      <form [formGroup]="upldCrewListDocs" novalidate>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="pt-2 position-relative">
          <div fxFlex="100" fxFlex.gt-xs="74" class="p-2">
            <mcn-input [formGroup]="upldCrewListDocs" controlName="filePath" placeholder="{{'FilePath'| translate}}"></mcn-input>
            <span class="xml-close-icon"> <i class="icon-close"></i></span>
            <mcn-error-messages [control]="upldCrewListDocs.controls.filePath" class="error customstyle" customErrorMsg=" {{'Fileis'| translate}}"></mcn-error-messages>
          </div>
          <div fxFlex="100" fxFlex.gt-xs="25" class="p-2">
            <span class="custom-upload">
              <span class="preview-content">Browse</span>
              <input type="file" name="file" id="file" class="upload-input" (change)="fileChange($event)" formControlName="file" placeholder="{{'Uploadfile'|translate}}" accept=".xml">
            </span>
          </div>
        </div>
        <div class="orange-grey-theme">
          <div class="button-container">
            <mcn-button type="button" text="{{'Upload'| translate}}" (click)="Close()" cssClass="btn-custom-primary"></mcn-button>
            <mcn-button type="button"  text="{{'Cancel'| translate}}" class="btn-custom-secondary">
            </mcn-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<button matDialogClose class="dialog-close">
  <i class="icon-close"></i>
</button>






