export enum IndexValue {
  IsMinuOne = -1,
  IsZero = 0,
  IsOne = 1,
  IsTwo = 2,
  IsThree = 3,
  IsFour = 4,
  IsFive = 5,
  IsSix = 6,
  IsSeven = 7,
  IsEight = 8,
  IsNine = 9,
  IsTen = 10,
  IsEleven = 11,
  IsTwelve = 12,
  IsFourteen = 14,
  IsFifteen = 15,
  IsSixteen = 16,
  IsTwenty = 20,
  IsTwentyOne = 21,
  IsTwentyFive = 25,
  IsTwoOneFour = 214,
  IsTwoOneFive = 215,
  IsTwoOneSix = 216,
  IsHundred = 100,
  IsNegFifty = -50,
  IsSixHundred = 600,
  IsFourHundred = 400,
  Isonethreeeightseven = 1387,
  IsThirteen = 13,
  IsSeventeen = 17,
  IsEighteen = 18,
  ThreeFiveSix = 356,
}

export enum ModuleName {
  Rms = 'Rms',
  Tariffmngt = 'tariffmngt',
  Declaration = 'Declaration',
  SRAS = 'SRAS',
  Cargo = 'Cargo',
  isTargetingAndMonitoring = 'isTargetingAndMonitoring'
}

export enum TableName {
  Declarations = 'Declarations',
  Cargo = 'Cargo'
}

export enum ClusterName {
  SRASCluster = 'SRASCluster'
}

export enum Constant {
  Customstation = 'C',
  Port = 'P',
  SpecificLocation = 'S',
  NoLocation = 'N',
  Insignificant = 'W1',
  Minor = 'W2',
  Moderate = 'W3',
  Major = 'W4',
  Catastrophic = 'W5',
  IsTrue = 'true',
  FromDate = 'txtdate_FromDate',
  CoveragePeriodFromDate = 'txtdate_CoveragePeriodFromDate',
  LevelOne = '1',
  LevelTwo = '2',
  LevelThree = '3',
  LevelFour = '4',
  LevelFive = '5',
  Predictive = 'Predictive',
  Outlier = 'Outlier',
  PredictiveValue = 'P',
  OutlierValue = 'O',
  true = 'true',
  false = 'false',
  operator1 = 'operator1',
  Weightage1 = 'weightage1',
  greaterThan = '>',
  greaterThanEql = '> =',
  lessThan = '<',
  lessThanEql = '< =',
  space = ' ',
  And = 'and',
  NoChangeRequired = 'NO CHANGE REQUIRED',
  TxtMinValue = 'txt_MinValue',
  ChangeRequired = '',
  Deactivate = '',
  NewLikelihoods = 'NewLikelihoods',
  RiskSelctProfileAllowedActions = 'RiskSelctProfileAllowedActions',
  IsNVDBMarketCommodity = 'C',
  IsNVDBMarketVehicle = 'V',
  Channel = ' Channel',
  NewTraderEligibility = 'New Trader Eligibility',
  M2 = 'M2',
  NotRanked = 'Not Ranked',
  SplitWithHash = '#',
  RED = 'RED',
  Red = 'Red',
  YELLOW = 'YELLOW',
  Yellow = 'Yellow',
  GREEN = 'GREEN',
  Green = 'Green',
  IsVessel = 'v',
  IsMalaysiaLatitude = 4.140634,
  IsMalaysiaLongitude = 109.6181485,
  Record = 'Record ',
  Successfully = ' Successfully',
  VALID = 'VALID',
  RmsDot = 'rms.',
  DataSourceType_N = 'N',
  DataSourceType_T = 'T',
  DataSourceType_S = 'S',
  IsAutoComplete = '&isAutoComplete=',
  PageIndex = '&pageIndex=',
  FilterCount = '&filterCount=',
  SourceMemberName = '&sourceMemberName=',
  AssociatedMember = '&associatedMember=',
  CumulativeScoreConfiguration = 'CumulativeScoreConfiguration',
  operator2 = 'operator2',
  Weightage2 = 'weightage2',
  IsMagnitudeOutlier = 'MAG',
  IsLOFOutlier = 'LOF',
  declarationNumber = 'declarationNumber',
  traderId = 'traderId',
  RPC_ = 'RPC_',
  // tslint:disable-next-line: max-line-length
  NoteToCreateNewRisk = 'Please note, this action will generate a new Risk and existing Risk will be de-linked for this profile.Click OK to proceed.',
  NoteToterRisk = 'Please note, this action will alter the Risk. Click OK to proceed.',
  astricstar = 'astric-star',
  ByDate = 'ByDate',
  ByRef = 'ByRef',
  OneMonth = '1 MONTH',
  ThreeMonth = '3 MONTH',
  SixMonth = '6 MONTH',
  TwelveMonth = '12 MONTH',
  TwentyfourMonth = '24 MONTH',
  ThirtysixMonth = '36 MONTH',
  RC = 'RC_',
  riskId = 'riskId',
  riskProfileId = 'riskProfileId',
  msgInboxId = 'msgInboxId',
  RISKINDICATORTYPES = 'RISKINDICATORTYPES',
  RiskPriority = 'RiskPriority',
  DEROWLEVELOPERATORS = 'DEROWLEVELOPERATORS',
  RiskActionTypes = 'RiskActionTypes',
  closedThen = ') THEN',
  FPSSetPoints = 'FPSSetPoints',
  SDSetPoints = 'SDSetPoints',
  end = 'END',
  PcaAlowedActions = 'PcaAlowedActions',
  watchListFileName = 'Template_WatchList.xlsx',
  RiskIndicatorFileName = 'Template_SRAS.xlsx'
}


export enum AllowedActions {
  RiskSelctProfileAllowedActions = 'RiskSelctProfileAllowedActions',
  RiskIdentificationAndAnalysisAllowedActions = 'RiskIdentificationAndAnalysisAllowedActions'
}


export enum ModelName {
  RiskValuesListModel = 'RiskValuesListModel',
  RisksRegisterListModel = 'RisksRegisterListModel',
  TemplateOpenModel = 'TemplateOpenModel',
  NVDBCommodityListModel = 'NVDBMarketCommodityListModel',
  RoutesListModel = 'RoutesListModel',
  OPMReportsListModel = 'OPMReportsListModel',
  RiskProfilesListModel = 'RiskProfilesListModel',
  RiskProfileTestModel = 'RiskProfileTestModel',
  ComplianceCategorizationsListModel = 'ComplianceCategorizationsListModel',
  SRASListModel = 'SRASListModel',
  RiskInstructionsModel = 'RiskInstructionsModel',
  RisksListModel = 'RisksListModel',
  Declaration = 'Declaration',
  Cargo = 'Cargo',
  PassengerAssessment = 'Passenger Assessment',
  Rms = 'Rms',
  LikelihoodsListModel = 'LikelihoodsListModel',
  NVDBHistoricalDataListModel = 'NVDBHistoricalDataListModel',
  TargetDecisionsListModel = 'TargetDecisionsListModel',
  NVDBMarketVehiclesListModel = 'NVDBMarketVehiclesListModel',
  DataElementEntitiesListModel = 'DataElementEntitiesListModel',
  CumulativeScoreConfigurationListModel = 'CumulativeScoreConfigurationListModel',
  rmsTypesModel = 'rmsTypesModel',
  RmsTypesModel = 'RmsTypesModel',
  TraderProfileOrgModel = 'TraderProfileOrgModel',
  TariffItemsModel = 'TariffItemsModel',
  ConsequencesListModel = 'ConsequencesListModel',
  TargetingDecisionListModel = 'TargetingDecisionListModel',
  TargetingandMonitoringViewModel = 'TargetingandMonitoringViewModel',
  CustomStationViewModel = 'CustomStationViewModel',
  WarehouseFormModel = 'WarehouseFormModel',
  LocationViewModel = 'LocationViewModel',
  RisksOpenModel = 'RisksOpenModel',
  PassportDetailsModel = 'PassportDetailsModel',
  PassengerModel = 'PassengerModel',
  RiskConfigurationModel = 'RiskConfigurationModel',
  SezWarehouseFormModel = 'SezWarehouseFormModel'
}

export enum TypesName {
  RISKTYPES = 'RISKTYPES',
  RISKSUBSOURCETYPES = 'RISKSUBSOURCETYPES',
  AreaOfImpact = 'AreaOfImpact',
  RiskPriority = 'RiskPriority',
  RiskLevelTypes = 'RiskLevelTypes',
  RISKTREATMENTTYPES = 'RISKTREATMENTTYPES',
  ReviewDecisionTypes = 'RiskRegisterReviewDecisionTypes',
  ValuationMethodTypes = 'VALUATIONMETHOD',
  PricingSouceTypes = 'PRICINGSOURCETYPES',
  DirectionTypes = 'DirectionTypes',
  MODEOFTRANSPORTDECLTYPES = 'MODEOFTRANSPORTDECLTYPES',
  VALUATIONMETHODTypes = 'VALUATIONMETHOD',
  RMSCHANNELS = 'RMSCHANNELS',
  RecommendationReasons = 'RecommendationReasons',
  DataSourceTypeDeclaration = 'RPDataSourceType_Declaration',
  DataSourceTypeManifest = 'RPDataSourceType_Manifest',
  DataSourceTypePassegerAssessment = 'RPDataSourceType_Passenger Assessment',
  RiskCategories = 'RISKCATEGORIES',
  DeclarationCategory = 'DeclarationCategory',
  RPDataSourceType = 'RPDataSourceType',
  CustomsControlProcedures = 'CustomsControlProcedures',
  Weightage = 'Weightage',
  TargetingStage = 'TargetingStage',
  ReviewDecisions = 'ReviewDecisions',
  BillTypes = 'BillTypes',
  TandMsources = 'TandMsources',
  DeclarationTypes = 'DeclarationTypes',
  TandMProcedure = 'TandMProcedure',
  ManifestFor = 'ManifestFor',
  MANIFESTFOR = 'MANIFESTFOR',
  RMSDurationMonths = 'RMSDurationMonths',
  RMSEntities = 'RMSEntities'

}

export enum RmsTypeIds {
  Opr_GreaterThan = '>',
  Opr_GreaterThanorEql = '> =',
  Opr_EqualTo = '=',
  entity_trader = 62,
  entity_hscode = 63,
  entity_country = 64,
  entity_port = 65,
  isCumulative = 475,
  isFivePointSummary = 476,
  isStandardDeviation = 477,
  cumulative = 'CumulativePercentage',
  fivePointSummary = '5PointSummary',
  standardDeviation = 'StandardDeviation',
  dataelement = 'DATAELEMENT',
  watchlist = 'WATCHLIST',
  entityRelation = 'ENTITYRELATION',
  randomSelection = 'RANDOMSELECTION',
  mirroranalysis = 'MIRRORANALYSIS',
  predectiveAnalysis = 'PREDANALYSIS',
  outDetection = 'OUTDET',
  thenTypeId = 509,
  endTypeId = 510
}

export enum ProfileName {
  RacmRiskValues = 'RiskValues',
  RisksRegister = 'RisksRegister',
  NVDBCommodity = 'NVDBMarketCommodity',
  NVDBMarketVehicles = 'NVDBMarketVehicles',
  Routes = 'Routes',
  Risks = 'Risks',
  RiskProfiles = 'RiskProfiles',
  Likelihoods = 'Likelihoods',
  Consequences = 'Consequences',
  ComplianceCategorizations = 'ComplianceCategorizations',
  Recommendation = 'Recommendation'
}

export enum Message {
  ConfirmDelete = 'ConfirmDelete',
  DeleteRACM = 'Delete Risk Assessment Criteria Metrics',
  DeleteRisksRegister = 'Delete Risks Register',
  DeleteNVDBCommodity = 'Delete NVDB Market Commodity',
  DeleteNVDBCommoditySpecification = 'Delete NVDB Market Commodity Specification',
  DeleteRoute = 'Delete Route',
  DeleteRisk = 'Delete Risk',
  RiskProfile = 'Risk Profile',
  DeleteRiskProfile = 'Delete Risk Profile',
  RisksRegister = 'Risks Register',
  RisksConfiguration = 'AI Risks Configuration'
}

export enum Racm {
  Insignificant = '1 - Insignificant',
  Minor = '2 - Minor',
  Moderate = '3 - Moderate',
  Major = '4 - Major',
  Catastrophic = '5 - Catastrophic',
  Rare = '1 - Rare',
  Unlikely = '2 - Unlikely',
  Likely = '4 - Likely',
  AlmostCertian = '5 - Almost Certain',
  RareValue = ' - Rare',
  MinorValue = ' - Minor',
  ModerateValue = ' - Moderate',
  MajorValue = ' - Major',
  SevereValue = ' - Severe',
  IsValueOne = '1',
  IsValueTwo = '2',
  IsValueThree = '3',
  IsValueFour = '4',
  IsValueFive = '5',
  IsValueSix = '6',
  IsValueSeven = '7',
  IsValueEight = '8',
  IsValueNine = '9',
  IsValueTen = '10',
  IsValueEleven = '11',
  IsValueTwelve = '12',
  IsValueFifteen = '15',
  IsValueSixteen = '16',
  IsValueTwenty = '20',
  IsValueTwentyFive = '25',
  RiskAssessmentCriteriaMetrics = 'RiskAssessmentCriteriaMetrics',
  IsAlmostCertain = 'C',
  IsScoreingModel1 = 'n'
}

export enum Status {
  Start = 'Start',
  Created = 'Created',
  Modified = 'Modified',
  Approved = 'Approved',
  ReturnedForCorrection = 'Returned For Correction',
  Submitted = 'Submitted',
  Activated = 'Activated',
  Treated = 'Treated',
  Treat = 'Treat',
  ProceedToChange = 'Proceed To Change',
  Identified = 'Identified',
  Analyse = 'Analyse',
  Analysed = 'Analysed',
  ReviewInProgress = 'Review In Progress',
  Deactivated = 'DeActivated',
  deactivated = 'Deactivated',
  activate = 'Activate',
  Completed = 'Completed'
}

export enum TransitionClaims {
  CalculateCompScoreConfigurations = 'CalculateCompScoreConfigurations',
  ApprovedToModifiedCompScoreConfigurations = 'ApprovedToModifiedCompScoreConfigurations',
  ActivatedToReviewInProgressRisksRegister = 'ActivatedToReviewInProgressRisksRegister',
  ModifiedToSubmittedRiskProfiles = 'ModifiedToSubmittedRiskProfiles',
  StartToCreatedTargetDecisions = 'StartToCreatedTargetDecisions',
  StartToCreatedCumulativeScoreConfiguration = 'StartToCreatedCumulativeScoreConfiguration',
}

export enum ActionNames {
  CalculateComplianceScore = 'Calculate Compliance Score',
  NoChangeRequired = 'NO CHANGE REQUIRED',
  ChangeRequired = 'CHANGE REQUIRED',
  TreatOrMitigate = 'TreatMitigate',
  RIConfigured = 'RI Configured',
  INSPECTION = 'INSPECTION',
  Configure = 'Configure',
  Save = 'Save',
  ASSESSMENT = 'ASSESSMENT',
  PROCEEDTOPAYMENT = 'PROCEEDTOPAYMENT',
  SCANNING = 'SCANNING',
  WEIGHING = 'WEIGHING'
}

export enum UserNames {
  RiskManager = 'Risk Manager',
  RiskAnalyst = 'Risk Analyst',
  SeniourRiskAnalyst = 'Senior Risk Analyst',
  ValuationAdmin = 'Valuation Admin',
  DeputyDirectorValuation = 'Deputy Director Valuation',
  RISKSUPERUSER = 'RISKSUPERUSER',
  OGA = 'AHMAD ZAFRI'
}

export enum UserRoles {
  RiskManager = 'RiskManager',
  RiskAnalyst = 'RiskAnalyst',
  RISKSUPERUSER = 'RISKSUPERUSER',
  RISKTARGETINGMONITORINGOFFICER = 'RISKTARGETINGMONITORINGOFFICER',
  OGA = 'OGA',
  Risk = 'RISKANALYST'
}

export enum EventType {
  Load = 'load',
  Click = 'click',
}

export enum TreatmentId {
  TreatOrMitigate = 438,
}

export enum ReviewDecision {
  NoChangeRequired = 192,
}

export enum RequiredWatchlists {
  Organization = 489,
  HSCode = 490,
  Country = 491,
  Port = 492,
  Others = 493,
  Passengers = 620,
  PassportDetails = 621,
}

export enum SuccessErrMsgs {
  InvalidDate = 'Invalid Date',
  ConfirmDelete = 'ConfirmDelete',
  RecordDeletedSuccessfully = 'RecordDeletedSuccessfully',
  InternalServerErrorPleaseTryAgainLater = 'InternalServerErrorPleaseTryAgainLater',
  PleaseSelectoneValue = 'PleaseSelectoneValue',
  RecordModifiedsuccessfully = 'RecordModifiedsuccessfully',
  PleaseselectRoutePointFrom = 'PleaseselectRoutePointFrom',
  PleaseselectRoutePointTo = 'PleaseselectRoutePointTo',
  PleaseFillEstimatedHours = 'PleaseFillEstimatedHours',
  Pleaseenterallfields = 'Pleaseenterallfields',
  OriginandDestinationportshouldnotbesame = 'OriginandDestinationportshouldnotbesame',
  Pleasefillinmandatoryfields = 'Pleasefillinmandatoryfields',
  WeightageScoreError = 'WeightageScoreError',
  RangenotValid = 'RangenotValid',
  RecordCreatedSuccessfully = 'RecordCreatedSuccessfully',
  RecordUpdatedSuccessfully = 'RecordUpdatedSuccessfully',
  ModelValidationFailed = 'ModelValidationFailed',
  EnterOneSearch = 'EnterOneSearch',
  DuplicateRecordsAreNotAllowed = 'DuplicateRecordsAreNotAllowed',
  RiskFrequency = 'Risk Frequency',
  RiskImpact = 'Risk Impact',
  FromRiskIndicators = 'FromRiskIndicators',
  DeleteRiskIndicatorsList = 'DeleteRiskIndicatorsList',
  DeleteRiskFrequency = 'DeleteRiskFrequency',
  DeleteRiskImpact = 'Delete Risk Impact',
  FromDateShouldbeGraterThanCurrentDate = 'FromDateShouldbeGraterThanCurrentDate',
  FromDateShouldbelessThanCurrentDate = 'FromDateShouldbelessThanCurrentDate',
  ToDateShouldbeGraterThanCurrentDate = 'ToDateShouldbeGraterThanCurrentDate',
  ToDateGraterthanFromDate = 'ToDateGraterthanFromDate',
  FromDateToDateRequired = 'FromDateToDateRequired',
  MinValueLessThanMaxValue = 'MinValueLessThanMaxValue',
  MaxValueGreaterThanMinValue = 'MaxValueGreaterThanMinValue',
  FromDateLessthanToDate = 'FromDateLessthanToDate',
  NVDBSpecification = 'NVDBSpecification',
  DeleteTargetDecision = 'DeleteTargetDecision',
  ValueShouldbeLessthanRank1 = 'ValueShouldbeLessthanRank1',
  ValueShouldbeLessthanRank2 = 'ValueShouldbeLessthanRank2',
  ValueShouldbeLessthanRank3 = 'ValueShouldbeLessthanRank3',
  ValueShouldbeLessthanRank4 = 'ValueShouldbeLessthanRank4',
  ValueShouldbeLessthanRank5 = 'ValueShouldbeLessthanRank5',
  NVDBCommodityDelete = 'NVDBCommodityDelete',
  SelectedVehicleData = ' the selected vehicle data ?',
  DeleteNVDBMarketVehicle = 'Delete NVDB Market Vehicle',
  FieldsAssociatedSuccessfully = 'FieldsAssociatedSuccessfully',
  CumulativePercentageError = 'CumulativePercentageError',
  DeleteCumulativePerShareRecord = 'DeleteCumulativePerShareRecord',
  SelectAnyRiskTreatment = 'SelectAnyRiskTreatment',
  RequiredWatchlistAssociation = 'RequiredWatchlistAssociation',
  Norecordexistsforselectedentitiy = 'Norecordexistsforselectedentitiy',
  Pleaseselectentity = 'Pleaseselectentity',
  Pleaseentercount = 'Pleaseentercount',
  Entityalreadyexisted = 'Entityalreadyexisted',
  DeleteSubType = 'DeleteSubType',
  PleaseCheckDisclaimer = 'PleaseCheckDisclaimer',
  PleaseselectanyoneAction = 'PleaseselectanyoneAction',
  DeleteRisk = 'DeleteRisk',
  DeleteRiskProfile = 'DeleteRiskProfile',
  SelectAnyOneRisk = 'SelectAnyOneRisk',
  SelectAnyOneCustomStationOrPort = 'SelectAnyOneCustomStationOrPort',
  SelectAnyOneCustomStation = 'SelectAnyOneCustomStation',
  SelectAnyOnePort = 'SelectAnyOnePort',
  SelectAnyOneAnalysis = 'SelectAnyOneAnalysis',
  WeightageMandatoryInRisksList = 'WeightageMandatoryInRisksList',
  DateValidation = 'DateValidation',
  RouteEntryROuteExitShouldNotBeSame = 'RouteEntryROuteExitShouldNotBeSame',
  ReviewDateValidation = 'ReviewDateValidation',
  AddRemarkstoReturnforCorrection = 'AddRemarkstoReturnforCorrection',
  DuplicateRouteEntry = 'DuplicateRouteEntry',
  RiskScoreError = 'RiskScoreError',
  DateGreaterCurrentDate = 'DateGreaterCurrentDate',
  MasterRecordCannotBeDeleted = 'MasterRecordCannotBeDeleted',
  TodateShouldbelessThanorEqualtoCurrentDateAndGreaterThanorEqualToFromDate =
  'TodateShouldbelessThanorEqualtoCurrentDateAndGreaterThanorEqualToFromDate',
  Riskscoreshouldgreaterthanzero = 'Riskscoreshouldgreaterthanzero',
  Pleaseenterselectivityrule = 'Pleaseenterallfieldsinselectivityrule',
  selectactionselectivityrule = 'selectactionselectivityrule',
  completeselectivityscopeandrule = 'completeselectivityscopeandrule',
  Associateminimumoneinspectioninstruction = 'Associateminimumoneinspectioninstruction',
  Pleaseselectsource = 'Pleaseselectsource',
  Pleaseselectfield = 'Pleaseselectfield',
  Pleaseselectoperator = 'Pleaseselectoperator',
  Pleaseentervalue = 'Pleaseentervalue',
  Pleaseselectcondition = 'Pleaseselectcondition',
  EnterNumberGreaterthanZero = 'EnterNumberGreaterthanZero',
  NumberValidationMessage = 'NumberValidationMessage',
  ExpiryDateShouldbegreaterthan = 'ExpiryDateShouldbegreaterthan',
  PleaseSelectRiskNameFirst = 'PleaseSelectRiskNameFirst'
}

export enum WorkflowStateNames {
  start = 'Start',
  submitted = 'Submitted',
  activated = 'Activated',
  deactivated = 'Deactivated',
  ruleConfigured = 'Rule Configured',
  returnedForCorrection = 'Returned For Correction'
}
export enum RiskprofileTestforMonths {
  One_Month = 1,
  Three_Month = 3,
  Six_Month = 6,
  Twele_Month = 12,
  Twentyfour_Month = 24,
  Thirtysix_Month = 36
}

export enum HttpStatusCode {
  Continue = 100,
  SwitchingProtocols = 101,
  Processing = 102,
  EarlyHints = 103,
  OK = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultiStatus = 207,
  AlreadyReported = 208,
  IMUsed = 226,
  Ambiguous = 300,
  MultipleChoices = 300,
  Moved = 301,
  MovedPermanently = 301,
  Found = 302,
  Redirect = 302,
  RedirectMethod = 303,
  SeeOther = 303,
  NotModified = 304,
  UseProxy = 305,
  Unused = 306,
  RedirectKeepVerb = 307,
  TemporaryRedirect = 307,
  PermanentRedirect = 308,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  RequestEntityTooLarge = 413,
  RequestUriTooLong = 414,
  UnsupportedMediaType = 415,
  RequestedRangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  MisdirectedRequest = 421,
  UnprocessableEntity = 422,
  Locked = 423,
  FailedDependency = 424,
  UpgradeRequired = 426,
  PreconditionRequired = 428,
  TooManyRequests = 429,
  RequestHeaderFieldsTooLarge = 431,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HttpVersionNotSupported = 505,
  VariantAlsoNegotiates = 506,
  InsufficientStorage = 507,
  LoopDetected = 508,
  NotExtended = 510,
  NetworkAuthenticationRequired = 511
}

export enum RiskFrequency {
  Rare = 'Rare',
  Unlikely = 'Unlikely',
  Moderate = 'Moderate',
  Likely = 'Likely',
  AlmostCertain = 'Almost Certain'
}

export enum RiskImpact {
  Insignificant = 'Insignificant',
  Minor = 'Minor',
  Moderate = 'Moderate',
  Major = 'Major',
  Catastrophic = 'Catastrophic'
}

export enum AlertMessages {
  delete = 'delete',
  WarnIcon = 'warn-icon'
}

export enum QueryStringParameter {
  id = 'id',
  pageIndex = 'pageIndex',
  recordPerPage = 'recordPerPage',
  srAId = 'srAId',
  riskValueId = 'RiskValueId',
  decId = 'decId',
  tariffCode = 'tariffCode',
  NVDBMarketComId = 'NVDBMarketComId',
  tariffItemSpecId = 'tariffItemSpecId',
  nvdbMarketVehicleId = 'nvdbMarketVehicleId',
  scoringModel = 'scoringModel',
  criteria = 'criteria',
  RiskIdentificationId = 'RiskIdentificationId',
  pageCount = 'pageCount',
}

export enum WebStorageParameter {
  RiskSelctProfileAllowedActions = 'RiskSelctProfileAllowedActions'
}

export enum RiskIndicatorControl {
  clusterName = 'clusterName',
  riskIndicator = 'riskIndicator',
  status = 'status'
}


export enum ConstantNumber {
  IsMinuOne = -1,
  IsZero = 0,
  IsOne = 1,
  IsTwo = 2,
  IsThree = 3,
  IsFour = 4,
  IsFive = 5,
  IsSix = 6,
  IsTen = 10,
  IsEleven = 11,
  IsTwelve = 12,
  IsFifteen = 15,
  IsSixteen = 16,
  IsTwenty = 20,
  IsTwentyOne = 21,
  IsTwentyFive = 25,
  IsTwoOneFour = 214,
  IsTwoOneFive = 215,
  IsTwoOneSix = 216,
  IsHundred = 100,
  IsTwentyFour = 24,
  IsThirtysix = 36
}

export enum RmsCustomPreferences {
  ValueOne = '1',
  ValueTwo = '2',
  ValueThree = '3',
  ValueFour = '4',
  Value_n = 'n',
  Value_y = 'y',
  Value_N = 'N',
  Value_Y = 'Y',
  Name_Yes = 'Yes',
  Name_No = 'No',
  AI = 'AI',
  NativeRMS = 'Native RMS',
  AIandNativeRMS = 'AI and Native RMS',
  CompoundScoringModel = 'Compound Scoring Model',
  ErrorandRevenueRankbased = 'Error and Revenue Rank based',
  RMS = 'RMS',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  GLRiskChannel = 'GL Risk Channel',
  SellRate = 'Sell Rate',
  MidRate = 'Mid Rate',
  BuyRate = 'Buy Rate'
}

// Risk Register Controls
export enum rrControls {
  orgUnitId = 'orgUnitId',
  auditRemarks = 'auditRemarks',
  inspectionDepId = 'inspectionDepId',
  inspectionRemarks = 'inspectionRemarks',
  prevmaintDepId = 'prevmaintDepId',
  prevmaintRemarks = 'prevmaintRemarks',
  qtyandStdDepId = 'qtyandStdDepId',
  qtyandStdRemarks = 'qtyandStdRemarks',
  technologyUpdteDepId = 'technologyUpdteDepId',
  technologyUpdteRemarks = 'technologyUpdteRemarks',
  supervisionDepId = 'supervisionDepId',
  supervisionRemarks = 'supervisionRemarks',
  testingDepId = 'testingDepId',
  testingRemarks = 'testingRemarks',
  orgArrangementDepId = 'orgArrangementDepId',
  orgArrangementRemarks = 'orgArrangementRemarks',
  technicalCtrlsDepId = 'technicalCtrlsDepId',
  technicalCtrlsRemarks = 'technicalCtrlsRemarks',
  infoDissemDepId = 'infoDissemDepId',
  infoDissemRemarks = 'infoDissemRemarks',
  othersFreqDepId = 'othersFreqDepId',
  othersFreqRemarks = 'othersFreqRemarks',
  contingyPlanningDepId = 'contingyPlanningDepId',
  contingyPlanningRemarks = 'contingyPlanningRemarks',
  designFeaturesDepId = 'designFeaturesDepId',
  designFeaturesRemarks = 'designFeaturesRemarks',
  fraudCtrlPlanningDepId = 'fraudCtrlPlanningDepId',
  fraudCtrlPlanningRemarks = 'fraudCtrlPlanningRemarks',
  minExposureDepId = 'minExposureDepId',
  minExposureRemarks = 'minExposureRemarks',
  valuationCtrlsDepId = 'valuationCtrlsDepId',
  valuationCtrlsRemarks = 'valuationCtrlsRemarks',
  separationDepId = 'separationDepId',
  separationRemarks = 'separationRemarks',
  publicRelationDepId = 'publicRelationDepId',
  publicRelationRemarks = 'publicRelationRemarks',
  othersImpactDepId = 'othersImpactDepId',
  othersImpactRemarks = 'othersImpactRemarks'
}

export enum WatchlistClaim {
  CreatedToActivatedWatchLists = 'CreatedToActivatedWatchLists',
  ModifiedToActivatedWatchLists = 'ModifiedToActivatedWatchLists',
  DeactivatedToActivatedWatchLists = 'DeactivatedToActivatedWatchLists',
  StartToCreatedWatchLists = 'StartToCreatedWatchLists'
}

export enum RiskImpact {
  NewConsequences = 'NewConsequences',
  consequenceLevel = 'consequenceLevel',

}

export enum ColorCode {
  ColorCode60 = '#606060',
  ColorCodeFF = '#FF8000',
  ColorCode4C = '#4C9900',
  ColorCode99 = '#99FFFF',
  ColorCodeFF000 = '#FF0000',
  ColorCode4d = '#4d4d4d',
  ColorCode84 = '#848484',
  ColorCode333 = '#333',
  widthSixPer = '60%',
  widthninehun = '900px',
  ColorCode0099 = '#009900',
  format = '0000'
}

export enum TraderCommodity {
  Origin = 'Origin',
  SupplierName = 'SupplierName',
  Destination = 'Destination',
  BuyerName = 'BuyerName',
  Commodity = 'Commodity',
  Value = 'Value',
  Suppliers = 'Suppliers',
  ExemptionId = 'ExemptionId',
  CostofExemption = 'CostofExemption',
  ReferenceName = 'ReferenceName',
  Cost = 'Cost',
  Validity = 'Validity',
  CPC = 'CPC',
  Transaction = 'Transaction',
  HSCode = 'HSCode',
  Number = 'No.',
  Name = 'Name',
  Director = 'Director',
  percentage = 'percentage',
  Year = 'Year',
  Frequency = 'Frequency',
  Percentage = 'Percentage(%)',
  ItemHSCode = 'Item HS Code',
  import = 'import',
  export = 'export',
  ClassificationCount = 'ClassificationCount',
  ValuationCount = 'ValuationCount',
  TariffCode = 'TariffCode',
  Importer = 'Importer',
  Exporter = 'Exporter',
  LocalManufactureName = 'LocalManufactureName',
  Sectors = 'Sectors',
  Import = 'Import',
  Export = 'Export',
  LocalMfg = 'LocalMfg',
  ImportProcedure = 'ImportProcedure',
  FTAExemption = 'FTAExemption',
  License = 'License',
  Permit = 'Permit',
  Certificate = 'Certificate',
  Others = 'Others',
  TariffRate = 'Tariff Rate',
  Sale = 'Sale',
  label = 'label',
  imPercent = 'imPercent',
  exPercent = 'exPercent',
  value = 'value',
  importsBarChart = 'importsBarChart',
  exportsBarChart = 'exportsBarChart',
  exportsLineChart = 'exportsLineChart',
  importsLineChart = 'importsLineChart',
  traderId = 'traderId',
  importTariffRates = 'importTariffRates',
  exportTariffRates = 'exportTariffRates',
  trendsImport = 'trendsImport',
  trendsExport = 'trendsExport',
  keySources = 'keySources',
  keyDestinations = 'keyDestinations',
  keyImportProcedure = 'keyImportProcedure',
  keyImportProcedureOther = 'keyImportProcedureOther'

}

export enum currentActionId {
  SubmittedToActivatedRisks = 'SubmittedToActivatedRisks',
  SubmittedToReturnedForCorrectionRisks = 'SubmittedToReturnedForCorrectionRisks',
  ModifiedToRuleConfiguredRiskRules = 'ModifiedToRuleConfiguredRiskRules',
  StartToCreatedNVDBCommoditySpecifications = 'StartToCreatedNVDBCommoditySpecifications'
}

export enum RmsTypeCode {
  NOCHANGEREQUIRED = 'NOCHANGEREQUIRED',
  CHANGEREQUIRED = 'CHANGEREQUIRED',
  DEACTIVATE = 'DEACTIVATE',
  ISBETWEEN = ' BETWEEN ',
  ISINTHERANGE = ' BETWEEN ',
  NUMISINTHERANGE = ' BETWEEN ',
  SUM = ' + ',
  AVERAGERANGE = ' + '
}

export enum RequiredWatchlistsTypeCode {
  Organization = 489,
  HSCode = 490,
  Country = 491,
  Port = 492,
  Others = 493,
  entity_trader = 451,
  entity_hsCode = 452,
  entity_country = 453,
  entity_port = 454
}

export enum AiRisks {
  Traders = 'Traders',
  TraderCommodity = 'Trader Commodity',
  ItemPrice = 'Item Price',
  TraderCompliance = 'Trader Compliance',
  Timedelay = 'Time Delay',
  InternalCollusion = 'Internal Collusion',
  Revenue = 'Revenue'
}
