import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class WizardService {
  // Observable string sources
  private serviceDocumentSource = new Subject<any>();

  // Observable string streams
  public serviceDocumentEmitted$ = this.serviceDocumentSource.asObservable();

  // Observable string sources
  private stepCompletionStatusSource = new Subject<number>();

  // Observable string streams
  public stepCompletionStatusEmitted$ = this.stepCompletionStatusSource.asObservable();

  // Service message commands
  public emitStepCompletionStatus(stepCompletionStatus: number) {
    this.stepCompletionStatusSource.next(stepCompletionStatus);
  }

  // Service message commands
  public emitServiceDocument(serviceDocument: any) {
    this.serviceDocumentSource.next(serviceDocument);
  }
}
