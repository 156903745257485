import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { RiskIdentificationAnalysisComponent } from './RiskIdentificationAnalysisComponent';
import { CommodityProfileComponent } from './commodityProfile/CommodityProfileComponent';
import { NVDBCommodityListComponent } from './marketResearchDataCommodity/NVDBCommodityListComponent';
import { MarketResearchDataVehicleComponent } from './marketResearchDataVehicle/MarketResearchDataVehicleComponent';
import { MirrorAnalysisComponent } from './mirrorAnalysis/MirrorAnalysisComponent';
import { NvdbHistoricalDataComponent } from './nvdbHistoricalData/NvdbHistoricalDataComponent';
import { OpmReportsComponent } from './opmReports/OpmReportsComponent';
import { TraderProfileComponent } from './traderProfile/TraderProfileComponent';
import { OpmComplianceReportListComponent } from './opmReports/opmComplianceReportList/OpmComplianceReportListComponent';
import {
  MarketResearchVehicalDetailsComponent
} from './marketResearchDataVehicle/marketResearchVehicalDetails/MarketResearchVehicalDetailsComponent';
import {
  NVDBCommodityFormComponent
} from './marketResearchDataCommodity/NVDBCommodityForm/NVDBCommodityFormComponent';
import { McnControlsModule } from '../../mcnControls/McnControlsModule';
import { CommonRemarksModule } from '../../commonComponents/CommonRemarksModule';
import { NVDBHistoricalDataService } from './services/nvdbHistoricalData/NVDBHistoricalDataService';
import {
  MarketResearchCommodityUploadComponent
} from './marketResearchDataCommodity/marketResearchCommodityUpload/MarketResearchCommodityUploadComponent';
import {
  MarketResearchVehicalUploadComponent
} from './marketResearchDataVehicle/marketResearchVehicalUpload/MarketResearchVehicalUploadComponent';
import { OpmComplianceReportsComponent } from './opmReports/opmComplianceReports/OpmComplianceReportsComponent';
import { NvdbAppliedTarrifHistoryComponent } from './nvdbHistoricalData/nvdbAppliedTarrifHistory/NvdbAppliedTarrifHistoryComponent';
import { NvdbOutlierComponent } from './nvdbHistoricalData/nvdbOutlier/NvdbOutlierComponent';
import {
  RiskIdentificationAnalysisSubheaderComponent
} from './riskIdentificationAnalysisSubheader/RiskIdentificationAnalysisSubheaderComponent';
import { NVDBHistoricalDataListResolver } from './services/nvdbHistoricalData/NVDBHistoricalDataResolver';
import { RiskIdentificationAndAnalysisSharedService } from './services/sharedServices/RiskIdentificationAndAnalysisSharedService';
import { NVDBMarketCommodityService } from './services/nvdbMarketCommodity/NVDBMarketCommodityService';
import {
  NVDBMarketCommodityListResolver, NVDBMarketCommodityOpenResolver
} from './services/nvdbMarketCommodity/NVDBMarketCommodityResolver';
import {
  TraderProfile360DegreeProfileComponent
} from './traderProfile/traderProfile360DegreeProfile/TraderProfile360DegreeProfileComponent';
import { TraderProfileService } from './traderProfile/traderProfile360DegreeProfile/TraderProfileService';
import { Commodity360DegreeProfileComponent } from './commodityProfile/commodity360DegreeProfile/Commodity360DegreeProfileComponent';
import { CommodityProfileService } from './commodityProfile/commodity360DegreeProfile/CommodityProfileService';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NVDBMarketVehiclesService } from './services/nvdbMarketVehicles/NVDBMarketVehiclesService';
import { NVDBMarketVehiclesListResolver, NVDBMarketVehiclesOpenResolver } from './services/nvdbMarketVehicles/NVDBMarketVehiclesResolver';
import {
  WhoTheTraderIsResolver, KeySuppliersBuyersResolver, LocalPurchasesResolver, CostOfExemptionResolver,
  QuotasByCbraResolver, BusinessVolumeResolver, ComplianceHistoryResolver, GoodsConfiscatedResolver,
  AssocPartiesResolver, TraderOrgsListResolver, ImportsBarChartResolver, ExportsBarChartResolver,
  ImportsLineChartResolver, ExportsLineChartResolver
} from './traderProfile/traderProfile360DegreeProfile/TraderProfileResolver';
import {
  TariffDescriptionResolver, RulingsAssociatedResolver, KeyTradersImportResolver, KeyTradersExportResolver,
  KeyProducersResolver, KeySectorsResolver, AppliedTariffsResolver, LpcoResolver, CommodityHsCodeListResolver,
  ImportTariffRatesResolver, ExportTariffRatesResolver, TrendsImportResolver,
  TrendsExportResolver, KeyImportProcedureResolver, KeyImportProcedureOtherResolver, KeySourcesResolver, KeyDestinationsResolver
} from './commodityProfile/commodity360DegreeProfile/CommodityProfileResolver';
import { NvdbCommodityPopUpComponent } from './marketResearchDataCommodity/nvdbCommodityPopUp/NvdbCommodityPopUpComponent';
import { OpmReportsListResolver } from './services/opmReports/OpmReportsResolver';
import { OpmReportsService } from './services/opmReports/OpmReportsService';

// Charts Module
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModule } from 'angular2-highcharts';
import { McnChartModule } from '../../McnChartModule';
import { NVDBOutliersSharedService } from './services/sharedServices/nvdbOutlierSharedService';
import { NVDBOutlierScatterChartComponent } from './nvdbHistoricalData/nvdbOutlierScatterChart/nvdbOutlierScatterChartComponent';
import { RmsModelChartComponent } from './rmsModelChart/RmsModelChartComponent';
import { MirrorAnalysisChartComponent } from './mirrorAnalysis/MirrorAnalysisChart/MirrorAnalysisChartComponent';
const riskIdentificationAnalysisRoutes: Routes = [
  {
    path: '',
    component: RiskIdentificationAnalysisComponent,
    data: {
      title: 'Risk Identification and Analysis'
    },
    children: [
      {
        path: 'trader-profile',
        component: TraderProfileComponent,
        data: {
          title: 'Trader Profile'
        },
        resolve: { traderOrgsListResolver: TraderOrgsListResolver }
      },
      {
        path: 'trader-profile-details',
        component: TraderProfile360DegreeProfileComponent,
        data: { title: 'Trader Profile 360°' },
        resolve: {
          whoTheTraderIsResolver: WhoTheTraderIsResolver,
          keySuppliersBuyersResolver: KeySuppliersBuyersResolver,
          localPurchasesResolver: LocalPurchasesResolver,
          costOfExemptionResolver: CostOfExemptionResolver,
          quotasByCbraResolver: QuotasByCbraResolver,
          businessVolumeResolver: BusinessVolumeResolver,
          complianceHistoryResolver: ComplianceHistoryResolver,
          goodsConfiscatedResolver: GoodsConfiscatedResolver,
          assocPartiesResolver: AssocPartiesResolver,

          importsBarChart: ImportsBarChartResolver,
          exportsBarChart: ExportsBarChartResolver,
          importsLineChart: ImportsLineChartResolver,
          exportsLineChart: ExportsLineChartResolver
        }
      },
      {
        path: 'commodity-profile',
        component: CommodityProfileComponent,
        data: {
          title: 'Commodity Profile'
        },
        resolve: { commodityHsCodeListResolver: CommodityHsCodeListResolver }
      },
      {
        path: 'commodity-profile-details',
        component: Commodity360DegreeProfileComponent,
        data: { title: 'Commodity Profile 360°' },
        resolve: {
          tariffDescriptionResolver: TariffDescriptionResolver,
          rulingsAssociatedResolver: RulingsAssociatedResolver,
          keyTradersImportResolver: KeyTradersImportResolver,
          keyTradersExportResolver: KeyTradersExportResolver,
          keyProducersResolver: KeyProducersResolver,
          keySectorsResolver: KeySectorsResolver,
          appliedTariffsResolver: AppliedTariffsResolver,
          lpcoResolver: LpcoResolver,

          importTariffRates: ImportTariffRatesResolver,
          exportTariffRates: ExportTariffRatesResolver,
          trendsImport: TrendsImportResolver,
          trendsExport: TrendsExportResolver,

          keySources: KeySourcesResolver,
          keyDestinations: KeyDestinationsResolver,

          keyImportProcedure: KeyImportProcedureResolver,
          keyImportProcedureOther: KeyImportProcedureOtherResolver,

        }
      },
      {
        path: 'opm-reports',
        component: OpmReportsComponent,
        data: { title: 'OPM Reports' },
        resolve: { opmReportsList: OpmReportsListResolver },
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'opm-compliance-reports',
        component: OpmComplianceReportListComponent,
        data: {
          title: ' '
        }
      },
      {
        path: 'nvdb-historical-data',
        component: NvdbHistoricalDataComponent,
        data: {
          title: 'NVDB - Historical Data'
        }
      },
      {
        path: 'nvdb-historical-data-List',
        component: NvdbHistoricalDataComponent,
        data: {
          title: 'NVDB - Historical Data'
        }
      },
      {
        path: 'market-research-data-commodity',
        component: NVDBCommodityListComponent,
        data: { title: 'Market Research Data For Commodity' },
        resolve: { nvdbCommodityList: NVDBMarketCommodityListResolver },
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'market-research-commodity-details',
        component: NVDBCommodityFormComponent,
        data: { title: 'Market Research Data For Commodity Details' },
      },
      {
        path: 'market-research-commodity-details-open',
        component: NVDBCommodityFormComponent,
        data: { title: 'Market Research Data For Commodity Details' },
        resolve: { serviceDocument: NVDBMarketCommodityOpenResolver }
      },
      {
        path: 'market-research-commodity-form-open',
        component: NVDBCommodityFormComponent,
        data: { title: 'Market Research Data For Commodity Details' },
        resolve: { serviceDocument: NVDBMarketCommodityOpenResolver }
      },
      {
        path: 'market-research-data-vehicle',
        component: MarketResearchDataVehicleComponent,
        data: { title: 'Market Research Data For Vehicle' },
        resolve: { nvdbVehicleList: NVDBMarketVehiclesListResolver },
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'market-research-vehicle-details',
        component: MarketResearchVehicalDetailsComponent,
        data: { title: 'Market Research Data For Vehicle Details' },
      },
      {
        path: 'market-research-vehicle-details-Form',
        component: MarketResearchVehicalDetailsComponent,
        data: { title: 'Market Research Data For Vehicle Details' },
        resolve: { serviceDocument: NVDBMarketVehiclesOpenResolver }

      },
      {
        path: 'market-research-vehicle-details-Open',
        component: MarketResearchVehicalDetailsComponent,
        data: { title: 'Market Research Data For Vehicle Details' },
        resolve: { serviceDocument: NVDBMarketVehiclesOpenResolver }

      },
      {
        path: 'mirror-analysis',
        component: MirrorAnalysisComponent,
        data: {
          title: 'Mirror Analysis'
        }
      },
      {
        path: 'Model-Charts',
        component: RmsModelChartComponent,
        data: { title: 'AI-ML-NLP Model Charts', preload: true }
      }
    ]
  }
];


@NgModule({
  declarations: [
    RiskIdentificationAnalysisComponent,
    CommodityProfileComponent,
    MarketResearchDataVehicleComponent,
    MirrorAnalysisComponent,
    MirrorAnalysisChartComponent,
    OpmReportsComponent,
    TraderProfileComponent,
    MarketResearchCommodityUploadComponent,
    MarketResearchVehicalUploadComponent,
    OpmComplianceReportsComponent,
    NvdbAppliedTarrifHistoryComponent,
    NvdbOutlierComponent,
    RiskIdentificationAnalysisSubheaderComponent,
    OpmComplianceReportListComponent,
    MarketResearchVehicalDetailsComponent,
    NvdbHistoricalDataComponent,
    NVDBCommodityListComponent,
    NVDBCommodityFormComponent,
    TraderProfile360DegreeProfileComponent,
    Commodity360DegreeProfileComponent,
    NvdbCommodityPopUpComponent,
    NVDBOutlierScatterChartComponent,
    NVDBOutlierScatterChartComponent,
    RmsModelChartComponent
  ],

  imports: [
    CommonModule,
    McnControlsModule,
    CommonRemarksModule,
    MatProgressBarModule,
    NgxChartsModule,
    ChartModule,
    McnChartModule,
    RouterModule.forChild(riskIdentificationAnalysisRoutes
    )
  ],

  exports: [
    RouterModule,
  ],

  entryComponents: [
    MarketResearchCommodityUploadComponent,
    MarketResearchVehicalUploadComponent,
    OpmComplianceReportsComponent,
    NvdbAppliedTarrifHistoryComponent,
    NvdbOutlierComponent,
    TraderProfile360DegreeProfileComponent,
    Commodity360DegreeProfileComponent,
    NvdbCommodityPopUpComponent,
    NVDBOutlierScatterChartComponent,
    MirrorAnalysisChartComponent
  ],

  providers: [
    NVDBHistoricalDataService,
    NVDBHistoricalDataListResolver,
    RiskIdentificationAndAnalysisSharedService,
    NVDBMarketCommodityService,
    NVDBMarketCommodityListResolver,
    NVDBMarketCommodityOpenResolver,
    TraderProfileService, TraderOrgsListResolver, WhoTheTraderIsResolver, KeySuppliersBuyersResolver, LocalPurchasesResolver,
    CostOfExemptionResolver, QuotasByCbraResolver, BusinessVolumeResolver,
    ComplianceHistoryResolver, GoodsConfiscatedResolver, AssocPartiesResolver,
    CommodityProfileService, TariffDescriptionResolver, RulingsAssociatedResolver, KeyTradersImportResolver, KeyTradersExportResolver,
    KeyProducersResolver, KeySectorsResolver, AppliedTariffsResolver, LpcoResolver, CommodityHsCodeListResolver,
    NVDBMarketVehiclesService, NVDBMarketVehiclesListResolver, NVDBMarketVehiclesOpenResolver, ImportTariffRatesResolver,
    ExportTariffRatesResolver, TrendsImportResolver, TrendsExportResolver, KeyImportProcedureResolver, KeyImportProcedureOtherResolver,
    KeySourcesResolver, KeyDestinationsResolver, ImportsBarChartResolver, ExportsBarChartResolver, ImportsLineChartResolver,
    ExportsLineChartResolver, OpmReportsService, OpmReportsListResolver, NVDBOutliersSharedService

  ],
})

export class RiskIdentificationAnalysisRoutingModule {
}
