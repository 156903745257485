export enum StateNames {
  Created = 'Created',
  Modified = 'Modified',
  Submitted = 'Submitted',
  Collected = 'Collected',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Approved = 'Approved',
  RefundIssued = 'Refund Issued',
  Start = 'Start'
}

export enum ActionName {
  StartToCreatedPayment = 'StartToCreatedPayment',
  ModifiedToSubmittedPayment = 'ModifiedToSubmittedPayment',
  CreatedToSubmittedPayment = 'CreatedToSubmittedPayment',
  SubmittedToCollectedPayment = 'SubmittedToCollectedPayment',
  StartToCreatedPaymentTransaction = 'StartToCreatedPaymentTransaction',
  add = 'add'
}

export enum TransactionTypeName {
  GATEWAYB2B = 'GATEWAY (B2B)',
  GATEWAYB2C = 'GATEWAY (B2C)',
  DIRECTDEBIT = 'DIRECT DEBIT',
  TELEGRAPHICTRANSFER = 'TELEGRAPHIC TRANSFER',
  NATIONALPAYMENTNETWORK = 'NATIONAL PAYMENT NETWORK'
}

export enum CommonConstants {
  associateBillList = 'associateBillList',
  billId = 'billId',
  makePayment = 'makePayment',
  CHEQUE = 'CHEQUE',
  BillsModel = 'BillsModel',
  PaymentModel = 'PaymentModel',
  VoucherDetailsModel = 'VoucherDetailsModel',
  paymentTransaction = 'paymentTransaction',
  paymentId = 'paymentId',
  associateTotalAmount = 'associateTotalAmount',
  associateVoucherList = 'associateVoucherList',
  Delete = 'delete',
  WarnIcon = 'warn-icon',
  DeletePaymentTransactionMandatoryMsg = 'Are you sure you want to Delete this Payment Transaction?',
  DeleteTitle = 'Delete',
  DeletePaymentMandatoryMsg = 'Are you sure you want to Delete this Payment?',
  Collect = 'Collect',
  Complete = 'Complete',
  Bill = 'BILL',
  Voucher = 'VOUCHER',
  CreateSuretyTransaction = 'PleaseCreateSuretyTransaction',
  AssociateSuretyTransactionDetails = 'AssociateSuretyTransactionDetails',
  PleaseCreatePaymentTransaction = 'PleaseCreatePaymentTransaction',
  CreateRefundPaymentTrans = 'CreateRefundPaymentTrans',
  SelectedPaymentTransDelSuccessfully = 'SelectedPaymentTransDelSuccessfully',
  PleaseAssociateBill = 'PleaseAssociateBill',
  PleaseAssociateVoucher = 'PleaseAssociateVoucher',
  PerformSave = 'PerformSave',
  PaymentDebitAmount = 'PaymentDebitAmount',
  CreditAmountToDebitAccount = 'CreditAmountToDebitAccount',
  DeletePaymentTransaction = 'DeletePaymentTransaction',
  Confirm = 'Confirm',
  PaymentTransactionNumber = 'PaymentTransactionNumber',
  InternalServerErrorPleaseTryAgainLater = 'InternalServerErrorPleaseTryAgainLater',
  DeleteRefundPaymentTransaction = 'DeleteRefundPaymentTrans',
  DeletePayment = 'DeletePayment',
  PaymentNumber = 'PaymentNumber',
  selectedPaymentdeletedsuccessfully = 'selectedPaymentdeletedsuccessfully',
  EncashSurety = 'EncashSurety',
  Warning = 'Warning',
  transactionTypeDebit = 'DEBIT ACCOUNT',
  PaymentReceiptModel = 'PaymentReceiptModel',
  Pleaseenteratleastoneoption = 'Pleaseenteratleastoneoption',
  AreYouSureYouWantToDeleteThisRefundsForPassenger = 'AreYouSureYouWantToDeleteThisRefundsForPassenger',
  PleaseAssociateAtleastoneiteminGoodsDetailList = 'PleaseAssociateAtleastoneiteminGoodsDetailList',
  RefundforPassengerAssessmentdetailssavedsuccessfully = 'RefundforPassengerAssessmentdetailssavedsuccessfully',
  NewQuantityshouldnotexceedmorethanthatQuantity = 'NewQuantityshouldnotexceedmorethanthatQuantity',
  Pleaseprovidevaliddataforassociategoodsdetails = 'Pleaseprovidevaliddataforassociategoodsdetails',
  SomeQuantityalreadyusedforotherRefundForPassenger = 'SomeQuantityalreadyusedforotherRefundForPassenger',
  Pleaseassociateatleastonegoodsdetails = 'Pleaseassociateatleastonegoodsdetails',
  Associatedpassengerassessmentgoodsdatasavedsuccessfully = 'Associatedpassengerassessmentgoodsdatasavedsuccessfully',
  PaymentBillsDetailsSubmit = 'PaymentBillsDetailsSubmit',
  AssociatedVouchersSubmit = 'AssociatedVouchersSubmit',
  CommonFilterMessage = 'CommonFilterMessage'
}

export enum paymentChannelInfo {
  ONLINEPAYMENT = 'ONLINE PAYMENT',
  SYSTEM = 'SYSTEM',
  V = 'V',
  B = 'B'
}

export enum TransactionType {
  CUSTOMSCOUNTER = 'CUSTOMS COUNTER',
  CASH = 'CASH',
}
export enum Refunds {
  dutyRate = 30,
  goodsDutyRatePercentage = 100,
  ZeroNumber = 0,
}
export enum RefundPassengerAssessmentStates {
  createdToApprovedRefundForPassenger = 'CreatedToApprovedRefundForPassenger',
  startToCreatedRefundForPassenger = 'StartToCreatedRefundForPassenger'
}
