import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mcn-button',
  template: `<button id="btn_{{idValue === '' ? text : idValue + text | removeSpace}}" [disabled]='disabled' type={{type}} class="{{cssClass}}" md-raised-button (click)="_setAction($event)">{{text}}</button>`
})

export class McnButtonComponent {
  @Input() cssClass: string;
  @Input() text: any;
  @Input() type: any;
  @Input() idValue: any = '';
  @Input() disabled: boolean;
  @Output() OnClick = new EventEmitter();

  private _self: McnButtonComponent = this;

  public _setAction(event: any) {
    const _self = this;
    _self.OnClick.emit(event);
  }
}
