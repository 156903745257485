import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { ToasterService } from '../../../toastrService/toasterService';
import { CustomStationFilterModel } from './associatonComponents/assCustsStations/CustomStationFilterModel';
import { TypeModel } from './otherInfo/models/TypeModel';
import { DocumentTypeModel } from './personalInfo/model/documentTypeModel';
import { RegistrationTypeModel } from './personalInfo/model/registrationTypeModel';
import { SubmitModel } from './wizardData/submitModel';
import { CheckUserModel } from './wizardData/wizardDataModel';
import { catchError, map } from 'rxjs/operators';
import { McnConfig } from '../../../mcnConfig/McnConfig';

import { AccountMessages } from '../../constant/accountConstant';
import { throwError } from 'rxjs';
@Injectable()
export class RegistrationService {
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public portType: TypeModel[];
  public isSort = false;
  public isSortCs = false;
  public searchObj1: any;
  public searchObj2: any;
  public orderIndex = 0;
  public orderIndexcS = 0;
  public registrationType: RegistrationTypeModel[];
  public document: DocumentTypeModel[];
  public documentType: RegistrationTypeModel[];
  constructor(
    private http: HttpClient,
    private appConfig: McnConfig,
    private toastrService: ToasterService,

  ) { }

  public SubmitRegData(formModel: SubmitModel): Observable<any> {
    return this.http.post(this.appConfig.registerReg, formModel, { headers: this.headers })
      .pipe(map((response) => this.extractSubmitData(response)), catchError((res) =>
       throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public RegisterAdditionalUSer(formModel: SubmitModel): Observable<any> {
    return this.http.post(this.appConfig.registerAdditionalUSer, formModel, { headers: this.headers })
      .pipe(map((response) => this.extractSubmitData(response)), catchError((res) =>
       throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getCeiliengLimitActiveUserCount(id: any): Observable<any> {
    return this.http.get(this.appConfig.getCeiliengLimitActiveUserCount + '?organizationId=' + id)
      .pipe(map((response) => this.extractSubmitData(response)), catchError((res) =>
        throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public UpdateRegisterAdditionalUser(formModel: SubmitModel): Observable<any> {
    return this.http.post(this.appConfig.updateRegisterAdditionalUser, formModel, { headers: this.headers })
      .pipe(map((response) => this.extractSubmitData(response)), catchError((res) =>
       throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public SwitchOrganizationMasterUser(userProfileId: any, organizationId: number): Observable<any> {
    const userdetails = {
      userId: userProfileId,
      personalId: organizationId,
      contactId: 0
    };
    return this.http.post(this.appConfig.switchOrganizationMasterUser, userdetails, { headers: this.headers })
      .pipe(map((response) => this.extractSubmitData(response)), catchError((res) =>
       throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }


  public checkUser(UserModel: CheckUserModel): Observable<any> {
    return this.http.post(this.appConfig.checkUser, UserModel, { headers: this.headers })
     .pipe(map((response) => response), catchError((res) =>
       throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public checkOrganization(orgName: string, brn: string): Observable<any> {
    return this.http.get(this.appConfig.checkOrganization + '?orgName=' + orgName + '&brn=' + brn)
      .pipe(map((response) => this.extractSubmitData(response)), catchError((res) =>
       throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public upload(fileToUpload: any) {
    const input = new FormData();
    input.append('file', fileToUpload);
    return this.http.post(this.appConfig.uploadFile, input);
  }

  public getTeleCode(id: number): Observable<any> {
    return this.http.get(this.appConfig.getRegistrationTelePhoneCountryCodeId + id, { headers: this.headers })
      .pipe(map((response) => this.extractDomainData(response)), catchError(() =>
       throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getTelePhoneCountryCode(): Observable<any> {
    return this.http.get(this.appConfig.getRegistrationTelePhoneCountryCode, { headers: this.headers })
      .pipe(map((response) => this.extractDomainData(response)), catchError((res) =>
       throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getportsList(expression: string, aliasName: string, associatedIds: any = [],
     pageIndex: number = 1, recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    let portListParameterModel = {
      expression: expression,
      aliasName: aliasName,
      countryName: this.appConfig.localCountryName,
      pageIndex: pageIndex.toString(),
      recordPerPage: recordPerPage.toString(),
      orderIndex: this.orderIndexcS.toString(),
      isDescending: 'true',
      associatedIds: associatedIds
    };

    if (this.isSort) {
      portListParameterModel = this.searchObj1;
    }
    portListParameterModel.orderIndex = this.orderIndex.toString();
    portListParameterModel.isDescending === 'true' ? portListParameterModel.isDescending = 'false' :
     portListParameterModel.isDescending = 'true';

    this.searchObj1 = portListParameterModel;
    return this.http.post(this.appConfig.getRegistrationPortsfilter, portListParameterModel)
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public getBusinessStakeHoldersList(postingName: string): Observable<any> {
    return this.http.get(this.appConfig.getOrgPostings + '?postingName=' + postingName)
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public getGovernmentStakeHoldersList(postingName: string): Observable<any> {
    return this.http.get(this.appConfig.getGovernmenttPostingfilter + '?postingName=' + postingName)
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public getCustomsStakeHoldersList(postingName: string): Observable<any> {
    return this.http.get(this.appConfig.getCustomPostingfilter + '?postingName=' + postingName)
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getcustomstationList(csmodel: CustomStationFilterModel, associatedIds: any = [],
     pageIndex: number = 1, recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {

    let CsFilterParamModel = {
      stationCode: csmodel.stationCode,
      stationName: csmodel.stationName,
      pageIndex: pageIndex.toString(),
      orderIndex: '0',
      isDescending: 'true',
      recordPerPage: recordPerPage,
      associatedIds: associatedIds
    };

    if (this.isSortCs) {
      CsFilterParamModel = this.searchObj2;
    }

    CsFilterParamModel.orderIndex = this.orderIndexcS.toString();
    CsFilterParamModel.isDescending === 'true' ? CsFilterParamModel.isDescending = 'false' : CsFilterParamModel.isDescending = 'true';
    this.searchObj2 = CsFilterParamModel;
    return this.http.post(this.appConfig.getRegistrationCSfilter, CsFilterParamModel, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getIndividualOrgList(pageIndex: number = 1, recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    return this.http.post(this.appConfig.listIndividualRegistartion + '?pageIndex=' + pageIndex +
     '&recordPerPage=' + recordPerPage, '', { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getOragnaizationOrgList(pageIndex: number = 1, recordPerPage: number =
     this.appConfig.appData.associatePageCount): Observable<any> {
    return this.http.post(this.appConfig.listOrganizations + '?pageIndex=' + pageIndex +
     '&recordPerPage=' + recordPerPage, '', { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getGovtOrgList(pageIndex: number = 1, recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    return this.http.post(this.appConfig.listGovernmentReg + '?pageIndex=' + pageIndex +
     '&recordPerPage=' + recordPerPage, '', { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getOrgByUserList(orgId: number, departmentId: number, pageIndex: number = 1,
     recordPerPage: number = this.appConfig.appData.pageCount): Observable<any> {
    return this.http.post(this.appConfig.userListByOrganization + '?organizationId=' +
     orgId + '&departmentId=' + departmentId + '&pageIndex=' + pageIndex + '&recordPerPage=' + recordPerPage, '', { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public registerUser(model: any): Observable<any> {
    return this.http.post(this.appConfig.submitUserData, model, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public upateUser(model: any): Observable<any> {
    return this.http.post(this.appConfig.updateUserData, model, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public openCustomsUserData(userProfId: any): Observable<any> {
    return this.http.get(this.appConfig.getCustomUserData + '?userProfileId=' + userProfId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public myProfile(): Observable<any> {
    return this.http.get(this.appConfig.getMyProfile, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public UpdateDepartment(formModel: any): Observable<any> {
    return this.http.post(this.appConfig.updateDepartment, formModel, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public editProfile(userId: any): Observable<any> {
    return this.http.get(this.appConfig.editProfile + '?userId=' + userId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getIndividualFilterData(fullname: any, refNo: any, status: any, pageIndex: number = 1,
     recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    const filterData = this.GetfilterData(fullname, refNo, status, null, null, null, null, 'I', pageIndex, recordPerPage);
    return this.http.post(this.appConfig.individualUserFilter, filterData, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getOrganaizationFilterData(fullname: any, refNo: any, orgName: any, status: any,
     pageIndex: number = 1, recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    const filterData = this.GetfilterData(fullname, refNo, status, orgName, null, null, null, 'O', pageIndex, recordPerPage);
    return this.http.post(this.appConfig.organizationslUserFilter, filterData, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getGovernmentFilterData(agencyName: any, agencyCode: any, fullname: any, status: any,
     pageIndex: number = 1, recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    const filterData = this.GetfilterData(fullname, null, status, agencyName, null, agencyCode, null, 'G', pageIndex, recordPerPage);
    return this.http.post(this.appConfig.organizationslUserFilter, filterData, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getCustomFilterData(deptId: any, firstName: any, loginId: any, status: any,
     pageIndex: number = 1, recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    const filterData = this.GetfilterData(firstName, null, status, null, deptId, null, loginId, 'C', pageIndex, recordPerPage);
    return this.http.post(this.appConfig.getFilterCustomsDeptPeopleData, filterData, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }


  public getAddressInfo(userId: any): Observable<any> {
    return this.http.get(this.appConfig.getAddressInfo + '?userId=' + userId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public handleError() {
    return this.toastrService.Error(this.appConfig.getHardCodeMSg(AccountMessages.InternalServerErrorPleaseTryAgainLater));
  }
  private getDataFromAPI(url: any): Observable<any> {
    const resp = this.http.get(url, { headers: this.headers });
    return resp;
  }

  private postDataFromAPI(url: any, body: any): Observable<any> {
    const resp = this.http.post(url, body, { headers: this.headers });
    return resp;
  }
  public extractDomainData(response: any) {
    const i = 0;
    let keys: string[];
    keys = Object.keys(response);
    const body = response[keys[i]];
    return body || [];
  }

  public extractSubmitData(response: any) {
    const body = response;
    return body || [];
  }

  private GetfilterData(name: any, registrationReferenceNo: any, status: any, organizationName: any,
     departmentId: any, agencyCode: any, loginId: any, stackHolderType: any, page: number, recordPerPage: number) {
    const filter = {
      name: name,
      registrationReferenceNo: registrationReferenceNo,
      status: status,
      organizationName: organizationName,
      departmentId: departmentId,
      agencyCode: agencyCode,
      loginId: loginId,
      stackHolderType: stackHolderType,
      skipRows: page,
      recordPerPage: recordPerPage,
    };
    return filter;
  }

  public getApplicantLicenseData(orgId: any): Observable<any> {
    return this.http.get(this.appConfig.getRegApplicantLicenseData + '?orgId=' + orgId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

}
