import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { McnConfig } from '../mcnConfig/McnConfig';
import { StorageService } from '../storageService/storageService';
import { ToasterService } from '../toastrService/toasterService';
import { tap } from 'rxjs/operators';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private toasterService: ToasterService,
    public appConfig: McnConfig,
    private router: Router,
    public storageService: StorageService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const httpReq = request;
    return next.handle(httpReq).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.disiplayResponceError(event);
      }
    },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.displayError(err);
        }
      }));
  }


  private disiplayResponceError(event: any) {
    if (!isNullOrUndefined(event.body) && !isNullOrUndefined(event.body.result) &&
      event.body.result !== 200 && event.body.hasOwnProperty('mcnMessageKey')) {
      if (!isNullOrUndefined(event.body.mcnMessageKey)) {
        this.toasterService.Warning(this.appConfig.getHardCodeMSg(event.body.mcnMessageKey));
      }
    }
  }

  private displayError(err: any) {
    switch (err.status) {
      case 400:
      case 403:
      case 404:
      case 412:
      case 500:
      case 502:
      case 503:
        this.toasterService.Error(this.appConfig.getHardCodeMSg('ErrorCode' + err.status));
        break;
      case 401:
        this.toasterService.Error(this.appConfig.getHardCodeMSg('ErrorCode' + err.status));
        this.router.navigate(['/Account/Login']);
        break;
      default:
        this.toasterService.Error(this.appConfig.getHardCodeMSg('InternalServerErrorPleaseTryAgainLater'));
        break;
    }
  }

}
