import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { TariffitemModel } from '../../models/TariffitemModel';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';

import { McnConfig } from '../../../mcnConfig/McnConfig';

import { TariffSharedService } from '../../services/sharedServices/TariffSharedServices';
import { TariffitemDutyModel } from '../../models/TariffitemDutyModel';
import { TariffitemDutyRateModel } from '../../models/TariffitemDutyRateModel';
import { RevenueAccountModel } from '../../models/RevenueAccount';
import { TariffItemSpecificationModel } from '../../models/TariffItemSpecificationModel';
import { TariffLinesFilterModel } from '../../models/TariffLinesFilterModel';
import { ToasterService } from '../../../toastrService/toasterService';
import { TariffItemProductModel } from '../../models/TariffItemProductModel';

import { ProductCodesFilterModel } from '../../models/ProductCodesFilterModel';

import { map, catchError } from 'rxjs/operators';
import { CBRAMDProductCodeModel } from '../../models/ProductCodesModel';
import { DutyRateCountryModel } from '../../models/DutyRateCountryModel';
import { DutyRateCountrySpecificationModel } from '../../models/DutyRateCountrySpecificationModel';
import { TariffAssoSpecificationModel } from '../../models/TariffAssoSpecificationModel';
import { throwError } from 'rxjs';


@Injectable()
export class TariffItemService {
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public serviceDocumentTariff: ServiceDocument<TariffitemModel> = new ServiceDocument<TariffitemModel>();
  public serviceDocumentDuty: ServiceDocument<TariffitemDutyModel> = new ServiceDocument<TariffitemDutyModel>();
  public serviceDocumentDutyList: ServiceDocument<TariffitemDutyModel> = new ServiceDocument<TariffitemDutyModel>();
  public serviceDocumentDutyRate: ServiceDocument<TariffitemDutyRateModel> = new ServiceDocument<TariffitemDutyRateModel>();
  public serviceDocumentRevenueAccount: ServiceDocument<RevenueAccountModel> = new ServiceDocument<RevenueAccountModel>();
  public serviceDocumentTatiffItemSpecification: ServiceDocument<TariffItemSpecificationModel>
    = new ServiceDocument<TariffItemSpecificationModel>();
  public serviceDocumentTatiffItemSpecificationList: ServiceDocument<TariffItemSpecificationModel>
    = new ServiceDocument<TariffItemSpecificationModel>();
  public serviceDocumentTariffItemProduct: ServiceDocument<TariffItemProductModel> = new ServiceDocument<TariffItemProductModel>();
  public serviceDocumentProductCodes: ServiceDocument<CBRAMDProductCodeModel> = new ServiceDocument<CBRAMDProductCodeModel>();
  public serviceDocumentDutyRateCountry: ServiceDocument<DutyRateCountryModel> = new ServiceDocument<DutyRateCountryModel>();
  public serviceDocumentDutyRateSpecification: ServiceDocument<DutyRateCountrySpecificationModel>
    = new ServiceDocument<DutyRateCountrySpecificationModel>();
  public serviceDocumentOtherDutiesSpecification: ServiceDocument<TariffAssoSpecificationModel>
    = new ServiceDocument<TariffAssoSpecificationModel>();
  constructor(
    private appConfig: McnConfig,
    private tariffSharedService: TariffSharedService,
    private http: HttpClient,
    private toasterService: ToasterService,
  ) { }

  public listTariffItem(tariffCode: string = '', subHeadingCode: string = '',
   headingCode: string = '', chapterCode: string = '', description: string = '',
    pageIndex: any = 1, recordPerPage: any = this.appConfig.appData.pageCount,
     orderIndex: any = 1, isDescending: any = true): Observable<ServiceDocument<TariffitemModel>> {
    const tariffLineFilter = new TariffLinesFilterModel();
    tariffLineFilter.tariffCode = tariffCode;
    tariffLineFilter.subHeadingCode = subHeadingCode;
    tariffLineFilter.headingCode = headingCode;
    tariffLineFilter.chapterCode = chapterCode;
    tariffLineFilter.description = description;
    tariffLineFilter.pageIndex = pageIndex;
    tariffLineFilter.recordPerPage = recordPerPage;
    tariffLineFilter.orderIndex = orderIndex;
    tariffLineFilter.isDescending = isDescending;
    return this.serviceDocumentTariff.list(this.appConfig.listTariffItem, tariffLineFilter)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public listSubheadingByHeading(expression: string = '', pageIndex: any = 1, recordPerPage:
    any = this.appConfig.appData.pageCount): Observable<ServiceDocument<TariffitemModel>> {
    return this.serviceDocumentTariff.list(this.appConfig.listTariffBySubHeading, new HttpParams().set('expression', expression)
      .set('subHeadingId', this.tariffSharedService.sharingData.subheadingId.toString()).set('pageIndex', pageIndex.toString())
      .set('recordPerPage', recordPerPage.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public listTariffItemsBySection(pageIndex: any = 1, recordPerPage: any = this.appConfig.appData.pageCount):
    Observable<ServiceDocument<TariffitemModel>> {
    return this.serviceDocumentTariff.list(this.appConfig.getTariffItemByFilter, new HttpParams()
      .set('sectionId', this.tariffSharedService.sharingData.sectionId.toString()).set('pageIndex', pageIndex.toString())
      .set('recordPerPage', recordPerPage.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public newTariffItem(): Observable<ServiceDocument<TariffitemModel>> {
    return this.serviceDocumentTariff.new(this.appConfig.newTariffItem, new HttpParams()
      .set('subHeadingId', this.tariffSharedService.sharingData.subheadingId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public openTariffItem(): Observable<ServiceDocument<TariffitemModel>> {

    return this.serviceDocumentTariff.open(this.appConfig.openTariffItem,
      new HttpParams().set('id', this.tariffSharedService.sharingData.tariffId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public deleteTariffItem(): Observable<ServiceDocument<TariffitemModel>> {

    return this.serviceDocumentTariff.delete(this.appConfig.deleteTariffItem)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public submitTariffItem(): Observable<any> {

    return this.serviceDocumentTariff.submit(this.appConfig.submitTariffItem)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  // Tariff Item Duty and Taxes
  public submitDutyDetails(): Observable<any> {

    return this.serviceDocumentDuty.submit(this.appConfig.submitDutyItemDetails)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public newDutyDetails(): Observable<ServiceDocument<TariffitemDutyModel>> {

    return this.serviceDocumentDuty.new(this.appConfig.newTariffItemDuty)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public openDutyDetails(): Observable<ServiceDocument<TariffitemDutyModel>> {

    return this.serviceDocumentDuty.open(this.appConfig.openTariffItemDuty, new HttpParams()
      .set('id', this.tariffSharedService.sharingData.tariffItemDutyId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public listDutyDetails(pageIndex: any = 1, recordPerPage: any = this.appConfig.appData.pageCount):
    Observable<ServiceDocument<TariffitemDutyModel>> {
    return this.serviceDocumentDutyList.list(this.appConfig.listTariffItemDuty, new HttpParams()
      .set('tariffItemId', this.tariffSharedService.sharingData.tariffId.toString()).set('pageIndex', pageIndex.toString())
      .set('recordPerPage', recordPerPage.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  // Tariff Item Duty Rate
  public newDutyRateDetails(): Observable<ServiceDocument<TariffitemDutyRateModel>> {

    return this.serviceDocumentDutyRate.new(this.appConfig.newTariffItemDutyRate)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public submitDutyRateDetails(): Observable<any> {

    return this.serviceDocumentDutyRate.submit(this.appConfig.submitDutyRateItemDetails)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public openDutyRate(): Observable<ServiceDocument<TariffitemDutyRateModel>> {
    return this.serviceDocumentDutyRate.open(this.appConfig.openTariffItemDutyRate, new HttpParams()
      .set('id', this.tariffSharedService.sharingData.tariffItemDutyId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  // Tariff Item Specification
  public newTariffItemSpecification(): Observable<ServiceDocument<TariffItemSpecificationModel>> {

    return this.serviceDocumentTatiffItemSpecification.new(this.appConfig.newTariffItemSpecification)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public submitTariffItemSpecification(): Observable<any> {

    return this.serviceDocumentTatiffItemSpecification.submit(this.appConfig.submitTariffItemSpecification)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public openTariffItemSpecification(): Observable<ServiceDocument<TariffItemSpecificationModel>> {

    return this.serviceDocumentTatiffItemSpecification.open(this.appConfig.openTariffItemSpecification, new HttpParams()
      .set('id', this.tariffSharedService.sharingData.tariffItemSpecificationId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public listTariffItemSpecification(pageIndex: any = 1, recordPerPage: any = this.appConfig.appData.pageCount):
    Observable<ServiceDocument<TariffItemSpecificationModel>> {
    return this.serviceDocumentTatiffItemSpecificationList.list(this.appConfig.listTariffItemSpecification, new HttpParams()
      .set('tariffItemId', this.tariffSharedService.sharingData.tariffId.toString()).set('pageIndex', pageIndex.toString())
      .set('recordPerPage', recordPerPage.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public checkTariffItemCode(tariffCode: string): Observable<any> {

    return this.http.get(this.appConfig.checkTariffItemCode + '?tariffCode='
      + this.tariffSharedService.sharingCodes.subheadingCode + '.' + tariffCode)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  // Filtered ProductCodes Data in Association
  public getProductCodeDetails(organizationName: string = '', productCode: string = '', productDescription: string = '',
    pageIndex: any = 1, recordPerPage: any = this.appConfig.appData.associatePageCount):
    Observable<ServiceDocument<CBRAMDProductCodeModel>> {
    const productCodesFilterModel = new ProductCodesFilterModel();
    productCodesFilterModel.organizationName = organizationName;
    productCodesFilterModel.productCode = productCode;
    productCodesFilterModel.productDescription = productDescription;
    productCodesFilterModel.pageIndex = pageIndex;
    productCodesFilterModel.recordPerPage = recordPerPage;

    productCodesFilterModel.tariffItemId = parseInt(this.tariffSharedService.sharingData.tariffId.toString());
    return this.serviceDocumentProductCodes.list(this.appConfig.getProductCodesDetails, productCodesFilterModel)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public submitProductCodeDetails(): Observable<any> {
    return this.serviceDocumentTariffItemProduct.submit(this.appConfig.submitProductCodesAssociation);
  }

  public listTariffItemProductCodes(pageIndex: any = 1, recordPerPage: any = this.appConfig.appData.pageCount):
    Observable<ServiceDocument<TariffItemProductModel>> {
    return this.serviceDocumentTariffItemProduct.list(this.appConfig.listTariffItemProductCodes, new HttpParams()
      .set('tariffItemId', this.tariffSharedService.sharingData.tariffId.toString())
      .set('pageIndex', pageIndex.toString()).set('recordPerPage', recordPerPage.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public newTariffItemProductCodes(): Observable<ServiceDocument<TariffItemProductModel>> {

    return this.serviceDocumentTariffItemProduct.new(this.appConfig.newTariffItemProductCodes, new HttpParams()
      .set('tariffItemId', this.tariffSharedService.sharingData.tariffId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public deleteTariffItemProductCodes(id: number): Observable<ServiceDocument<TariffItemProductModel>> {

    return this.serviceDocumentTariffItemProduct.open(this.appConfig.deleteTariffItemProductCodes,
      new HttpParams().set('id', id.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  // Country Association for DutyRateCountry
  public newDutyRateCountryAssociation(): Observable<ServiceDocument<DutyRateCountryModel>> {

    return this.serviceDocumentDutyRateCountry.new(this.appConfig.newDutyRateCountriesAssociation,
      new HttpParams().set('tariffItemsDutyRateId', this.tariffSharedService.sharingData.tariffItemDutyRateId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public submitDutyRateCountryAssociation(): Observable<any> {

    return this.serviceDocumentDutyRateCountry.submit(this.appConfig.submitDutyRateCountryAssociation)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  // Specification Association
  public getSpecificationDetails(code: string = '', caption: string = '', pageIndex: any = 1, recordPerPage:
    any = this.appConfig.appData.associatePageCount): Observable<any> {
    const getSpecificationDetailsParamModel = {
      tariffItemId: this.tariffSharedService.sharingData.tariffItemId.toString(),
      code: code,
      caption: caption,
      isDescending: true,
      orderIndex: 0,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      isAntiDumping: this.tariffSharedService.sharingData.isAntiDumping,
      isOtherDuties: this.tariffSharedService.sharingData.isOtherDuties
    };

    return this.serviceDocumentTariff.list(this.appConfig.getSpecificationAssociations, getSpecificationDetailsParamModel)
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  // Tariff  Specification

  public newTariffDutyRateSpecification(): Observable<ServiceDocument<DutyRateCountrySpecificationModel>> {
    return this.serviceDocumentDutyRateSpecification.new(this.appConfig.newSpecificationAssociation,
      new HttpParams().set('dutyRateCountryId',
        this.tariffSharedService.sharingData.dutyRateCountryId.toString()).set('tariffItemId',
          this.tariffSharedService.sharingData.tariffItemId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public submitTariffDutyRateSpecification(): Observable<any> {

    return this.serviceDocumentDutyRateSpecification.submit(this.appConfig.submitDutyRateSpecificationAssociation)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  // other Duties Association
  public newTariffOtherDutiesSpecification(): Observable<ServiceDocument<TariffAssoSpecificationModel>> {

    return this.serviceDocumentOtherDutiesSpecification.new(this.appConfig.newOtherDutiesSpecificationAssociation,
      new HttpParams().set('dutyRateId', this.tariffSharedService.sharingData.dutyRateId.toString()).set('tariffItemId',
        this.tariffSharedService.sharingData.tariffItemId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public submitTariffOtherDutiesSpecification(): Observable<any> {

    return this.serviceDocumentOtherDutiesSpecification.submit(this.appConfig.submitOtherDutiesSpecificationAssociation)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getTariffCodeDetails(id: any): Observable<any> {

    return this.http.get(this.appConfig.getTariffCodeDetails + '?tariffItemId=' + id)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  private handleError() {
    return this.toasterService.Error('Internal Server Error.Please Try Again Later.');
  }
  private extractSubmitData = (response: any): void => {
    const body = response;
    return body || [];
  }
}
