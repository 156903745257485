import { Component, OnInit } from '@angular/core';
import {
  areaData2018, bar_ChartData,
  bubble_ChartData, candle_ChartData, guageData2018,
  lineData2018, map_ChartData,
  pieChartData2018, scatter_ChartData,
  yMulti2018, yMulti2017,
  guageData2017, pieChartData2017,
  areaData2017, lineData2017
} from './data/data';
import { Statistics, UserDashboardData } from './models/Statistics';
import { UserDashboardService } from './userDashboardService';
import { StorageService } from '../../storageService/storageService';
@Component({
  selector: 'app-user-dashboard',
  templateUrl: './userDashboardComponent.html',
})

export class userDashboardComponent implements OnInit {
  public areaData: any;
  public areaPrevYrData: any;
  public guageData: any;
  public guagePrevYrData: any;
  public stackedChartData: any;
  public stackedChartPrevYrData: any;
  public lineData: any;
  public linePrevYrData: any;
  public pieChartData: any;
  public pieChartPrevYrData: any;
  public bar_ChartData: any;
  public map_ChartData: any;
  public candle_ChartData: any = candle_ChartData;
  public bubble_ChartData: any = bubble_ChartData;
  public scatter_ChartData: any = scatter_ChartData;
  public date: Date;
  public statisticsData: Statistics;
  public userDashboard: UserDashboardData;

  constructor(private userDashboradService: UserDashboardService,    public webStorageService: StorageService) {
  }
  public countries = [
    { value: 'ma', viewValue: 'Malaysia' },
  ];


  public years = [
    { value: '2020', viewValue: '2020' },
    { value: '2021', viewValue: '2021' }
  ];

  ngOnInit() {
    this.date = new Date();
    this.userDashboradService.getStats().subscribe((res) => {
      this.userDashboard = res;
      this.statisticsData = this.userDashboard.statistics
        ||
      {
        berthAvailability: null,
        exportBills: 1800,
        importBills: 1250,
        manifestReceived: 3450,
        totalContainers: 990,
        vesselsInPort: null,
        manifestsProcessed: 4769,
        doProcessed: 7913
      };
      this.stackedChartData = this.userDashboard.bolStackedChartByStatusCurrentYear || yMulti2018;
      this.stackedChartPrevYrData = this.userDashboard.bolStackedChartByStatusPreviousYear || yMulti2017;
      this.guageData = this.userDashboard.arrivalByMotCurrentYear || guageData2018;
      this.guagePrevYrData = this.userDashboard.arrivalByMotPreviousYear || guageData2017;
      this.pieChartData = this.userDashboard.importationByVesselTypeCurrentYear || pieChartData2018;
      this.pieChartPrevYrData = this.userDashboard.importationByVesselTypePreviousYear || pieChartData2017;
      this.areaData = this.userDashboard.manifestByModeTypeCurrentYear || areaData2018;
      this.areaPrevYrData = this.userDashboard.manifestByModeTypePreviousYear || areaData2017;
      this.bar_ChartData = this.userDashboard.topExportingCountryCurrentYear || bar_ChartData;
      this.lineData = this.userDashboard.transactionProcessedCurrentYear || lineData2018;
      this.linePrevYrData = this.userDashboard.transactionProcessedPreviousYear || lineData2017;
      this.map_ChartData = this.userDashboard.importationValueByCountryCurrentYear || map_ChartData;
    });
  }

}
