import { ProfileModel } from '@agility/frameworkcore';
import { BankBranchModel } from './BankBranchModel';

export class BankModel extends ProfileModel {
  public bankId: number;
  public bankName: string;
  public bankCode: string;
  public isAutoDebitforExcise: boolean;
  public bankBranch: BankBranchModel;
  public status: boolean;

}


