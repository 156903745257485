export enum TypeCodeConstants {
  PETROLEUMINFO = 'PETROLEUM INFO',
  TARIFFVALUATION = 'Tariffvaluation',
  MalaysiaRinggit = 'Malaysia Ringgit',
  TARIFFVEHICLETYPES = 'TARIFFVEHICLETYPES',
  TARIFFVEHICLECATEGORY = 'TARIFFVEHICLECATEGORY',
  TRANSMISSIONTYPES = 'TRANSMISSIONTYPES',
  DECLARATION = 'Declaration',
  CONFIGURATIONTYPE = 'CONFIGURATIONTYPE',
}

export enum VehicleTypes {
  VEHICLE = 'VEHICLE',
  MOTORCYCLE = 'MOTORCYCLE'
}

export enum TarrifValConstants {
  PetroleumInformationModel = 'PetroleumInformationModel',
  MotorVehiclePriceDataModel = 'MotorVehiclePriceDataModel',
  VehicleAgeConfigurationModel = 'VehicleAgeConfigurationModel',
  ExchangeRateModel = 'ExchangeRateModel',
  OtherGoodsPriceDataFileName = 'Template_PriceDocketOtherGoods.xlsx',
  ExchangeRateFileName = 'Template_ExchangeRate.xlsx',
  FileMimeType = 'application/vnd.ms-excel',
  MotorVehicleValuationModel = 'MotorVehicleValuationModel',
  ListMotorVehicleValuationModel = 'ListMotorVehicleValuationModel'
}
export enum Status {
  Submit = 'Submitted',
  Created = 'Created',
  Modified = 'Modified',
  Save = 'Save',
  Approved = 'Approved',
  ReturnForCorrection = 'Return for Correction',
  ReturnedForCorrection = 'Returned For Correction',
  Activated = 'Activated',
  Start = 'Start',
  ApprovedToRequestedForCancellationPriceDataOtherGood = 'ApprovedToRequestedForCancellationPriceDataOtherGood',
  ReturnedForCorrectionToSubmitteReturnForCorrectionTValuation = 'ReturnedForCorrectionToSubmitteReturnForCorrectionTValuation',
  RequestedForCancellationToReturnedForCorrectionTValuation = 'RequestedForCancellationToReturnedForCorrectionTValuation',
  SubmittedToReturnedForCorrectionPriceDataOtherGood = 'SubmittedToReturnedForCorrectionPriceDataOtherGood',
  RequestedForCancellationToRejectedTValuation = 'RequestedForCancellationToRejectedTValuation',
  SubmittedToRejectedTValuation = 'SubmittedToRejectedTValuation',
  RequestedForCancellationToApprovedCancellationRequestPriceDataOtherGood = 'RequestedForCancellationToApprovedCancellationRequestPriceDataOtherGood',
  TValuationType = 'TariffValuation',
  SubmittedToReturnforCorrectionMotorVehcilePriceData = 'SubmittedToReturnforCorrectionMotorVehcilePriceData',
  RequestedForCancellationToCancellationReturnedForCorrectionTValuation = 'RequestedForCancellationToCancellationReturnedForCorrectionTValuation',
  CancellationReturnedForCorrectionToRequestedForCancellation = 'CancellationReturnedForCorrectionToRequestedForCancellation'
}
export enum ErroMessages {
  WarningMsg = 'CommonFilterMessage',
  WarningMsgs = 'Please Select option!',
  Delete = 'delete',
  WarnIcon = 'warn-icon',
  DeleteMotorVehicleMandatoryMsg = 'Are you sure you want to Delete Motor Vehicle ?',
  DeleteTitle = 'Delete',
  SuccessMsg = 'Selected Price Data  deleted successfully.',
  AlertMsg = 'At least one Petroleum Price is required.',
  TarrifDateWarningMsg = 'Please Select Either End Date or Infinite Date ',
  MonthErrorMsg = 'Start Month Should not be more than 72 months',
  EndMonthErrorMsg = 'End Month Should be greater than Start Month',
  StartMonthErrorMsg = 'Start Month should be more than the previous End Month',
  PreviousStartMonthErrorMsg = 'Start Month should be greater than or equal to Previous Start Month',
  DeleteVehicleAgeRateMsg = 'Are you sure you want to Delete Vehicle Age(%) ?',
  VehicleAgeConfigurationErrorMsg = 'Vehicle Age Configuration with same Configuration Type and Vehicle Type combination is already exist. So, Vehicle Age Configuration cannot be created',
  DateErrorMessage = 'End date should not be less than Start date',
  PetroleumValidation = 'Dutiable Goods with Same Currency and Start and EndDate was already Created',
  ExchageRateValidation = 'Exchange Rate Should not be Empty Please select another Currency',
  ExchageRateAlert = 'ExchageRateAlert',
  acknowledgeSubmissionofMotorVehiclesValuationDetails = 'Please acknowledge the submission of Motor Vehicles Valuation Details!',
  DeleteMotorVehicle = 'DeleteMotorVehicle',
  ConfirmDelete = 'ConfirmDelete',
  ApplicationNumber = 'Application Number? ',
  selectedMotorVehicleValuationdeletedsuccessfully = 'selectedMotorVehicleValuationdeletedsuccessfully',
  InternalServerErrorPleaseTryAgainLater = 'InternalServerErrorPleaseTryAgainLater',
  UploadDocuments = 'Upload Documents',
  CreateCancellationRequestBeforeUploadDocument = 'CreateCancellationRequestBeforeUploadDocument',
  UploadAtLeast1Doc = 'UploadAtLeast1Doc',
  AtleastoneTransactionisMandatory = 'AtleastoneTransactionisMandatory',
  DeleteTransaction = 'DeleteTransaction',
  ConfirmDeleteTransaction = 'ConfirmDeleteTransaction',
  DeletedTransactionItem = 'DeletedTransactionItem',
  InternalServerError = 'InternalServerError',
  UnabletoDeleteTransactionAfterSubmissionofPriceData = 'UnabletoDeleteTransactionAfterSubmissionofPriceData',
  ErrorMessageVehicleAgeConfiguration = 'ErrorMessageVehicleAgeConfiguration'
}

export enum UserRoles {
  ValuationDeputyDirector = 'ValuationDeputyDirector',
  PetroleumDataEntryOfficer = 'PetroleumDataEntryOfficer',
  ValuationDeskOfficer = 'ValuationDeskOfficer'
}
export enum VehicleCondition {
  NEW = 'NEW',
  USED = 'USED',
  CLASSIC = 'CLASSIC'
}

export enum InvoiceType {
  CIF = 'CIF',
  CIFC = 'CIFC',
  CNF = 'CNF',
  FOB = 'FOB'
}

export enum VehicleCategories {
  VEHICLE = 'TARIFFVEHICLETYPES_VEH',
  MOTORCYCLE = 'TARIFFVEHICLETYPES_MOT'
}
export enum ModuleNameConstants {
  TariffValManagement = 'tariffvaluation',
}
export enum ConfigurationTypes {
  OPEN = 'OPEN',
  FRANCHISE = 'FRANCHISE',
  INDIVIDUAL = 'INDIVIDUAL'
}
export enum StartTypes {
  LessThan = 'Less Than',
  GreaterThanEqualsTo = 'Greater Than Equals To'
}
export enum CurrentActionId {
  SaveNewMotorVehicleValuation = 'SaveNewMotorVehicleValuation',
  CreatedToSubmittedMotorVehicleValuation = 'CreatedToSubmittedMotorVehicleValuation',
  ModifiedToSubmittedMotorVehicleValuation = 'ModifiedToSubmittedMotorVehicleValuation',
  SubmittedToReturnForCorrectionMotorVehicleValuation = 'SubmittedToReturnForCorrectionMotorVehicleValuation',
  SubmittedToRecommendedMotorVehicleValuation = 'SubmittedToRecommendedMotorVehicleValuation',
  PriceListIssuedToGazettedMotorVehicleValuation = 'PriceListIssuedToGazettedMotorVehicleValuation',
  SubmittedToReturnforCorrectionMotorVehcilePriceData = 'SubmittedToReturnforCorrectionMotorVehcilePriceData',
  SubmittedToApprovedMotorVehcilePriceData = 'SubmittedToApprovedMotorVehcilePriceData'
}
export enum ProfileNames {
  PaymentDataProfile = 'MotorVehicleValuation',
  ExchangeRateDataProfile = 'ExchangeRate',
  MotorVehiclePriceData = 'MotorVehiclePriceData'
}

export enum Mode {
  JourneyMode = 'journeyMode'
}

export enum TariffValutionAlertMessage {
  delete = 'delete',
  WarnIcon = 'warn-icon',

}
