import { Component, Input, Output, OnInit, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'mcn-delete-icon',
  template: `<a fxLayoutAlign="center center" *ngIf="this.isEnable" class="action-icon" matTooltip="{{'Delete'|translate}}" >
                <i class="icon-delete" (click)="_setAction($event)"></i>
              </a>`
})
export class McnDeleteIconButtonComponent implements OnInit, OnChanges {
  @Input() allowedActions: any[];
  @Input() workflowStateId: string;
  @Output() OnClick = new EventEmitter();
  public isEnable: boolean;

  ngOnInit(): void {
    this.getData();
  }

  public _setAction(event: any) {
    const _self: McnDeleteIconButtonComponent = this;
    _self.OnClick.emit(event);
  }

  public ngOnChanges(): void {
    this.getData();
  }


  private getData() {
    if (this.allowedActions !== undefined) {
      this.isEnable = this.allowedActions.findIndex(x => x.fromStateId === this.workflowStateId && x.actionName === 'Delete') > -1;
    }
  }
}
