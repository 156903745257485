import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ManifestBalancingService } from './ManifestBalancingService';
import { ManifestBalancingListModel } from './models/ManifestBalancingListModel';

@Injectable()
export class ManifestBalancingListResolver implements Resolve<ServiceDocument<ManifestBalancingListModel>> {
  constructor(private service: ManifestBalancingService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<ManifestBalancingListModel>> {
    return this.service.listManifestBalancing('', '');
  }
}

@Injectable()
export class ManifestBalancingViewResolver implements Resolve<ServiceDocument<ManifestBalancingListModel>> {
    constructor(private service: ManifestBalancingService) { }

    public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<ManifestBalancingListModel>> {
        return this.service.viewMB(route.params.id);
    }
}