import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { StorageService } from '../../../../storageService/storageService';
import { ForgotPasswordActivation } from './ForgotPasswordActivation';
import { ValidationService } from '../../../../validations/ValidationService';
import { SafeUnsubscriber } from '../../../../common/SafeUnsubscriber';

@Component({
  selector: 'app-forgot-pwd',
  templateUrl: './forgotPasswordActivationComponent.html',
})

export class ForgotPasswordActivationComponent extends SafeUnsubscriber implements OnInit {
  public forgotPasswordActivationForm: FormGroup;
  public forgotPasswordActivationFormSubmitted: boolean;
  public forgotPasswordActivation: ForgotPasswordActivation = new ForgotPasswordActivation();
  public emailId: any;
  public token: any;
  public notSame = false;
  public usersCount: any;
  public usersError = false;
  @ViewChild('confirmPassword') public cnfrmPasswordVar: any;
  @ViewChild('newPassword') public passwordVar: any;
  public bar0: string;
  public bar1: string;
  public bar2: string;
  public bar3: string;
  public bar4: string;

  public force = 0;
  public regex = /[$-/:-?{-~!"^_`\[\]]/g;

  public lowerLetters = false;
  public upperLetters = false;
  public numbers = false;
  public symbols = false;

  public flags = [];

  public passedMatches = 0;
  public colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
  public selectedValue: string;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ForgotPasswordActivationComponent>,
    private validationService: ValidationService,
    public storageService: StorageService,
  ) {
    super();
  }

  public measureStrength(p) {
    this.force = 0;
    this.lowerLetters = /[a-z]+/.test(p);
    this.upperLetters = /[A-Z]+/.test(p);
    this.numbers = /[0-9]+/.test(p);
    this.symbols = this.regex.test(p);

    this.flags = [this.lowerLetters, this.upperLetters, this.numbers, this.symbols];

    this.passedMatches = 0;

    for (let i = 0; i < this.flags.length; i++) {
      this.passedMatches += this.flags[i] === true ? 1 : 0;
    }
    this.force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    this.force += this.passedMatches * 10;

    // penality (short password)
    this.force = (p.length <= 6) ? Math.min(this.force, 10) : this.force;

    // penality (poor variety of characters)
    this.force = (this.passedMatches === 1) ? Math.min(this.force, 10) : this.force;
    this.force = (this.passedMatches === 2) ? Math.min(this.force, 20) : this.force;
    this.force = (this.passedMatches === 3) ? Math.min(this.force, 40) : this.force;

    return this.force;
  }

  public getColor(s) {
    let idx = 0;
    if (s <= 10) {
      idx = 0;
    } else if (s <= 20) {
      idx = 1;
    } else if (s <= 30) {
      idx = 2;
    } else if (s <= 40) {
      idx = 3;
    } else {
      idx = 4;
    }
    return {
      idx: idx + 1,
      col: this.colors[idx],
    };
  }

  public setBarColors(count, col) {
    for (let n = 0; n < count; n++) {
      this['bar' + n] = col;
    }
  }

  ngOnInit() {
    this.forgotPasswordActivationForm = this.fb.group({
      emailId: [this.forgotPasswordActivation.emailId, [Validators.required as any, this.validationService.EmailValidation()]],
      token: [this.forgotPasswordActivation.token],
      confirmPassword: [this.forgotPasswordActivation.confirmPassword, [Validators.required as any]],
      newPassword: [this.forgotPasswordActivation.newPassword, [Validators.required as any,
      this.validationService.PasswordValidation(8, 0)]],
    });
    this.forgotPasswordActivationForm.valueChanges.subscribe((data) => {
      this.setBarColors(5, '#DDD');
      if (data.password) {
        const c = this.getColor(this.measureStrength(data.password));
        this.setBarColors(c.idx, c.col);
      }
    });
  }

  public validateForgotPasswprdActivation(model: ForgotPasswordActivation, isValid: boolean) {
  }
  public cancel() {
    event.preventDefault();
    this.dialogRef.close();
  }

  public cnfrmPasskey(val: string) {
    if (this.forgotPasswordActivationForm.get('confirmPassword').valid && val !== this.passwordVar.nativeElement.value) {
      this.notSame = true;
    } else {
      this.notSame = false;
    }
  }
  public emailKeyPress() {
    this.usersError = false;
  }
}
