import { Component, NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { StorageService } from '../../storageService/storageService';

@Component({
  selector: 'alert-message-modal',
  templateUrl: './AlertMessageModalComponent.html'
})
export class AlertMessageModalComponent {
  public title: any;
  public Message: any;
  public email: any;
  public type: any;
  public url: any;
  public userId: any;
  public icon: string;
  public state: string;
  public isButtons = true;
  public regOrganaizationId: any;
  public dailogSaveButtonClicked = false;
  public organizationId: any;
  public userProfileId: any;

  constructor(
    public webStorage: StorageService,
    private dialogRef: MatDialogRef<AlertMessageModalComponent>,
  ) { }

  public activateDeactivateUser(event: any) {
    this.dailogSaveButtonClicked = true;
    event.preventDefault();
    this.dialogRef.close();
  }
  public cancelDeactivateUSR() {
    this.dailogSaveButtonClicked = false;
    this.dialogRef.close();
  }
  public close(closeAction: any) {
    this.dialogRef.close(closeAction);
  }
}

