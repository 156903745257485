import { FxContext, ServiceDocument } from '@agility/frameworkcore';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonSharedService } from '../../common/CommonSharedService';
import { GlobalSplCharforSplit } from '../../common/Constants';
import { ShipClearanceConfig } from '../../mcnConfig/ShipClearanceConfig';
import { StorageService } from '../../storageService/storageService';
import { ToasterService } from '../../toastrService/toasterService';
import { ShipStoreDetailsModel } from '../models/ShipStoreDetailsModel';
import { ShipStoreModel } from '../models/ShipStoreModel';
import { ShipClrSharedService } from '../services/sharedServices/ShipClrSharedServices';

@Injectable()
export class ShipStoreService {
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public serviceDocument: ServiceDocument<ShipStoreModel> = new ServiceDocument<ShipStoreModel>();
  public mcserviceDocument: ServiceDocument<ShipStoreDetailsModel> = new ServiceDocument<ShipStoreDetailsModel>();
  public serviceDocumentShipStoreList: ServiceDocument<ShipStoreDetailsModel> = new ServiceDocument<ShipStoreDetailsModel>();
  public filterServiceDocument: ServiceDocument<ShipStoreModel> = new ServiceDocument<ShipStoreModel>();
  public modeOfSourceData: any;
  public isSort = false;
  public searchObj: any;
  public orderBy: string;
  private refreshDynamicData = new Subject<string>();

  // Observable string streams
  public callRefreshDynamicData$ = this.refreshDynamicData.asObservable();

  // Service message commands
  public hitRefreshDynamicData() {
    this.refreshDynamicData.next();
  }

  constructor(
    private appConfig: ShipClearanceConfig,
    private toastrService: ToasterService,
    private fxContext: FxContext,
    private shipClrSharedService: ShipClrSharedService,
    private http: HttpClient,
    private commonSharedService: CommonSharedService,
    private router: Router,
    private storageService: StorageService
  ) { }

  private handleError() {
    return this.toastrService.Error('Internal Server Error.Please Try Again Later.');
  }

  public list(expression: string = '', aliasName: string = '', pageIndex: any = 1,
    recordPerPage: any = this.appConfig.appData.pageCount,
    orderIndex: any = 1, isDescending: any = true): Observable<ServiceDocument<ShipStoreModel>> {
    const listShipStore = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending,
    };
    return this.serviceDocument.list(this.appConfig.listShipStore, listShipStore)
      .pipe(map((response) => response), catchError(
        (res) => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public new(): Observable<ServiceDocument<ShipStoreModel>> {
    return this.serviceDocument.new(this.appConfig.newShipStore)
      .pipe(map((response) => response), catchError(() =>
        throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public open(): Observable<ServiceDocument<ShipStoreModel>> {
    if (this.commonSharedService.isNotNullOrUndefined(this.shipClrSharedService.sharingData.shipDeclarationId)
      && this.shipClrSharedService.sharingData.shipDeclarationId > 0) {
      return this.serviceDocument.open(this.appConfig.openShipStore, new HttpParams().set('ShipDeclarationId',
        this.shipClrSharedService.sharingData.shipDeclarationId.toString()))
        .pipe(map((response) => response), catchError(() => throwError(
          { msg: this.handleError(), ngNavigationCancelingError: true })));
    } else {
      this.router.navigate(['/shipClrCreateData/ship-stores']);
    }
  }



  public openShipStoreDetails(): Observable<ServiceDocument<ShipStoreDetailsModel>> {

    return this.mcserviceDocument.open(this.appConfig.openShipStoreDetails, new HttpParams()
      .set('id', this.shipClrSharedService.sharingData.shipStoreDetailId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public delete(shipStoreDetailId: any): Observable<any> {
    return this.http.post(this.appConfig.deleteShipStoreDetails, new HttpParams()
      .set('shipStoreDetailId', shipStoreDetailId.toString()));
  }

  public deleteall(shipStoreId: any): Observable<any> {
    return this.http.post(this.appConfig.deleteShipStoreDetailsAll, new HttpParams()
      .set('shipStoreId', shipStoreId.toString()));
  }


  public listShipStoreDetails(expression: string, aliasName: string,
    pageIndex: number = 1, recordPerPage: number = this.appConfig.appData.associatePageCount,
    orderIndex: any = 1, isDescending: any = true):
    Observable<ServiceDocument<ShipStoreDetailsModel>> {
    if (this.commonSharedService.isNotNullOrUndefined(expression)) {
      expression = expression + GlobalSplCharforSplit.Char1
        + 'ShipStoreDetailsModel.ShipDeclarationId ==' + this.shipClrSharedService.sharingData.shipDeclarationId;
    } else { expression = 'ShipStoreDetailsModel.ShipDeclarationId ==' + this.shipClrSharedService.sharingData.shipDeclarationId; }
    const ShipstoreFilterModel = {
      expression: expression,
      aliasName: 'ShipStoreDetailsModel',
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending,
    };
    return this.mcserviceDocument.list(this.appConfig.listShipStoreDetails, ShipstoreFilterModel)
      .pipe(map((response) => response), catchError(
        (res) => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }


  public submitStoreShipExcelUploadData(): Observable<any> {
    return this.serviceDocument.submit(this.appConfig.submitExcelUploadData)
      .pipe(map((response) => response), catchError(() =>
        throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public submit(): Observable<any> {
    return this.serviceDocument.submit(this.appConfig.submitShipStore)
      .pipe(map((response) => response), catchError(() =>
        throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public newShipStoreDetails(): Observable<ServiceDocument<ShipStoreDetailsModel>> {

    return this.mcserviceDocument.new(this.appConfig.newShipStoreDetails)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public submitShipStoreDetails(): Observable<any> {

    return this.mcserviceDocument.submit(this.appConfig.submitShipStoreDetails)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }


  public submitExcelforShipStore(formModel: ShipStoreDetailsModel): Observable<any> {
    return this.http.post(this.appConfig.submitExcelUploadData, formModel, { headers: this.headers })
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public exportDatatoExcel(shipStoreDetailsId: number): Observable<any> {
    const shipStoreDetailsModel = {
      shipStoreDetailId: shipStoreDetailsId,
      languageid: this.fxContext.languageId.toString()
    };
    return this.http.post(this.appConfig.downloadTemplateForShipStoreDetails, shipStoreDetailsModel, { responseType: 'blob' })
      .pipe(map((response) => response), catchError((res) =>
        throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }


}
