
export enum GlobalSplCharforSplit {
  Char1 = '£',
  Char2 = '€',
  Char3 = '¥',
  Char4 = 'Ω',
  Char5 = 'π'
}
export enum SuretyEncash {
 PAYMENTCHANNELTYPES_SURENC = 'PAYMENTCHANNELTYPES_SURENC'
}
export enum CommonTypes {
  MODEOFTRANSPORTDECLTYPES = 'MODEOFTRANSPORTDECLTYPES',
  PURPOSEOFCALLTYPES = 'PURPOSEOFCALLTYPES',
  SHIPSTAUSTYPES = 'SHIPSTAUSTYPES',
  VOYAGETYPES = 'VOYAGETYPES',
  POSTIONOFSHIPTYPES = 'POSTIONOFSHIPTYPES',
  BARGEUOMTYPES = 'BARGEUOMTYPES',
  IMPORTEDGENERALCARGOTYPES = 'IMPORTEDGENERALCARGOTYPES',
  STORAGETYPE = 'STORAGETYPE',
  MANIFESTFOR = 'MANIFESTFOR',
  HANDLINGINSTRUCTION = 'HANDLINGINSTRUCTION',
  CARGOVEHICLETYPE = 'CARGOVEHICLETYPE',
  REGISTRATIONTYPE = 'REGISTRATIONTYPE',
  REGISTRATION_ORGANIZATION_TYPE = 'REGISTRATION_ORGANIZATION_TYPE',
  VEHICLETYPES = 'VEHICLETYPES',
  CANCELLATIONREASONS = 'CANCELLATIONREASONS',
  VESSELTYPES = 'VESSELTYPES',
  VESSELTRADE = 'VESSELTRADE',
  VESSELTERM = 'VESSELTERM',
  POSITIONOFBRIDGE = 'POSITIONOFBRIDGE',
  TYPEOFHULL = 'TYPEOFHULL',
  AREAOFOPERATION = 'AREAOFOPERATION',
  DOCUMENTTYPES = 'DOCUMENTTYPES',
  GOVERNMENTAGENCYMASTER = 'GOVERNMENTAGENCYMASTER',
  DRDeclarationTypes = 'DRDeclarationTypes',
  COMMERCIALINVOICETYPE = 'COMMERCIALINVOICETYPE',
  CONTAINERKINDTYPE = 'CONTAINERKINDTYPE',
  FCLLCLTYPE = 'FCLLCLTYPE',
  DECLARATIONPACKAGETYPES = 'DECLARATIONPACKAGETYPES',
  GSTINTERCEPTTYPES = 'GSTINTERCEPTTYPES',
  VALUATIONMETHOD = 'VALUATIONMETHOD',
  PURPOSEOFUSAGE = 'PURPOSEOFUSAGE',
  PAGOODSTYPES = 'PAGOODSTYPES',
  PATYPEOFGOODS = 'PATYPEOFGOODS',
  PAGOODSDESCRIPTION = 'PAGOODSDESCRIPTION',
  PACURRENCYTYPES = 'PACURRENCYTYPES',
  COLLECTIONACCOUNTTYPES = 'COLLECTIONACCOUNTTYPES',
  PAYMENTCHANNELTYPES = 'PAYMENTCHANNELTYPES',
  REFUNDACCOUNTTYPES = 'REFUNDACCOUNTTYPES',
  REVENUEACCOUNTTYPES = 'REVENUEACCOUNTTYPES',
  SAMPLEREQUESTSOURCETYPES = 'SAMPLEREQUESTSOURCETYPES',
  ReferenceDocument = 'ReferenceDocument',
  InspectionSource = 'InspectionSource',
  RequestSource = 'RequestSource',
  RequestMedium = 'RequestMedium',
  REGISTRATIONAUTHORITY = 'RegistrationAuthorityTypes',
  BUSINESSENTITYTYPES = 'BUSINESSENTITYTYPES',
  CUSTOMSCOUNTER = 'PAYMENTCHANNELTYPES_CUSCOU',
  ONLINEPAYMENT = 'PAYMENTCHANNELTYPES_ONLPAY',
  SYSTEM = 'PAYMENTCHANNELTYPES_SYS',
  CUSTOMSCOUNTER_Accounting = 'PAYMENTCHANNELTYPES_CUSCOU',
  ONLINEPAYMENT_Accounting = 'PAYMENTCHANNELTYPES_ONLPAY',
  SYSTEM_Accounting = 'PAYMENTCHANNELTYPES_SYS',
  PAYMENTCHANNELTYPES_SURENC_SURENC = 'PAYMENTCHANNELTYPES_SURENC_SURENC',
  SURETYTRANSACTIONTYPES = 'SURETYTRANSACTIONTYPES',
  SURETYTYPE = 'SURETYTYPE',
  FinalImport_SubDeclaration = 'Final Import - Sub Declaration',
  FinalImport_Exempted = 'Final Import - Exempted',
  DeclarationTransactionTypeImport = 'DeclarationTransactionTypeImport',
  DeclarationTransactionTypeExport = 'DeclarationTransactionTypeExport',
  FinalImportNormalImport = 'DeclarationTransactionTypeImport_FinalImportNormalImport',
  FinalImportCourierImport = 'DeclarationTransactionTypeImport_FinalImportCourierImport',
  FinalImportSubDeclaration = 'DeclarationTransactionTypeImport_FinalImportSubDeclaration',
  FinalImportExempted = 'DeclarationTransactionTypeImport_FinalImportExempted',
  ProvisionalMasterProvisionalDeclaration = 'DeclarationTransactionTypeImport_ProvisionalMasterProvisionalDeclaration',
  FinalImportSpecialCondition = 'DeclarationTransactionTypeImport_FinalImportSpecialCondition',
  SpecialRegimesInwardProcessing = 'DeclarationTransactionTypeImport_SpecialRegimesInwardProcessing',
  ReImportReturnafterprocessing = 'DeclarationTransactionTypeImport_ReImportReturnafterprocessing',
  FinalExportNormalExport = 'DeclarationTransactionTypeExport_FinalExportNormalExport',
  FinalExportSpecialCondition = 'DeclarationTransactionTypeExport_FinalExportSpecialCondition',
  ProvisionalExportProvisional = 'DeclarationTransactionTypeExport_ProvisionalExportProvisional',
  ReExportInwardFinishedGoods = 'DeclarationTransactionTypeExport_ReExportInwardFinishedGoods',
  ReExportProvisional = 'DeclarationTransactionTypeExport_ReExportProvisional',
  TemExportIndustrialManufacturing = 'DeclarationTransactionTypeExport_TemExportIndustrialManufacturing',
  OutwardTempExportProvisional = 'DeclarationTransactionTypeExport_TempExportProvisional',
  TemImportPropagandaResearch = 'DeclarationTransactionTypeImport_TemImportPropagandaResearch',
  TemporaryImportRepair = 'DeclarationTransactionTypeImport_TemporaryImportRepair',
  TemporaryImportATACarnet = 'DeclarationTransactionTypeImport_TemporaryImportATACarnet',
  ReImportReturnafterPropagandaResearch = 'DeclarationTransactionTypeImport_ReImportReturnafterPropagandaResearch',
  ReImportGoodsorMachinesWronglysent = 'DeclarationTransactionTypeImport_ReImportGoodsorMachinesWronglysent',
  ReImportReturnafterRepair = 'DeclarationTransactionTypeImport_ReImportReturnafterRepair',
  ReImportATACarnet = 'DeclarationTransactionTypeImport_ReImportATACarnet',
  TempExportPropagandaResearch = 'DeclarationTransactionTypeExport_TempExportPropagandaResearch',
  TempExportGoodsorMachinesForRepair = 'DeclarationTransactionTypeExport_TempExportGoodsorMachinesForRepair',
  TempExportATACarnet = 'DeclarationTransactionTypeExport_TempExportATACarnet',
  ReExportReturnafterPropagandaResearch = 'DeclarationTransactionTypeExport_ReExportReturnafterPropagandaResearch',
  ReExportReturnafterRepair = 'DeclarationTransactionTypeExport_ReExportReturnafterRepair',
  ReExportATACarnet = 'DeclarationTransactionTypeExport_ReExportATACarnet',
  ReExportPropagandaResearchProvisional = 'DeclarationTransactionTypeExport_ReExportPropagandaResearchProvisional',
  ReExportReturnAfterRepairProvisional = 'DeclarationTransactionTypeExport_ReExportReturnAfterRepairProvisional',
  TemporaryExportPropagandaResearcProvisional = 'DeclarationTransactionTypeExport_TemporaryExportPropagandaResearcProvisional',
  TemExportGoodsorMachinesRepairProvisional = 'DeclarationTransactionTypeExport_TemExportGoodsorMachinesRepairProvisional',
  LoadTypes = 'LoadTypes',
  SAMPLEREQUESTCONDITION = 'SAMPLEREQUESTCONDITION',
  PAYMENTREFERENCETYPES = 'PAYMENTREFERENCETYPES',
  BILLREFERENCETYPES = 'BILLREFERENCETYPES',
  AssessmentGroupTypes = 'AssessmentGroupTypes',
  AssessmentForTypes = 'AssessmentForTypes',
  AssessmentItemForTypes = 'AssessmentItemForTypes',
  SAMPLECONDITION = 'SAMPLECONDITION',
  PAYMENTFOR = 'PAYMENTFOR',
  REFUNDPAYMENTACCOUNTTYPES = 'REFUNDPAYMENTACCOUNTTYPES',
  TARIFFVALUATIONAPPLICATIONTYPE = 'TARIFFVALUATIONAPPLICATIONTYPE',
  TARIFFVEHICLETYPES = 'TARIFFVEHICLETYPES',
  TARIFFVEHICLECONDITION = 'TARIFFVEHICLECONDITION',
  TARIFFINVOICETYPE = 'TARIFFINVOICETYPE',
  TARIFFPAYMENTTERM = 'TARIFFPAYMENTTERM',
  TARIFFVEHICLEENGINETYPE = 'TARIFFVEHICLEENGINETYPE',
  TARIFFVEHICLECATEGORY = 'TARIFFVEHICLECATEGORY',
  TRANSMISSIONTYPES = 'TRANSMISSIONTYPES',
  MsgTypes = 'MsgTypes',
  MsgPrioritys = 'MsgPrioritys',
  ExemptionTypes = 'ExemptionTypes',
  ExemptionApplicableTypes = 'ExemptionApplicableTypes',
  ExemptionDutyTypes = 'ExemptionDutyTypes',
  EXEMPTIONSECTIONTYPE = 'EXEMPTIONSECTIONTYPE',
  ExemptionRateBasis = 'ExemptionRateBasis',
  TransitType = 'TransitType',
  BANNED = 'SPECIFICATIONTYPES_BANNED',
  BANNEDONBASISOFORIGIN = 'SPECIFICATIONTYPES_BANNEDONBASISOFORIGIN',
  DISCLAIMER = 'SPECIFICATIONTYPES_DISCLAIMER',
  CERTIFICATESINGLEVALUE = 'SPECIFICATIONTYPES_CERTIFICATESINGLEVALUE',
  YESORNOQUESTION = 'SPECIFICATIONTYPES_YESORNOQUESTION',
  GRADEOrSPECS = 'SPECIFICATIONTYPES_GRA/SPE',
  DeMinimisCondition = 'DeMinimisCondition',
  ImportType = 'ImportType',
  ExportType = 'ExportType',
  APPEALCATEGORYFORCLASSIFICATION = 'APPEALCATEGORYFORCLASSIFICATION',
  APPEALCATEGORYFORVALUATION = 'APPEALCATEGORYFORVALUATION',
  TUGBOAT = 'TUG BOAT',
  EITHERORQUESTION = 'SPECIFICATIONTYPES_EITORQUE',
  SPECIFICATIONFIELDTYPEDAT = 'SPECIFICATIONFIELDTYPE_DAT',
  SPECIFICATIONFIELDTYPECHEBOX = 'SPECIFICATIONFIELDTYPE_CHEBOX',
  SPECIFICATIONFIELDTYPENUM = 'SPECIFICATIONFIELDTYPE_NUM',
  SPECIFICATIONFIELDTYPESTR = 'SPECIFICATIONFIELDTYPE_STR',
  SPECIFICATIONFIELDTYPEALPNUM = 'SPECIFICATIONFIELDTYPE_ALPNUM',
  CAPTIONTYPE_VEHCON = 'CAPTIONTYPE_VEHCON',
  CAPTIONTYPE_CHANO = 'CAPTIONTYPE_CHANO.',
  CAPTIONTYPE_REG_DATE = 'CAPTIONTYPE_REG_DATE',
  CAPTIONTYPE_MANUFACT_YEAR = 'CAPTIONTYPE_MANUFACT_YEAR',
  CAPTIONTYPE_BRAND = 'CAPTIONTYPE_BRAND',
  CAPTIONTYPE_MODEL = 'CAPTIONTYPE_MODEL',
  CAPTIONTYPE_ENGINETYPE = 'CAPTIONTYPE_ENGINETYPE',
  CAPTIONTYPE_ENGINECAPACITY = 'CAPTIONTYPE_ENGINECAPACITY',
  CAPTIONTYPE_ENGINENO = 'CAPTIONTYPE_ENGINENO',
  GOODSCATEGORY = 'GOODSCATEGORY',
  OVERSEASELLER = 'OVERSEASELLER',
  DIRECTIONTYPES = 'DIRECTIONTYPES',
  PORTTYPE = 'PORTTYPE',
  Genders = 'Genders',
  Others = 'OtherTypes',
  UnavilabiltyTypes = 'UNAVAILABILITYTYPES',
  ReleaseByTypes = 'ReleaseByTypes',
  ReleaseByTransit = 'ReleaseBy_Transit',
  ReleaseByEmpty = 'ReleaseBy_Empty',
  ReleaseByExport = 'ReleaseBy_Export',
  ReleaseByImport = 'ReleaseBy_Import',
  ReleaseBySCD = 'ReleaseBy_SimplifiedCourierDeclaration',
  ReleaseByExportSCD = 'ReleaseBy_ExportSimplifiedCourierDeclaration',
  ReleaseByImportDeclaration = 'ReleaseBy_ImportDeclaration',
  ReleaseByExportDeclaration = 'ReleaseBy_ExportDeclaration',
  ReleaseByEmptyDeclaration = 'ReleaseBy_EmptyDeclaration',
  ReleaseByTransitDeclaration = 'ReleaseBy_TransitDeclaration',
  ReleaseByTransitOriginDeclaration = 'ReleaseBy_TransitOriginDeclaration',
  ReleaseByTransitDestinationDeclaration = 'ReleaseBy_TransitDestinationDeclaration',
  ReleaseBySCDeclaration = 'ReleaseBy_SCDeclaration',
  ReleaseByExportSCDeclaration = 'ReleaseBy_ExportSCDeclaration',
  ReleaseBySCDTypeName = 'Simplified Courier Declaration',
  ReleaseByExportSCDTypeName = 'Export Simplified Courier Declaration',
  CargoGoodsTypes = 'Cargo_GoodsTypes',
  DeclarationDocumentTypes = 'Declaration_DocumentTypes',
  TariffValuationDocumentTypes = 'DOCUMENTTYPES_TARVALDOC',
  CargoDocumentTypes = 'Cargo_DocumentTypes',
  DeclarationPermitDocumentTypes = 'Declaration_PermitDocumentTypes',
  POSTINGTYPES = 'POSTINGTYPES',
  QuotaCondition = 'QuotaCondition',
  QuotaDirection = 'Quota Direction',
  QUOTADETAILSAPPLYBY = 'QUOTADETAILSAPPLYBY',
  UnAvailable_Others = 'UNAVAILABILITYTYPES_OTH',
  SHIFTPATTERNS = 'SHIFTPATTERNS',
  SHIFTDIRECTIONS = 'SHIFTDIRECTIONS',
  SHIFTPATTERNS24 = 'SHIFTPATTERNS_24/7',
  SHIFTPATTERSNWEEK = 'SHIFTPATTERNS_WEEKLYROTATION',
  SHIFTPATTERNSOFF = 'SHIFTPATTERNS_OFFICEHOURS',
  RPSHIFTYPES = 'SHIFTTYPES',
  GROUPORDER = 'GROUPORDER',
  TASKASSIGNMENT = 'TASKASSIGNMENT',
  FTAQuotaConditionTypes = 'FTAQuotaConditionTypes ',
  SHIFTPATTERNOFFICEHOURS = 'SHIFTPATTERNS_OFFICEHOURS',
  TASKASSIGNMENAUTO = 'TASKASSIGNMENT_AUTO',
  DeclarationSourceBillOfLading = 'DeclarationSource_BillOfLading',
  DeclarationSourceMASTERPROVISIONALDECLARATION = 'DeclarationSource_MASTERPROVISIONALDECLARATION',
  DeclarationSourceFUNCTIONALUNITS = 'DeclarationSource_FUNCTIONALUNITS',
  DeclarationSourceInwardProcessing = 'DeclarationSource_InwardProcessing',
  DeclarationSourceInwardProvisional = 'DeclarationSource_InwardProvisional',
  DeclarationSourceOutwardProvisional = 'DeclarationSource_OutwardProvisional',
  DeclarationReasonsType = 'DeclarationReasons',
  DeclarationForTemExportGoodorMachinesAndReapir = 'DeclarationFor_TemExportGoodorMachinesAndReapir',
  DeclarationSourceReExportPropagandaResearch = 'DeclarationSource_ReExportPropagandaResearch',
  DeclarationSourceReExportRepair = 'DeclarationSource_ReExportRepair',
  DeclarationSourceReExportRepairProvisonal = 'DeclarationSource_ReExportRepairProvisonal',
  DeclarationSourceTemExportPropagandaProvisional = 'DeclarationSource_TemExportPropagandaProvisional',
  DeclarationSourceTemExportGoodorMachinesProvisional = 'DeclarationSource_TemExportGoodorMachinesProvisional',
  DeclarationSourceReExportPropagandaProvisional = 'DeclarationSource_ReExportPropagandaProvisional',
  DecSourceScDeclaration = 'DeclarationSource_SCDeclaration',
  DecSourceExportScDeclaration = 'DeclarationSource_ExportSCDeclaration',
  DeclarationSourcePropagandaResearch = 'DeclarationSource_Propaganda_Research',
  DeclarationSourceRepair = 'DeclarationSource_Repair',
  DRAWBACKTYPE = 'DRAWBACKTYPE',
  PAYMENT = 'PAYMENT',
  INS_SAMPLEREQUEST = 'INS_SAMPLEREQUEST',
  CONFIGURATIONTYPE = 'CONFIGURATIONTYPE',
  COMMERCIALINVOICETYPECIF = 'COMMERCIALINVOICETYPE_CIF',
  COMMERCIALINVOICETYPEFOB = 'COMMERCIALINVOICETYPE_FOB',
  COMMERCIALINVOICETYPECNF = 'COMMERCIALINVOICETYPE_CNF',
  COMMERCIALINVOICETYPECNI = 'COMMERCIALINVOICETYPE_CNI',
  DeclarationCategory = 'DECLARATIONCATEGORY',
  LicenseCategoryType = 'LicenseCategoryType',
  LicenseNameType = 'LicenseNameType',
  DeclarationTransactionTypeImportCourierImport = 'DeclarationTransactionTypeImport_FinalImportCourierImport',
  DeclarationTransactionTypeExportCourierExport = 'DeclarationTransactionTypeExport_FinalExportCourierExport',
  DeclarationTransactionTypeExportDrawback = 'DeclarationTransactionTypeExport_ReExportDrawback',
  DeclarationTransactionTypeExportDrawbackProvisional = 'DeclarationTransactionTypeExport_ReExportDrawbackProvisional',
  DeclarationForTemExportGoodorMachines = 'DeclarationFor_TemExportGoodorMachines',
  DeclarationForTemExportRepair = 'DeclarationFor_TemExportRepair',
  DeclarationForExportDrawback = 'DRAWBACKTYPE',
  DeclarationForSameStateGoods = 'DRAWBACKTYPE_SAMESTATEGOODS',
  DeclarationForIndustrialManufacturing = 'DRAWBACKTYPE_INDUSTRIALMANUFACTUREDEXPORT',
  DeclarationSourceNormalImport = 'DeclarationSource_NormalImport',
  DeclarationSourceDrawbackProvisional = 'DeclarationSource_DrawbackProvisional',
  DrawbackDeclarationCategory = 'DRAWBACKDECLARATIONCATEGORY',
  PAYMENTCHANNELTYPES_CUSCOU = 'PaymentChannelTypeForCustom',
  FREEZONEFORMTYPES = 'FREEZONEFORMTYPES',
  DeclarationSourceFinalExportProvisional = 'DeclarationSource_FinalExportProvisional',
  DeclarationTransactionTypeTransit = 'DeclarationTransactionTypeTransit',
  TransitFinalTransit = 'Transit_FinalTransit',
  TransitATACarnet = 'Transit_ATACarnet',
  TransitMovementTypes = 'Transit_MovementTypes',
  MovementTypeK8Transit = 'MovementType_K8Transit',
  MovementTypeK3InterRegional = 'MovementType_K3InterRegional',
  MovementTypeK8Import = 'MovementType_K8Import',
  MovementTypeK8Export = 'MovementType_K8Export',
  MovementTypeK8Normal = 'MovementType_K8Normal',
  DeclarationSourceWarehouse = 'DeclarationSource_Warehouse',
  DeclarationSourceFreeZone = 'DeclarationSource_FreeZone',
  GoodsType = 'GOODSTYPE',
  CommodityType = 'COMMODITYTYPE',
  ReasonForWHForm = 'REASONFORWHFORM',
  WarehouseFormType = 'FORMTYPE',
  WarehouseGenerationType = 'GENERATIONTYPE',
  MOVEMENTREASONTYPES = 'MOVEMENTREASONTYPES',
  DiscrepancyType = 'DiscrepancyType',
  RemittanceReason = 'REMITTANCETYPE',
  VEHICLEBRANDTYPES = 'VEHICLEBRANDTYPES',
  PORTTYPESEAPOR = 'PORTTYPE_SEAPOR',
  PORTTYPELANPOR = 'PORTTYPE_LANPOR',
  PORTTYPEAIRPOR = 'PORTTYPE_AIRPOR',
  PORTTYPERAILPOR = 'PORTTYPE_RAIPOR',
  PORTTYPEFREZON = 'PORTTYPE_FREZON',
  PORTTYPELMW = 'PORTTYPE_LMW',
  PORTTYPEWAR = 'PORTTYPE_WAR',
  PORTTYPEDFS = 'PORTTYPE_DFS',
  IsPermitAvailable = 'IsPermitAvailable',
  DfsOutboundTypes = 'DfsOutboundTypes',
  GatePassScaningDocumentTypes = 'GatePassScaning_DocumentTypes',
  FzFormTypes = 'FzFormTypes',
  FzDeclarationTypes = 'FZDECLARATIONTYPES',
  FzSrcDecTypes = 'FzSrcDecTypes',
  DUTYTYPES = 'DUTYTYPES',
  DUTYRATEBASISTYPES = 'DUTYRATEBASISTYPES',
  DutyRatebasisTypes_AD = 'DutyRatebasisTypes_AD-VALOREM',
  DutyRatebasisTypes_SPEC = 'DutyRatebasisTypes_SPECIFIC',
  AGENCYTYPES = 'AGENCYTYPES',
  AGENCYTYPESCBR = 'AGENCYTYPES_CBR',
  InspectionLocation = 'INSPECTIONLOCATION',
  AppointmentType = 'APPOINTMENTTYPE',
  InspectionLocationPort = 'INSPECTIONLOCATION_PORT',
  ServiceName = 'ServiceName',
  ServiceGroup = 'ServiceGroup',
  ServiceReference = 'ServiceReference',
  ServiceType = 'ServiceType',
  CommodityTypeCodes = 'DECLARATIONCOMMODITYTYPE',
  DecFinishedGoodsCommodityTypeCodes = 'DECLARATIONFINISHEDGOODSCOMMODITY',
  DecFinishedGoods = 'Finished Goods',
  DeclarationSourceWarehouseForms = 'DeclarationSource_WarehouseForms',
  PurposeofUsage_Sez = 'PurposeofUsage_Sez',
  CargoCategory = 'CargoCategory',
  DeclarationWarehouseOriginTypes = 'Declaration_WarehouseOriginTypes',
  DeclarationSourceSCDeclaration = 'DeclarationSource_SCDeclaration',
  LandedByMistakeWithDO = 'DeclarationTransactionTypeImport_LandedByMistakeWithDO',
}

export enum TypesName {
  SeizureReasons = 'SeizureReasons',
  PcaItemTypes = 'PcaItemTypes',
  DISCREPANCIESTYPES = 'DISCREPANCIESTYPES',
  PCADISCREPANCIESTYPES = 'PCADISCREPANCIESTYPES',
  PCAAUDITNOTETYPES = 'PCAAUDITNOTETYPES',
  PcaDocsCategoryTypes = 'PcaDocsCategoryTypes',
  DIRECTIONTYPES = 'DIRECTIONTYPES',
  DirectionTypes = 'DirectionTypes',
  PCAPREMISES = 'PCAPREMISES',
  PremisesLocation = 'PremisesLocation',
  BusinessEntityTypes = 'BusinessEntityTypes',
  BusinessPaidCapital = 'BusinessPaidCapital',
  FullTimeEmployees = 'FullTimeEmployees',
  GoodsTypes = 'GoodsTypes',
  CategoryAgricultural = 'CategoryAgricultural',
  SubCategoryFreshVeg = 'SubCategoryFreshVeg',
  TARIFFINVOICETYPE = 'TARIFFINVOICETYPE',
  COLLECTIONACCOUNTTYPES = 'COLLECTIONACCOUNTTYPES',
  PaymentTerm = 'PaymentTerm',
  SpecialTreatment = 'SpecialTreatment',
  JustTransaction = 'JustTransaction',
  JustTransAdjust = 'JustTransAdjust',
  JustTransCannot = 'JustTransCannot'
}

export enum ModuleNames {
  Cargo = 'Cargo',
  Declaration = 'Declaration',
  Inspection = 'Inspection',
  Assessment = 'Assessment',
  Payment = 'Payment',
  Accounting = 'Accounting',
  Registration = 'Registration',
  Surety = 'Surety',
  ShipClearance = 'ShipClearance',
  VesselShipClearance = 'SHIPCLEARANCE',
  Common = 'Common',
  Tariffvaluation = 'Tariffvaluation',
  TariffValuation = 'TariffValuation',
  Inbox = 'Inbox',
  Tariffexemption = 'Tariffexemption',
  TariffExemption = 'TariffExemption',
  Tariffmngt = 'tariffmngt',
  Tariffruling = 'Tariffruling',
  TariffRuling = 'TariffRuling',
  TARIFFRULING = 'TARIFFRULING',
  ResourcePlanning = 'ResourcePlanning',
  Release = 'Release',
  Tariffquota = 'Tariffquota',
  Refunds = 'Refunds',
  Drawback = 'Drawback',
  Bwh = 'BWH',
  Permit = 'Permit',
  Pca = 'Pca',
  License = 'License',
  Generic = 'Generic',
  Rms = 'Rms',
  Dfs = 'DFS',
  FreeZone = 'FREEZONE',
  ServiceReceipt = 'ServiceReceipt',
  Sez = 'Sez',
}

export enum ProfileNames {
  AccountingConfiguration = 'AccountingConfiguration',
  RevenueSourceGroup = 'RevenueSourceGroup',
  RevenueSourceConfiguration = 'RevenueSourceConfiguration',
  RefundAccount = 'RefundAccount',
  RefundPaymentAccount = 'RefundPaymentAccount',
  CollectionAccount = 'CollectionAccount',
  RevenueAccount = 'RevenueAccount',
  Inspection = 'Inspection',
  Gatepass = 'Gatepass',
  GatepassDetails = 'GatepassDetails',
  InspectionReport = 'InspectionReport',
  MotorVehicleValuation = 'MotorVehicleValuation',
  ClassificationAppeal = 'ClassificationAppeal',
  CustomStation = 'CustomStation',
  QuentityUnit = 'QuentityUnit',
  Posting = 'Posting',
  CustomPreference = 'CustomPreference',
  ValuationAppeal = 'ValuationAppeal',
  PassengerAssessment = 'PassengerAssessment',
  DrDeclaration = 'DrDeclaration',
  ResourceUnavailability = 'ResourceUnavailability',
  DepartmentProcess = 'DepartmentProcess',
  ShiftConfiguration = 'ShiftConfiguration',
  RPDutyRoster = 'RPDutyRoster',
  Refund = 'Refund',
  MotorVehiclePriceData = 'MotorVehiclePriceData',
  PetroleumInformation = 'PetroleumInformation',
  DrawbackFacilityApproval = 'DrawbackFacilityApproval',
  DrawbackPCC = 'DrawbackPCC',
  Drawback = 'Drawback',
  ClaimRight = 'ClaimRight',
  Specification = 'Specification',
  Section = 'Section',
  Chapter = 'Chapter',
  Heading = 'Heading',
  SubHeading = 'SubHeading',
  Tariffitem = 'Tariffitem',
  TariffitemDuty = 'TariffitemDuty',
  TariffitemDutyRate = 'TariffitemDutyRate',
  TariffItemSpecification = 'TariffItemSpecification',
  ConditionalProhibition = 'ConditionalProhibition',
  Exemption = 'Exemption',
  ExemptionRequestTariffLine = 'ExemptionRequestTariffLine',
  ExemptionLawRegulation = 'ExemptionLawRegulation',
  SectionItemDetails = 'SectionItemDetails',
  ExemptionReqApprovalCondition = 'ExemptionReqApprovalCondition',
  ExemptionCategoryInformation = 'ExemptionCategoryInformation',
  FTAManagement = 'FTAManagement',
  TariffLineDetails = 'TariffLineDetails',
  DecExtensionRequest = 'DecExtensionRequest',
  PriceDataOtherGood = 'PriceDataOtherGood',
  AdditionalTariffRateQuota = 'AdditionalTariffRateQuota',
  FtaAdditionalTariffRateQuota = 'FtaAdditionalTariffRateQuota',
  TariffRateQuotaDetails = 'TariffRateQuotaDetails',
  TariffRateQuota = 'TariffRateQuota',
  FTATariffRateQuota = 'FTATariffRateQuota',
  ShiftUserGroup = 'ShiftUserGroup',
  Duty = 'Duty',
  Ruling = 'Ruling',
  ValuationAppealItem = 'ValuationAppealItem',
  SampleRequest = 'SampleRequest',
  WarehouseForms = 'WarehouseForms',
  SezWarehouseForms = 'SezWarehouseForms',
  SezWHFormItems = 'SezWHFormItems',
  WHFormItems = 'WHFormItems',
  ShipDeclaration = 'ShipDeclaration',
  ShipVesselAdvisePKP = 'ShipVesselAdvisePKP',
  ShipStores = 'FAL3ShipStores',
  ShipStoresDetails = 'FAL3ShipStoresDetails',
  ShipCrew = 'ShipCrew',
  EndemicCountry = 'ShipEndemicCountry',
  ShipBlacklist = 'ShipBlacklist',
  HealthDetails = 'ShipPKPHealthDetails',
  VaccinationDetails = 'ShipVaccinationDetails',
  Vaccination = 'ShipVaccination',
  ShipIssuancePort = 'ShipIssuancePort',
  RemittanceRequests = 'RemittanceRequests',
  SezRemittanceRequests = 'SezRemittanceRequests',
  DiscrepancyAdjRequest = 'DiscrepancyAdjRequests',
  SezDiscrepancyAdjRequest = 'SezDiscrepancyAdjRequest',
  ShipImmigration = 'ShipImmigration',
  ShipPassengerDetails = 'FAL6ShipPassengerDetails',
  FAL6ShipCoPassengerDetails = 'FAL6ShipCoPassengerDetails',
  AssessmentPricingData = 'AssessmentPricingData',
  ShipFormA = 'ShipFormA',
  VMExtensionHistory = 'VMExtensionHistory',
  ShipEffect = 'ShipEffect',
  ShipCargo = 'ShipCargo',
  ShipCargoDetailsData = 'ShipCargoDetailsData',
  ShipCrewDetails = 'ShipCrewDetails',
  PortOfCalls = 'ShipPortofCallDetails',
  ShipPassenger = 'ShipPassenger',
  ShipEffectsDetails = 'ShipEffectsDetails',
  ShipDangerousGood = 'ShipDangerousGood',
  VehicleMovementRequests = 'VehicleMovementRequests',
  SezVehicleMovementRequests = 'SezVehicleMovementRequests',
  ShipPanSecurity = 'ShipPanSecurity',
  TransferRequests = 'TransferRequests',
  SezTransferRequests = 'SezTransferRequests',
  DestructionRequest = 'DestructionRequests',
  SezDestructionRequest = 'SezDestructionRequests',
  WarehouseBalance = 'WarehouseBalance',
  SezWarehouseBalance = 'SezWarehouseBalance',
  ShipMaritimeDeclaration = 'ShipMaritimeDeclaration',
  GatePassWeightDetails = 'GatePassWeightDetails',
  DfsForms = 'DutyFreeShopForms',
  DfsFormItems = 'DutyFreeShopItems',
  DiscrepancyAdjustmentRequest = 'DiscrepancyAdjustmentRequest',
  LegalProvisions = 'LegalProvisions',
  AuditReports = 'AuditReports',
  DfsTransferRequest = 'DfsTransferRequest',
  PassengerDetails = 'PassengerDetails',
  FZDeclarations = 'FZDeclarations',
  AbsoluteQuota = 'AbsoluteQuotaDetails',
  InstructionStep = 'InstructionStep',
  InspectionInstruction = 'InspectionInstruction',
  FZTransferRequests = 'FZTransferRequests',
  DfsRemittanceRequest = 'RemittanceRequest',
  FZForms = 'FZForms',
  AppointmentRequest = 'AppointmentRequest',
  Appointment = 'Appointment',
  Service = 'Service',
  ServicePrice = 'ServicePrice',
  ServiceReceipt = 'ServiceReceipt',
  Correlation = 'Correlation',
  WCOCorrelation = 'WCOCorrelation',
}

export enum DataProfiles {
  ReleaseProfile = 'TariffRateQuota',
}
export enum SuretyProfiles {
  SuretyDataProfile = 'Surety',
  SuretyDocumentDataProfile = 'SuretyDocument',
  SuretyTransactionDataProfile = 'SuretyTransaction',
  FormatCRequestDataProfile = 'FormatCRequest',
}

export enum ModeofTransport {
  Air = 'AIR',
  Sea = 'SEA',
  Rail = 'RAIL',
  Land = 'LAND',
  MODEOFTRANSPORTDECLTYPES_SEA = 'MODEOFTRANSPORTDECLTYPES_SEA',
  MODEOFTRANSPORTDECLTYPES_AIR = 'MODEOFTRANSPORTDECLTYPES_AIR',
  MODEOFTRANSPORTDECLTYPES_LAN = 'MODEOFTRANSPORTDECLTYPES_LAN',
  MODEOFTRANSPORTDECLTYPES_RAI = 'MODEOFTRANSPORTDECLTYPES_RAI'
}

export enum ModeofTransportClass {
  Air = 'declare-plane-icon',
  Sea = 'declare-ship-icon',
  Rail = 'declare-train-icon',
  Land = 'declare-truck-icon',
  WhiteAir = 'white-plane-icon',
  WhiteSea = 'white-ship-icon',
  WhiteRail = 'white-declare-train-icon',
  WhiteLand = 'white-declare-truck-icon'
}

export enum HttpResponseKey {
  mcnProfileResponse = 'mcnProfileResponse'
}

export enum CargoConstants {
  Start = 'Start',
  Created = 'Created',
  Modified = 'Modified',
  Cancelled = 'Cancelled',
  Submitted = 'Submitted',
  CreatedToCancelledJourney = 'CreatedToCancelledJourney',
  SubmittedToCancellationRequestedJourney = 'SubmittedToCancellationRequestedJourney',
  CancelJourneymsg = 'Cancel Journey',
  CancelConfirmationmsg = 'Are you sure you want to cancel this Journey ?',
  CreatedToSubmittedJourney = 'CreatedToSubmittedJourney',
  SubmittedToArrivedJourney = 'SubmittedToArrivedJourney',
  JourneyTypeImport = 'I',
  JourneyTypeExport = 'E',
  Import = 'Import',
  Export = 'Export',

  // PurposeOfCallData
  Normal = 'N',
  NormalValue = '1-Normal',
  Heighten = 'H',
  HeightenValue = '2-Heightened',
  Exceptional = 'E',
  ExceptionalValue = '3-Exceptional',

  Ship_Icon = 'ship-icon',
  Plain_Icon = 'plane-icon',
  TrainIcon = 'declare-train-icon',
  PlainIcon = 'declare-plane-icon',
  ShipIcon = 'declare-ship-icon',
  TruckIcon = 'declare-truck-icon',

  CarrierType = 'CarrierType',
  JourneyType = 'JourneyType',
  AgentData = 'agentData',
  AgentList = 'agentList',
  AssociatedAgentData = 'associatedAgentData',
  Journey = 'Journey',
  OptionalValue = '--',
}

export enum DeclarationConstants {
  Start = 'Start',
  Created = 'Created',
  Modified = 'Modified',
  Cancelled = 'Cancelled',
  CreatedToSubmittedPassengerAssessment = 'CreatedToSubmittedPassengerAssessment',
  DutyCalculated = 'Duty Calculated',
  DraftCreated = 'Draft Created',
  InProgress = 'In Progress',
  DutyCalculatedToSubmittedDeclaration = 'DutyCalculatedToSubmittedDeclaration',
  DraftCreatedToDutyCalculatedDeclaration = 'DraftCreatedToDutyCalculatedDeclaration',
  Paid = 'Paid',
  PaidBill = 'Paid Bill',
  CalculateDuty = 'Calculate Duty',
  SubmittoCustoms = 'Submit to Customs',
  Edit = 'Edit',
  FinalExportNormalExport = 'Final Export - Normal Export',
  UploadAtLeast1Doc = 'UploadAtLeast1Doc',
  UnabletoUploadFile = 'UnabletoUploadFile',
  ConfirmDelete = 'ConfirmDelete',
  SelectedFileDeletedSuccessfully = 'SelectedFileDeletedSuccessfully',
  ModifiedtoDutyCalculated = 'ModifiedtoDutyCalculated',
  SelectedFileExist = 'SelectedFileExist',
  DeclarationSubmittedtoAcknowledgeClaimValue = 'DeclarationSubmittedtoAcknowledgeClaimValue',
  ModifiedToSubmittedPassengerAssessment = 'ModifiedToSubmittedPassengerAssessment',
  SubmittedToApprovedDeclaration = 'SubmittedToApprovedDeclaration',
  LmwLicenseApprovedValidation = 'LmwLicenseApprovedValidation',
  Submit = 'Submit',
  DraftCreatedToInProgressATADeclaration = 'DraftCreatedToInProgressATADeclaration',
  DraftCreatedToSubmittedATADeclaration = 'DraftCreatedToSubmittedATADeclaration',
  StartToDraftCreatedDeclaration = 'StartToDraftCreatedDeclaration',
  SaveDraftCreatedDeclaration = 'SaveDraftCreatedDeclaration',
  DraftCreatedToClearedScDEclarations = 'DraftCreatedToClearedSCD',
}

export enum UserRoles {
  Exporter = 'Exporter',
  Importer = 'Importer',
  Cashier = 'Cashier',
  DataEntryOperator = 'DataEntryOperator',
  ForwardingAgent = 'ForwardingAgent',
  TRADER = 'TRADER',
  InspectionSupervisor = 'InspectionSupervisor',
  Inspector = 'Inspector',
  InspectorTeamLead = 'InspectorTeamLead',
  NCCOfficer = 'NCCOfficer',
  ValuationOfficer = 'ValuationOfficer',
  VERIFICATIONOFFICER = 'VerificationOfficer',
  VERIFICATIONSUPERVISOR = 'VerificationSupervisor',
  FinancialController = 'FinancialController',
  ClassificationOfficer = 'ClassificationOfficer',
  WarehouseOperator = 'WarehouseOperator',
  InspectorSupervisor = 'InspectorSupervisor',
  Laberatery = 'LABORATORY',
  ValuationDeskOfficer = 'ValuationDeskOfficer',
  SYSCUSTOMS = 'SYSCUSTOMS',
  Laboratory = 'Laboratory',
  CRSecretariat = 'CRSecretariat',
  MarineAdministrator = 'MARINEADMINISTRATOR',
  MOHAdministrator = 'MOHADMINISTRATOR',
  QuotaApprover = 'QuotaApprover',
  QuotaDataEntryOfficer = 'QuotaDataEntryOfficer',
  CashierRole = 'CASHIER',
  ShippingAgentRoleName = 'ShippingAgent',
  FinancialControllerRole = 'FINANCIALCONTROLLER',
  FreightForwarderUserRole = 'FreightForwarder',
  PortOperator = 'PortOperator',
  OGAInspectorOfficer = 'OGAInspector',
  WarehouseAuthority = 'WAREHOUSEAUTHORITY'
}

export enum NormalizedRoles {
  HODABTState = 'HODABTState',
  Cashier = 'Cashier',
  Manufacturer = 'Manufacturer',
  DataEntryOperator = 'DataEntryOperator',
  EnforcementOfficer = 'Enforcement Officer',
  CivilOfficer = 'Civil Officer',
  ClassificationOfficer = 'Classification Officer',
  BSChild = 'BSChild',
  SysCustoms = 'SysCustoms',
  ShipStoreSupplier = 'ShipStore Supplier',
  CargoTerminalOperator = 'CargoTerminal Operator',
  CarrierAgent = 'Carrier Agent',
  OGAInspector = 'OGA Inspector',
  QuotationSecretariat = 'QuotationSecretariat(HQ / State)',
  ChairmenofTenderCommittee = 'Chairmen of Tender Committee',
  IPPOperator = 'IPP Operator',
  InspectorTeamLead = 'Inspector Team Lead',
  ValuationOfficer = 'Valuation Officer',
  ForwardingAgent = 'Forwarding Agent',
  BERNASUser = 'BERNASUser',
  SuretyJuniorOfficer = 'Surety Junior Officer',
  StateABTOfficer = 'State ABT Officer',
  InspectorSupervisor = 'Inspector Supervisor',
  PortOperator = 'Port Operator',
  LiLMWoperator = 'LiLMW operator',
  ShipOwner = 'Ship Owner',
  AMLAInvstOfficer = 'AMLAInvst Officer',
  CPPOperator = 'CPP Operator',
  EnfStoreOfficer = 'EnfStore Officer',
  KTM = 'KTM',
  DRAuditor = 'DR Auditor',
  NCCOfficer = 'NCC Officer',
  OMKSSOfficer = 'OMKSS Officer',
  InvestigationOfficer = 'Investigation Officer',
  JLMPortOfficer = 'JLM Port Officer',
  DFSOperator = 'DFS Operator',
  WeighingOfficer = 'Weighing Officer',
  ShippingAgent = 'Shipping Agent',
  CEAOfficer = 'CEA Officer',
  ICDOperator = 'ICD Operator',
  BSMaster = 'BSMaster',
  MyAcR = 'MyAcR',
  WarehouseAuthority = 'Warehouse Authority',
  GovernmentMaster = 'Government Master',
  PSBOperator = 'PSB Operator',
  CustomsDataEntryOfficer = 'Customs DataEntry Officer',
  CustomsAccountAdmin = 'Customs Account Admin',
  Inspector = 'Inspector',
  WarehouseOperator = 'Warehouse Operator',
  CRSecretariat = 'CR Secretariat',
  SysAdmin = 'SysAdmin',
  VerificationSupervisor = 'Verification Supervisor',
  TariffDutiesManager = 'Tariff Duties Manager',
  FreightForwarder = 'Freight Forwarder',
  CustomAccoutningAdministrator = 'Custom Accoutning Administrator',
  ABTHQ = 'ABTHQ',
  CustomsManifestOfficer = 'Customs Manifest Officer',
  SysPayments = 'SysPayments',
  Registrator = 'Registrator',
  HeadofAuctionCommittee = 'Head of Auction Committee',
  TraderAgent = 'Trader Agent',
  CSDirector = 'CSDirector',
  CustomsManifestSupervisor = 'Customs Manifest Supervisor',
  AgentLicenseAccess = 'Agent License Access',
  PrincipalShippingAgent = 'Principal Shipping Agent',
  TenderSecretariat = 'Tender Secretariat(HQ)',
  CustomRegistrationAdmin = 'Custom Registration Admin',
  Importer = 'Importer',
  PTJOfficer = 'PTJ Officer',
  FinancialController = 'Financial Controller',
  EnfCollectionOfficer = 'EnfCollection Officer',
  SuretySeniorOfficer = 'Surety Senior Officer',
  EnfHeadofStation = 'Enf Head of Station',
  DataEntryOfficer = 'Data Entry Officer',
  AssessmentSupervisor = 'Assessment Supervisor',
  PaymentSupport = 'Payment Support',
  LicensedCarrier = 'Licensed Carrier',
  PaymentAuthorizer = 'Payment Authorizer',
  ChairmanofQuotationPanelCommittee = 'Chairman of Quotation Panel Committee',
  DRBroker = 'DR Broker',
  ScanningOfficer = 'Scanning Officer',
  AssessmentOfficer = 'Assessment Officer',
  HSManager = 'HS Manager',
  Exporter = 'Exporter',
  LegalAdvisorPPJ = 'Legal Advisor PPJ',
  VerificationOfficer = 'Verification Officer',
  OrganizationMaster = 'Organization Master',
  RefundsSupervisior = 'REFUNDS SUPERVISIOR',
  RefundsDeskOfficer = 'REFUNDS DESK OFFICER',
  DGCUSTOMS = 'DGCustoms',
  DGCustoms = 'DGCUSTOMS',
  ValuationDeskOfficer = 'ValuationDeskOfficer',
  PetroleumDataEntryOfficer = 'PetroleumDataEntryOfficer',
  VerificationOfficerRole = 'VERIFICATIONOFFICER',
  ClassificationOfficerRole = 'ClassificationOfficer',
  VerificationOfficerUserRole = 'VerificationOfficer',
  WarehouseOperatorRole = 'WarehouseOperator',
  PortOperatorRole = 'PortOperator',
  NCCOfficerRole = 'NCCOfficer',
  QuotaApprover = 'QUOTA APPROVER',
  VerificationSupervisorRole = 'VerificationSupervisor',
  PERMITASSIGNINGOFFICER = 'PERMITASSIGNINGOFFICER',
  PERMITAPPROVALOFFICER = 'PERMITAPPROVALOFFICER',
  PERMITREVIEWOFFICER = 'PERMITREVIEWOFFICER',
  PERMITRECOMMENDOFFICER = 'PERMITRECOMMENDOFFICER',
  OGAPortOperator = 'OGAPortOperator',
  FreeZoneOperator = 'FreeZoneOperator',
  ImporterRole = 'IMPORTER',
  CustomsManifestOfficerRole = 'CUSTOMSMANIFESTOFFICER',
  PortOperatorUserRole = 'PORTOPERATOR'
}

export enum InspectionReferenceType {
  BillofLading = 'Bill of Lading',
  Declaration = 'Declaration',
  Assessment = 'Assessment'
}

export enum States {
  Start = 'Start',
  Created = 'Created',
  Modified = 'Modified',
  Submitted = 'Submitted',
  Accepted = 'Accepted',
  SuretyRequested = 'Surety Requested',
  Activate = 'Activate',
  Deactivate = 'Deactivate',
  ReturnedforCorrection = 'Returned for Correction',
  Activated = 'Activated',
  DraftCreated = 'Draft Created',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Submited = 'Submited',
  Paid = 'Paid',
  ReturnedForCorrection = 'Returned for Correction'
}

export enum SampleRequest {

  // CurrentActionId's
  AcceptedToSuretyRequestedSampleRequest = 'AcceptedToSuretyRequestedSampleRequest',
  SuretySubmittedToAssignedSampleRequest = 'SuretySubmittedToAssignedSampleRequest',
  StartToCreatedSamepleRequest = 'StartToCreatedSamepleRequest',
  CreatedToModifiedSampleRequest = 'CreatedToModifiedSampleRequest',
  CreatedToSubmittedSampleRequest = 'CreatedToSubmittedSampleRequest',
  SubmittedToReturnedforCorrectionSampleRequest = 'SubmittedToReturnedforCorrectionSampleRequest',
  ModifiedToSubmittedSampleRequest = 'ModifiedToSubmittedSampleRequest',
  SuretyRequestedToSuretySubmittedSampleRequest = 'SuretyRequestedToSuretySubmittedSampleRequest',
  RegisteredToCompletedSampleRequest = 'RegisteredToCompletedSampleRequest',
  AcceptedToAssignedSampleRequest = 'AcceptedToAssignedSampleRequest',
  SenttoLabToLabAuthoritySubmittedSampleRequest = 'SenttoLabToLabAuthoritySubmittedSampleRequest',
  AssignedToRegisteredSampleRequest = 'AssignedToRegisteredSampleRequest',
  LabAuthSubmittedToCompletedSampleRequest = 'LabAuthSubmittedToCompletedSampleRequest',
  SubmittedToInitiatorToCompletedSampleRequest = 'SubmittedToInitiatorToCompletedSampleRequest',

  // DropDown Data
  DrawSample = 'Draw Sample',
  Photo = 'Photo Required',

  // Sample Request webstorage Texts
  UserProfile = 'userProfile',
  TRADER = 'TRADER', // 3254
  NCC = 'NCC',  // 3759
  CLASSIFICATIONOFFICER = 'CLASSIFICATION OFFICER',
  VALUATIONOFFICER = 'VALUATION OFFICER',
  VerificationOfficer = 'VERIFICATION OFFICER',
  VerificationSupervisor = 'VERIFICATION SUPERVISOR',
  Source = 3254,
  BillOfLadingId = 'billOfLadingId',
  SampleRequestReferenceId = 'sampleRequestReferenceId',
  SampleRequestId = 'sampleRequestId',
  SampleRequestNumber = 'sampleRequestNumber',
  StateName = 'stateName',
  RequestedSource = 'requestedSource',
  SAMPLEREQUEST = 'SAMPLE REQUEST',
  CHEQUEPAYMENTS = 'CHEQUE PAYMENTS',
  referenceId = 'referenceId',
  referenceNo = 'referenceNo',
  sampleRequestDataProfile = 'SampleRequest',
  sampleRequest = 'Sample Request',
  SuretyRequested = 'Surety Requested',
  LICENSEISSUANCE = 'LICENSE ISSUANCE',
  OGAInspector = 'OGA INSPECTOR',
  OGA = 'OGA'
}

// Payment
export enum PaymentChannelMode {
  OnlinePayment = 'ONLINE PAYMENT',
  System = 'SYSTEM',
  CustomsCounter = 'CUSTOMS COUNTER',

  OnlinePaymentTypeCode = 'PAYMENTCHANNELTYPES_ONLPAY',
  SystemTypeCode = 'PAYMENTCHANNELTYPES_SYS',
  CustomsCounterTypeCode = 'PAYMENTCHANNELTYPES_CUSCOU',

  Cash = 'CASH',
  Cheque = 'CHEQUE',
  BankDraft = 'BANK DRAFT',
  CreditCard = 'CREDIT CARD',
  DebitCard = 'DEBIT CARD',
  Deposit = 'DEPOSIT',
  DebitAccount = 'DEBIT ACCOUNT',
  DirectDEbit = 'DIRECT DEBIT',
  BILL = 'BILL',
  VOUCHER = 'VOUCHER',
  BILLREFERENCETYPES = 'BILLREFERENCETYPES',
  CustomsOrganization = 'Customs Organization',
  TraderOrganization = 'Trader Organization',
  Collect = 'Collect'
}

export enum PaymentProfileNames {
  PaymentDataProfile = 'Payment',
  BillsDataProfile = 'Bills',
  VoucherDetailsDataProfile = 'VoucherDetails',
  PaymentReceiptDataProfile = 'PaymentReceipt',
  PaymentTransaction = 'PaymentTransaction',
  RefundPaymentTransaction = 'RefundPaymentTransaction',
  RefundForPassenger = 'RefundForPassenger',
  PaymentBillDetails = 'PaymentBillDetails',
  PaymentVoucherDetails = 'PaymentVoucherDetails'
}

export enum PaymentChannelModeClass {
  OnlinePayment = 'payment-icon online-payment-icon',
  System = 'payment-icon system-icon',
  CustomsCounter = 'payment-icon customs-counter-icon'
}
export enum PaymentActions {
  StartToCreatedPayment = 'StartToCreatedPayment',
  ModifiedToSubmittedPayment = 'ModifiedToSubmittedPayment',
  CreatedToSubmittedPayment = 'CreatedToSubmittedPayment',
}

export enum PostingNames {
  Trader = 'Trader (Importer/Exporter)'
}
export enum multiLang {
  en = 'en',
  arb = 'arb'
}
export enum CommonMessages {
  EndDateTimeErrorMessage = 'EndDateTimeErrorMessage',
  EndDateErrorMessage = 'EndDateErrorMessage',
  EndTimeErrorMessage = 'EndTimeErrorMessage',
  PleaseEnterAllRequiredFiledsbeforebrowsethefile = 'PleaseEnterAllRequiredFiledsbeforebrowsethefile',
  SelectedFilesizeshouldbebelow10MB = 'SelectedFilesizeshouldbebelow10MB',
  InternalServerErrorPleaseTryAgainLater = 'InternalServerErrorPleaseTryAgainLater',
  SpaceNotAllowed = 'SpaceNotAllowed',
  CrossDateFieldsErrorMessage = 'CrossDateFieldsErrorMessage'
}
export enum CargoProfiles {
  JourneyProfile = 'Journey',
  ManifestProfile = 'Manifest',
  BillOfLadingProfile = 'BillOfLading',
  HbItemProfile = 'HbItem',
  HbPartyProfile = 'HbParty',
  ContainerProfile = 'Container',
  VehicleProfile = 'Vehicle',
  DeliveryOrderProfile = 'DeliveryOrder',
  NoObjectionCertificateProfile = 'NoObjectionCertificate',
  ShutOutCertificateProfile = 'ShutOutCertificate',
  SCFinalOutturnCertificateProfile = 'SCFinalOutturnCertificate',
  SCLoadUnloadDetailsProfile = 'SCLoadUnloadDetails',
  AirCarrierProfile = 'AirCarrier',
  LandCarrierProfile = 'LandCarrier',
  RailCarrierProfile = 'RailCarrier',
  VesselProfile = 'Vessel',
  FlightInformationProfile = 'AirCarrierFlightInformation',
  PilotInformationProfile = 'AirCarrierPilotInformation',
  LandVechicleProfile = 'LandVechicleProfile',
  LandDriverProfile = 'LandDriverProfile',
  CarrierDriverProfile = 'CarrierDriver',
  CarrierVehicleProfile = 'CarrierVehicle',
  RailDriverInformationProfile = 'RailDriverInformation',
  SCCertificateProfile = 'SCCertificate',
  SCChartererDetailProfile = 'SCChartererDetail',
  SCOwnerDetailProfile = 'SCOwnerDetail',
  SuppManifestProfile = 'SuppManifest'

}
export enum DeclarationProfiles {
  DeclarationProfile = 'Declaration',
  FunctionalUnitProfile = 'FunctionalUnit',
  PassengerAssessmentProfile = 'PassengerAssessment',
  DRADeclarationProfile = 'DRDeclaration',
  xmlUpload = 'DeclarationXMLUpload',
  InvoiceProfile = 'Invoice',
  InvoiceItemProfile = 'InvoiceItem',
  ValueDeclaration = 'ValueDeclaration',
  DeclarationCancellation = 'DeclarationCancellation',
  ScDeclarationProfile = 'SimplifiedCourierDeclaration',
  SCDItemProfile = 'SimplifiedCourierDeclarationItem'
}

export enum RemarksHistoryModuleMethodNames {
  CargoMethod = 'GetCargoRemarksHistoryData',
  DeclarationMethod = 'GetDeclarationRemarksHistoryData',
  InspectionMethod = 'GetInspectionRemarksHistoryData',
  AssessmentMethod = 'GetAssessmentRemarksHistoryData',
  PaymentMethod = 'GetPaymentRemarksHistoryData',
  AccountingMethod = 'GetAccountingRemarksHistoryData',
  RegistrationMethod = 'GetRegistrationRemarksHistoryData',
  SuretyMethod = 'GetSuretyRemarksHistoryData',
  ShipClearanceMethod = 'GetShipClearanceRemarksHistoryData',
  VesselShipClearanceMethod = 'GetSHIPCLEARANCERemarksHistoryData',
  CommonMethod = 'GetCommonRemarksHistoryData',
  TariffvaluationMethod = 'GetTariffValuationRemarksHistoryData',
  InboxMethod = 'GetInboxRemarksHistoryData',
  TariffexemptionMethod = 'GetTariffexemptionRemarksHistoryData',
  TariffmngtMethod = 'GetTariffMngtRemarksHistoryData',
  TariffRulingMethod = 'GetTariffRulingRemarksHistoryData',
  ResourcePlanningMethod = 'GetResourcePlanningRemarksHistoryData',
  ReleaseMethod = 'GetReleaseRemarksHistoryData',
  QuotaMethod = 'GetQuotaRemarksHistoryData',
  RefundsMethod = 'GetRefundsRemarksHistoryData',
  ExemptionMethod = 'GetExemptionRemarksHistoryData',
  VesselMethod = 'GetVesselRemarksHistoryData',
  DrwabackMethod = 'GetRefundsRemarksHistoryData'
}

export enum ReleaseProfileNames {
  GatePassDataProfile = 'GatePass',
}

export enum ValuationProfile {
  ExchangeRateProfile = 'ExchangeRate',
}
export enum InspectionProfileNames {
  InspectionDataProfile = 'Inspection',
  InspectionReportDataProfile = 'InspectionReport',
  InspectionSampleRequestDataProfile = 'SampleRequest',
}
export enum RefundsProfileNames {
  RefundsDataProfile = 'refund'
}
export enum AccountingProfileNames {
  AccountingConfigurationDataProfile = 'AccountingConfiguration',
  CollectionAccountDataProfile = 'CollectionAccount',
  PassengerRefundAccountDataProfile = 'RefundAccount',
  RevenueAccountDataProfile = 'RevenueAccount',
  RevenueSourceConfigurationDataProfile = 'RevenueSourceConfiguration',
  RevenueGroupDataProfile = 'RevenueGroup'
}
export enum AssessmentProfileNames {
  AssessmentRequestDataProfile = 'AssessmentRequest'
}
export enum TariffRullingProfileNames {
  RulingDataProfile = 'Ruling',
  RulingsAdviceRequestDataProfile = 'RulingsAdviceRequest',
  ClassificationAppealDataProfile = 'ClassificationAppeal',
  ValuationAppealDataProfile = 'ValuationAppeal'
}
export enum TariffManagementProfileNames {
  SpecificationDataProfile = 'Specification',
  RulingsAdviceRequestDataProfile = 'RulingsAdviceRequest',
  ClassificationAppealDataProfile = 'ClassificationAppeal',
  ValuationAppealDataProfile = 'ValuationAppeal',
  ConditionalProhibitionDataProfile = 'ConditionalProhibition'
}
export enum TariffValuationProfileNames {
  MotorVehiclePriceDataDataProfile = 'MotorVehiclePriceData',
  MotorVehicleValuationDataProfile = 'MotorVehicleValuation',
  PetroleumInformationDataProfile = 'PetroleumInformation',
  VehicleAgeConfigurationDataProfile = 'VehicleAgeConfiguration',
  ConditionalProhibitionDataProfile = 'ConditionalProhibition',
  PriceDataOtherGoodDataProfile = 'PriceDataOtherGood'
}
export enum TariffExemptionProfileNames {
  ExemptionCategoryInformationDataProfile = 'ExemptionCategoryInformation',
  ExemptionApprovalConditionDataProfile = 'ExemptionApprovalCondition',
  ExemptionLawRegulationDataProfile = 'ExemptionLawRegulation',
  ValuationAppealDataProfile = 'ValuationAppeal',
  FTAManagementDataProfile = 'FTAManagement'
}

export enum RefundsMessages {
  refundsSaveMessage = 'refundsSaveMessage'
}
export enum CommonMessages {
  DocumentsAreUploadedMsg = 'UploadSuccess',
  CommonFilterMessage = 'CommonFilterMessage'
}
export enum Index {
  zero = 0,
  one = 1,
  two = 2
}

export enum HttpStatusCode {
  Continue = 100,
  SwitchingProtocols = 101,
  Processing = 102,
  EarlyHints = 103,
  OK = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultiStatus = 207,
  AlreadyReported = 208,
  IMUsed = 226,
  Ambiguous = 300,
  MultipleChoices = 300,
  Moved = 301,
  MovedPermanently = 301,
  Found = 302,
  Redirect = 302,
  RedirectMethod = 303,
  SeeOther = 303,
  NotModified = 304,
  UseProxy = 305,
  Unused = 306,
  RedirectKeepVerb = 307,
  TemporaryRedirect = 307,
  PermanentRedirect = 308,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  RequestEntityTooLarge = 413,
  RequestUriTooLong = 414,
  UnsupportedMediaType = 415,
  RequestedRangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  MisdirectedRequest = 421,
  UnprocessableEntity = 422,
  Locked = 423,
  FailedDependency = 424,
  UpgradeRequired = 426,
  PreconditionRequired = 428,
  TooManyRequests = 429,
  RequestHeaderFieldsTooLarge = 431,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HttpVersionNotSupported = 505,
  VariantAlsoNegotiates = 506,
  InsufficientStorage = 507,
  LoopDetected = 508,
  NotExtended = 510,
  NetworkAuthenticationRequired = 511
}

export enum CommonStates {
  start = 'Start',
  created = 'Created',
  modified = 'Modified',
  submitted = 'Submitted',
  approved = 'Approved',
  rejected = 'Rejected',
  activated = 'Activated',
  reviewinProgress = 'Review In Progress',
  proceedtoChange = 'Proceed To Change',
  returnedforCorrection = 'Returned for Correction',
  OGAReportSubmitted = 'OGA Report Submitted',
  SenttoOGA = 'Sent to OGA',
  deactivated = 'Deactivated',
  NotifiedAEOPanelMembers = 'Notified AEO Panel Members',
  Executed = 'Executed',
  pendingManifest = 'Conditional Approved Pending Manifest',
  cleared = 'Cleared'
}
export enum PermitDataProfiles {
  ProductCodeDataProfile = 'ProductCode',
  MDTMTMethodProfile = 'MDTMTMethod',
  MDTMTMethodProfile1 = 'TreatmentMethod',
  ScientificNameDataProfile = 'CBRAMDScientificName',
  SupportingDocProfile = 'PMTMDDocument',
  CheckListDataProfile = 'CheckList',
  PermitConfigurationDataProfile = 'PermitConfiguration',
  CfgItemCategoryProfile = 'CfgItemCategory',
  CfgItemSubCategoryProfile = 'CfgItemSubCategory',
  PermitItemDataProfile = 'cfgItem',
  CfgNotificationsContent = 'CfgNotificationsContent',
  PMTAuthorizedItemDataProfile = 'PMTAuthorizedItem',
  PMTAuthorizedItemCategDataProfile = 'PmtAuthItemCategory',
  BranchUsersDataProfile = 'MDBranch',
  ApplicationItemDataProfile = 'ApplicationItem',
  ApplicationItemCategoryProfile = 'ApplicationItemCategory',
  ApplicationItemSubCategory = 'ApplicationItemSubCategory',
  EndUserDetailsProfile = 'EndUserDetails',
  ConsignorDetailsDataProfile = 'ConsignorDetails',
  ConsigneeDetailsDataProfile = 'ConsigneeDetails',
  ItemOtherDetailsProfile = 'ItemOtherDetails',
  PermitApplication = 'PermitApplication'
}

export enum LicenseProfiles {
  InspectionDetailProfile = 'InspectionDetails',
  LicenseReasonsProfile = 'LicenseReasons',
  ComplianceCheckList = 'ComplianceCheckList',
  LicenseListDetails = 'LicenseListDetails',
  LicenseSupportDocuments = 'SupportingDocuments',
  AdditionalFacilityDataProfile = 'AdditionalFacility',
  MachineryDetails = 'MachineryDetails',
  StoredGoods = 'WHGoodsDetails'
}

export enum LicenseDataProfiles {
  ActsDetailsDataProfile = 'ActsDetails',
  SectionsRegulationsDataProfile = 'SectionsRegulations',
  LicenseListDetails = 'LicenseListDetails',
  LicenseNameDetails = 'LicenseNameDetails',
  LicenseReasons = 'LicenseReasons',
  MachineryDetailsDataProfile = 'MachineryDetails',
  RecommendationsDetailsDataProfile = 'RecommendationsDetails',
  AdditionalFacilityDataProfile = 'AdditionalFacility',
  LicenseConfigurationsDataProfile = 'LicenseConfigurations',
  AEOSalesValue = 'AEOSalesValue',
  AEOGoodsDetails = 'AEOGoodsDetails',
  complianceCheckList = 'complianceCheckList',
  AEOcomplianceCheckList = 'AEOcomplianceCheckList',
  LicenseInformation = 'LicenseInformation',
  SuspensionRequest = 'SuspensionRequest',
  LicenseOffDetails = 'LicenseOffDetails',
  WHGoodsDetails = 'WHGoodsDetails',
  MachineryDetails = 'MachineryDetails',
  NotifyPanelMem = 'NotifyPanelMem',
  SuretyDetails = 'SuretyDetails',
  RenewalRequestSuretyDetails = 'RenewalRequestSuretyDetails',
  RenewalRequest = 'RenewalRequest'
}

export enum LicenseActions {
  CreatedToActivatedActsDetails = 'ActivateActsDetails',
  ModifiedToActivatedActsDetails = 'ActivateModifiedActsDetails',
  DeactivateToActivatedActsDetails = 'ActivateDeactivatedActsDetails',
  DeactivateActsDetails = 'DeactivateActsDetails',
  CreatedToActivatedSectionsRegulations = 'CreatedToActivatedSectionsRegulations',
  ModifiedToActivatedSectionsRegulations = 'ModifiedToActivatedSectionsRegulations',
  ActivatedToDeactivatedSectionsRegulations = 'ActivatedToDeactivatedSectionsRegulations',
  DeactivatedToActivatedSectionsRegulations = 'DeactivatedToActivatedSectionsRegulations',
  // License Reasons
  CreatedToActivatedLicenseReasons = 'CreatedToActivatedLicenseReasons',
  ModifiedToActivatedLicenseReasons = 'ModifiedToActivatedLicenseReasons',
  ActivatedToDeactivatedLicenseReasons = 'ActivatedToDeactivatedLicenseReasons',
  CreatedToActivatedRecommendationsDetails = 'CreatedToActivatedRecommendationsDetails',
  ModifiedToActivatedRecommendationsDetails = 'ModifiedToActivatedRecommendationsDetails',

  // License AEO GoodsDetails
  ModifiedToSubmittedAEOGoodsDetails = 'ModifiedToSubmittedAEOGoodsDetails',
  CreatedToSubmittedAEOGoodsDetails = 'CreatedToSubmittedAEOGoodsDetails',
}
export enum LicenseListActions {
  CreatedToActivatedLicenseListDetails = 'CreatedToActivatedLicenseListDetails',
  ModifiedToActivatedLicenseListDetails = 'ModifiedToActivatedLicenseListDetails',
  DeactivateToActivatedLicenseListDetails = 'DeactivateToActivatedLicenseListDetails'
}
export enum LicenseNameActions {
  CreatedToActivatedLicenseNameDetails = 'CreatedToActivatedLicenseNameDetails',
  ModifiedToActivatedLicenseNameDetails = 'ModifiedToActivatedLicenseNameDetails',
  DeactivateToActivatedLicenseNameDetails = 'DeactivateToActivatedLicenseNameDetails'
}
export enum LicenseSupportDocsActions {
  CreatedToActivatedLicenseSupportDocsDetails = 'CreatedToActivatedLicenseSupportDocsDetails',
  ModifiedToActivatedLicenseSupportDocsDetails = 'ModifiedToActivatedLicenseSupportDocsDetails',
  DeactivateToActivatedLicenseSupportDocsDetails = 'DeactivateToActivatedLicenseSupportDocsDetails'
}

export enum MachineryDetailsActions {
  CreatedToActivatedMachineryDetails = 'CreatedToActivatedMachineryDetails',
  ModifiedToActivatedMachineryDetails = 'ModifiedToActivatedMachineryDetails',
  DeactivateToActivatedMachineryDetails = 'DeactivateToActivatedMachineryDetails'
}

export enum GenericMasterDataTabConstants {
  supportingDocsTabId = 0,
  InspectionDetailsTabId = 1,
  ConditionsRecommendationsTabId = 2,
  MachinaryEquipmentTabId = 3,
  LicenseReasonsTabId = 4,
  ActsDetailsTabId = 5,
  LicenseDetailsTabId = 6,
  ActivityDetailsTabId = 7,
  ComplianceCheckListTabId = 8
}

export enum LicenseAdditionalFacilityActions {
  ActivatedToDeactivatedAdditionalFacility = 'ActivatedToDeactivatedAdditionalFacility',
  ModifiedToActivatedAdditionalFacility = 'ModifiedToActivatedAdditionalFacility',
  CreatedToActivatedAdditionalFacility = 'CreatedToActivatedAdditionalFacility',
  DeactivateToActivatedAdditionalFacility = 'DeactivateToActivatedAdditionalFacility'
}
export enum SalesValueActions {
  CreatedToSubmittedAEOSalesValue = 'CreatedToSubmittedAEOSalesValue',
  ModifiedToSubmittedAEOSalesValue = 'ModifiedToSubmittedAEOSalesValue',
}
export enum SuretyDetailsActions {
  CreatedToSubmittedSuretyTransaction = 'CreatedToSubmittedSuretyTransaction',
  ModifiedToSubmittedSuretyTransaction = 'ModifiedToSubmittedSuretyTransaction',
}
export enum AdditionalInfoActions {
  CreatedToModifiedAEOAdditionalInfo = 'CreatedToModifiedAEOAdditionalInfo',
  SaveModifiedAEOAdditionalInfo = 'SaveModifiedAEOAdditionalInfo',
}
export enum OfficialDetails {
  CreatedToModifiedLicenseOfficialDetails = 'CreatedToModifiedLicenseOfficialDetails',
  SaveModifiedLicenseOfficialDetails = 'SaveModifiedLicenseOfficialDetails',
}

