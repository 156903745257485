import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material';
import { SwUpdate } from '@angular/service-worker';
import { AlertModalService } from './AlertModalService';
import { McnConfig } from '../mcnConfig/McnConfig';
import { AccountMessages } from '../account/constant/accountConstant';

@Injectable()
export class PWAService {
  constructor(private swUpdate: SwUpdate, private snackbar: MatSnackBar,
    private alertModalService: AlertModalService,
    public appConfig: McnConfig) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.alertModalService.confirm('info', 'warn-icon', 'Info', this.appConfig.getHardCodeMSg(AccountMessages.Newversionavailable), '')
          .subscribe((res) => {
            if (res === true) {
              window.location.reload();
            }
          });
      });
    }
  }
}
