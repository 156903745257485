import { ServiceDocument } from '@agility/frameworkcore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, map } from 'rxjs/operators';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { ToasterService } from '../../../toastrService/toasterService';
import { AccountMessages } from '../../constant/accountConstant';
import { DepartmentPostingModel } from '../../registration/registartionProcess/customs/models/DepartmentPostingModel';
import { CustomStationModel } from '../commonRegistrationComponents/associatonComponents/models/customStationModel';
import { LocationViewModel } from '../commonRegistrationComponents/associatonComponents/models/LocationViewModel';
import { PostingModel } from '../commonRegistrationComponents/associatonComponents/models/postingModel';
import { UserCustomStationModel } from '../commonRegistrationComponents/associatonComponents/models/userCustomStationModel';
import { UserPostingModel } from '../commonRegistrationComponents/associatonComponents/models/userPostingModel';
import { DepartmentModel } from '../commonRegistrationComponents/wizardData/departmentModel';
import { SubmitModel } from '../commonRegistrationComponents/wizardData/submitModel';
@Injectable()
export class CustomsService {
  public departmentItem: any;
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public postings: PostingModel[] = [];
  public stations: CustomStationModel[] = [];
  public ports: LocationViewModel[] = [];
  public postingServiceDocument: ServiceDocument<PostingModel> = new ServiceDocument<PostingModel>();
  public deptPostingServiceDocument: ServiceDocument<DepartmentPostingModel> = new ServiceDocument<DepartmentPostingModel>();
  public userProfileViewServiceDocument: ServiceDocument<SubmitModel> = new ServiceDocument<SubmitModel>();
  public departmentserviceDocument: ServiceDocument<DepartmentModel> = new ServiceDocument<DepartmentModel>();

  constructor(private http: HttpClient,
    private toastrService: ToasterService,
    private spinnerService: Ng4LoadingSpinnerService,
    private appConfig: McnConfig) { }
  // Department Related
  public list(): Observable<any> {
    return this.http.post(this.appConfig.listDepartment, '', { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public open(id: number): Observable<any> {
    return this.http.get(this.appConfig.openDepartment + '?id=' + id, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public usersList(departmentId: any): Observable<any> {
    return this.http.post(this.appConfig.getUserDataByDeptId + '?id=' + departmentId, '', { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public openOrganization(): Observable<any> {
    return this.http.get(this.appConfig.getCustomOrganizationData, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getDepartments(id: number): Observable<any> {
    return this.http.get(this.appConfig.openDepartmentByOrgId + '?organaizationId=' + id, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));

  }

  public getDepartmentUser(deptId: number) {
    return this.http.get(this.appConfig.listDepartmentUsersByDeptId + '?deptId=' + deptId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public customsDepartmentUserList(deptId: any = '', deptCode: any = '', pageIndex: number = 1,
   recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    return this.http.post(this.appConfig.listCustomsDepartmentsByOrgId + '?deptId=' + deptId +
     '&deptCode=' + deptCode + '&pageIndex=' + pageIndex + '&recordPerPage=' + recordPerPage, '', { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public submitDepartment(model: any): Observable<any> {
    return this.http.post(this.appConfig.submitDepartment, model, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  // User Association Related Methods

  // Ports
  public associatePorts(userProfileId: string): Observable<any> {
    return this.http.get(this.appConfig.getAssociatedPortData + '?userProfileId=' + userProfileId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public submitUserPort(model: any[], userProfileId: any): Observable<any> {
    return this.http.post(this.appConfig.submitUserPort + '?userProfileId=' + userProfileId, model, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  // Profiles
  public associateProfiles(userProfileId: string): Observable<any> {
    return this.http.get(this.appConfig.getAssociatedUserProfile + '?userProfileId=' + userProfileId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public getCustomProfiles(profileName: any, deptId: any, pageIndex: number = 1,
     recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    const profile = this.setProfileName(profileName);
    return this.http.get(this.appConfig.getCustomsUserProfiles + '?deptId=' + deptId +
     '&pageIndex=' + pageIndex + '&recordPerPage=' + recordPerPage + profile, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getCustomsDepatrmentProfiles(profileName: any, associatedIds: any, pageIndex: number = 1,
     recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    const profile = this.setProfileName(profileName);
    const ids = associatedIds.length > 0 ? associatedIds.join() : null;
    return this.http.get(this.appConfig.getCustomsDepatrmentProfiles + '?pageIndex=' + pageIndex +
     '&recordPerPage=' + recordPerPage + profile
      + '&associatedIds=' + ids, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getOrganaizationProfiles(profileName: any, orgId: any, pageIndex: number = 1,
     recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    const profile = this.setProfileName(profileName);
    return this.http.get(this.appConfig.getOrganaizationProfiles + '?orgId=' + orgId +
     '&pageIndex=' + pageIndex + '&recordPerPage=' + recordPerPage + profile, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getGovernmentProfiles(profileName: any, orgId: any, pageIndex: number = 1,
     recordPerPage: number = this.appConfig.appData.associatePageCount): Observable<any> {
    const profile = this.setProfileName(profileName);
    return this.http.get(this.appConfig.getGovernmentProfiles + '?orgId=' + orgId +
     '&pageIndex=' + pageIndex + '&recordPerPage=' + recordPerPage + profile, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  private setProfileName(profileName: any) {
    return (profileName === null || profileName === '' || profileName === undefined) ? '' : '&profileName=' + profileName;
  }

  public submitUserProfile(model: any[], userProfileId: any): Observable<any> {
    const userPostings: UserPostingModel = new UserPostingModel();
    userPostings.associatedProfile = model;
    userPostings.userId = userProfileId;
    return this.http.post(this.appConfig.submitUserProfile, userPostings, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  // CustomStations
  public getAssociatedUserCustomStation(userProfileId: any): Observable<any> {
    return this.http.get(this.appConfig.getAssociatedUserCustomStation + '?userProfileId=' + userProfileId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public submitUserCustomstation(model: any[], userProfileId: any): Observable<any> {
    const userStations: UserCustomStationModel = new UserCustomStationModel();
    userStations.associatedCustomStations = model;
    return this.http.post(this.appConfig.submitUserCustomStations + '?userProfileId=' +
     userProfileId, userStations.associatedCustomStations, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  // Activate
  public activate(userProfileId: any): Observable<any> {
    return this.http.get(this.appConfig.sendActivationMail + '?userId=' + userProfileId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public activateAndDeactivate(userProfileId: any, activateUser: boolean, cancelled: boolean): Observable<any> {
    return this.http.get(this.appConfig.updateUserState + '?userId=' + userProfileId +
     '&activateUser=' + activateUser + '&cancelUser=' + cancelled, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }
  public checkUserActivation(userProfileId: any): Observable<any> {
    return this.http.get(this.appConfig.checkUserActivation + '?userId=' + userProfileId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public getEncryptData(userId: any): Observable<any> {
    return this.http.get(this.appConfig.encryption + '?value=' + userId, { responseType: 'text' });
  }

  public resetPassword(emailId: any) {
    return this.http.get(this.appConfig.sendResetPasswordLink + '?emailId=' + emailId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  // department Postings
  public getFilterPostingsList(postingName: string): Observable<ServiceDocument<PostingModel>> {
    return this.postingServiceDocument.list(this.appConfig.filterAssociationProfiles + '?profileName=' + postingName)
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public newCustomsDeptPosting(deptId: any): Observable<ServiceDocument<DepartmentPostingModel>> {
    return this.deptPostingServiceDocument.new(this.appConfig.newCustomsDeptPosting + '?departmentId=' + deptId)
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public submitDeptAssProfiles(): Observable<ServiceDocument<DepartmentPostingModel>> {
    return this.deptPostingServiceDocument.submit(this.appConfig.submitDepartmentAssProfiles)
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public customsGetMyProfile(userId: any) {
    return this.userProfileViewServiceDocument.view(this.appConfig.getMyProfile + '?userId=' + userId)
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }


  public deActivate(userProfileId: any, activateUser: boolean): Observable<any> {
    return this.http.get(this.appConfig.updateUserState + '?userId=' + userProfileId +
     '&activateUser=' + activateUser, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
         msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  private handleError() {
    return this.toastrService.Error(this.appConfig.getHardCodeMSg(AccountMessages.InternalServerErrorPleaseTryAgainLater));
  }
}
