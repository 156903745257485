﻿/* tslint:disable: max-line-length */
import { Injectable } from '@angular/core';

@Injectable()
export class ValidationPatterns {
    public NumericAndDecimalPattern = /^\-{0,1}\d+(.\d+){0,1}$/;
    public NumericsPattern = /^([0-9]{1,100})$/;
    public AllowCharectersPattern = /^[a-zA-Z]*$/;
    public AllowAlphanumericsPattern = /^[a-zA-Z0-9 ]+$/;
    public AllowSpecialCharectersPattern = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
    public AllowAllPattern = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
    public EmailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public RomanNumberPattern = /^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;
    public PasswordPattern = /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,15})$/;
    public NumericsPatternWithNegative = /^-?[0-9]\d*(\.\d+)?$/;
}
