import { Component, OnInit, Renderer } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

import { FilterMenuService } from '../../../common/filter-menu.service';
import { OpmReportsService } from '../services/opmReports/OpmReportsService';
import { ServiceDocument } from '@agility/frameworkcore';
import { ReportCategorizationModel } from '../models/ReportCategorizationModel';
import { ProfileName, ModelName, Message } from '../../riskSelectivityProfiling/common/Constants';
import { RiskIdentificationAndAnalysisSharedService } from '../services/sharedServices/RiskIdentificationAndAnalysisSharedService';
import { StorageService } from '../../../../app/storageService/storageService';



@Component({
  selector: 'app-opm-reports',
  templateUrl: './OpmReportsComponent.html',

})
export class OpmReportsComponent implements OnInit {


  public filterMenuOpened: boolean;
  public riskImpctFltrForm: FormGroup;
  public researchCommodityForm: FormGroup;
  public newFrequecy: boolean;
  public modified: boolean;
  public openResearchCommodity: boolean;
  public opmReportsServiceDocument: any = [];
  public opmReportsList: ReportCategorizationModel[];
  public pageCount: number;
  private reportsExpression: any;


  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private filterMenuService: FilterMenuService,
    private router: Router,
    public webStorage: StorageService,
    private activatedRoute: ActivatedRoute,
    private opmReportsService: OpmReportsService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
  ) {
  }

  ngOnInit() {

    this.activatedRoute.data.subscribe(() => {
      this.opmReportsServiceDocument = this.opmReportsService.opmReportsServiceDocument;
      if (this.opmReportsServiceDocument.dataProfile !== undefined) {
        this.opmReportsList = this.opmReportsServiceDocument.dataProfile.dataList;
      } else {
        this.opmReportsList = [];
      }
      if (this.opmReportsServiceDocument.totalCount !== null) {
        this.pageCount = this.opmReportsServiceDocument.totalCount;
      }
    });

    this.filterMenuService.isFilterMenuOpen().subscribe((isFilterMenuOpened: boolean) => {
      this.filterMenuOpened = isFilterMenuOpened;
    });
    this.riskImpctFltrForm = this.fb.group({
      itemHsCode: [''],
      country: [''],
      status: ['']
    });


    this.researchCommodityForm = this.fb.group({
      pricingDataSource: [''],
    });


  }

  public FiltermenuOpen() {
    if (this.filterMenuOpened === undefined || this.filterMenuOpened === false) {
      this.filterMenuOpened = true;
    } else {
      this.filterMenuOpened = false;
    }
  }


  public GetComplianceReports(name: string) {
    this.riskIdentificationAndAnalysisSharedService.sharingData.opmReportName = name;
    this.router.navigate(['risk-identification-Analysis/opm-compliance-reports']);
  }

  public getNextReportsList(response: any) {
    this.opmReportsService.listOpmReports(
      this.reportsExpression, ModelName.OPMReportsListModel, response.PageIndex, response.PageCount)
      .subscribe((_response) => {
        this.opmReportsList = this.opmReportsServiceDocument.dataProfile.dataList;
      });
  }

}
