import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { DataSource } from '@angular/cdk/table';
import { Observable, of, pipe } from 'rxjs';

import { CommonService } from '@agility/frameworkcore';

import { ModuleNames, CommonTypes } from '../../common/Constants';
import { MsgConfigService } from './services/MsgConfigService';
import { ToasterService } from '../../toastrService/toasterService';
import { StorageService } from '../../storageService/storageService';
import { MessageConfigModel } from './models/MessageConfigModel';
export interface Data { }

export class ExampleDataSource extends DataSource<any> {
  constructor(private data: Data[]) {
    super();
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  public connect(): Observable<Data[]> {
    return of(this.data);
  }

  public disconnect() {
    // EMPTY
  }
}

@Component({
  selector: 'app-messaging-sys',
  templateUrl: './MessagingSysComponent.html',
})
export class MessagingSysComponent implements OnInit {
  public actionConfigForm: FormGroup;
  public msgConfigForm: FormGroup;
  public roleConfigForm: FormGroup;
  public serviceDocument: any;
  public isCascading: boolean;
  public isEdit = false;
  public displayedColumns = ['moduleName', 'profileName', 'actionType', 'role', 'status', 'icons'];
  public dataSource: ExampleDataSource | null;
  public msgType: any[] = [];
  public msgPriority: any[] = [];
  public msgList: any[] = [];
  public moduleName: any;
  public dataProfileId: any;
  public profileUrl: any = null;
  public claimTypeUrl: any = null;
  public roleUrl: any = null;
  public msgConfigData: any;
  public roleData: any[] = [];
  public pageCount: any;
  public selectedRoles: any[] = [];
  public profileCascading: boolean;
  public claimCascading: boolean;
  public isClaimBased: boolean;
  public isStateBased: boolean;
  public isUserBased: boolean;
  public msgConfigOptions: any[] = [
    {
      typeId: 1,
      name: 'Claim Based',
    },
    {
      typeId: 2,
      name: 'State Based',
    },
    {
      typeId: 3,
      name: 'User Based',
    }
  ];
  private messageConfigModel: MessageConfigModel;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public appConfig: McnConfig,
    private fb: FormBuilder,
    public storageService: StorageService,
    private commonService: CommonService,
    private msgConfigService: MsgConfigService,
    private toasterService: ToasterService) {
    this.appConfig.getCascadingTypes(ModuleNames.Inbox, CommonTypes.MsgTypes).subscribe((res) => {
      this.msgType = this.extractDomainData(res);
    });
  }

  ngOnInit() {
    this.actionConfigForm = this.fb.group({
      modName: [''],
      configOption: ['']
    });

    this.roleConfigForm = this.fb.group({
      roles: [[]]
    });

    this.activatedRoute.data
      .subscribe((res) => {
        this.setConfigOptions(true, false, false);
        this.setValidations(res, true);
      });
  }



  public getConfigOption(event: any) {
    if (event.options) {
      if (event.options.typeId === 2) {
        this.setConfigOptions(false, true, false);
        this.msgConfigForm.controls.stateId.setValidators([Validators.required]);
        this.roleConfigForm.controls.roles.setValidators([Validators.required]);
        this.msgConfigForm.controls.claimId.clearValidators();
        this.msgConfigForm.controls.isStateBased.setValue(true);
        this.msgConfigForm.controls.isUserBased.setValue(false);
        this.msgConfigForm.controls.isRoleBased.setValue(false);
      } else if (event.options.typeId === 3) {
        this.setConfigOptions(false, false, true);
        this.msgConfigForm.controls.claimId.clearValidators();
        this.msgConfigForm.controls.stateId.clearValidators();
        this.roleConfigForm.controls.roles.clearValidators();
        this.msgConfigForm.controls.isStateBased.setValue(false);
        this.msgConfigForm.controls.isUserBased.setValue(true);
        this.msgConfigForm.controls.isRoleBased.setValue(false);

      } else if (event.options.typeId === 1) {
        this.setConfigOptions(true, false, false);
        this.msgConfigForm.controls.claimId.setValidators([Validators.required]);
        this.msgConfigForm.controls.stateId.clearValidators();
        this.roleConfigForm.controls.roles.setValidators([Validators.required]);
        this.msgConfigForm.controls.isRoleBased.setValue(true);
        this.msgConfigForm.controls.isStateBased.setValue(false);
        this.msgConfigForm.controls.isUserBased.setValue(false);
      }
    }
  }

  public getModule(event: any) {
    if (event.options) {
      this.profileUrl = this.appConfig.getModuleProfilesApiUrl;
      this.moduleName = event.options.dataSourceSchema;
      if (!this.isEdit && event.options.dataSourceSchema === this.msgConfigForm.controls.moduleName.value) {
        this.msgConfigForm.controls.profileId.setValue('');
        this.msgConfigForm.controls.claimId.setValue('');
        this.roleConfigForm.controls.roles.setValue([]);
        this.setCascading(false, true, false);
      }
    }
  }
  public getProfile(event: any) {
    if (event.options) {
      this.claimTypeUrl = this.isStateBased === true ? this.appConfig.getModuleStateApiUrl : this.appConfig.getModuleClaimsApiUrl;
      this.dataProfileId = event.options.dataProfileId;
      if (!this.isEdit && event.options.dataProfileId === this.msgConfigForm.controls.profileId.value) {
        this.roleConfigForm.controls.roles.setValue([]);
        this.setCascading(false, false, true);
      }
      this.messageConfigModel.profileName = event.options.name;
    }
  }

  public getClaims(event: any) {
    if (event.options) {
      this.roleUrl = this.appConfig.getModuleRolesApiUrl;
      if (!this.isEdit && event.options.transitionId === this.msgConfigForm.controls.claimId.value) {
        this.roleConfigForm.controls.roles.setValue([]);
        this.setCascading(true, false, true);
      }
    }
  }

  public getConfigList(modulename: any) {
    if (modulename === null || modulename === undefined || modulename === '') {
      this.actionConfigForm.controls.modName.setValue(null);
      this.msgConfigService.list().subscribe((res) => {
        this.setInboxData(res);
      });
    } else {
      this.msgConfigService.list(modulename.options.dataSourceSchema).subscribe((res) => {
        this.setInboxData(res);
      });
    }
  }

  private setInboxData(res: any) {
    this.dataSource = new ExampleDataSource(res);
    if (res.length > 0) {
      this.pageCount = Number(res[0].totalCount);
    } else {
      this.pageCount = 0;
    }
    this.msgList = res;
  }

  public getMessageConfigList(response: any) {
    this.msgConfigService.list(this.actionConfigForm.controls.modName.value, response.PageIndex, response.PageCount)
      .subscribe((res) => {
        this.dataSource = new ExampleDataSource(res);
      });
  }

  public submit(event: any, msgModel: MessageConfigModel) {
    msgModel.msgConfigId = this.messageConfigModel.msgConfigId;
    msgModel.msgTemplateId = this.messageConfigModel.msgTemplateId;
    msgModel.profileName = this.messageConfigModel.profileName;
    if (this.msgConfigForm.valid) {
      if (!this.isEdit) {
        this.msgConfigService.checkClaim(this.msgConfigForm.controls.claimId.value, this.msgConfigForm.controls.msgType.value).subscribe(
          (response) => {
            if (response > 0) {
              this.toasterService.Warning('Selected claim type already configured.');
              return false;
            }
          });
      }
      const roles: any[] = this.roleConfigForm.controls.roles.value;
      const keys: any[] = [];
      roles.forEach(x => {
        keys.push(x['id']);
      });
      msgModel.roleIds = keys;
      this.msgConfigService.submit(msgModel).subscribe(() => {
        this.msgConfigService.new().subscribe((res) => {
          this.setValidations(res);
          this.profileUrl = '';
          this.claimTypeUrl = '';
          this.roleUrl = '';
          this.isEdit = false;
          this.dataProfileId = '';
          this.setCascading(false, false, false);
          this.roleConfigForm.controls.roles.setValue([]);
          this.selectedRoles = [];
        });
      });
    } else {
      Object.keys(this.msgConfigForm.controls).forEach(field => {
        const control = this.msgConfigForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  public cancel() {
    this.msgConfigService.new().subscribe((res) => {
      this.setValidations(res);
      this.messageConfigModel = res;
      this.dataProfileId = '';
      this.profileUrl = '';
      this.claimTypeUrl = '';
      this.roleUrl = '';
      this.isEdit = false;
      this.roleConfigForm.controls.roles.setValue([]);
      this.actionConfigForm.controls.configOption.setValue('1');
      this.setConfigOptions(true, false, false);
      this.selectedRoles = [];
      this.setCascading(false, false, false);
    });
  }

  public editMsgConfig(id: any) {
    this.msgConfigService.open(id).subscribe((res) => {
      this.isEdit = true;
      this.messageConfigModel = res;
      this.msgConfigForm = this.commonService.getFormGroup(res, 1);
      this.msgConfigForm.controls.msgType.setValue(res.msgType.toString());
      this.selectedRoles = res.roleIds;
      this.actionConfigForm.controls.configOption.setValue(res.isStateBased === true ? '2' : (res.isUserBased === true ? '3' : '1'));
      this.dataProfileId = res.profileId;
      this.moduleName = res.moduleName;
      this.profileUrl = this.appConfig.getModuleProfilesApiUrl;
      this.claimTypeUrl = this.appConfig.getModuleClaimsApiUrl;
      this.roleUrl = this.appConfig.getModuleRolesApiUrl;
      this.setCascading(true, true, true);
    });
  }

 public deleteMsgConfig(id: any) {
   this.msgConfigService.open(id, true).subscribe((res) => {
     this.toasterService.Success('Successfully Deleted Selected Template.');
      this.getConfigList('');
    });
  }

  public clearConfigList() {
    this.dataSource = new ExampleDataSource([]);
  }

  private setConfigOptions(isClaimBased: boolean, isStateBased: boolean, isUserBased: boolean) {
    this.isClaimBased = isClaimBased;
    this.isStateBased = isStateBased;
    this.isUserBased = isUserBased;
  }

  private setCascading(isCascading: boolean, profileCascading: boolean, claimCascading: boolean) {
    this.isCascading = isCascading;
    this.profileCascading = profileCascading;
    this.claimCascading = claimCascading;
  }

  private extractDomainData(response: any) {
    const i = 0;
    let keys: string[];
    keys = Object.keys(response);
    const body = response[keys[i]];
    return body || [];
  }

  private setValidations(res: any, isfirst: boolean = false) {
    if (isfirst) {
      this.msgConfigForm = this.commonService.getFormGroup(res.dataModel, 0);
      this.messageConfigModel = res.dataModel;
    } else {
      this.msgConfigForm = this.commonService.getFormGroup(res, 0);
      this.messageConfigModel = res;
    }
    this.msgConfigForm.controls.msgType.setValidators(Validators.required);
    this.msgConfigForm.controls.moduleName.setValidators(Validators.required);
    this.msgConfigForm.controls.profileId.setValidators(Validators.required);
    if (res.isStateBased != null && res.isStateBased) {
      this.msgConfigForm.controls.stateId.setValidators(Validators.required);
      this.actionConfigForm.controls.configOption.setValue('2');
      this.msgConfigForm.controls.claimId.clearValidators();
    } else {
      this.msgConfigForm.controls.claimId.setValidators(Validators.required);
      this.msgConfigForm.controls.stateId.clearValidators();
      this.actionConfigForm.controls.configOption.setValue('1');
    }
    if (res.isUserBased == null || !res.isUserBased) {
      this.roleConfigForm.controls.roles.setValidators(Validators.required);
    } else if (res.isUserBased != null && res.isUserBased) {
      this.actionConfigForm.controls.configOption.setValue('3');
    }
    this.msgConfigForm.controls.msgSubject.setValidators(Validators.required);
    this.msgConfigForm.controls.msgBody.setValidators(Validators.required);
    this.msgConfigForm.controls.isActive.setValue(true);
    this.msgConfigForm.controls.isRoleBased.setValue(true);
  }
}


