import { FxContext } from '@agility/frameworkcore';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FilterMenuService } from '../../common/filter-menu.service';

@Component({
  selector: 'dashboard-subheader',
  templateUrl: './dashboardSubheaderComponent.html',
})

export class DashboardSubheaderComponent implements OnInit {
  public currentRoute: string;
  public pageTitle: string;
  public isInbox = false;
  public isSysAdmin = false;
  public showHide = '';
  public dshowHide = '';
  public jshowHide = '';
  public vshowHide = '';
  public goBackLink = '';
  public subHeaderLinksList: any = {
    gridUrl: '',
    listUrl: '',
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fxContext: FxContext,
    private filterMenuService: FilterMenuService,
  ) {
    this.currentRoute = this.router.url;
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        let currentRoute = this.activatedRoute.root;
        while (currentRoute.children[0] !== undefined) {
          currentRoute = currentRoute.children[0];
        }
        this.pageTitle = currentRoute.snapshot.data.title;
        this.isSysAdmin = (this.fxContext.userProfile.userRoles.findIndex(x => x.name === 'SysAdmin') > -1);
      });
  }

  ngOnInit() {
  }

  public create(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public toggleClassName() {
    this.filterMenuService.toggleFilterMenu();
  }

  public isActive(viewType: string): boolean {
    if (viewType === 'grid') {
      return this.router.url.includes('/journey-landing/journey-grid-view');
    } else if (viewType === 'list') {
      return this.router.url.includes('/journey-landing/journey-list-view');
    }
  }
}
