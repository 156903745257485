import { Injectable } from '@angular/core';

export interface MyData {
  routeId: number;
  rmsPreferenceId: number;
  deEntityId: number;
  deFieldId: number;
  rmsTypeId: number;
  rmsSubTypeId: number;
  customsprefid: number;
  displayName: string;
}

@Injectable()
export class RMSPreferencesSharedService {
  sharingData: MyData = {
    routeId: 0,
    rmsPreferenceId: 0,
    deEntityId: 0,
    deFieldId: 0,
    rmsTypeId: 0,
    rmsSubTypeId: 0,
    customsprefid: 0,
    displayName: ''
  };

  constructor() {
  }
}
