import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'mcn-date',
  template: `<div [formGroup]="formGroup">
        <mat-form-field class="mat-input-container" floatLabel="auto">
          <input matInput id="txtdate_{{optionalId}}{{placeholder | removeSpace}}" [matDatepicker]="picker"
          placeholder="{{placeholder}}"
          [value]="selectedValue"
           formControlName="{{controlName}}" [required]="required"
          [readonly]="readonly" (dateChange)="_setAction($event)">
          <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="allowInputOnly"></mat-datepicker-toggle>
          <mat-datepicker #picker [disabled]="completeDisable"></mat-datepicker>
        </mat-form-field>
    </div>`,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})

export class McnDatepickerComponent {
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() optionalId = '';
  @Input() placeholder: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() completeDisable = false;
  @Input() allowInputOnly = false;
  @Input() cssClass: string;
  @Output() OnChange = new EventEmitter();
  @Input() selectedValue = '';
  private _control: AbstractControl;
  private _self: McnDatepickerComponent;

  ngOnInit(): void {
    const _self = this;
    _self._control = this.formGroup.controls[_self.controlName];
    this.selectedValue = ((this.selectedValue == null || this.selectedValue === '') ? this.formGroup.controls[this.controlName].value : this.selectedValue);
    if (_self._control) {
      if (_self.disabled && _self.disabled.toString().toLowerCase() == 'true') {
        _self._control.disable();
      }
    }
    if (!isNullOrUndefined(this.selectedValue)) {
      this.formGroup.controls[this.controlName].setValue(this.selectedValue);
    }
  }
  public _setAction(event: any) {
    const _self = this;
    _self.OnChange.emit(event);
  }
}
