﻿import { ProfileModel } from '@agility/frameworkcore';
import { McnTypesModel } from '../../mdm/models/McnTypesModel';

export class CancellationHistoryModel extends ProfileModel {
    public cancellationId: number;
    public reasonId?: number;
    public remarks: string;
    public referenceType: string;
    public referenceId?: number;
    public cancelRequestDate?: Date;
    public approverReasonId?: number;
    public approverRemarks: string;
    public approver: string;
    public reasonData: any;
    public approverReasonData: any;
    public requester: string;
    public requestedStateId: string;
    public personalId?: number;
    public recordStateId: string;
    public title: string;
}

export enum RefType {
    journey = 'Journey',
    manifest = 'Manifest',
    billOfLading = 'Bill Of Lading',
    billOfLadingLine = 'BillOfLadingLine',
    hBParty = 'HBParty',
    container = 'Container'
}
