import { ProfileModel } from '@agility/frameworkcore';

export class VolumeUnitModel extends ProfileModel {

  public volumeUnitId: number;

  public unitName: string;

  public unitCode: string;

  public description: string;

  public status: boolean;
}
