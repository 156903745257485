import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ChangePassword } from './changePassword/changePassword';
import { catchError, map } from 'rxjs/operators';
import { ToasterService } from '../../../toastrService/toasterService';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { throwError } from 'rxjs';

@Injectable()
export class PasswordManagementService {
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient,
    private toastrService: ToasterService,
    private appConfig: McnConfig) { }

  public SubmitChangePassword(changePassword: ChangePassword): Observable<any> {
    return this.http.post(this.appConfig.changePassword + '?emailId=' +
      changePassword.emailId + '&newPassword=' + changePassword.newPassword + '&currentPassword=' + changePassword.currentPassword,
      { headers: this.headers }).pipe(map((response) => response),
        catchError((res) => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  // Forgot Password
  public SendForgotPasswordLink(emailId: string): Observable<any> {
    return this.http.get(this.appConfig.forgotPassword + '?emailId=' + emailId, { headers: this.headers })
      .pipe(map((response) => response),
        catchError((res) => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  private handleError() {
    return this.toastrService.Error('Internal Server Error.Please Try Again Later.');
  }

}
