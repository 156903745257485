import { ProfileModel } from '@agility/frameworkcore';
import { BillOfLadingModel } from '../../billofLading/BillOfLading';

export class SupplementaryManifestModel extends ProfileModel {
    public suppManifestId: number;
    public manifestId: number;
    public suppManifestNumber: string;
    public suppManifestDate: Date;
    public totalNoOfHBs: number;
    public isLateSubmitted: string;
    public remarks: string;
    public submittedDate: Date;
    public assignedTo: number;
    public assignedBy: number;
    public assignedDate: Date;
    public correctionReasons: string;
    public agentname: string;
    public manifestNumber: string;
    public suppManifestCount: number;
    public billOfLadingList: BillOfLadingModel[];
    public billOfLadingCount?: number;
    public hbItemCount?: number;
    public isDisableSuppBlAdd?: boolean;
    public manifest: any;
    public isJourneyArrived: boolean;
    public arrivalDate: Date;
}
