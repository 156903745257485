<div fxLayout="row wrap" fxLayoutAlign="space-between center" >
  <div fxFlex="100" fxFlex.gt-sm="30" class="px-2">
   <!--  <mcn-select [formGroup]="stackedChartForm" optionKey="value"
                optionValue="viewValue" placeholder="{{'Country' | translate}}" controlName="country"
                [optionsData]="this.countryData" (OnChange)="onCounrtySelect($event)" selectedValue="ma">
    </mcn-select> -->

    <!--<mat-form-field>
      <mat-select placeholder="Country" (selectionChange)="onCounrtySelect($event.value)" value="ma">
        <mat-option *ngFor="let country of this.countryData" [value]="country.value">
          {{ country.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>-->
  </div>
    <span fxFlex="25"></span>
    <div fxFlex="100" fxFlex.gt-sm="45" fxLayout="row" fxLayoutAlign="end center">
      <div fxFlex="30" class="px-2">
        <mcn-select [formGroup]="stackedChartForm" optionKey="value"
                    optionValue="viewValue" placeholder="{{'Month' | translate}}" controlName="month"
                    [optionsData]="this.months" (OnChange)="onMonthSelect($event)">
        </mcn-select>
        <!--<mat-form-field>
          <mat-select placeholder="Month" (selectionChange)="onMonthSelect($event.value)">
            <mat-option *ngFor="let month of this.months" [value]="month.value">
              {{ month.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>-->
      </div>
      <div fxFlex="30" class="px-2">
        <mcn-select [formGroup]="stackedChartForm" optionKey="value"
                    optionValue="viewValue" placeholder="{{'year' | translate}}" controlName="year"
                    [optionsData]="this.yearData" (OnChange)="onYearSelect($event)" selectedValue="2021">
        </mcn-select>

        <!--<mat-form-field>
          <mat-select placeholder="Year" (selectionChange)="onYearSelect($event.value)" value="2018">
            <mat-option *ngFor="let year of this.yearData" [value]="year.value">
              {{ year.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>-->
      </div>
    </div>
</div>
<!--<div class="data-details">
    <ul class="data-links" fxLayout="row wrap" fxLayoutAlign="start center">
        <li class="cursor-pointer" (click)="clickDay(1) " [ngClass]="{'active': selectedItem == 1}">
            <a>Day</a>
        </li>
        <li class="cursor-pointer" (click)="clickWeek(2)" [ngClass]="{'active': selectedItem == 2}">
            <a>Week</a>
        </li>
        <li class="cursor-pointer" [ngClass]="{ 'active': selectedItem ===  0,'active': selectedItem === 3}" (click)="clickMonth(3)">
            <a>Month</a>
        </li>
        <li class="cursor-pointer"(click)="clickYear(4)" [ngClass]="{'active': selectedItem == 4}">
            <a>Year</a>
        </li>
    </ul>
</div>-->
<ngx-charts-bar-vertical-stacked [view]="view"
                                 [scheme]="colorScheme"
                                 [results]="this.optionsData"
                                 [gradient]="gradient"
                                 [xAxis]="showXAxis"
                                 [yAxis]="showYAxis"
                                 [legend]="showLegend"
                                 [showXAxisLabel]="showXAxisLabel"
                                 [showYAxisLabel]="showYAxisLabel"
                                 [xAxisLabel]="xAxisLabel"
                                 [barPadding]=" this.isMonthChart ? 20:50"
                                 [yAxisLabel]="yAxisLabel"
                                 (select)="onSelect($event)">
</ngx-charts-bar-vertical-stacked>
