<div fxLayout="column wrap" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
  <div fxLayout="column wrap">
    <div fxLayout="row wrap">
      <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="center center">
        <h2 class="main-title pb-0"><span>{{'UploadForm'| translate}}</span></h2>
      </div>
    </div>
  </div>
  
  <div fxLayout="column">
    <div mat-dialog-content>
      <div class="mode-of-transport">
        <form class="" [formGroup]="upldShipStoreDocs" novalidate>
          <div fxLayoutWrap fxLayout="row" fxLayoutAlign="start center" class="pt-2">
            <div class="p-2">
              <mcn-fileUpload labelName="{{'FileUpload' | translate }}" [formGroup]="upldShipStoreDocs"
                              actionLabelName="{{'Browse' | translate }}" [isExcelUpload]="true" (OnChange)="fileChange($event)">
              </mcn-fileUpload>
            </div>
            <div fxFlex="100" class="p-2">
              <mcn-input [formGroup]="upldShipStoreDocs" ngDefaultControl controlName="filePath"
                         placeholder="{{'ExcelFile' | translate}}" class="astric-star"></mcn-input>
              <mcn-error-messages [control]="upldShipStoreDocs.controls.filePath"
                                  class="error customstyle" customErrorMsg="Excel File is "></mcn-error-messages>
            </div>
          </div>
          <div class="form-footer">
            <div class="button-container">
              <mcn-button type="button" text=" {{ 'Upload' | translate }}"
                          (click)="uploadExcelFileUplExlFle()" cssClass="btn-custom-primary"></mcn-button>
              <button type="button" mat-raised-button matDialogClose class="btn-custom-secondary"
                      (click)="Close()">
                {{'Cancel' | translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<button matDialogClose class="dialog-close">
  <i class="icon-close"></i>
</button>






