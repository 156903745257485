import { OnInit } from '@angular/core';
import { Injectable } from '@angular/core';


export enum Profiles {
  Journey = 'Journey',
  SuppManifest = 'SuppManifest',
  BillOfLading = 'BillOfLading',
  DeliveryOrder = 'DeliveryOrder',
  HbItem = 'HbItem',
  HBParty = 'HBParty',
  Manifest = 'Manifest',
  DeclarationCancellation = 'DeclarationCancellation',
  Declaration = 'Declaration',
  PassengerAssessment = 'PassengerAssessment',
  InspectionMember = 'InspectionMember',
  InspectionReport = 'InspectionReport',
  Assessment = 'Assessment',
  AssessmentRequest = 'AssessmentRequest',
  AssessedItem = 'AssessedItem',
  Inspection = 'Inspection',
  Payment = 'Payment',
  Bills = 'Bills',
  VoucherDetails = 'VoucherDetails',
  RefundForPassenger = 'RefundForPassenger',
  GatePass = 'GatePass',
  GatePassdetails = 'GatePassDetails',
  Surety = 'Surety',
  SuretyTransaction = 'SuretyTransaction',
  DocumentReviewRequest = 'DocumentReviewRequest',
  ExemptionCategoryInformation = 'ExemptionCategoryInformation',
  FTAManagement = 'FTAManagement',
  SectionItemDetails = 'SectionItemDetails',
  Exemption = 'Exemption',
  ValuationAppeal = 'ValuationAppeal',
  TariffRuling = 'TariffRuling',
  Ruling = 'Ruling',
  ClassificationAppeal = 'ClassificationAppeal',
  ShipDeclaration = 'ShipDeclaration',
  ShipFormA = 'ShipFormA',
  ShipImmigration = 'ShipImmigration',
  ShipVesselAdvisePKP = 'ShipVesselAdvisePKP',
  ShipPanSecurity = 'ShipPanSecurity',
  DestructionRequest = 'DestructionRequest'
}


export interface IRegistrationRouting {
  AgentAssociation: string;
  CeilingLimit: string;
}

export interface IAccounting {
  AccountingConfiguration: string;
  CollectionAccount: string;
  RefundAccount: string;
  RefundPaymentAccount: string;
  RevenueSourceGroup: string;
  RevenueAccountDetails: string;
  RevenueSourceConfiguration: string;
}

export interface ICarrierRegistrationRouting {
  AirCarrier: string;
  Vessel: string;
  SCCertificate: string;
  SCOwner: string;
  SCCharted: string;
}

export interface ICargoRouting {
  Journey: string;
  Manifest: string;
  Billoflading: string;
  HBParty: string;
  BillofladingLine: string;
  Container: string;
  DisChargeContainer: string;
  Vechicle: string;
  SOC: string;
  SCFinalOutcome: string;
  DeliveryOrder: string;
  SuppManifest: string;
  ShippmentOder: string;
}

export interface IDeclarationRouting {
  Declaration: string;
  Invoice: string;
  DeclarationCancellation: string;
  FunctionalUnit: string;
  PassengerAssessmentGoods: string;
  DrInvoiceItems: string;
  DRDeclaration: string;
  PACurrency: string;
  PassengerInfo: string;
  PassengerAssessment: string;
}

export interface IShipClearanceRouting {
  ShipDeclaration: string;
  ShipFormA: string;
  ShipImmigration: string;
  ShipVesselAdvisePKP: string;
  ShipPanSecurity: string;
}

export interface IInspectionRouting {
  Inspection: string;
  InspectionReport: string;
  InspectionMember: string;
  SampleRequestDelegation: string;
  SampleRequest: string;
}

export interface IAssessmentRouting {
  AssessmentPricingData: string;
  AssessmentRequest: string;
  SampleRequest: string;
  AssessedInvoice: string;
  AssessedItems: string;
  DocumentReviewReq: string;
  ReferClassification: string;
  ReferValuation: string;
}

export interface IPaymentRouting {
  Bill: string;
  Payment: string;
  Vocher: string;
  PaymentReciept: string;
  RefundPayment: string;
  PaymentTransaction: string;
}

export interface IReleaseRuting {
  GatePass: string;
  GatePassDetails: string;
  GatePassEntryExitDetails: string;
}

export interface ISuretyRouting {
  SuretyTrans: string;
  GeneralBond: string;
  BankGarenty: string;
  SuretyTransaction: string;
  SuretyFromateRequest: string;
}

export interface IDrawbackRouting {
  DrawbackClaimRequest: string;
  DrawbackClaimRightTransfer: string;
  DrawbackDeclarationExtensionRequest: string;
  DrawbackFacilityApproval: string;
  DrawbackPcc: string;
}

export interface IRefundRouting {
  Refund: string;
}

export interface IResourcePlanningRouting {
  DepartmentProcess: string;
  ShiftUserGroup: string;
  Shift: string;
  ShiftConfiguration: string;
  RPDutyRoster: string;
  ResourceUnavailability: string;
}

export interface ITariffQuotaRouting {
  FTATariffRateQuota: string;
  TariffRateQuota: string;
  AdditionalTariffRateQuota: string;
  FTATariffRateQuotadetails: string;
  FtaAdditionalTariffRateQuota: string;
  TariffRateQuotaDetails: string;
}

export interface ITariffRulingRouting {
  ClassificationAppealDraftReport: string;
  RulingDraftReport: string;
  ClassificationAppeal: string;
  ValuationAppeal: string;
  Ruling: string;
}

export interface ITariffValuationRouting {
  MotorVehiclePriceData: string;
  MotorVehicleValuation: string;
  VehicleAgeConfiguration: string;
  PriceDataOtherGood: string;
  PetroleumInformation: string;
}

export interface ITariffExemptionRouting {
  ExemptionCategoryInformation: string;
  FTAManagement: string;
  SectionItemDetails: string;
  Exemption: string;
}

export interface IRiskSelectivityProfilingRouting {
  Risks: string;
  RiskProfiles: string;
}


export interface IBondedWarehouseRouting {
  WarehouseForms: string;
  WarehouseFormsDtls: string;
  DiscrepancyRequest: string;
  VehicleMovementRequest: string;
  VehicleMovementRequestDtls: string;
  RemitDutiesRequest: string;
  RemitDutiesRequestDtls: string;
  DestructionRequest: string;
  TransferRequest: string;
  TransferRequestDtls: string;
  StorageExtensionRequest: string;
}

export interface IPermitRouting {
  PermitApplication: string;
  PMTAuthorizationRequest: string;
}

export interface ILicensingRouting {
  RenewalRequest: string;
  LicenseInformationForGeneric: string;
  LicenseInformationForAEO: string;
  LicenseAuthorizationRequest: string;
  SuspensionRequest: string;
}

export interface IFreeZoneRouting {
  FZDeclarations: string;
  FZDeclarationsDetails: string;
  FZTransferRequests: string;
  FZTransferRequestsDetails: string;
}

export interface IDfsRouting {
  DfsTransferRequest: string;
  DfsTransferRequestDetails: string;
}


@Injectable()
export class McnRoutingConfig implements OnInit {
  accounting: IAccounting = {
    AccountingConfiguration: 'accounting/payment-channels/payment-channels-landing/payment-channels-list-view',
    CollectionAccount: 'accounting/collection-accounts/collection-accounts-landing/collection-accounts-list-view',
    RefundAccount: 'accounting/passenger-refund-accounts/passenger-refund-landing/passenger-refund-list-view',
    RefundPaymentAccount: 'accounting/refund-payment-account/refund-payment-landing/refund-payment-list-view',
    RevenueSourceGroup: 'accounting/revenue-source-group/revenue-source-group-list-view',
    RevenueSourceConfiguration: 'accounting/create-revenue/view-revenue-source/<sourceId>',
    RevenueAccountDetails: 'accounting/revenue-accounts/view-revenue-accounts/<revenueaccountId>'
  };
  registrationRouting: IRegistrationRouting = {
    AgentAssociation: 'user-registration/agent-association',
    CeilingLimit: 'user-registration/ceiling-limit'
  };
  carrierRegistrationRouting: ICarrierRegistrationRouting = {
    AirCarrier: 'carrier/add-carrier/preview-and-active/<airCNo>',
    Vessel: 'carrier-vessel/add-vessel/preview/<vslNo>',
    SCCertificate: 'carrier-vessel/add-vessel/certificates-open/<sccNo>',
    SCOwner: 'carrier-vessel/add-vessel/ship-owner-open/<scoNo>',
    SCCharted: 'carrier-vessel/add-vessel/charter-details-open/<sccharNo>',

  };
  cargoRouting: ICargoRouting = {
    Journey: 'cargo/cargo-journey-details/existing-journey/<journeyNo>',
    Billoflading: 'bill/journey-details/<journeyNo>/bill-of-lading/bill-of-lading-details',
    BillofladingLine: 'bill/journey-details/<journeyNo>/bill-of-lading-line/bill-of-lading-line-details/<blLineNo>',
    DisChargeContainer: 'vehicle/discharge-container/dc-info/<dcNo>',
    Container: 'bill/journey-details/<journeyNo>/bill-of-lading-line/list-container',
    Manifest: 'journey/journey-details/<journeyNo>/manifest/manifest-details/<manifestNo>',
    HBParty: 'bill/journey-details/<journeyNo>/bill-of-lading/hb-party/<hbPartyNo>',
    Vechicle: 'vehicle/journey-detail/<journeyNo>/bill-of-lading-line-vehicle-details/add-vehicle/<vecicleNo>',
    SOC: 'certificates/Soc/soc-details/<socId>',
    SCFinalOutcome: 'certificates/Fot/fot-details/<fotId>',
    DeliveryOrder: 'order/delivery-details/<doId>',
    SuppManifest: 'journey/journey-manifest-details/<journeyNo>/manifest/suppManifest-details/<supManifestNo>',
    ShippmentOder: 'order/shipment-order/shipment-order-details/<shipOrderId>'
  };
  declarationRouting: IDeclarationRouting = {
    DeclarationCancellation: 'declaration/cancellation-request',
    Invoice: 'declaration-invoiceItem/declaration-invoice-details/invoice',
    Declaration: 'declaration/declaration-details/declaration-info',
    FunctionalUnit: 'declaration-functionalUnit/functional-unit/functional-details/edit',
    PassengerAssessmentGoods: 'declaration-passengerAssessment/passenger-assessment/passenger-assessment-details/goods-detail-list/<PAGid>',
    DrInvoiceItems: 'declaration-directRelease/direct-release/direct-release-details/invoice-items',
    DRDeclaration: 'declaration-directRelease/direct-release/direct-release-details/release-info-open',
    PACurrency: 'declaration-passengerAssessment/passenger-assessment/passenger-assessment-details/currency-details/<PACurrId>',
    PassengerInfo: 'declaration-passengerAssessment/passenger-assessment/passenger-assessment-details/passenger-info-details',
    PassengerAssessment: 'declaration-passengerAssessment/passenger-assessment/passenger-assessment-details/passenger-info-details'
  };

  shipClearanceRouting: IShipClearanceRouting = {
    ShipDeclaration: '/shipClrViewData/view-ship-arvl-decl',
    ShipVesselAdvisePKP: '/shipClrCreateData/view-vessel-pkp',
    ShipFormA: '/shipClrCreateData/view-petrol-safety',
    ShipImmigration: '/shipClrCreateData/view-imgrtn-frm',
    ShipPanSecurity: '/shipClrCreateData/view-pre-arvl-sec'
  };

  inspectionRouting: IInspectionRouting = {
    Inspection: 'inspection/inspection-request-details/inspection-request-info',
    SampleRequest: 'inspection/sample-request/sample-request-details/view-sample-request',
    InspectionReport: 'inspection/inspection-request-details/inspection-request-report',
    InspectionMember: 'inspection/inspection-request-details/inspection-request-info',
    SampleRequestDelegation: ''
  };
  assessmentRouting: IAssessmentRouting = {
    AssessmentPricingData: 'assessment-pricing-database/pricing-database/pricing-database-list',
    AssessmentRequest: 'assessment/assessment-request-details/<assReqId>/assessment-request-info/<assReqId>',
    SampleRequest: 'assessment/assessment-request-details/<assReqId>/sample-request',
    AssessedInvoice: '',
    AssessedItems: 'assessment/assessment-request-details/<assReqId>/assessment-request-info/<assReqId>',
    DocumentReviewReq: 'assessment/assessment-request-details/<assReqId>/document-review/<docId>',
    ReferClassification: 'assessment/assessment-request-details/<assReqId>/refer-classification',
    ReferValuation: ''
  };

  paymentRouting: IPaymentRouting = {
    Payment: 'payments/payment-details/payment-info-view',
    Vocher: 'payments/vouchers/voucher-details',
    PaymentReciept: 'payments/payments-receipts-details-view',
    RefundPayment: 'payments/pa-refund-details/Refund-info/<paRefundId>',
    PaymentTransaction: 'payments/payment-details/payment-info-view',
    Bill: 'payments/bill-details-view',
  };
  releaseRuting: IReleaseRuting = {
    GatePass: 'releases/view/gate-pass-info-view',
    GatePassDetails: 'releases/view/gate-pass-details-view',
    GatePassEntryExitDetails: 'releases/view/gate-pass-details-view'
  };
  suretyRouting: ISuretyRouting = {
    SuretyTrans: 'surety/trans-list',
    SuretyTransaction: 'surety/surety-transaction/surety-transaction-Info',
    BankGarenty: 'surety/create-surety/bank-edit-gauranty',
    GeneralBond: 'surety/create-surety/general-edit-bond',
    SuretyFromateRequest: 'surety/create-securities-and-payments/created-securities/<formateReqId>'
  };

  tariffExemptionRouting: ITariffExemptionRouting = {
    ExemptionCategoryInformation: 'tariff-exemption-category/exemptn-category/exem-categ-details/exem-categ-info-dtls',
    FTAManagement: 'tariff-exemption-ftaMngt/fta-management/fta-mngmnt-dtls/view-fta-mngmnt',
    SectionItemDetails: 'tariff-exemption-request/exemption-request/exemption-request-details/exemption-request-info',
    Exemption: 'tariff-exemption-request/exemption-request/exemption-request-details/exemption-request-info'
  };

  drawbackRouting: IDrawbackRouting = {
    DrawbackClaimRequest: 'drawback-claim-application/claim-application/create-claim-application/claim-preview',
    DrawbackClaimRightTransfer:
      'drawback-claim-transfer/claim-transfer-application/create-claim-transfer-application/claim-transfer-preview',
    DrawbackDeclarationExtensionRequest: 'drawback-request-extension/request-extension/open-request-extension',
    DrawbackFacilityApproval: 'drawback/facility-application/preview-fac-appln/preview-fac-info',
    DrawbackPcc: 'drawback/facility-application/create-fac-application/pcc-form-open-details'
  };

  refundRouting: IRefundRouting = {
    Refund: 'refunds/refund-details/refund-info/<refundId>'
  };

  resourcePlanningRouting: IResourcePlanningRouting = {
    DepartmentProcess: 'resource-planning/dp-management/associate-user-profiles',
    ShiftUserGroup: 'resource-planning/shifts/create-shifts/edit-usergroups',
    Shift: 'resource-planning/shifts/create-shifts/shifts-preview',
    ShiftConfiguration: 'resource-planning/shifts/create-shifts/shifts-configuration-form-edit',
    RPDutyRoster: 'resource-planning/shifts/create-shifts/edit-dutyroster',
    ResourceUnavailability: 'resource-planning/resource-unavailability/resource-u-landing/resource-unavailability-list'
  };

  tariffQuotaRouting: ITariffQuotaRouting = {
    FTATariffRateQuota: 'tariff-quotas/create-fta-quotas/fta-management-info',
    TariffRateQuota: 'tariff-quotas/quotas-details/management-info',
    AdditionalTariffRateQuota: 'tariff-quotas/additional-quotas-list',
    FTATariffRateQuotadetails: 'tariff-quotas/create-fta-quotas/edit-fta-quotas-details',
    FtaAdditionalTariffRateQuota: 'tariff-quotas/additional-fta-quotas-list',
    TariffRateQuotaDetails: 'tariff-quotas/create-quotas/edit-quotas-details'
  };

  tariffRulingRouting: ITariffRulingRouting = {
    ClassificationAppealDraftReport: 'tariff-ruling/create-appeal/appeal-draft-rprt',
    RulingDraftReport: 'tariff-ruling/rulings-details/draft-report-dtls',
    ClassificationAppeal: 'tariff-ruling/create-appeal/classfcn-dtls',
    ValuationAppeal: 'tariff-ruling/create-appeal/valuation-dtls',
    Ruling: 'tariff-ruling/rulings-details/ruling-info-details'
  };

  tariffValuationRouting: ITariffValuationRouting = {
    MotorVehiclePriceData: 'tariff-valuation/motor-vehicle/motor-vehicle-details',
    MotorVehicleValuation: 'tariff-valuation/motor-vehicle-trader/motor-vehicle-trader-details-data',
    VehicleAgeConfiguration: 'tariff-valuation/vehicle-age-configuration/vehicle-age-configuration-details',
    PriceDataOtherGood: 'tariff-valuation/other-goods-price/other-goods-pricedata-list',
    PetroleumInformation: 'tariff-valuation/petroleum/petroleum-pricedetails'
  };

  riskSelectivityProfilingRouting: IRiskSelectivityProfilingRouting = {
    Risks: 'risk-selectivity-profiling/new-risk-wizard',
    RiskProfiles: 'risk-selectivity-profiling/risk-profile-info'
  };

  bondedWarehouseRouting: IBondedWarehouseRouting = {
    WarehouseForms: 'warehouse/add/warehouse-info-open',
    WarehouseFormsDtls: 'warehouse/details/warehouse-details',
    DiscrepancyRequest: 'warehouse/add/discrepancy',
    VehicleMovementRequest: 'warehouseVehicleExt/add/vehicle-movement-open',
    VehicleMovementRequestDtls: 'warehouseVehicleExt/details/vehicle-movement-details',
    RemitDutiesRequest: 'warehouseRemitDuties/add/remit-duties-open',
    RemitDutiesRequestDtls: 'warehouseRemitDuties/details/remit-duties-details',
    DestructionRequest: 'warehouseRemitDuties/add/destruction-form',
    TransferRequest: 'warehouseTrnsfrGoods/add/transfer-request-open',
    TransferRequestDtls: 'warehouseTrnsfrGoods/details/transfer-request-details',
    StorageExtensionRequest: 'warehouseStgExt/add-storage-info'
  };


  permitRouting: IPermitRouting = {
    PermitApplication: 'permitApplication/create-applcn/permit-applcn-viewfrm/<permitApplicationId>',
    PMTAuthorizationRequest: 'permitAuthRequest/create-auth-req/permit-auth-viewfrm/<pmtAuthorizationRequestId>',
  };

  licensingRouting: ILicensingRouting = {
    RenewalRequest: 'License-generic/license-renewal/create-renewal-license/req-renewal-form-edit',
    LicenseInformationForGeneric: 'License-generic/license-application/create-license-app/license-app-edit',
    LicenseInformationForAEO: 'License-aeo/license-application/create-license-app/license-app-edit',
    LicenseAuthorizationRequest: 'License-generic/authorization-req/create-autho-req/autho-req-form-details',
    SuspensionRequest: 'License-generic/req-suspension-license/create-suspension-license/license-suspension-edit'
  };

  freeZoneRouting: IFreeZoneRouting = {
    FZDeclarations: 'freezone/import-export/create-fz/freezone-open-info',
    FZDeclarationsDetails: 'freezone/import-export/fz-details/fz-info-details',
    FZTransferRequests: 'freezone/transfer-request/create-transfer-request/tranfer-request-infoOpen',
    FZTransferRequestsDetails: 'freezone/transfer-request/transfer-request-details/transfer-info-details'
  };

  dfsRouting: IDfsRouting = {
    DfsTransferRequest: 'dfs/transfer-request/create-transfer-request/tranfer-request-open-info',
    DfsTransferRequestDetails: 'dfs/transfer-request/transfer-request-details/transfer-info-details'
  };


  ngOnInit() {
  }

  createMsgParamObject(routingUrl: any, routeParams: any, msgInboxId: any, profile: any = null, moduleName: any = null) {
    const msgParamObj = {
      routingUrl: routingUrl,
      routeParams: routeParams,
      msgInboxId: msgInboxId,
      Profile: profile,
      claimId: null,
      stateId: null,
      userId: null,
      refNumber: null,
      moduleName: moduleName
    };
    return msgParamObj;
  }

}
