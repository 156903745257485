/* tslint:disable: max-line-length */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { StorageService } from '../storageService/storageService';

// tslint:disable-next-line: class-name
export interface myData {
  pageCount: number;
  associatePageCount: number;
  associateLessPageCount: number;
  sessionTimeout: number;
  localeJsonFile: any;
}

@Injectable()
export class PcaConfig implements OnInit {

  pcaWFStatesApiUrl: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/GetpcaWorkflowStates';
  getSCRemarksHistoryData: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/GetSCRemarksHistoryData';


  // Autocomplete
  getAutoCompleteCustomStation: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AutoCompletes/GetCustomStation?filterValue=';
  getAutoCompleteDeclarationNumber: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AutoCompletes/GetDeclarationNumber?filterValue=';
  getAutoCompleteTariffItems: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AutoCompletes/GetTariffItems?filterValue=';
  getAutoCompleteQuantityUnits: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AutoCompletes/GetQuantityUnits?filterValue=';
  getAutoCompleteQuestions: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AutoCompletes/GetQuestions?filterValue=';
  getAutoCompleteCountry: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AutoCompletes/GetCountry?filterValue=';
  getAutoCompleteCity: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AutoCompletes/GetCity?filterValue=';
  getAutoCompleteStates: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AutoCompletes/GetStates?filterValue=';
  getAutoCompletePostalCode: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AutoCompletes/GetPostalCode?filterValue=';


  // AnalysisReport
  newAnalysisReports: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AnalysisReport/NewAnalysisReport';
  listAnalysisReports: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AnalysisReport/ListAnalysisReport';
  openAnalysisReports: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AnalysisReport/OpenAnalysisReport';
  deleteAnalysisReports: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AnalysisReport/DeleteAnalysisReport';
  submitAnalysisReports: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AnalysisReport/SubmitAnalysisReport';
  pcaTypes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PcaTypes/GetPcaModuleType';
  getMcnTypes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PcaTypes/GetModuleType';

  listAssociatedCountryofOrigin: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCCountryofOrigin/ListCountryofOrigin';
  submitCountryOrg: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCCountryofOrigin/SubmitCountryofOrigin';
  filterCountryOrg: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCCountryofOrigin/CountryofOriginFilterList';

  listSCCountryofDestination: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCCountryofDestination/ListCountryofDestination';
  submitSCCountryDestination: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCCountryofDestination/SubmitCountryofDestination';
  filterSCCountryDestination: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCCountryofDestination/CountryofDestinationFilterList';

  listAssociatedCustomsStation: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCCustomStations/ListCustomStations';
  submitCustomsStation: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCCustomStations/SubmitCustomStations';
  filterCustomsStation: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCCustomStations/CustomStationsFilterList';

  listAssociatedDuty: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCDuties/ListDuties';
  submitriskDuty: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCDuties/SubmitDuties';
  filterDuty: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCDuties/DutiesFilterList';

  listAssociatedExemptions: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCExemptions/ListSCExemptions';
  submitriskExemptions: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCExemptions/SubmitSCExemptions';
  filterExemptions: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCExemptions/SCExemptionsFilterList';

  listAssociatedTariffLine: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCItemCode/ListSCItemCode';
  submitSCTariffLine: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCItemCode/SubmitSCItemCode';
  filterTariffLine: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCItemCode/SCItemCodeFilterList';

  listAssociatedRegisteredOrg: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCRegisteredOrganization/ListSCRegisteredOrganization';
  submitRegisteredOrg: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCRegisteredOrganization/SubmitSCRegisteredOrganization';
  filterRegisteredOrg: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCRegisteredOrganization/SCRegisteredOrganizationFilterList';

  listSCRiskTarget: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCRiskTarget/ListSCRiskTarget';
  submitSCRiskTarget: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCRiskTarget/SubmitSCRiskTarget';
  filterSCRiskTarget: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCRiskTarget/SCRiskTargetFilterList';

  listAssociatedBusinessEntity: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCBusinessEntities/ListSCBusinessEntities';
  submitBusinessEntity: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCBusinessEntities/SubmitSCBusinessEntities';
  filterBusinessEntity: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCBusinessEntities/SCBusinessEntitiesFilterList';

  listFta: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCFtaManagement/ListSCFtaManagement';
  submitFta: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCFtaManagement/SubmitSCFtaManagement';
  filterFtaManagement: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCFtaManagement/SCFtaManagementFilterList';

  listTransactionTypes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TransactionType/ListTransactionType';
  submitTransactionTypes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TransactionType/SubmitTransactionType';
  filterTransactionTypesManagement: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TransactionType/TransactionTypeFilterList';

  listAssociatedDirectors: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCOrganizationOwnerList/ListSCOrganizationOwnerList';
  submitDirectors: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCOrganizationOwnerList/SubmitSCOrganizationOwnerList';
  filterDirectors: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SCOrganizationOwnerList/SCOrganizationOwnerListFilterList';

  // Answer
  newAnswer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Answer/NewAnswer';
  openAnswer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Answer/OpenAnswer';
  deleteAnswer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Answer/DeleteAnswer';
  submitAnswer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Answer/SubmitAnswer';

  // OrganizationDetails
  newOrganizationDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/NewOrganizationDetails';
  openOrganizationDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/OpenOrganizationDetails';
  submitOrganizationDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/SubmitPcaOrganizationDetails';
  deleteOrganizationDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/DeleteOrganizationDetails';

  // Records
  newRecord: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/NewRecord';
  openRecord: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/OpenRecord';
  submitRecord: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/SubmitRecord';
  deleteRecord: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/DeleteRecord';

  // Main Buyers
  newMainBuyer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/NewMainBuyer  ';
  openMainBuyer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/OpenMainBuyer';
  submitMainBuyer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/SubmitMainBuyer';
  deleteMainBuyer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/DeleteMainBuyer';

  // BoardMembers
  newBoardMember: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/NewBoardMember';
  openBoardMember: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/OpenBoardMember';
  submitBoardMember: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/SubmitBoardMember';
  deleteBoardMember: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/DeleteBoardMember';

  // OwnerShipShare
  newOwnershipShare: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/NewOwnershipShare';
  openOwnershipShare: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/OpenOwnershipShare';
  submitOwnershipShare: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/SubmitOwnershipShare';
  deleteOwnershipShare: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/DeleteOwnershipShare';

  // Product Information Details
  newProductInformationDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/NewProductInformationDetails';
  openProductInformationDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/OpenProductInformationDetails';
  submitProductInformationDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/SubmitProductInformationDetails';
  deleteProductInformationDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/DeleteProductInformationDetails';

  // Marketing Companies
  newMarketingCompanies: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/NewMarketingCompanies';
  openMarketingCompanies: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/OpenMarketingCompanies';
  submitMarketingCompanies: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/SubmitMarketingCompanies';
  deleteMarketingCompanies: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/DeleteMarketingCompanies';

  // Exporting Companies
  newExportingCompanies: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/NewExportingCompanies';
  openExportingCompanies: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/OpenExportingCompanies';
  submitExportingCompanies: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/SubmitExportingCompanies';
  deleteExportingCompanies: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/DeleteExportingCompanies';

  // Goods Agreement
  newGoodsAgreement: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/NewGoodsAgreement';
  openGoodsAgreement: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/OpenGoodsAgreement';
  submitGoodsAgreement: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/SubmitGoodsAgreement';
  deleteGoodsAgreement: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/DeleteGoodsAgreement';


  // Payments List
  newPaymentList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/NewPayment';
  openPaymentList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/OpenPayment';
  submitPaymentList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/SubmitPayment';
  deletePaymentList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationDetails/DeletePayment';


  // AuditInstruction
  newAuditInstruction: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditInstruction/NewAuditInstruction';
  listAuditInstruction: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditInstruction/ListAuditInstruction';
  openAuditInstruction: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditInstruction/OpenAuditInstruction';
  deleteAuditInstruction: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditInstruction/DeleteAuditInstruction';
  submitAuditInstruction: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditInstruction/SubmitAuditInstruction';

  // AuditProgram
  newAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/NewAuditProgram';
  listAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/ListAuditProgram';
  openAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/OpenAuditProgram';
  deleteAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/DeleteAuditProgram';
  submitAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/SubmitAuditProgram';
  getSelectivityCriteriaAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AutoCompletes/GetSelectivityCriteria?filterValue=';
  executeAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/ExecuteAuditProgram';
  getAuditOrganizationsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/GetAuditOrganizationsList';
  getHitReason: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/GetHitReason';

  // ORGANIZATION LIST
  getAuditOrganizationList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/AuditOrganizationList';

  // AuditReport
  newAuditReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/NewAuditReport';
  listAuditReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/ListAuditReport';
  openAuditReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/OpenAuditReport';
  deleteAuditReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/DeleteAuditReport';
  submitAuditReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/SubmitAuditReport';
  getAssociatedARLegalProvisionsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/GetAssociatedARLegalProvisionsList';

  openSeizedDocumentViewDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/SeizedDocumentViewDetailsList';


  getPastImportExportHistory: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/GetPastImportExportHistoryList';
  getAuditInstructions: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/GetAuditInstructionsList';


  listLegalProvisionAssocaition: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportLegalProvision/ListLegalProvisionAssocaition';
  SubmitLegalProvisionAssocaition: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportLegalProvision/SubmitLegalProvisionAssocaition';
  legalProvisionAssocaitionFilterList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportLegalProvision/LegalProvisionAssocaitionFilterList';

  // ORGANIZATION DETAILS
  getOrganizationDetailList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/OrganizationDetailList';

  // DECLARATION DISCREPANCIES
  getDiscrepancyList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/DiscrepancyList';

  // AUDIT NOTES LIST
  getAuditReportNoteList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/AuditReportNoteList';

  // QUESTION AND ANSWER LIST
  getAnswerList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/AnswerList';

  // SEIZED ITEM LIST
  getSeizedItemList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/SeizedItemList';

  // SEIZED DOCUMENTS LIST
  getSeizedDocumentList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/SeizedDocumentList';



  // AuditReportNotes
  newAuditReportNotes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportNotes/NewAuditReportNotes';
  openAuditReportNotes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportNotes/OpenAuditReportNotes';
  deleteAuditReportNotes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportNotes/DeleteOpenAuditReportNotes';
  submitAuditReportNotes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportNotes/SubmitAuditReportNotes';

  // Discrepancy
  newDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/NewDiscrepancy';
  listDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/ListDiscrepancy';
  openDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/OpenDiscrepancy';
  deleteDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/DeleteDiscrepancy';
  submitDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/SubmitDiscrepancy';

  getAssociatedDiscrepancyDeclarationList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/GetAssociatedDiscrepancyDeclarationList';
  filterListDiscrepancyDeclaration: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/ListDiscrepancyDeclaration';
  submitDiscrepancyDeclaration: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/SubmitDiscrepancyDeclaration';
  listGetAssociatedDiscrepancyDeclarations: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/ListGetAssociatedDiscrepancyDeclarations';

  // FormsDiscrepancy
  newFormsDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/FormsDiscrepancy/NewFormsDiscrepancy';
  listFormsDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/FormsDiscrepancy/ListFormsDiscrepancy';
  openFormsDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/FormsDiscrepancy/OpenFormsDiscrepancy';
  deleteFormsDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/FormsDiscrepancy/DeleteFormsDiscrepancy';
  submitFormsDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/FormsDiscrepancy/SubmitFormsDiscrepancy';

  // LegalProvision
  newLegalProvision: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/NewLegalProvision';
  listLegalProvision: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/ListLegalProvision';
  openLegalProvision: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/OpenLegalProvision';
  deleteLegalProvision: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/DeleteLegalProvision';
  submitLegalProvision: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/SubmitLegalProvision';

  // OrganizationAuditPlan
  newOrganizationAuditPlan: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/NewOrganizationAuditPlan';
  listOrganizationAuditPlan: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/ListOrganizationAuditPlan';
  openOrganizationAuditPlan: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/OpenOrganizationAuditPlan';
  deleteOrganizationAuditPlan: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/DeleteOrganizationAuditPlan';
  submitOrganizationAuditPlan: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/SubmitOrganizationAuditPlan';
  getAuditTypesViewList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/GetAuditTypesViewList';
  addPCAVisit: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/AddPCAVisit';
  getAssociatedAuditorsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/GetAssociatedAuditorsList';
  listAssociateAuditors: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AssociateAuditors/ListAssociateAuditors';
  submitAssociateAuditors: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AssociateAuditors/SubmitAssociateAuditors';
  associateAuditorsFilterList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AssociateAuditors/AssociateAuditorsFilterList';
  deleteVisitSchedule: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/DeleteVisitSchedule';
  orgAudPlanWFStatesApiUrl: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/GetOrgAudPlanWorkflowStates';
  getAuditProgramOrgList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/GetAuditProgramOrgList';
  listAuditInstructionsSub: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/ListAuditInstructionsSub';
  getDeclarationList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/GetDeclarationList';
  getAssociatedTemplatesList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/GetAssociatedTemplatesList';

  // Associate Templates
  listAssociateTemplates: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AssociateTemplates/ListAssociateTemplates';
  submitAssociatedTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AssociateTemplates/SubmitAssociateTemplates';
  filterAssociatedTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AssociateTemplates/AssociateTemplatesFilterList';

  // AUDIT INSTRUCTIONS
  listAssociateAuditIns: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AssociateAuditIns/ListAssociateAuditIns';
  submitAssociateAuditIns: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AssociateAuditIns/SubmitAssociateAuditIns';
  associateAuditInsFilterList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AssociateAuditIns/AssociateAuditInsFilterList';
  associatedAuditInsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AssociateAuditIns/AssociatedAuditInsList';

  // AUDITORS LIST
  getAuditPlanAuditorList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/AuditPlanAuditorList';

  // VISIT SCHEDULE
  getVisitList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/VisitList';

  // AUDIT SUMMARY LIST
  getAuditSummaryList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/AuditSummaryList';

  // PcaForm
  newPcaForm: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/NewPcaForm';
  listPcaForm: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/ListPcaForm';
  openPcaForm: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/OpenPcaForm';
  deletePcaForm: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/DeletePcaForm';
  submitPcaForm: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/SubmitPcaForm';

  // SEIZED DOCUMENTS LIST
  getDocumentList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/FormDocumentList';

  // Question
  newQuestion: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Question/NewQuestion';
  listQuestion: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Question/ListQuestion';
  openQuestion: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Question/OpenQuestion';
  deleteQuestion: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Question/DeleteQuestion';
  submitQuestion: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Question/SubmitQuestion';

  // RiskReport
  newRiskReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/RiskReport/NewRiskReport';
  listRiskReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/RiskReport/ListRiskReport';
  openRiskReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/RiskReport/OpenRiskReport';
  deleteRiskReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/RiskReport/DeleteRiskReport';
  submitRiskReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/RiskReport/SubmitRiskReport';

  // SeizedDocument
  newSeizedDocument: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedDocument/NewSeizedDocument';
  openSeizedDocument: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedDocument/OpenSeizedDocument';
  deleteSeizedDocument: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedDocument/DeleteSeizedDocument';
  submitSeizedDocument: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedDocument/SubmitSeizedDocument';

  // SeizedItem
  newSeizedItem: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedItem/NewSeizedItem';
  openSeizedItem: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedItem/OpenSeizedItem';
  deleteSeizedItem: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedItem/DeleteSeizedItem';
  submitSeizedItem: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedItem/SubmitSeizedItem';

  // SelectivityCriteria
  newSelectivityCriteria: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/NewSelectivityCriteria';
  listSelectivityCriteria: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/ListSelectivityCriteria';
  openSelectivityCriteria: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/OpenSelectivityCriteria';
  deleteSelectivityCriteria: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/DeleteSelectivityCriteria';
  submitSelectivityCriteria: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/SubmitSelectivityCriteria';

  // Country Of Origin
  getCountryOfOrigin: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/CountryOfOriginList';

  // Country Of Destination
  getCountryOfDestination: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/CountryOfDestinationList';

  // Tariff Line
  getTariffLine: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/ItemCodeList';

  // Organization List
  getOrganizationList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/PcaOrganizationList';

  // Directors List
  getDirectorsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/DirectorList';

  // Duties List
  getDutiesList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/PcaDutyList';

  // Risk Target List
  getRiskTargetList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/RiskTargetList';

  // Business Entities
  getBusinessEntitiesList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/BusinessEntitiesList';

  // Pca Exemptions
  getPcaExemptionsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/PcaExemptionsList';

  // FTA Management
  getFTAManagementsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/FtaManagementList';

  // Transaction Type
  getTransactionTypeList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/TransactionTypeList';

  // Location List
  getLocationList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/LocationList';

  // List Preview Result Org
  listPreviewResultOrg: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/ListPreviewResultOrg';

  // Store
  newStore: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/NewStore';
  listStore: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/ListStore';
  openStore: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/OpenStore';
  deleteStore: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/DeleteStore';
  submitStore: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/SubmitStore';

  // STORE OPERATORS LIST
  getStoreOperatorList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/StoreOperatorList';

  // Template
  newTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/NewTemplate';
  listTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/ListTemplate';
  openTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/OpenTemplate';
  deleteTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/DeleteTemplate';
  submitTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/SubmitTemplate';
  getAssociatedQuestionsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/GetAssociatedQuestionsList';

  // Template Question
  getTemplateQuestionList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/TemplateQuestionList';

  listQuestionDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TemplateQuestionDetails/ListQuestionsDetails';
  submitQuestionDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TemplateQuestionDetails/SubmitQuestionsDetails';
  filterListQuestionDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TemplateQuestionDetails/QuestionsDetailsFilterList';

  // TransactionAudit
  listTransactionAudit: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TransactionAudit/ListTransactionAudit';
  openTransactionAudit: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TransactionAudit/OpenTransactionAudit';
  submitTransactionAudit: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TransactionAudit/SubmitTransactionAudit';

  // Documents Upload
  openLglProvUploadDocument: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/OpenLglProvUploadDocument';
  submitLglProvUploadDocument: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/SubmitLglProvUploadDocument';
  deleteLglProvUploadDocuments: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/DeleteLglProvUploadDocuments';
  downloadLglProvUploadDocuments: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/DownloadLglProvUploadDocuments';

  // AddDetails
  newAddDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AddDetails/NewAddDetails';
  openAddDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AddDetails/OpenAddDetails';
  deleteAddDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AddDetails/DeleteAddDetails';
  submitAddDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AddDetails/SubmitAddDetails';
  listAddDetails: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AddDetails/ListAddDetails';

  // Add Details Submitted invoice
  listAddDetailsSubmittedInvoice: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AddDetailsSubmittedInvoice/ListAddDetailsSubmittedInvoice';
  newAddDetailsSubmittedInvoice: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AddDetailsSubmittedInvoice/NewAddDetailsSubmittedInvoice';
  openAddDetailsSubmittedInvoice: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AddDetailsSubmittedInvoice/OpenAddDetailsSubmittedInvoice';
  deleteAddDetailsSubmittedInvoice: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AddDetailsSubmittedInvoice/DeleteAddDetailsSubmittedInvoice';
  submitAddDetailsSubmittedInvoice: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AddDetailsSubmittedInvoice/SubmitAddDetailsSubmittedInvoice';




  constructor(
    private http: HttpClient,
    private storageService: StorageService) { }
  ngOnInit() {
  }

  public getCascadingPcaTypes(moduleName: any, value: any): Observable<any> {
    const api = this.pcaTypes.split('?');
    return this.http.get(api[0] + '?moduleName=' + moduleName + '&typeName=' + value);
  }

  public getCascadingTypes(moduleName: any, value: any): Observable<any> {
    const api = this.getMcnTypes.split('?');
    return this.http.get(api[0] + '?moduleName=' + moduleName + '&typeName=' + value);
  }
}
