import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Pipe({ name: 'replaceToRefNumber' })
export class ReplaceToRefNumberPipe implements PipeTransform {
  /**
   * Constructor
   */
  constructor() {
  }
  /**
   * Transform a Message that is replaced with RefNumber
   * @param messageBody The messageBody passed as string
   * @param refNumber The refNumber passed as string
   * @returns {string} The string type
   */
  transform(value: string, replaceString: string, replaceValue: string): string {
    if (!isNullOrUndefined(value)) {
      const replaceStringArray = replaceString.split(',');
      replaceStringArray.forEach(x => {
        value = value.replace(x, replaceValue);
      });
    }
    return value;
  }
}