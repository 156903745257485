import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../storageService/storageService';
import { CustomsService } from '../../registration/commonRegistrationComponents/customsService';
import { Activation } from '../../constant/accountConstant';
import { SafeUnsubscriber } from '../../../common/SafeUnsubscriber';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-activated-success',
  templateUrl: './ActivatedSuccessComponent.html',
  providers: [CustomsService]
})
export class ActivatedSuccessComponent extends SafeUnsubscriber implements OnInit {
  public errorMessage: string;
  public activationMessage: string;
  public isShowSuccessActivation = true;
  constructor(
    private router: Router,
    public webStorage: StorageService,
    private ref: ChangeDetectorRef,
    private customsService: CustomsService) {
    super();
  }

  ngOnInit() {
    const userId = this.webStorage.get('activationUserId');
    if (!isNullOrUndefined(userId)) {
      this.customsService.checkUserActivation(userId).subscribe((response) => {
        if (response === Activation.Zero) {
          this.activationMessage = Activation.alreadyRegistered;
          this.isShowSuccessActivation = true;
        } else if (response === Activation.One || response === Activation.three) {
          this.activationMessage = Activation.activationLinkExpired;
          this.isShowSuccessActivation = false;
        } else if (response === Activation.two) {
          this.isShowSuccessActivation = true;
          this.activationMessage = Activation.registeredSuccess;
        }
        this.customsService.activateAndDeactivate(this.webStorage.get('activationUserId'), true, false).subscribe((res) => {
        });
        this.ref.markForCheck();
      });
    }
  }

  public gotoLogin() {
    this.router.navigate(['Account/Login']);
  }
}
