import { SafeUnsubscriber } from '../../../common/SafeUnsubscriber';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { TariffSearchComponent } from '../tariffSearch/TariffSearchComponent';
import { DutyTaxOutputPopupComponent } from '../dutyTaxOutputPopup/DutyTaxOutputPopupComponent';
import { MatDialog } from '@angular/material';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '@env/environment';
import { FxContext } from '@agility/frameworkcore';
import { StorageService } from '../../../storageService/storageService';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-logo',
  templateUrl: './logoComponent.html',
})
export class LogoComponent extends SafeUnsubscriber implements OnInit {
  public Duty = false;
  public Tariff = false;
  public Microclear = true;
  public tariffPolicyDtls: FormGroup;
  public dutyTaxEstimatorDtls: FormGroup;
  public labelImpFrom = 'Importing from';
  public labelImpTo = 'Importing to';
  public labelciffob = 'CIF Price (Per Unit)';
  public radioData = [
    { 'radioID': 1, 'radioName': 'Import' },
    { 'radioID': 2, 'radioName': 'Export' }
  ];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public appConfig: McnConfig,
    private http: HttpClient,
    private oauthService: OAuthService,
    private fxContext: FxContext,
    public storageService: StorageService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.tariffPolicyDtls = this.fb.group({
      hsCode: [''],
      radio: ['', [Validators.required as any]],
      country: [''],
    });
    this.dutyTaxEstimatorDtls = this.fb.group({
      radio: [''],
      importingFrom: [''],
      importingTo: [''],
      currency: [''],
      hsCode: ['', [Validators.required as any]],
      itemDesc: [''],
      cif: ['', [Validators.required as any]],
      quantity: ['', [Validators.required as any]],
      specifications: [''],
      uom: ['']
    });
  }

  public DutyEstimator() {
    this.Duty = true;
    this.Tariff = false;
    this.Microclear = false;
  }

  public searchTariffResults() {
    if (this.tariffPolicyDtls.valid) {
      this.getTariffData().subscribe((data) => {
        let tariffData: any;
        const tariffList: any[] = [];
        const dutyList: any[] = [];
        const quotaList: any[] = [];
        const exemptionList: any[] = [];
        const prohibitionList: any[] = [];

        tariffData = data['item1'][0];
        for (const tariff of data['item1']) {
          tariffList.push(
            {
              hsCode: tariff.tariffCode,
              desc: tariff.description,
              uom: tariff.uoM1Name
            });
        }
        for (const duty of data['item2']) {
          dutyList.push(
            {
              duty: duty.dutyName,
              startDate: duty.startDate,
              endDate: duty.endDate,
              tariffItemDutyId: duty.tariffItemDutyId,
              tariffItemDutyRateId: duty.tariffItemDutyRateId
            });
        }
        for (const quota of data['item3']) {
          quotaList.push({
            quotaName: quota.quotaName,
            quotaRefCode: quota.quotaRefCode,
            direction: this.tariffPolicyDtls.value.radio === '1' ? 'Import' : 'Export'
          });
        }
        for (const exemption of data['item4']) {
          exemption.exemptionCategoryInfoId = exemption.exemptionCategoryInfoId === 4008 ? 'General Exemption' : 'Exemption Request';
          exemptionList.push({
            exemptionRequestNumber: exemption.exemptionRequestNumber,
            exemptionCategoryInfoId: exemption.exemptionCategoryInfoId,
            direction: exemption.direction,
            approvedExemptionRate: exemption.approvedExemptionRate
          });
        }
        for (const prohibition of data['item5']) {
          prohibitionList.push(
            {
              referenceNumber: prohibition.referenceNumber,
              title: prohibition.title,
              isExpanded: false,
              conditionalProhibitionAgency: prohibition.conditionalProhibitionAgency
            });
        }
        this.dialog.open(TariffSearchComponent, {
          panelClass: 'custom-modal-container',
          data: {
            tariffData: { hsCode: tariffData.tariffCode, desc: tariffData.description, uom: tariffData.uoM1Name },
            dutyData: dutyList,
            quotaData: quotaList,
            exemptionData: exemptionList,
            prohibitionData: prohibitionList,
            tariffListData: tariffList,
          }
        });
      });
    } else {
      Object.keys(this.tariffPolicyDtls.controls).forEach(field => {
        const control = this.tariffPolicyDtls.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  public getTariffData() {
    return this.http.get(this.appConfig.anonymousTariffItemDetailsApiUrl +
      this.tariffPolicyDtls.value.hsCode + '&direction=' + this.tariffPolicyDtls.value.radio);
  }

  public getTariffDutyData() {
    return this.http.get(this.appConfig.anonymousTariffItemDutyDetailsApiUrl +
      this.dutyTaxEstimatorDtls.value.hsCode +
      '&direction=' + this.dutyTaxEstimatorDtls.value.radio +
      '&country=' + this.dutyTaxEstimatorDtls.value.importingFrom +
      '&cif=' + this.dutyTaxEstimatorDtls.value.cif +
      '&quantity=' + this.dutyTaxEstimatorDtls.value.quantity +
      '&uom=' + this.dutyTaxEstimatorDtls.value.uom);
  }

  public TariffPolicy() {
    this.Tariff = true;
    this.Duty = false;
    this.Microclear = false;
  }

  public dutyTaxCharges() {
    if (this.dutyTaxEstimatorDtls.valid) {
      this.getTariffDutyData().subscribe((data) => {
        this.dialog.open(DutyTaxOutputPopupComponent, {
          panelClass: 'custom-modal-container',
          width: '900px',
          data: {
            dutyData: data,
            direction: this.dutyTaxEstimatorDtls.value.radio,
            country: this.dutyTaxEstimatorDtls.value.importingFrom,
            cif: this.dutyTaxEstimatorDtls.value.cif,
            quantity: this.dutyTaxEstimatorDtls.value.quantity,
            uom: this.dutyTaxEstimatorDtls.value.uom,
            hscode: this.dutyTaxEstimatorDtls.value.hsCode
          }
        });
      });
    } else {
      Object.keys(this.dutyTaxEstimatorDtls.controls).forEach(field => {
        const control = this.dutyTaxEstimatorDtls.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  public goToIDPServerLogin() {
    this.oauthService.configure(environment.appSettings.authConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(x => {
      if (this.oauthService.hasValidAccessToken()) {
        this.fxContext.IsAuthenticated = this.oauthService.hasValidAccessToken();
      } else {
        this.oauthService.initImplicitFlow();
      }
    });
  }

  public onChangeProcedureType(event: any) {
    const selectedValue = event.options.radioID;

    this.labelImpFrom = selectedValue === 2 ? 'Exporting to' : 'Importing from';
    this.labelImpTo = selectedValue === 2 ? 'Exporting from' : 'Importing to';
    this.labelciffob = selectedValue === 2 ? 'FOB Price (per Unit)' : 'CIF Price (per Unit)';
    this.dutyTaxEstimatorDtls.controls['importingTo'].setValue('Malaysia');
  }

  public goToHome() {
    this.Tariff = false;
    this.Duty = false;
    this.Microclear = true;
    this.router.navigate(['Account/Login']);
  }

  public clear() {
    this.dutyTaxEstimatorDtls = this.fb.group({
      radio: [''],
      importingFrom: [''],
      importingTo: [''],
      currency: [''],
      hsCode: ['', [Validators.required as any]],
      itemDesc: [''],
      cif: ['', [Validators.required as any]],
      quantity: ['', [Validators.required as any]],
      specifications: [''],
      uom: ['']
    });
  }

  public getHSCodeDesc(event: any) {
    const itemDesc = event.options.description;
    this.dutyTaxEstimatorDtls.controls['itemDesc'].setValue(itemDesc);
  }
}
