/* tslint:disable: max-line-length */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { StorageService } from '../storageService/storageService';

export interface myData {
  pageCount: number;
  associatePageCount: number;
  associateLessPageCount: number;
  sessionTimeout: number;
  localeJsonFile: any;
}

@Injectable()
export class McnConfig implements OnInit {
  allowdCeilingLimit = 20;
  isInbox = false;
  localCountryName = 'MY';
  exchangeRateToCurrencyName = 'Saudi Arabia Riyal';
  exchangeRateToCurrencyId = 88;
  passwordLength = 35;
  idNumberLimit = 35;
  contactNumberLimit = 15;
  brnNumberLimit = 35;
  orgNameLimit = 100;
  gstNumberLimit = 35;
  renualDateLimit = 30;
  historicalDataDateLimit = 30;
  appKey = '8080808080808080';
  appData: myData = { pageCount: 9, associatePageCount: 5, sessionTimeout: 0, associateLessPageCount: 5, localeJsonFile: null };


  // MDM
  getTransitRoutesApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/TransitRoute/GetTransitRoutes?transitfilter=';
  localcountryApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Country/GetLocalCountry';
  countryApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Country/GetCountry?filterValue=';
  stateApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/State/GetAllState?filterValue=';
  cityApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/City/GetCity?filterValue=';
  getOriginDestinationCustomStations: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/CustomStation/GetOriginDestinationCustomStations?customStationFilter=';
  bankApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Bank/GetBank?filterValue=';
  bankBranchApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/BankBranch/GetBankBranch?filterValue=';
  containerISOCodeApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/ContainerIsoCode/GetContainerISOCode?filterValue=';
  currencyApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Currency/GetCurrency?filterValue=';
  locationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Location/GetLocation?filterValue=';
  getFinalDestinations: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Location/GetFinalDestinations?filterValue=';
  getOriginDestinationLocations: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Location/GetOriginDestinationLocations?locationNameFilter=';
  quantityUnitApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/QuantityUnit/GetQuentityUnit?filterValue=';
  sTACodesApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/StaCodeType/GetSTACodeTypes?filterValue=';
  sTACodeCategoriesApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/StaCodeType/GetSTACodeCategories?filterValue=';
  storageLocationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/StorageLocation/GetStorageLocation?filterValue=';
  telePhoneCountryCodeApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/TelePhoneCountryCode/GetTelePhoneCountryCode?filterValue=';
  getTelePhoneCountryCodeId: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/TelePhoneCountryCode/GetTelePhoneCountryCodeId/';
  vehicleBrandApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/VehicleBrand/GetVehicleBrand?filterValue=';
  volumeUnitApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/VolumeUnit/GetVolumeUnit?filterValue=';
  weightUnitApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/WeightUnit/GetWeightUnit?filterValue=';
  brandModelApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Brandmodel/GetVehicleBrand?filterValue=';
  getMcnTypes: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/McnTypes/GetModuleType';
  regionsApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Region/GetRegions?filterValue=';
  cascadingPortLocationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Location/GetDestinationLocationByOrigin?filterValue=';
  journeyLocationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Location/GetModeLocation?locationNameFilter=';
  getPortsfilter: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Location/GetPortsfilter';
  getCSfilter: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/CustomStation/GetCSfilter';
  getTypeModulesApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/McnTypes/GetAllModules';
  getParentTypesApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/McnTypes/GeParentTypes';
  customStationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/CustomStation/GetCustomStation?filterValue=';
  postalApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/PostalCode/GetPostalCode?filterValue=';
  getTelePhoneCountryCode: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/TelePhoneCountryCode/GetTelePhoneCountryCode?filterValue=';

  // need
  fTAManagementApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/FtaManagement/GetFTAManagements?filterValue=';
  regOrganizationUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Organization/GetRegOrganization?filterValue=';
  regOGAOrganizationUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Organization/GetRegCustomAndOGAOrganization?filterValue=';
  organizationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Organization/GetOrganization?filterValue=';
  postingOrganizationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Organization/GetPostingOrganizations?filterValue=';

  getRegistrationCSfilter: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/MasterData/GetCSfilter';
  getCustomPostingfilter: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/GetCustomPostingfilter';
  getRegistrationLocalcountryApiUrl: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/MasterData/GetLocalCountry';
  getRegistrationCountryApiUrl: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Country/GetCountry?filterValue=';
  getRegistrationStateApiUrl: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/MasterData/GetAllState?filterValue=';
  getRegistrationCityApiUrl: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/MasterData/GetCity?filterValue=';
  getRegistrationpostalApiUrl: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/MasterData/GetPostalCode?filterValue=';
  getRegistrationcustomStationApiUrl: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/MasterData/GetCustomStation?filterValue=';
  getRegistrationTelePhoneCountryCode: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/MasterData/GetTelePhoneCountryCode?filterValue=';
  getRegistrationPortsfilter: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/MasterData/GetPortsfilter';
  getOrgPostings: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/GetOrgPostings';
  getGovernmenttPostingfilter: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/GetGovernmenttPostingfilter';
  getRegistrationTelePhoneCountryCodeId: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/MasterData/GetTelePhoneCountryCodeId/';

  listProfileConfiguration: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/ProfileConfiguration/ListProfileConfiguration';
  newProfileConfiguration: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/ProfileConfiguration/NewProfileConfiguration';
  openProfileConfiguration: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/ProfileConfiguration/OpenProfileConfiguration';
  deleteProfileConfiguration: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/ProfileConfiguration/DeleteProfileConfiguration';
  submitProfileConfiguration: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/ProfileConfiguration/SubmitProfileConfiguration';
  getPostingProfiles: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/ProfileConfiguration/GetPostingProfiles?profilefilter=';
  getRegistrationServices: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/ProfileConfiguration/GetServices?value=';
  getRegistrationMcnTypes: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/MasterData/GetModuleType';
  listPosting: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/ListPosting';
  newPosting: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/NewPosting';
  openPosting: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/OpenPosting';
  deleteposting: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/Deleteposting';
  submitPosting: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/SubmitPosting';
  getPostinRoles: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/GetPostinRoles';
  submitAssociatePostingRole: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/SubmitAssociatePostingRole';
  getNewPostingRole: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Posting/GetNewPostingRole';

  // Service
  mdmcountryApiUrl: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Country/GetCountry?filterValue=';
  mdmstateApiUrl: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/State/GetAllState?filterValue=';
  mdmcityApiUrl: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/City/GetCity?filterValue=';
  mdmgetMcnTypes: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/McnTypes/GetModuleType';
  mdmgetTypeModulesApiUrl: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/McnTypes/GetAllModules';
  mdmgetParentTypesApiUrl: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/McnTypes/GeParentTypes';
  mdmlocationApiUrl: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Location/GetLocation?filterValue=';
  listBank: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Bank/ListBank';
  newBank: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Bank/NewBank';
  openBank: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Bank/OpenBank';
  deleteBank: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Bank/DeleteBank';
  submitBank: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Bank/SubmitBank';
  checkBankCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Bank/CheckBankCode';
  listBankBranch: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/BankBranch/ListBankBranch';
  newBankBranch: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/BankBranch/NewBankBranch';
  openBankBranch: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/BankBranch/OpenBankBranch';
  deleteBankBranch: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/BankBranch/DeleteBankBranch';
  submitBankBranch: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/BankBranch/SubmitBankBranch';
  checkBankBranchCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/BankBranch/CheckBankBranchCode';
  listCity: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/City/ListCity';
  newCity: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/City/NewCity';
  openCity: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/City/OpenCity';
  deleteCity: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/City/DeleteCity';
  submitCity: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/City/SubmitCity';
  checkCityName: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/City/CheckCityName';
  newCountry: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Country/NewCountry';
  listCountry: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Country/ListCountry';
  checkUnCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Country/CheckUnCode';
  openCountry: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Country/OpenCountry';
  deleteCountry: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Country/DeleteCountry';
  submitCountry: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Country/SubmitCountry';
  listCurrency: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Currency/ListCurrency';
  newCurrency: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Currency/NewCurrency';
  openCurrency: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Currency/OpenCurrency';
  deleteCurrency: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Currency/DeleteCurrency';
  submitCurrency: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Currency/SubmitCurrency';
  checkCurrencyCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Currency/CheckCurrencyCode';
  listCustomPreference: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomPreference/ListCustomPreference';
  newCustomPreference: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomPreference/NewCustomPreference';
  openCustomPreference: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomPreference/OpenCustomPreference';
  deleteCustomPreference: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomPreference/DeleteCustomPreference';
  submitCustomPreference: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomPreference/SubmitCustomPreference';
  listCustomStation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomStation/ListCustomStation';
  newCustomStation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomStation/NewCustomStation';
  openCustomStation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomStation/OpenCustomStation';
  deleteCustomStation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomStation/DeleteCustomStation';
  submitCustomStation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomStation/SubmitCustomStation';
  checkCustomStationCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/CustomStation/CheckCustomStationCode';
  listLocation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Location/ListLocation';
  newLocation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Location/NewLocation';
  openLocation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Location/OpenLocation';
  deleteLocation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Location/DeleteLocation';
  submitLocation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Location/SubmitLocation';
  checkLocationCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/Location/CheckLocationCode';
  listMcnType: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/McnTypes/ListMcnType';
  newMcnType: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/McnTypes/NewMcnType';
  submitMcnType: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/McnTypes/SubmitMcnType';
  checkMcnTypes: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/McnTypes/CheckMcnTypes';
  deleteMcnType: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/McnTypes/DeleteMcnType';
  getParentTypeListApiUrl: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/McnTypes/ParentTypesList';
  openMcnType: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/McnTypes/OpenMcnType';
  listPostalCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/PostalCode/ListPostalCode';
  newPostalCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/PostalCode/NewPostalCode';
  openPostalCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/PostalCode/OpenPostalCode';
  deletePostalCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/PostalCode/DeletePostalCode';
  submitPostalCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/PostalCode/SubmitPostalCode';
  checkPostalCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/PostalCode/CheckPostalCode';
  listQuantityUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/QuantityUnit/ListQuantityUnit';
  newQuantityUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/QuantityUnit/NewQuantityUnit';
  openQuantityUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/QuantityUnit/OpenQuantityUnit';
  deleteQuantityUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/QuantityUnit/DeleteQuantityUnit';
  submitQuantityUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/QuantityUnit/SubmitQuantityUnit';
  checkQuantityUnitCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/QuantityUnit/CheckQuantityUnitCode';
  listState: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/State/ListState';
  newState: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/State/NewState';
  openState: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/State/OpenState';
  deleteState: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/State/DeleteState';
  submitState: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/State/SubmitState';
  getStateName: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/State/GetStateName/';
  listStorageLocation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/StorageLocation/ListStorageLocation';
  newStorageLocation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/StorageLocation/NewStorageLocation';
  openStorageLocation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/StorageLocation/OpenStorageLocation';
  deleteStorageLocation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/StorageLocation/DeleteStorageLocation';
  submitStorageLocation: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/StorageLocation/SubmitStorageLocation';
  checkStorageLocationCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/StorageLocation/CheckStorageLocationCode';
  listTelephoneCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/TelePhoneCountryCode/ListTelephoneCode';
  newTelephoneCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/TelePhoneCountryCode/NewTelephoneCode';
  openTelephoneCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/TelePhoneCountryCode/OpenTelephoneCode';
  deleteTelephoneCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/TelePhoneCountryCode/DeleteTelephoneCode';
  submitTelephoneCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/TelePhoneCountryCode/SubmitTelephoneCode';
  checkTelephoneCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/TelePhoneCountryCode/CheckTelephoneCode';
  listVehicleBrand: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VehicleBrand/ListVehicleBrand';
  newVehicleBrand: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VehicleBrand/NewVehicleBrand';
  openVehicleBrand: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VehicleBrand/OpenVehicleBrand';
  deleteVehicleBrand: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VehicleBrand/DeleteVehicleBrand';
  submitVehicleBrand: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VehicleBrand/SubmitVehicleBrand';
  listUnitWeight: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/WeightUnit/ListUnitWeight';
  newUnitWeight: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/WeightUnit/NewUnitWeight';
  openUnitWeight: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/WeightUnit/OpenUnitWeight';
  deleteUnitWeight: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/WeightUnit/DeleteUnitWeight';
  submitUnitWeight: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/WeightUnit/SubmitUnitWeight';
  checkWeightUnitCode: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/WeightUnit/CheckWeightUnitCode';
  listVolumeUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VolumeUnit/ListVolumeUnit';
  newVolumeUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VolumeUnit/NewVolumeUnit';
  openVolumeUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VolumeUnit/OpenVolumeUnit';
  deleteVolumeUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VolumeUnit/DeleteVolumeUnit';
  checkVolumeUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VolumeUnit/CheckVolumeUnit';
  submitVolumeUnit: any = environment.appSettings.apiEndpoints.coreMdmApiBaseUrl + '/api/VolumeUnit/SubmitVolumeUnit';

  // Registration
  getCeiliengLimitActiveUserCount = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/GetCeiliengLimitActiveUserCount';
  regDeparmentsApiUrl = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/ListOfDepartmentsByOrganaization?departmentName=';
  deparmentApiUrl = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/ListDepartment?departmentName=';
  allShippingForwardingAgentsOrganizationApiUrl: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetShippingForwardingAgentsOrganization?organizationFilter=';
  allBusinessStackHolderOrganizations: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetAllBusinessStackHolderOrganizations?organizationFilter=';
  getAgentForwardingOrganizations: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetAgentForwardingOrganizations?organizationFilter=';
  agentAssociationWFStatesApiUrl: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/OrganizationAgentAssociation/GetAgentAssociationWfStates';
  changePassword: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/ChangePassword';
  forgotPassword: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/SendResetPasswordLink';
  login: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/account/login';
  dashboardLogin: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/account/DashboardLogin';
  register: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/account/register';
  registerGetUserData: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/UserService/GetUserData';
  getOrganizations: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/account/getOrganizations';
  logout: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/account/logout';
  validateCaptcha: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/account/ValidateCaptcha';
  celingLimitList = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/CeilingLimitRequest/ListCeilingLimit';
  celingLimitNew = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/CeilingLimitRequest/NewCeilingLimit';
  celingLimitOpen: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/CeilingLimitRequest/OpenCeilingLimit';
  celingLimitDelete: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/CeilingLimitRequest/DeleteCeilingLimit';
  celingLimitSubmit: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/CeilingLimitRequest/SubmitCeilingLimit';
  listAdditionalUsers: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/ListAdditionalUsers';
  filterAdditionalUsers: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/FilterAdditionalUsers';
  listOrganizationAgentAssociation: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/OrganizationAgentAssociation/ListOrganizationAgentAssociation';
  newOrganizationAgentAssoc: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/OrganizationAgentAssociation/NewOrganizationAgentAssoc';
  openOrganizationAgentAssoc: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/OrganizationAgentAssociation/OpenOrganizationAgentAssoc';
  submitOrganizationAgentAssoc: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/OrganizationAgentAssociation/SubmitOrganizationAgentAssoc';
  deleteOrganizationAgentAssoc: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/OrganizationAgentAssociation/DeleteOrganizationAgentAssoc';
  listForwardingAgentsForAgentAssociation: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/OrganizationAgentAssociation/ListForwardingAgentsForAgentAssociation';
  checkForDeclaration: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/OrganizationAgentAssociation/CheckForDeclaration';
  listDepartment: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/ListDepartment';
  newDepartment: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/NewDepartment';
  openDepartment: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/OpenDepartment';
  deleteDepartment: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/DeleteDepartment';
  submitDepartment: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/SubmitDepartment';
  getUserDataByDeptId: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/GetUserDataByDeptId';
  getCustomOrganizationData: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/GetCustomOrganizationData';
  openDepartmentByOrgId: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/OpenDepartmentByOrgId';
  listDepartmentUsersByDeptId: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/ListDepartmentUsersByDeptId';
  listCustomsDepartmentsByOrgId: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/ListCustomsDepartmentsByOrgId';
  getAssociatedPortData: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetAssociatedPortData';
  submitUserPort: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/SubmitUserPort';
  getAssociatedUserProfile: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetAssociatedUserProfile';
  getCustomsUserProfiles: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetCustomsUserProfiles';
  getCustomsDepatrmentProfiles: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetCustomsDepatrmentProfiles';
  getOrganaizationProfiles: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetOrganaizationProfiles';
  getGovernmentProfiles: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetGovernmentProfiles';
  submitUserProfile: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/SubmitUserProfile';
  getAssociatedUserCustomStation: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetAssociatedUserCustomStation';
  submitUserCustomStations: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/SubmitUserCustomStations';
  sendActivationMail: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/SendActivationMail';
  updateUserState: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/UpdateUserState';
  checkUserActivation: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/CheckUserActivation';
  encryption: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/McnEncrypt/Encryption';
  sendResetPasswordLink: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/SendResetPasswordLink';
  filterAssociationProfiles: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/FilterAssociationProfiles';
  newCustomsDeptPosting: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/NewCustomsDeptPosting';
  submitDepartmentAssProfiles: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/SubmitDepartmentAssProfiles';
  getMyProfile: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/GetMyProfile';
  registerReg: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/Register';
  registerAdditionalUSer: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/RegisterAdditionalUSer';
  updateRegisterAdditionalUser: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/UpdateRegisterAdditionalUser';
  switchOrganizationMasterUser: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/SwitchOrganizationMasterUser';
  checkUser: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/CheckUser';
  checkOrganization: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/CheckOrganization';
  uploadFile: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Manager/uploadFile';
  listIndividualRegistartion: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/ListIndividualRegistartion';
  listOrganizations: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/ListOrganizations';
  listGovernmentReg: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/ListGovernmentReg';
  userListByOrganization: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/UserListByOrganization';
  submitUserData: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/SubmitUserData';
  updateUserData: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/UpdateUserData';
  getCustomUserData: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/GetCustomUserData';
  updateDepartment: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/UpdateDepartment';
  editProfile: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/EditProfile';
  individualUserFilter: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/IndividualUserFilter';
  organizationslUserFilter: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/OrganizationslUserFilter';
  getFilterCustomsDeptPeopleData: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Department/GetFilterCustomsDeptPeopleData';
  getAddressInfo: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/GetAddressInfo';
  getLoginedOrganizationInfo: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/GetLoginedOrganizationInfo';
  getUserDetails: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/GetUserDetails';
  getConsigneeByOrgnaizationId: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/Registration/GetConsigneeByOrgnaizationId';
  decryption: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/McnEncrypt/Decryption';
  refreshToken: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/account/RefreshToken';
  getProfileOnPersonalId: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/GetProfileOnPersonalId';
  getRegApplicantLicenseData: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationProcess/GetOrganizationLicenseInfoData';

  // Cargo
  cargoWorkFlowStates: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShutOutCertificate/GetCargoFlowStates';
  exportJourneyListApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/GetJourney?journeyNumberFilter=';
  getJourneyDataApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShutOutCertificate/GetJourneyDataAsync?journeyNameFilter=';
  getJourneyDataApiUrlFOT: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/FinalOutturnCertificate/GetJourneyDataAsync?journeyNameFilter=';
  getLoadUnloadJourneyDataApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCLoadUnloadDetails/GetLoadUnloadJourneyData?journeyNameFilter=';
  vesselApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/GetVesselData?vesselFilter=';
  getRefBillOfLadingNumber: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/GetRefBillOfLadingNumbers?billFilter=';
  getNocIssueBillOfLadingDataApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/GetNocIssueBillOfLadingData?blNumberfilter=';
  getDeliverOrderJourneysDataApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/GetDeliverOrderJourneys?filterValue=';
  getDeliverOrderConsigneesDataApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/GetDeliverOrderConsignees?filterValue=';
  ediTransactions: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/EDITransactions/ListTransactionsAsync';
  ediUpload: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/EDITransactions/PostEDIAsync';
  edidownload: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/EDITransactions/DownloadEDI';

  // Services
  listDeliveryOrder: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/DeliveryOrder/ListDeliveryOrder';
  newDeliveryOrder: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/DeliveryOrder/NewDeliveryOrder';
  openDeliveryOrder: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/DeliveryOrder/OpenDeliveryOrder';
  deleteDeliveryOrder: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/DeliveryOrder/DeleteDeliveryOrder';
  submitDeliveryOrder: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/DeliveryOrder/SubmitDeliveryOrder';
  listFinalOutturnCertificate: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/FinalOutturnCertificate/ListFinalOutturnCertificate';
  newFinalOutturnCertificate: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/FinalOutturnCertificate/NewFinalOutturnCertificate';
  openFinalOutturnCertificate: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/FinalOutturnCertificate/OpenFinalOutturnCertificate';
  getBOLData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/FinalOutturnCertificate/GetBOLData';
  deleteFinalOutCertificate: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/FinalOutturnCertificate/DeleteFinalOutCertificate';
  submitFinalOutturnCertificate: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/FinalOutturnCertificate/SubmitFinalOutturnCertificate';
  listBillOfLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/ListBillOfLading';
  listDoIsuuedBillOfLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/ListDoIsuuedBillOfLading';
  listBillOfLadingByManifestId: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/ListBillOfLadingByManifestId';
  newBillOfLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/NewBillOfLading';
  openBillOfLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/OpenBillOfLading';
  openBillOfLadingHistory: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/OpenBillOfLadingHistory';
  openBillTypeHistory: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/OpenBillTypeHistory';
  openBillOfLadingCancelHistory: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/OpenBillOfLadingCancelHistory';
  submitBillOfLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/SubmitBillOfLading';
  listOfNOCIssueBillOfLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/ListOfNOCIssueBillOfLading';
  listContainer: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ListContainer';
  listContainerByJourneyId: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ListContainerByJourneyId';
  listContainerForHbItems: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ListContainerForHbItems';
  listContainerByHouseBill: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ListContainerByHouseBill';
  listHBContainerItemByHBItemIdPagignation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ListHBContainerItemByHBItemIdPagignation';
  listDischargeContainerJourney: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ListDischargeContainerJourney';
  listFilterContainerDischargeJourney: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ListFilterContainerDischargeJourney';
  getNewContainer: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/GetNewContainer';
  openContainer: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/OpenContainer';
  openContainerCancelHistory: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/OpenContainerCancelHistory';
  deleteContainer: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/DeleteContainer';
  submitContainer: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/SubmitContainer';
  submitDischargeContainer: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/SubmitDischargeContainer';
  listULD: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ULDList';
  uldDataList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ULDDataList';
  getNewContainerItem: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/GetNewContainerItem';
  submitAssociateContainer: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/SubmitAssociateContainer';
  listHBContainerItem: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ListHBContainerItem';
  listHBContainerItemByHBItemId: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/ListHBContainerItemByHBItemId';
  submitRequestForAmendmentContainer: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/SubmitRequestForAmendmentContainer';
  listVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/ListVehicle';
  newVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/NewVehicle';
  openVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/OpenVehicle';
  deleteVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/DeleteVehicle';
  submitVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/SubmitVehicle';
  associateVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/AssociateVehicle';
  newVehicleItem: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/NewVehicleItem';
  listHBVehicleItem: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/ListHBVehicleItem';
  listVehicleByHBItemId: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/ListVehicleByHBItemId';
  listVehiclesByJourneyId: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/ListVehiclesByJourneyId';
  listHBVehicleItemByHBItemIdPagignation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vehicle/ListHBVehicleItemByHBItemIdPagignation';
  listHbItem: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBItem/ListHbItem';
  newHbItem: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBItem/NewHbItem';
  openHBItem: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBItem/OpenHBItem';
  deleteHBItem: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBItem/DeleteHBItem';
  submitHbItem: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBItem/SubmitHbItem';
  getFilterBillOfLandingLineList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBItem/GetFilterBillOfLandingLineList';
  openHBItemCancelHistory: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBItem/OpenHBItemCancelHistory';
  getHbItemAmendment: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBItem/GetHbItemAmendment';
  listHBParty: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBParty/ListHBParty';
  listHBPartyAmendmentSublist: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBParty/ListHBPartyAmendmentSublist';
  newHBParty: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBParty/NewHBParty';
  openHBParty: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBParty/OpenHBParty';
  deleteHBParty: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBParty/DeleteHBParty';
  submitHBParty: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBParty/SubmitHBParty';
  listJourney: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/ListJourney';
  listSupplementaryManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Manifest/ListSupplementaryManifest';
  newJourney: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/NewJourney';
  openJourney: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/OpenJourney';
  getJourneyDetailsData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/GetJourneyDetailsData';
  viewJourney: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/ViewJourney';
  openJourneyDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/OpenJourneyDetails';
  deleteJourney: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/Delete';
  submitJourney: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/SubmitJourney';
  listAssociatedSlotAgents: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/ListAssociatedSlotAgents';
  submitAssociatedSlotAgents: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/SubmitAssociatedSlotAgents';
  getSlotAgents: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/GetSlotAgents';
  openJourneyAdditionalInfo: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/OpenJourneyAdditionalInfo';
  submitJourneyAdditionalInfo: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/SubmitJourneyAdditionalInfo';
  openCancellationHistory: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/OpenCancellationHistory';
  getTranshipmentExportJourneyData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/GetTranshipmentExportJourneyData';
  getFlightInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/GetFlightInformation';
  getRouteNoInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/GetRouteNoInformation';
  listBillOfLadingSublist: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/ListBillOfLadingSublist';
  listManifestCargoData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/ListManifestCargoData/';
  listManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Manifest/ListManifest';
  listManifestByJourneyId: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Manifest/ListManifestByJourneyId';
  newManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Manifest/NewManifest';
  openManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Manifest/OpenManifest';
  deleteManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Manifest/DeleteManifest';
  deleteSuppManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SuppManifest/DeleteSuppManifest';
  submitManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Manifest/SubmitManifest';
  checkManifestValidations: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Manifest/CheckManifestValidations';
  listSuppleManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SuppManifest/ListSupplementaryManifest';
  getBillofLadingCount: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Manifest/GetBillofLadingCount/';
  newSupplementaryManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SuppManifest/NewSupplementaryManifest';
  openSupplementaryManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SuppManifest/OpenSupplementaryManifest';
  submitSupplementaryManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SuppManifest/SubmitSupplementaryManifest';
  listSuppManifestBillOfLadings: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SuppManifest/ListSuppManifestBillOfLadings';
  listShipmentOrderOutwardManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/ListShipmentOrderOutwardManifest';
  newShipmentOrderOutwardManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/NewShipmentOrderOutwardManifest';
  openShipmentOrderOutwardManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/OpenShipmentOrderOutwardManifest';
  listManifestShipmentOrders: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/ListManifestShipmentOrders';
  listShipmentOrdersByManifestId: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/ListShipmentOrdersByManifestId';
  submitShipmentOrderOutwardManifest: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/SubmitShipmentOrderOutwardManifest';
  openManifestCancelHistory: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Manifest/OpenManifestCancelHistory';
  listHBDocument: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBDocument/ListHBDocument';
  newHBDocument: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBDocument/NewHBDocument';
  submitHBDocument: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBDocument/SubmitHBDocument';
  openHBDocument: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBDocument/OpenHBDocument';
  deleteHBDocument: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/HBDocument/DeleteHBDocument';
  listSCLoadUnloadDetailsModel: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCLoadUnloadDetails/ListSCLoadUnloadDetailsModel';
  newSCLoadUnloadDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCLoadUnloadDetails/NewSCLoadUnloadDetails';
  openSCLoadUnloadDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCLoadUnloadDetails/OpenSCLoadUnloadDetails';
  submitSCLoadUnloadDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCLoadUnloadDetails/SubmitSCLoadUnloadDetails';
  getBOLLData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCLoadUnloadDetails/GetBOLLData';

  getCargoDischargListBOLData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCLoadUnloadDetails/GetCargoDischargListBOLData';
  deleteLoadUnloadDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCLoadUnloadDetails/DeleteLoadUnloadDetails';
  ListNOCBillofLadingSublist: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Noc/ListNOCBillofLadingSublist';
  listJourneyNoc: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Noc/ListJourneyNoc';
  listNOCBillofLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Noc/ListNOCBillofLading';
  newNOCBillofLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Noc/NewNOCBillofLading';
  openNOCBillofLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Noc/OpenNOCBillofLading';
  deleteNOCBillofLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Noc/DeleteNOCBillofLading';
  submitNOCBillofLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Noc/SubmitNOCBillofLading';
  listShipmentOrder: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/ListShipmentOrder';
  newShipmentOrder: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/NewShipmentOrder';
  openShipmentOrder: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/OpenShipmentOrder';
  submitShipmentOrder: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/SubmitShipmentOrder';
  getNewHBContainerItem: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/GetNewContainerItemAsync';
  submitContainers: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Container/SubmitAssociateContainersAsync';
  deleteBillOfLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/DeleteBillOfLading';
  listShutOutCertificate: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShutOutCertificate/ListShutOutCertificate';
  newShutOutCertificate: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShutOutCertificate/NewShutOutCertificate';
  openShutOutCertificate: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShutOutCertificate/OpenShutOutCertificate';
  shutOutGetBOLData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShutOutCertificate/GetBOLData';
  deleteShutOutCertificate: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShutOutCertificate/DeleteShutOutCertificate';
  submitShutOutCertificate: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShutOutCertificate/SubmitShutOutCertificate';
  billOfLadingFilterList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/BillOfLadingFilterList';
  getCargoRemarksHistoryData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/GetCargoRemarksHistoryData';
  getBillOfLadingDtlsList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/BillOfLading/GetBillOfLadingDtlsList';
  getPendingServiceReceiptsForDO: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/DeliveryOrder/GetPendingServcRecptsForDeliveryOrder';

  // Carrier Registration
  airCarrierApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/GetAirCarrier?airCarrierNameFilter=';
  filghtinformationApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/GetFlightInformation?flightNoFilter=';
  railCarrierApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/GetRailCarrier?railCarrierNameFilter=';
  routeinformationApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/GetRouteNoInformation?routeNoFilter=';
  landCarrierApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/GetLandCarrier?landCarrierNameFilter=';
  driverinformationApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/GetDriverInformation?driverNameFilter=';
  vehicleinformationApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/GetVehicleInformation?vehicleNumberFilter=';

  // Service
  listAirCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/ListAirCarrier';
  newAirCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/NewAirCarrier';
  openAirCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/OpenAirCarrier';
  openCompleteAirCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/OpenCompleteAirCarrier';
  getAirflightSublist: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/GetAirflightSublist';
  getPilotSubList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/GetPilotSubList';
  deleteAirCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/DeleteAirCarrier';
  submitAirCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/SubmitAirCarrier';
  listAirCarrierFlightInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/ListAirCarrierFlightInformation';
  newAirCarrierFlightInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/NewAirCarrierFlightInformation';
  openAirCarrierFlightInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/OpenAirCarrierFlightInformation';
  deleteAirCarrierFlightInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/DeleteAirCarrierFlightInformation';
  deleteAllAirCarrierFlightInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/DeleteAllAirCarrierFlightInformation';
  submitAirCarrierFlightInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/SubmitAirCarrierFlightInformation';
  listAirCarrierPilotInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/ListAirCarrierPilotInformation';
  newAirCarrierPilotInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/NewAirCarrierPilotInformation';
  openAirCarrierPilotInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/OpenAirCarrierPilotInformation';
  deleteAirCarrierPilotInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/DeleteAirCarrierPilotInformation';
  submitAirCarrierPilotInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/SubmitAirCarrierPilotInformation';
  deleteAllAirCarrierPilotInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/DeleteAllAirCarrierPilotInformation';
  checkFlightNumber: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/CheckFlightNumber';
  checkCarrierName: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/AirCarrier/CheckCarrierName';
  listLandCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/ListLandCarrier';
  newLandCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/NewLandCarrier';
  openLandCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/OpenLandCarrier';
  openCompleteLandCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/OpenCompleteLandCarrier';
  refCarrierVehicleList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/RefCarrierVehicleList';
  refDriverList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/RefDriverList';
  deleteLandCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/DeleteLandCarrier';
  submitLandCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/SubmitLandCarrier';
  listCarrierDriver: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/ListCarrierDriver';
  newCarrierDriver: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/NewCarrierDriver';
  openCarrierDriver: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/OpenCarrierDriver';
  deleteCarrierDriver: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/DeleteCarrierDriver';
  deleteAllCarrierDriver: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/DeleteAllCarrierDriver';
  submitCarrierDriver: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/SubmitCarrierDriver';
  listCarrierVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/ListCarrierVehicle';
  newCarrierVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/NewCarrierVehicle';
  openCarrierVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/OpenCarrierVehicle';
  deleteCarrierVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/DeleteCarrierVehicle';
  deleteAllCarrierVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/DeleteAllCarrierVehicle';
  submitCarrierVehicle: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/SubmitCarrierVehicle';
  checkLandCarrierName: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/LandCarrier/CheckCarrierName';
  listRailCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/ListRailCarrier';
  newRailCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/NewRailCarrier';
  openRailCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/OpenRailCarrier';
  opencompleteRailCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/OpencompleteRailCarrier';
  viewInfotmationList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/ViewInfotmationList';
  viewDriverList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/ViewDriverList';
  deleteRailCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/DeleteRailCarrier';
  submitRailCarrier: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/SubmitRailCarrier';
  listRailCarrierInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/ListRailCarrierInformation';
  newRailCarrierInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/NewRailCarrierInformation';
  openRailCarrierInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/OpenRailCarrierInformation';
  deleteRailCarrierInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/DeleteRailCarrierInformation';
  deleteAllRailCarrierInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/DeleteAllRailCarrierInformation';
  submitRailCarrierInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/SubmitRailCarrierInformation';
  listRailDriverInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/ListRailDriverInformation';
  newRailDriverInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/NewRailDriverInformation';
  openRailDriverInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/OpenRailDriverInformation';
  deleteRailDriverInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/DeleteRailDriverInformation';
  deleteAllRailDriver: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/DeleteAllRailDriver';
  submitRailDriverInformation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/SubmitRailDriverInformation';
  checkRailCarrierName: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/RailCarrier/CheckCarrierName';
  newVessel: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/NewVessel';
  submitVessel: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/SubmitVessel';
  listVessel: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/ListVessel';
  listVesselAmmended: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/ListVesselAmmended';
  openVessel: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/OpenVessel';
  viewVessel: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/ViewVessel';
  openVesselHistory: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/OpenVesselHistory';
  deleteVessel: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/DeleteVessel';
  checkIMONumber: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/CheckIMONumber';
  newSCOwnerDetail: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/NewSCOwnerDetail';
  openSCOwnerDetail: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/OpenSCOwnerDetail';
  submitSCOwnerDetail: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/SubmitSCOwnerDetail';
  newSCCertificates: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/NewSCCertificates';
  openSCCertificates: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/OpenSCCertificates';
  submitSCCertificates: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/SubmitSCCertificates';
  newSCChartererDetail: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/NewSCChartererDetail';
  openSCChartererDetail: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/OpenSCChartererDetail';
  submitSCChartererDetail: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/SubmitSCChartererDetail';
  listVesselDocument: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/ListVesselDocument';
  openVesselDocument: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/OpenVesselDocument';
  submitVesselDocument: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/SubmitVesselDocument';
  deleteVesselDocument: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/DeleteVesselDocument';
  checkShipName: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/CheckShipName';
  fileDownload: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/FileDownload';
  getVesselRemarksHistoryData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Vessel/GetVesselRemarksHistoryData';
  getBLInspectionStatus: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/GetBLInspectionStatus';
  getBLDeclarationStatus: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Journey/GetBLDeclarationStatus';

  // Declaration
  specificationCountry: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetSpecificationCountry?countryNameFilter=';
  getSuretyReferenceDeclarations = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetSuretyReferenceDeclarations?declarationNumber=';
  rulingsTypeApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/GetFilterRulings';
  fuRefTariffItemsApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/GetFuRefTariffItems?tariffItemfilter=';
  refferenceDeclarationApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/ListOfRefferenceDeclaration?filterValue=';
  exemptedDeclarationApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/ListOfExemptedDeclaration?filterValue=';
  functionalUnitReferenceApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetFunctionalUnit?filterValue=';
  getBillOfLading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/GetBillOfLading?billOfLadingNumberFilter=';
  declarationWorkFlowStates: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetDeclarationWorkFlowStates';
  functionalUnitWorkFlowStates: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/GetFunctionalUnitWorkFlowStates';
  getFunctionalUnitItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/GetFunctionalUnitDetails?filterVlaue=';
  checkValuationForm: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/CheckValuationForm';
  getValFormCount: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/GetValFormCount';
  licenseInfoApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetLicenseNumbers?filterValue=';
  getPendingServiceReceipts: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetPendingServiceReceipts';
  getWHRefTariffItemsApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/GetWarehouseRefTariffItems?tariffItemfilter=';
  getSCDRefTariffItemsApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/GetSCDRefTariffItems?tariffItemfilter=';
  openDeclWHFormItemApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/OpenDeclWHFormItem';
  getScDeclarationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetScDeclarationList?filterValue=';
  openDeclSCDItemApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/OpenDeclSCDItem';





  // Service
  fileDownlaod: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/XmlUpload/FileDownlaod';
  xmlFileDownload: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/XmlUpload/FileDownload';
  commonFileDownload: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FileManagement/FileDownload';
  commonTemplateFileDownload: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FileManagement/DownloadTemplateFile';
  commonDeleteUploadDocuments: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FileManagement/DeleteUploadDocuments';
  listDeclarationBillOfLadingLines: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/ListDeclarationBillOfLadingLines';
  newDeclarationBillOfLadingLines: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/NewDeclarationBillOfLadingLines';
  openDeclarationBillOfLadingLines: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/OpenDeclarationBillOfLadingLines';
  DeleteDeclarationBillOfLadingLines: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DeleteDeclarationBillOfLadingLines';
  submitDeclarationBillOfLadingLines: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SubmitDeclarationBillOfLadingLines';
  getBillOfLadingList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetBillOfLadingList';
  openDeclarationBillOfLadingDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/OpenDeclarationBillOfLadingDetails';
  openShipmentOrderDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/OpenShipmentOrderDetails';
  listDecShipmentOrderOutwardManifest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/ListShipmentOrderOutwardManifest';
  shipmentOrderFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/ShipmentOrderFilterList';
  submitDeclarationShipmentNote: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/SubmitDeclarationShipmentNote';
  openHbPartyDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/OpenHbPartyDetails';
  getHbContainerList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetHbContainerList';
  decViewContainer: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/ViewContainer';
  declarationRulingList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DeclarationRulingList';
  newDeclarationRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/NewDeclarationRuling';
  openDeclarationRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/OpenDeclarationRuling';
  deleteDeclarationRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DeleteDeclarationRuling';
  submitDeclarationRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SubmitDeclarationRuling';
  declarationRulingFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DeclarationRulingFilterList';
  declarationCancellationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationCancellation/DeclarationCancellationList';
  decNccCancellationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/DeclarationCancellationList';
  newDeclarationCancellation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationCancellation/NewDeclarationCancellation';
  openDeclarationCancellation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationCancellation/OpenDeclarationCancellation';
  deleteDeclarationCancellation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationCancellation/DeleteDeclarationCancellation';
  submitDeclarationCancellation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationCancellation/SubmitDeclarationCancellation';
  declarationCancellationDocumentList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationCancellation/DeclarationCancellationDocumentList';
  newDeclarationCancellationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationCancellation/NewDeclarationCancellationDocument';
  openDeclarationCancellationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationCancellation/OpenDeclarationCancellationDocument';
  deleteDeclarationCancellationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationCancellation/DeleteDeclarationCancellationDocument';
  submitDeclarationCancellationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationCancellation/SubmitDeclarationCancellationDocument';
  declarationDelegationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/DeclarationDelegationList';
  declarationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/DeclarationList';
  newDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/NewDeclaration';
  openDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/OpenDeclaration';
  openDutyDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/OpenDutyDeclaration';
  updateDutyCalculatedtoCreatedState: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/UpdateDutyCalculatedtoCreatedState';
  deleteDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/DeleteDeclaration';
  deleteDeclarationData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/DeleteDeclarationData';
  submitDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/SubmitDeclaration';
  getLpcoTypes: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetLpcoTypes';
  getTariffSpecifications: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetTariffSpecifications';
  submitDutyDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/SubmitDutyDeclaration';
  listOfDeclarationDelegateAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/ListOfDeclarationDelegateAssociation';
  declarationDelegateFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/DeclarationDelegateFilterList';
  submitDeclarationDelegate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/SubmitDeclarationDelegate';
  getAssociatedLicensedCarriersDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetAssociatedLicensedCarriersDetails';
  submitDecLicensedCarriersDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/SubmitDecLicensedCarriersDetails';
  getReferenceDeclarationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetReferenceDeclarationList';
  getWarehouseReferenceList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetWarehouseReferenceList';
  getCoreModulesStatus: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetCoreModulesStatus';
  getAssociatedDeclarationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetAssociatedDeclarationList';
  getAssociatedWarehouseList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetAssociatedWarehouseList';
  openDeclarationConsigneeInfo: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/OpenDeclarationConsigneeInfo';
  submitDeclarationConsigneeInfo: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/SubmitDeclarationConsigneeInfo';
  openDeclarationSenderReceiverInfo: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/OpenDeclarationSenderReceiverInfo';
  submitDeclarationSenderReceiverInfo: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/SubmitDeclarationSenderReceiverInfo';
  detailedSpecificationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DetailedSpecificationList';
  submitDetailedSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SubmitDetailedSpecification';
  dRDeclarationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/DRDeclarationList';
  newDRDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/NewDRDeclaration';
  openDRDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/OpenDRDeclaration';
  deleteDRDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/DeleteDRDeclaration';
  submitDRDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/SubmitDRDeclaration';
  dRInvoiceItemList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/DRInvoiceItemList';
  newDRInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/NewDRInvoiceItem';
  openDRInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/OpenDRInvoiceItem';
  deleteDRInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/DeleteDRInvoiceItem';
  submitDRInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/SubmitDRInvoiceItem';
  dRInvoiceItemFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/DRInvoiceItemFilterList';
  newDRDeclarationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/NewDRDeclarationDocument';
  submitDRDeclarationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/SubmitDRDeclarationDocument';
  openDRDeclarationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DRDeclaration/OpenDRDeclarationDocument';
  declarationExemptionList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DeclarationExemptionList';
  declarationExemptionFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DeclarationExemptionFilterList';
  newDeclarationExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/NewDeclarationExemption';
  openDeclarationExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/OpenDeclarationExemption';
  deleteDeclarationExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DeleteDeclarationExemption';
  submitDeclarationExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SubmitDeclarationExemption';
  newFunctionalUnit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/NewFunctionalUnit';
  listFilterFunctionalUnit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/ListFilterFunctionalUnit';
  openFunctionalUnit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/OpenFunctionalUnit';
  submitFunctionalUnit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/SubmitFunctionalUnit';
  deleteFunctionalUnit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/DeleteFunctionalUnit';
  checkBusinessValidation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/CheckBusinessValidation';
  getFunctionalUnitList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/GetFunctionalUnitList';
  newFunctionalUnitReferenceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/NewFunctionalUnitReferenceItem';
  listFunctionalUnitReferenceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/ListFunctionalUnitReferenceItem';
  openFunctionalUnitReferenceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/OpenFunctionalUnitReferenceItem';
  submitFunctionalUnitReferenceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/SubmitFunctionalUnitReferenceItem';
  deleteFunctionalUnitReferenceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/DeleteFunctionalUnitReferenceItem';
  newFunctionalUnitAmendment: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/NewFunctionalUnitAmendment';
  openFunctionalUnitAmendment: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/OpenFunctionalUnitAmendment';
  submitFunctionalUnitAmendment: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/SubmitFunctionalUnitAmendment';
  getAssociatedFunctionalUnitCustomStation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/GetAssociatedFunctionalUnitCustomStation';
  submitFunctionalUnitCustomStations: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/SubmitFunctionalUnitCustomStations';
  invoiceItemList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/InvoiceItemList';
  listInvoiceItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/ListInvoiceItems';
  newInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/NewInvoiceItem';
  newSCDInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/NewSCDInvoiceItem';
  getInvoiceTariffItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/GetInvoiceItem?tariffItemfilter=';
  openInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/OpenInvoiceItem';
  deleteInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DeleteInvoiceItem';
  submitInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SubmitInvoiceItem';
  invoiceItemFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/InvoiceItemFilterList';
  scdinvoiceItemFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SCDInvoiceItemFilterList';
  getAssociatedInvoiceItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/GetAssociatedInvoiceItems';
  invoiceListItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/InvoiceListItems';
  submitAssoicatedInvoiceItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SubmitAssoicatedInvoiceItems';
  inwardInvoiceItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/InwardInvoiceItems';
  associatedRwaItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/AssociatedRwaItems';
  getReferenceDeclarationRawItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/GetReferenceDeclarationRawItems';
  checkingRawInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/CheckingRawInvoiceItem';
  checkPetroleumItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/CheckPetroleumItems';
  submitAssociateRawInvoiceItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SubmitAssociateRawInvoiceItems';
  getPccItemsCount: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/GetPccItemsCount';
  invoiceList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Invoice/InvoiceList';
  newInvoice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Invoice/NewInvoice';
  openInvoice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Invoice/OpenInvoice';
  deleteInvoice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Invoice/DeleteInvoice';
  submitInvoice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Invoice/SubmitInvoice';
  getExchangeRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Invoice/GetExchangeRate';
  submitAssoicatedInvoice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Invoice/SubmitAssoicatedInvoice';
  invoicesFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Invoice/InvoicesFilterList';
  getReferenceDeclarationInvoices: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Invoice/GetReferenceDeclarationInvoices';
  getInvioceItemsCount: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Invoice/GetInvioceItemsCount';
  paCurrencyDetailsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/PACurrencyDetailsList';
  newPACurrencyDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/NewPACurrencyDetails';
  openPACurrencyDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/OpenPACurrencyDetails';
  deletePACurrencyDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/DeletePACurrencyDetails';
  submitPACurrencyDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/SubmitPACurrencyDetails';
  paCurrencyDetailsFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/PACurrencyDetailsFilterList';
  paGoodsDetailsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/PAGoodsDetailsList';
  newPAGoodsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/NewPAGoodsDetails';
  openPAGoodsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/OpenPAGoodsDetails';
  deletePAGoodsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/DeletePAGoodsDetails';
  submitPAGoodsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/SubmitPAGoodsDetails';
  submitPAStates: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/SubmitPAStates';
  paGoodsDetailsFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/PAGoodsDetailsFilterList';
  paUploadDocumentList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/PaUploadDocumentList';
  newPaUploadDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/NewPaUploadDocument';
  submitPaUploadDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/SubmitPaUploadDocument';
  openPaUploadDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/OpenPaUploadDocument';
  passengerAssessmentApprovalList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/PassengerAssessmentApprovalList';
  passengerAssessmentGoodsApprovalList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/PassengerAssessmentGoodsApprovalList';
  newPassengerAssessmentApproval: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/NewPassengerAssessmentApproval';
  openPassengerAssessmentApproval: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/OpenPassengerAssessmentApproval';
  submitPassengerAssessmentApproval: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/SubmitPassengerAssessmentApproval';
  submitPAAssociatedApprovalDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/SubmitPAAssociatedApprovalDetails';
  deletePAApprovalsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/DeletePAApprovalsDetails';
  passengerAssessmentList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/PassengerAssessmentList';
  passengerAssessmentHistoryList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/PassengerAssessmentHistoryList';
  newPassengerAssessment: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/NewPassengerAssessment';
  openPassengerAssessment: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/OpenPassengerAssessment';
  submitPassengerAssessment: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/SubmitPassengerAssessment';
  passessmentsHistoryFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PassengerAssessment/PAssessmentsHistoryFilterList';
  permitList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/PermitList';
  newPermit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/NewPermit';
  openPemit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/OpenPemit';
  deletePermit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DeletePermit';
  submitPermit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SubmitPermit';
  applicablePermitList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/ApplicablePermitList';
  declarationPermitDocumentList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/PermitDocumentList';
  newDeclarationPermitDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/NewPermitDocument';
  openDeclarationPermitDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/OpenDeclarationPermitDocument';
  deleteDeclarationPermitDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/DeletePermitDocument';
  submitDeclarationPermitDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SubmitPermitDocument';
  declarationDocumentList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/DeclarationDocumentList';
  newDeclarationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/NewDeclarationDocument';
  openDeclarationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/OpenDeclarationDocument';
  deleteDeclarationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/DeleteDeclarationDocument';
  submitDeclarationDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/SubmitDeclarationDocument';
  valueDeclarationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ValueDeclaration/ValueDeclarationList';
  newValueDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ValueDeclaration/NewValueDeclaration';
  openValueDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ValueDeclaration/OpenValueDeclaration';
  submitValueDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ValueDeclaration/SubmitValueDeclaration';
  newDeclarationXmlFileUpload: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/XmlUpload/NewDeclarationXmlFileUpload';
  declarationXmlUploadFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/XmlUpload/DeclarationXmlUploadFilterList';
  submitDeclarationXmlUploadFile: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/XmlUpload/SubmitDeclarationXmlUploadFile';
  submitCargoXmlUploadFile: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/CargoXmlUpload/SubmitCargoXmlUploadFile';
  CargoXmlTempletDownloadFile: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/CargoXmlUpload/FileDownlaod';
  xmlDownloadTemplate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/XmlUpload/DownloadTemplate';
  associateList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/AssociateList';
  getDeclarationRemarksHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetDeclarationRemarksHistoryData';
  getFunctionalUnitDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FunctionalUnit/GetFunctionalUnitDetails';
  getApplicantLicenseData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetApplicantLicenseInfoData';
  ListOfTransitLicensesData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/ListOfTransitLicenses?filterValue=';
  getInvoiceItemsDutyDetailsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/GetInvoiceItemsDutyDetailsList';

  listDeclWarehouseForm: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/ListDeclWarehouseForm';
  submitDeclWarehouseForm: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/SubmitDeclWarehouseForm';
  declWarehouseFormFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/DeclWarehouseFormFilterList';
  sezDeclWHFormFilterList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/DeclSezWhFormFilterList';
  getJourneyList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/GetJourneyList';
  getBillOfLadingsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DeclarationProcess/BillOfLadingsListByJourney';
  associatedPermitsForATACarnet: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/AssociatedPermitsForATACarnet';
  applicablePermitListForATACarnet: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/ApplicablePermitListForATACarnet';
  submitPermitForATACarnet: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/SubmitPermitForATACarnet';

  // Simplified Courier Declaration
  scDeclarationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SCDeclarations/SCDeclarationList';
  newscDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SCDeclarations/NewSCDeclaration';
  openscDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SCDeclarations/OpenSCDeclaration';
  deletescDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SCDeclarations/DeleteSCDeclaration';
  submitscDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SCDeclarations/SubmitSCDeclaration';
  getCourierBlLines: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SCDeclarations/GetCourierBlLines?filterValue=';

  // Inspection
  inspectionRefNumberApiUrl: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/GetRefrenceNumber?refNumberFilter=';
  sampleRequestRefNumberApiUrl: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/GetSampleRequestReferenceNumber?refNumberFilter=';
  inspectionWFStatesApiUrl: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/GetInspectionWFStates';
  sampleRequestWFStatesApiUrl: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/GetSampleRequestWFStates';
  getGovBusinessStackholderApiUrl: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/GetGovBusinessStackHolders';
  getGovBusinessStackholderForRulingApiUrl: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl +
    '/api/SampleRequest/GetGovBusinessStackHolders?filterValue=';

  newSampleRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/NewSampleRequest';
  getInspectionInstructionStep: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionReport/GetInspectionInstructionStep';
  openSampleRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/OpenSampleRequest';
  openInspectionReport: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionReport/OpenInspectionReport';
  listOfSampleRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/ListOfSampleRequest';
  submitSampleRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/SubmitSampleRequest';
  listOfHBItemByBOLId: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/ListOfHBItemByBOLId';
  deleteSampleRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/DeleteSampleRequest';
  openSampleRequestSuretyTransaction: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/OpenSuretyTransactionData';

  // server
  getDeclarationStatus: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/GetDeclarationStatus';
  listAssociateRulings: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/ListAssociateRulings';
  listAssociateBLLines: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/ListAssociateBLLines';
  openDetailedSpecification: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/OpenDetailedSpecification';
  listExemption: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/ListExemption';
  openPermit: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/OpenPermit';
  listDelegation: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/ListDelegation';
  newDelegation: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/NewDelegation';
  openDelegation: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/OpenDelegation';
  submitDelegation: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/SubmitDelegation';
  invoiceItemsdata: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/InvoiceItemsdata';
  openInvoiceReferenceDetails: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/OpenInvoiceReferenceDetails';
  inspInvoiceItemFilterList: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/InvoiceItemFilterList';
  openInvoiceItems: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/OpenInvoiceItems';
  openReferenceDetails: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/OpenReferenceDetails';
  getBillOfLadingData: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/GetBillOfLadingData';
  openBillOfLadingReferenceDetails: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/OpenBillOfLadingReferenceDetails';
  submitSeizeDetails: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/ReferenceDetails/SubmitSeizeDetails';
  getTariffItem: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/TariffItem/GetTariffItem';
  newInspectionDocuments: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionDocument/NewInspectionDocuments';
  submitInspectionDocuments: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionDocument/SubmitInspectionDocuments';
  openInspectionDocuments: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionDocument/OpenInspectionDocuments';
  listInspectionDocuments: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionDocument/ListInspectionDocuments';
  listInspectionRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/ListInspectionRequest';
  listFilterInspectionRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/ListFilterInspectionRequest';
  openInspectionRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/OpenInspectionRequest';
  submitInspectionRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/SubmitInspectionRequest';
  newInspectionRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/NewInspectionRequest';
  deleteInspectionRequest: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/DeleteInspectionRequest';
  getTransactionHistory: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/GetTransactionHistory';
  inspectionMemberList: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/InspectionMemberList';
  openSampleRequestForNCCOfficer: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/OpenSampleRequestForNCCOfficer';
  submitSampleRequestDetails: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/SubmitSampleRequestDetails';
  newSampleRequestDetails: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/NewSampleRequestDetails';
  listJitMembers: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/ListJitMembers';
  associatedJitMembers: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/AssociatedJitMembers';
  updateAssociatedJITMembers: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/UpdateAssociatedJITMembers';
  listInspectionReport: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionReport/ListInspectionReport';
  newInspectionMember: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionMember/NewInspectionMember';
  newInspectionReportInstructionStep: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionReport/NewInspectionReportInstructionStep';
  submitInspectionInstructionstep: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionReport/SubmitInspectionInstructionStep';
  submitInspectionReport: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionReport/SubmitInspectionReport';
  deleteInspectionReport: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionReport/DeleteInspectionReport';
  inspListJitMembers: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionMember/ListJitMembers';
  getInspectionMember: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionMember/GetInspectionMember';
  submitInspectionMember: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionMember/SubmitInspectionMember';
  listFilterInspectionRequestInstruction: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequestInstruction/ListFilterInspectionRequestInstruction';
  newInspectionRequestInstruction: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequestInstruction/NewInspectionRequestInstruction';
  submitInspectionRequestInstruction: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequestInstruction/SubmitInspectionRequestInstruction';
  checkSuretyTransaction: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/SampleRequest/CheckSuretyTransaction';
  getInspectionRemarksHistoryData: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/GetInspectionRemarksHistoryData';
  refferenceDeclarationForAppointmentApiUrl: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionRequest/ListOfRefferenceDeclarationsForAppointment?filterValue=';

  // Inspection Instructions
  listInstructionSteps: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InstructionStep/ListInstructionSteps';
  newInstructionStep: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InstructionStep/NewInstructionStep';
  openInstructionStep: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InstructionStep/OpenInstructionStep';
  submitInstructionStep: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InstructionStep/SubmitInstructionStep';
  deleteInstructionStep: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InstructionStep/DeleteInstructionStep';
  listInspectionInstruction: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionInstruction/ListInspectionInstruction';
  newInspectionInstruction: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionInstruction/NewInspectionInstruction';
  openInspectionInstruction: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionInstruction/OpenInspectionInstruction';
  submitInspectionInstruction: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionInstruction/SubmitInspectionInstruction';
  deleteInspectionInstruction: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionInstruction/DeleteInspectionInstruction';
  getGovtRegOrganization: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionInstruction/GetGovtRegOrganization?filterValue=';
  getAssociatedInstructionSteps: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionInstruction/GetAssociatedInspectionInstructionSteps';
  getInstructionStepsToAssociate: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InstructionStep/GetInstructionStepsToAssociate';
  submitInspectionInstructionSteps: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InspectionInstruction/SubmitInspectionInstructionSteps';
  getAssociatedInstructionStepDocuments: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InstructionStep/GetAssociatedInstructionStepDocuments';
  getInstrDocumentTypesToAssociate: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InstructionStep/GetInstrDocumentTypesToAssociate';
  submitInstructionStepDocumentTypes: any = environment.appSettings.apiEndpoints.inspectionApiBaseUrl + '/api/InstructionStep/SubmitInstructionStepDocumentTypes';

  // Assessment
  priorityApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/GetPriority?moniterAssessmentNameFilter=';
  assessmentRefNumberApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/GetRefrenceNumber?refNumberFilter=';
  assessmentWorkFlowStates: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/GetAssessmentRequestWfStates';
  // Services
  listAssessedItemsAmended: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItemsAmended/ListAssessedItemsAmended';
  openAssessedItemsAmended: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItemsAmended/Open';
  openDocumentReviewRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DocumentReview/OpenDocumentReviewRequest';
  newReviewRequestDocuments: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DocumentReview/NewReviewRequestDocuments';
  submitDocumentReviewRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DocumentReview/SubmitDocumentReviewRequest';
  submitAssociateReviewDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DocumentReview/SubmitAssociateReviewDocument';
  getDocuments: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DocumentReview/GetDocuments';
  newAdditionalReviewDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DocumentReview/NewAdditionalReviewDocument';
  listAdditionalReviewDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DocumentReview/ListAdditionalReviewDocument';
  submitReviewRequestDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DocumentReview/SubmitReviewRequestDocument';
  listAssessedNote: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedNote/ListAssessedNote';
  newAsmntBillofLadingLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentBillofLadingLine/NewAsmntBillofLadingLine';
  getBLlines: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentBillofLadingLine/GetBLlines';
  assessmentRulingList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/AssessmentRulingList';
  newAssessmentRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/NewAssessmentRuling';
  openAssessmentRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/OpenAssessmentRuling';
  deleteAssessmentRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/DeleteAssessmentRuling';
  submitAssessmentRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/SubmitAssessmentRuling';
  getAssociatedRulings: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/GetAssociatedRulings';
  getRulingDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/GetRulingDetails';
  assessmentExemptionList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentExemption/AssessmentExemptionList';
  newAssessmentExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentExemption/NewAssessmentExemption';
  openAssessmentExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentExemption/OpenAssessmentExemption';
  deleteAssessmentExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentExemption/DeleteAssessmentExemption';
  submitAssessmentExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentExemption/SubmitAssessmentExemption';
  getAssociatedExemptions: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentExemption/GetAssociatedExemptions';
  openAssessedItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/OpenAssessedItem';
  deleteAssessmentItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/DeleteAssessmentItem';
  submitAssessedItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/SubmitAssessedItem';
  newAssessedItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/NewAssessedItem';
  openDeclarationItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/OpenDeclarationItem';
  getTariffItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/GetTariffItemDetails';
  submitDetainItemData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/SubmitDetainItemData';
  newDetainItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/NewDetainItem';
  openAssessedDetainItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessedItem/OpenAssessedDetainItem';
  listPermitAssessment: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPermit/ListPermitAssessment';
  newAssessmentPermit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPermit/NewAssessmentPermit';
  openAssessmentPermit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPermit/OpenAssessmentPermit';
  submitAssessedPermit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPermit/SubmitAssessedPermit';
  newPermitDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPermit/NewPermitDocument';
  openPermitDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPermit/OpenPermitDocument';
  openInvItemPermitDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InvoiceItem/OpenPermitDocument';
  deletePermitDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPermit/DeletePermitDocument';
  submitPermitDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPermit/SubmitPermitDocument';
  permitDocumentList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPermit/PermitDocumentList';
  getPricingAssessmentRequestsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/AssessmentPricingDatabase/GetPricingAssessmentRequestsList';
  listAssessmentNote: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentNote/ListAssessmentNote';
  listClassification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ReferClassificationValuation/ListClassification';
  newClassification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ReferClassificationValuation/NewClassification';
  submitClassification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ReferClassificationValuation/SubmitClassification';
  openClassification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ReferClassificationValuation/OpenClassification';
  listRiskTarget: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRiskTargets/ListRiskTarget';
  voluntaryList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/VoluntaryList';
  newVoluntary: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/NewVoluntary';
  openVoluntary: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/OpenVoluntary';
  deleteVoluntary: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/DeleteVoluntary';
  submitVoluntary: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/SubmitVoluntary';
  declarationListForManulaAssesmentReq: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/AssessmentRequest/DeclarationListForManulaAssesmentReq';
  listAssessementRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/ListAssessementRequest';
  filterListAssessementRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/FilterListAssessementRequest';
  openAssessmentRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/OpenAssessmentRequest';
  submitAssessmentRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/SubmitAssessmentRequest';
  newManualAssessmentRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/NewManualAssessmentRequest';
  submitManualAssessmentRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/SubmitManualAssessmentRequest';
  getMoniterAssessmentRequestsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/GetMoniterAssessmentRequestsList';
  verficationOfficerListForManualAssesment: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/AssessmentRequest/VerficationOfficerListForManualAssesment';
  assignSelfAssessmentReq: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/AssignSelfAssessmentReq';
  completeAssessmentRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/CompleteAssessmentRequest';
  filterAssessementRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/FilterAssessementRequest';
  getHistory: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/GetHistory';
  getAssessedInvoiceList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/GetAssessedInvoiceList';
  listAssessedItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/ListAssessedItems';
  listAssessedItemsbyInvoiceId: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/ListAssessedItemsbyInvoiceId';
  assignRequests: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/AssignRequests';
  listAssessmentPricingDatabase: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/ListAssessmentPricingDatabase';
  newAssessmentPricingDatabase: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/NewAssessmentPricingDatabase';
  openAssessmentPricingDatabase: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/OpenAssessmentPricingDatabase';
  deleteAssessmentPricingDatabase: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/AssessmentPricingDatabase/DeleteAssessmentPricingDatabase';
  submitAssessmentPricingDatabase: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/AssessmentPricingDatabase/SubmitAssessmentPricingDatabase';
  getItemCodes: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/GetItemCodes';
  getAssessmentPricingTotalRecords: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/AssessmentPricingDatabase/GetAssessmentPricingTotalRecords';
  submitExcelUploadData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/SubmitExcelUploadData';
  downloadTemplate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/DownloadTemplate';
  exportDatatoExcelAndDonwload: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/ExportDatatoExcelAndDonwload';
  exportErrorDatatoExcel: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/ExportErrorDatatoExcel';
  exceluploadErrorList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/ExceluploadErrorList';
  getPriceDataList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/GetPriceDataList';
  getMotorVehiclePriceData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentPricingDatabase/GetMotorVehiclePriceData';
  submitAsmntBillofLadingLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentBillofLadingLine/SubmitAsmntBillofLadingLine';
  openSocUploadDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/PassengerAssessment/OpenSocUploadDocument';
  getAssessmentRemarksHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/GetAssessmentRemarksHistoryData';



  // Payment
  paymentWFStatesApiUrl: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/GetPaymentWFStates';
  getPassengerAssessmentsApiUrl: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl +
    '/api/PassengerAssessmentRefund/GetPassengerAssessments?passengerFilter=';
  // Services
  listRefundForPassengerAssessment: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl +
    '/api/PassengerAssessmentRefund/ListRefundForPassengerAssessment';
  newRefundForPassengerAssessment: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerAssessmentRefund/NewRefundForPassengerAssessment';
  submitRefundForPassengerAssessment: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl +
    '/api/PassengerAssessmentRefund/SubmitRefundForPassengerAssessment';
  openRefundForPassengerAssessment: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl +
    '/api/PassengerAssessmentRefund/OpenRefundForPassengerAssessment';
  getPassengerRefundGoodsDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerAssessmentRefund/GetPassengerRefundGoodsDetails';
  getPassengerGoodsDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerAssessmentRefund/GetPassengerGoodsDetails';
  submitRefundPassengerGoodsDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerAssessmentRefund/SubmitRefundPassengerGoodsDetails';
  deletePARefunds: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerAssessmentRefund/DeletePARefunds';
  openPaymentReceipt: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentReceipt/OpenPaymentReceipt';
  listPaymentReceipt: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentReceipt/ListPaymentReceipt';
  listPaymentTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentTransaction/ListPaymentTransaction';
  newPaymentTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentTransaction/NewPaymentTransaction';
  openPaymentTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentTransaction/OpenPaymentTransaction';
  submitPaymentTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentTransaction/SubmitPaymentTransaction';
  deletePaymentTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentTransaction/DeletePaymentTransaction';
  paymentTransactionModelListByPaymentId: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/PaymentTransactionModelListByPaymentId';
  newRefundPaymentTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentTransaction/NewRefundPaymentTransaction';
  openRefundPaymentTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentTransaction/OpenRefundPaymentTransaction';
  submitRefundPaymentTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentTransaction/SubmitRefundPaymentTransaction';
  deleteRefundPaymentTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentTransaction/DeleteRefundPaymentTransaction';
  refundPaymentTranListByPaymentId: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/RefundPaymentTranListByPaymentId';
  listPayment: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/ListPayment';
  newPayment: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/NewPayment';
  openPayment: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/OpenPayment';
  submitPayment: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/SubmitPayment';
  deletePayment: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/DeletePayment';
  paymentBillDetailListByPaymentId: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/PaymentBillDetailListByPaymentId';
  paymentVoucherDetailsListByPaymentId: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/PaymentVoucherDetailsListByPaymentId';
  getDebitAccountBalance: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/DebitAccount/GetDebitAccountBalance';
  getBillsData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentBillDetail/GetBillsData';
  listBill: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentBillDetail/ListBill';
  openBillDetail: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentBillDetail/OpenBillDetail';
  openBillDetailData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentBillDetail/OpenBillDetailsData';
  newPaymentBillDetail: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentBillDetail/NewPaymentBillDetail';
  openPaymentBillDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentBillDetail/OpenPaymentBillDetails';
  submitPaymentBillDetail: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentBillDetail/SubmitPaymentBillDetail';
  listVoucherDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentVoucherDetails/ListVoucherDetails';
  openVoucherDetail: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentVoucherDetails/OpenVoucherDetail';
  newPaymentVoucherDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentVoucherDetails/NewPaymentVoucherDetails';
  submitPaymentVoucherDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentVoucherDetails/SubmitPaymentVoucherDetails';
  openPaymentVoucherDetail: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentVoucherDetails/OpenPaymentVoucherDetail';
  getFilteredVoucherDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentVoucherDetails/GetFilteredVoucherDetails';
  getPaymentRemarksHistoryData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PaymentProcess/GetPaymentRemarksHistoryData';

  // Accounting
  accountingWFStatesApiUrl: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/AccountingConfiguration/GetAccountingWFStates';
  getRevenueSourceGroups: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/GetRevenueSourceGroups';
  getRevenueSourceData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/GetRevenueSourceData';
  getRevenueSourceTypes: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/GetRevenueSourceTypes?sourceNameFilter=';
  // Services
  listCollectionAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/CollectionAccount/ListCollectionAccount';
  newCollectionAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/CollectionAccount/NewCollectionAccount';
  openCollectionAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/CollectionAccount/OpenCollectionAccount';
  deleteCollectionAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/CollectionAccount/DeleteCollectionAccount';
  submitCollectionAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/CollectionAccount/SubmitCollectionAccount';
  getCollectionAccountTransactions: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/CollectionAccount/GetCollectionAccountTransactions';
  checkCollectionAccountNumber: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/CollectionAccount/CheckCollectionAccountNumber';
  CheckCollectionTransactionType: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/CollectionAccount/CheckCollectionTransactionType';
  checkCollectionCreation: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/CollectionAccount/CheckCollectionCreation';
  listDebitAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/DebitAccount/ListDebitAccount';
  listDebitTransactions: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/DebitAccount/ListDebitTransactions';
  listRefundAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerRefundAccount/ListRefundAccount';
  newRefundAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerRefundAccount/NewRefundAccount';
  openRefundAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerRefundAccount/OpenRefundAccount';
  deleteRefundAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerRefundAccount/DeleteRefundAccount';
  submitRefundAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerRefundAccount/SubmitRefundAccount';
  getRefundPaymentTransactions: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerRefundAccount/GetRefundPaymentTransactions';
  checkActivatedRefundAccountCount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/PassengerRefundAccount/CheckActivatedRefundAccountCount';
  listAccountingConfiguration: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/AccountingConfiguration/ListAccountingConfiguration';
  newAccountingConfiguration: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/AccountingConfiguration/NewAccountingConfiguration';
  openAccountingConfiguration: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/AccountingConfiguration/OpenAccountingConfiguration';
  deleteAccountingConfiguration: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/AccountingConfiguration/DeleteAccountingConfiguration';
  submitAccountingConfiguration: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/AccountingConfiguration/SubmitAccountingConfiguration';
  listRefundPaymentAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundPayment/ListRefundPaymentAccount';
  newRefundPaymentAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundPayment/NewRefundPaymentAccount';
  openRefundPaymentAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundPayment/OpenRefundPaymentAccount';
  deleteRefundPaymentAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundPayment/DeleteRefundPaymentAccount';
  submitRefundPaymentAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundPayment/SubmitRefundPaymentAccount';
  listRevenueAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueAccount/ListRevenueAccount';
  newRevenueAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueAccount/NewRevenueAccount';
  openRevenueAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueAccount/OpenRevenueAccount';
  deleteRevenueAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueAccount/DeleteRevenueAccount';
  submitRevenueAccount: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueAccount/SubmitRevenueAccount';
  getAssociatedSourceConfigData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceConfiguration/GetAssociatedSourceConfigData';
  getRevenueSourceConfigData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceConfiguration/GetRevenueSourceConfigData';
  updateAssociatedRevenueSourceConfig: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl +
    '/api/RevenueSourceConfiguration/UpdateAssociatedRevenueSourceConfig';
  getRevenueAccountPaymentDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueAccount/GetRevenueAccountPaymentDetails';
  checkCustomRevenueCodeAsync: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueAccount/CheckCustomRevenueCode';
  newRevenueTransactionType: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceConfiguration/NewRevenueTransactionType';
  openRevenueTransactionType: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceConfiguration/OpenRevenueTransactionType';
  submitRevenueTransactionType: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceConfiguration/SubmitRevenueTransactionType';
  listPaymentChannelTypes: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/AccountingConfiguration/ListPaymentChannelTypes';
  listRevenueSourceConfiguration: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceConfiguration/ListRevenueSourceConfiguration';
  newRevenueSourceConfiguration: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceConfiguration/NewRevenueSourceConfiguration';
  openRevenueSourceConfiguration: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceConfiguration/OpenRevenueSourceConfiguration';
  deleteRevenueSourceConfiguration: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl +
    '/api/RevenueSourceConfiguration/DeleteRevenueSourceConfiguration';
  submitRevenueSourceConfiguration: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl +
    '/api/RevenueSourceConfiguration/SubmitRevenueSourceConfiguration';
  listRevenueSourceGroup: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/ListRevenueSourceGroup';
  newRevenueSourceGroup: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/NewRevenueSourceGroup';
  openRevenueSourceGroup: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/OpenRevenueSourceGroup';
  deleteRevenueSourceGroup: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/DeleteRevenueSourceGroup';
  submitRevenueSourceGroup: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/SubmitRevenueSourceGroup';
  newAssociateRevenueSourceGroup: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/NewAssociateRevenueSourceGroup';
  openAssociateRevenueSourceGroup: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/OpenAssociateRevenueSourceGroup';
  deleteAssociateRevenueSourceGroup: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/DeleteAssociateRevenueSourceGroup';
  submitAssociateRevenueSourceGroup: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/SubmitAssociateRevenueSourceGroup';
  getRevenueSources: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/GetRevenueSources';
  checkGroupName: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueSourceGroup/CheckGroupName';
  getAccountingRemarksHistoryData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/AccountingConfiguration/GetAccountingRemarksHistoryData';
  getPaymentBillDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RevenueAccount/GetPaymentBillDetails';


  // Surety
  suretyWFStatesApiUrl: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/GetSuretyWFStates';
  // Service
  newSuretyTransactionDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransactionDetails/NewSuretyTransactionDetails';
  submitSuretyTransactionDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransactionDetails/SubmitSuretyTransactionDetails';
  listOfSuretyTransactionDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransactionDetails/ListOfSuretyTransactionDetails';
  openSuretyTransactionDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransactionDetails/OpenSuretyTransactionDetails';
  openTransactionDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransactionDetails/OpenTransactionDetails';
  listSuretyTransactionDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransactionDetails/ListSuretyTransactionDetails';
  listOfSuretyTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransaction/ListOfSuretyTransaction';
  getSuretyTransactions: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransaction/GetSuretyTransactions';
  listOfSuretyTransactionData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransaction/ListOfSuretyTransactionData';
  newSuretyTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransaction/NewSuretyTransaction';
  submitSuretyTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransaction/SubmitSuretyTransaction';
  openSuretyTransaction: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransaction/OpenSuretyTransaction';
  openSuretyTransactionData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyTransaction/OpenSuretyTransactionData';
  listFormatCRequest: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/FormatCRequest/ListFormatCRequest';
  newFormatCRequest: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/FormatCRequest/NewFormatCRequest';
  openFormatCRequest: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/FormatCRequest/OpenFormatCRequest';
  submitFormatCRequest: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/FormatCRequest/SubmitFormatCRequest';
  deleteFormatCRequest: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/FormatCRequest/DeleteFormatCRequest';
  listFormatCRequestSurety: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/FormatCRequestSurety/ListFormatCRequestSurety';
  suretyListFormatCRequest: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/FormatCRequestSurety/ListFormatCRequest';
  newFormatCRequestSurety: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/FormatCRequestSurety/NewFormatCRequestSurety';
  submitFormatCRequestSurety: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/FormatCRequestSurety/SubmitFormatCRequestSurety';
  getfilteredSurities: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/GetfilteredSurities';
  newSuretyThirdPartyDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyThirdPartyDetails/NewSuretyThirdPartyDetails';
  openSuretyOrganization: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyThirdPartyDetails/OpenSuretyOrganization';
  listSuretyThirdPartyDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyThirdPartyDetails/ListSuretyThirdPartyDetails';
  submitSuretyThirdPartyDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyThirdPartyDetails/SubmitSuretyThirdPartyDetails';
  registrationOrganisationFilterList: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl +
    '/api/SuretyThirdPartyDetails/RegistrationOrganisationFilterList';
  listSuretyDocument: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/ListSuretyDocument';
  newSuretyDocument: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/NewSuretyDocument';
  openSuretyDocument: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/OpenSuretyDocument';
  deleteSuretyDocument: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/DeleteSuretyDocument';
  submitSuretyDocument: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/SubmitSuretyDocument';
  listSurety: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/ListSurety';
  newSurety: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/NewSurety';
  openSurety: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/OpenSurety';
  deleteSurety: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/DeleteSurety';
  submitSurety: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/SubmitSurety';
  getSuretyRemarksHistoryData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/SuretyProcess/GetSuretyRemarksHistoryData';



  // Release
  releaseWorkFlowStates: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/GetReleaseWorkFlowStates';
  carrierVehicleApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/GetVehicleData?vehicleFilter=';
  carrierDriverApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/GetNRICPassportNoByVehicle?driverFilter=';
  // Service
  gatePassScanDetailsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassScanDetails/GatePassScanDetailsList';
  newGatePassScanDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassScanDetails/NewGatePassScanDetails';
  openGateGatePassScanDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassScanDetails/OpenGateGatePassScanDetails';
  submitGatePassScanDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassScanDetails/SubmitGatePassScanDetails';
  newGatePassDetailEscortTeam: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetailEscortTeam/NewGatePassDetailEscortTeam';
  openGatePassDetailEscortTeam: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetailEscortTeam/OpenGatePassDetailEscortTeam';
  submitGatePassDetailEscortTeam: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetailEscortTeam/SubmitGatePassDetailEscortTeam';
  deletEscortTeamDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetailEscortTeam/DeletEscortTeamDetails';
  listGatePassDeclarationItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclarationItem/ListGatePassDeclarationItem';
  newGatePassDeclarationItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclarationItem/NewGatePassDeclarationItem';
  newGatePassSCDeclarationItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclarationItem/NewGatePassSCDeclarationItem';
  openGatePassDeclarationItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclarationItem/OpenGatePassDeclarationItem';
  deleteGatePassDeclarationItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclarationItem/DeleteGatePassDeclarationItem';
  gatePassDeclarationItemSubmit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclarationItem/GatePassDeclarationItemSubmit';
  listFilterGatePassDeclarationItemList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/GatePassDeclarationItem/ListFilterGatePassDeclarationItemList';
  listFilterGatePassSCDItemList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/GatePassDeclarationItem/ListFilterGatePassSCDItemList';
  newGatePassDetailContainer: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclarationItem/NewGatePassDetailContainer';
  gatePassDetailContainerSubmit: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclarationItem/GatePassDetailContainerSubmit';
  listFilterGatePassDetailContainers: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclarationItem/ListFilterGatePassDetailContainers';
  gatePassDeclarationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclaration/GatePassDeclarationList';
  listFilterGatePassDeclarationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclaration/ListFilterGatePassDeclarationList';
  newGatePassDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclaration/NewGatePassDeclaration';
  openGatePassDeclarationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclaration/OpenGatePassDeclarationDetails';
  deleteGatePassDeclarationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclaration/DeleteGatePassDeclarationDetails';
  submitGatePassDeclarationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclaration/SubmitGatePassDeclarationDetails';
  getAssociatedData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclaration/GetAssociatedData';
  getAssociatedSCDData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclaration/GetAssociatedSCDData';
  getGatePassDeclarationId: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDeclaration/GetGatePassDeclarationId';
  gatePassDetailsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetails/GatePassDetailsList';
  newGatePassDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetails/NewGatePassDetails';
  openGatePassDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetails/OpenGatePassDetails';
  deleteGatePassDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetails/DeleteGatePassDetails';
  submitGatePassDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetails/SubmitGatePassDetails';
  gatePassEntryExitList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassEntryExitDetails/GatePassEntryExitList';
  newGatePassEnrtyExitDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassEntryExitDetails/NewGatePassEnrtyExitDetails';
  openGatePassEntryExitDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassEntryExitDetails/OpenGatePassEntryExitDetails';
  deleteGatePassEntryExitDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassEntryExitDetails/DeleteGatePassEntryExitDetails';
  submitGatePassEntryExitDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassEntryExitDetails/SubmitGatePassEntryExitDetails';
  gatePassEntryList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassEntryDetails/GatePassEntryList';
  newGatePassEnrtyDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassEntryDetails/NewGatePassEnrtyDetails';
  openGatePassEntryDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassEntryDetails/OpenGatePassEntryDetails';
  deleteGatePassEntryDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassEntryDetails/DeleteGatePassEntryDetails';
  submitGatePassEntryDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassEntryDetails/SubmitGatePassEntryDetails';
  getInspectionData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetails/GetInspectionData';
  gatePassList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/GatePassList';
  newGatePass: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/NewGatePass';
  openGatePass: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/OpenGatePass';
  gatePassHistory: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/GatePassHistory';
  deleteGatePass: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/DeleteGatePass';
  submitGatePass: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/SubmitGatePass';
  getVehicleByVehicleId: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/GetVehicleByVehicleId';
  getNRICPassportNoByVehicleId: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/GetNRICPassportNoByVehicleId';
  getGatePassDriverDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/GetGatePassDriverDetails';
  gatePassDetailsListByGatePassId: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassDetails/GatePassDetailsListByGatePassId';
  gatePassWeighingDetailsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassWeighingDetails/GatePassWeighingDetailsList';
  newGatePassWeighingDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassWeighingDetails/NewGatePassWeighingDetails';
  openGatePassWeighingDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassWeighingDetails/OpenGatePassWeighingDetails';
  submitGatePassWeighingDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassWeighingDetails/SubmitGatePassWeighingDetails';
  deleteWeighingDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePassWeighingDetails/DeleteWeighingDetails';
  getReleaseRemarksHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/GatePass/GetReleaseRemarksHistoryData';
  ListOgaApprovalRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/OgaApprovalRequest/ListOgaApprovalRequest';
  openOgaApprovalRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/OgaApprovalRequest/OpenOgaApprovalRequest';
  submitOgaApprovalDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/OgaApprovalRequest/SubmitOgaApprovalDetails';

  // Tariff Management
  sectionTypesUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Section/GetSectionTypes?isNew=';
  tariffSectionApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Section/GetSections?sectionNameFilter=';
  tariffChapterApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Chapter/GetChapters?chapterNameFilter=';
  tariffHeadingApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Heading/GetHeadings?headingNameFilter=';
  tariffSubHeadingApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SubHeading/GetSubHeadings?subHeadingNameFilter=';
  tariffItemApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffItem/GetTariffItem?tariffItemfilter=';
  getPetroleumTariffItemsApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffItem/GetPetroleumTariffItems?tariffItemfilter=';
  getCBRAorRMCDdata: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/ConditionalProhibition/GetCBRAorRMCDNames/?name=CBRA&authorityNameFilter=';
  GetTariffQuantityUnitApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Duty/GetTariffQuantityUnit?unitNameFilter=';
  tariffSpecifManagerWorkFlowStates: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Specification/GetTariffSpecifManagerWorkFlowStates';
  getDutyNames: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffItem/GetDutyDetails/?dutyNameFilter=';
  getRevenueAccountData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffItem/GetRevenueAccountNumbers/?revenueAccountNameFilter=';
  getSpecificationCodes: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffItem/GetSpecificationCodes/?specificationCodeFilter=';
  // Service
  listChapter: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Chapter/ListChapter';
  listChapterBySection: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Chapter/ListChapterBySection';
  newChapter: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Chapter/NewChapter';
  openChapter: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Chapter/OpenChapter';
  submitChapter: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Chapter/SubmitChapter';
  deleteChapter: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Chapter/DeleteChapter';
  checkChapterCode: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Chapter/CheckChapterCode';
  listConditionProhibition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/ListConditionProhibition';
  newConditionProhibition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/NewConditionProhibition';
  openConditionProhibition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/OpenConditionProhibition';
  submitConditionProhibition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/SubmitConditionProhibition';
  deleteConditionProhibition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/DeleteConditionProhibition';
  tariffManagementlistTariffItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/ListTariffItemDetails';
  submitTariffItemsAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/SubmitTariffItemsAssociation';
  newTariffItemsAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/NewTariffItemsAssociation';
  getCustomStationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/GetCustomStationDetails';
  newTariffItemsOgaApprover: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/NewTariffItemsOgaApprover';
  GetOgaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/GetOgaDetails';
  submitOgaAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/SubmitOgaAssociation';
  newCustomStationAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/NewCustomStationAssociation';
  submitCustomStationAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/SubmitCustomStationAssociation';
  getCountriesDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/GetCountriesDetails';
  newCountriesAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/NewCountriesAssociation';
  submitCountriesAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/SubmitCountriesAssociation';
  getInspectionInstructionDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/GetInspectionInstructionDetails';
  newInspectionInstructionsAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/ConditionalProhibition/NewInspectionInstructionsAssociation';
  submitInspectionInstructionAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/ConditionalProhibition/SubmitInspectionInstructionAssociation';
  getTraderDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/GetTraderDetails';
  newTradersAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/NewTradersAssociation';
  submitTraderAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/SubmitTraderAssociation';
  getTransactionTypeDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/GetTransactionTypeDetails';
  newTransactionTypesAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/NewTransactionTypesAssociation';
  submitTransactionTypeAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/ConditionalProhibition/SubmitTransactionTypeAssociation';
  getCBRAorRMCDNames: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ConditionalProhibition/GetCBRAorRMCDNames';
  listDuty: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Duty/ListDuty';
  newDuty: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Duty/NewDuty';
  openDuty: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Duty/OpenDuty';
  deleteDuty: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Duty/DeleteDuty';
  checkDutyCode: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Duty/CheckDutyCode';
  submitDuty: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Duty/SubmitDuty';
  checkDutyName: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Duty/CheckDutyName';
  listHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Heading/ListHeading';
  listHeadingByChapter: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Heading/ListHeadingByChapter';
  getHeadingsByFilter: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Heading/GetHeadingsByFilter';
  newHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Heading/NewHeading';
  openHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Heading/OpenHeading';
  deleteHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Heading/DeleteHeading';
  submitHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Heading/SubmitHeading';
  checkHeadingCode: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Heading/CheckHeadingCode';
  listSection: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Section/ListSection';
  newSection: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Section/NewSection';
  openSection: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Section/OpenSection';
  deleteSection: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Section/DeleteSection';
  submitSection: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Section/SubmitSection';
  getTariffManagementHistory: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Section/GetTariffManagementHistory';
  checkSection: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Section/CheckSection';
  listSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Specification/ListSpecification';
  newSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Specification/NewSpecification';
  openSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Specification/OpenSpecification';
  deleteSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Specification/DeleteSpecification';
  submitSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Specification/SubmitSpecification';
  checkSpecificationCode: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Specification/CheckSpecificationCode';
  listSubheading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SubHeading/ListSubheading';
  listSubheadingByHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SubHeading/ListSubheadingByHeading';
  getSubHeadingsByFilter: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SubHeading/GetSubHeadingsByFilter';
  newSubHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SubHeading/NewSubHeading';
  openSubHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SubHeading/OpenSubHeading';
  deleteSubHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SubHeading/DeleteSubHeading';
  submitSubHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SubHeading/SubmitSubHeading';
  checkSubHeadingCode: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SubHeading/CheckSubHeadingCode';
  listTariffItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/ListTariffItem';
  listTariffBySubHeading: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/ListTariffBySubHeading';
  getTariffItemByFilter: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/GetTariffItemByFilter';
  newTariffItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/NewTariffItem';
  openTariffItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/OpenTariffItem';
  deleteTariffItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/DeleteTariffItem';
  submitTariffItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/SubmitTariffItem';
  submitDutyItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/SubmitDutyItemDetails';
  newTariffItemDuty: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/NewTariffItemDuty';
  openTariffItemDuty: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/OpenTariffItemDuty';
  listTariffItemDuty: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/ListTariffItemDuty';
  newTariffItemDutyRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/NewTariffItemDutyRate';
  submitDutyRateItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/SubmitDutyRateItemDetails';
  openTariffItemDutyRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/OpenTariffItemDutyRate';
  newTariffItemSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/NewTariffItemSpecification';
  submitTariffItemSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/SubmitTariffItemSpecification';
  openTariffItemSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/OpenTariffItemSpecification';
  listTariffItemSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/ListTariffItemSpecification';
  checkTariffItemCode: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/CheckTariffItemCode';
  getProductCodesDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffItem/GetProductCodesDetails';
  submitProductCodesAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/SubmitProductCodesAssociation';
  listTariffItemProductCodes: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/ListTariffItemProductCodes';
  newTariffItemProductCodes: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/NewTariffItemProductCodes';
  deleteTariffItemProductCodes: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/DeleteTariffItemProductCodes';
  newDutyRateCountriesAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/NewDutyRateCountriesAssociation';
  submitDutyRateCountryAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/SubmitDutyRateCountryAssociation';
  getSpecificationAssociations: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/GetSpecificationAssociations';
  newSpecificationAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/NewSpecificationAssociation';
  submitDutyRateSpecificationAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/Tariffitem/SubmitDutyRateSpecificationAssociation';
  newOtherDutiesSpecificationAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/Tariffitem/NewOtherDutiesSpecificationAssociation';
  submitOtherDutiesSpecificationAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/Tariffitem/SubmitOtherDutiesSpecificationAssociation';
  getTariffCodeDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Tariffitem/GetTariffCodeDetails';
  getTariffMngtRemarksHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffItem/GetTariffMngtRemarksHistoryData';

  // WCO Correlations
  listWCOCorrelation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/WcoCorrelation/ListWCOCorrelation';
  newWCOCorrelation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/WcoCorrelation/NewWCOCorrelation';
  openWCOCorrelation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/WcoCorrelation/OpenWCOCorrelation';
  submitWCOCorrelation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/WcoCorrelation/SubmitWCOCorrelation';
  subHeadingsLstForWcoCorrelation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/WcoCorrelation/SubHeadingsLstForWcoCorrelation';
  listWCOCorrelationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/WcoCorrelation/ListWCOCorrelationDetails';
  submitWCOCorrelationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/WcoCorrelation/SubmitWCOCorrelationDetails';

  // Tariff Valuation
  getCurrency: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/GetCurrency?currencyNameFilter=';
  exchageRateWorkFlowStates: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/GetExchangeRateWorkFlowStates';
  exchangeRateCurrencyApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/GetCurrency?currencyNameFilter=';
  motorVehicleValuationWFStatesApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/MotorVehicleValuation/GetMotorVehicleValuationWFStates';
  MotorVehiclePricingDataWFStatesApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/MotorVehiclePricingData/GetMotorPricingdataWfStates';
  PetroleumWFStatesApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/GetPetroleumWFStates';
  getCurrencyNames: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/GetCurrencyDetails/?currencyNameFilter=';
  // Service
  listVehicleAgeConfiguration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/VehicleAgeConfiguration/ListVehicleAgeConfiguration';
  newVehicleAgeConfiguration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/VehicleAgeConfiguration/NewVehicleAgeConfiguration';
  openVehicleAgeConfiguration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/VehicleAgeConfiguration/OpenVehicleAgeConfiguration';
  submitVehicleAgeConfiguration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/VehicleAgeConfiguration/SubmitVehicleAgeConfiguration';
  listVehicleAgeConfigurationRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/VehicleAgeConfiguration/ListVehicleAgeConfigurationRate';
  newVehicleAgeConfigurationRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/VehicleAgeConfiguration/NewVehicleAgeConfigurationRate';
  submitVehicleAgeConfigurationRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/VehicleAgeConfiguration/SubmitVehicleAgeConfigurationRate';
  deleteVehicleAgeRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/VehicleAgeConfiguration/DeleteVehicleAgeRate';
  listMotorVehiclePriceData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehiclePricingData/ListMotorVehiclePriceData';
  newMotorVehiclePricingData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehiclePricingData/NewMotorVehiclePricingData';
  openMotorVehiclePriceData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehiclePricingData/OpenMotorVehiclePriceData';
  submitMotorVehiclePriceData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehiclePricingData/SubmitMotorVehiclePriceData';
  deleteMotorVehiclePriceData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehiclePricingData/DeleteMotorVehiclePriceData';
  deletePetroleum: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/DeletePetroleum';
  newPetroleum: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/NewPetroleum';
  submitPetroleum: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/SubmitPetroleum';
  listPetroleum: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/ListPetroleum';
  openPetroleum: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/OpenPetroleum';
  newPetroleumPrice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/NewPetroleumPrice';
  submitPetroleumPrice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/SubmitPetroleumPrice';
  listPetroleumPriceByPetroleum: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/ListPetroleumPriceByPetroleum';
  openPetroleumPrice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/OpenPetroleumPrice';
  deletePetroleumPrice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/DeletePetroleumPrice';
  tarValGetExchangeRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/GetExchangeRate';
  getPetroleumDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Petroleum/GetPetroleumDetails';
  newPriceDataForOtherGoods: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PriceDataForOtherGoods/NewPriceDataForOtherGoods';
  newPriceDataCancellDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PriceDataForOtherGoods/NewPriceDataCancellDocument';
  submitPriceDataCancellDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PriceDataForOtherGoods/SubmitPriceDataCancellDocument';
  submitPriceDataForOtherGoods: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PriceDataForOtherGoods/SubmitPriceDataForOtherGoods';
  openPriceDataCancelModel: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PriceDataForOtherGoods/OpenPriceDataCancelModel';
  listPriceDataForOtherGoods: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PriceDataForOtherGoods/ListPriceDataForOtherGoods';
  deleteOtherGoodTransactionItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PriceDataForOtherGoods/DeleteOtherGoodTransactionItem';
  openPriceDataForOtherGoods: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PriceDataForOtherGoods/OpenPriceDataForOtherGoods';
  listPriceDataOtherGoodsTransaction: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/PriceDataForOtherGoods/ListPriceDataOtherGoodsTransaction';
  tarValexceluploadErrorList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/PriceDataForOtherGoods/ExceluploadErrorList';
  tarValDownloadTemplate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/DownloadTemplate';
  listMotorVehicleValuation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehicleValuation/ListMotorVehicleValuation';
  newMotorVehicleValuation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehicleValuation/NewMotorVehicleValuation';
  submitMotorVehicleValuation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehicleValuation/SubmitMotorVehicleValuation';
  openMotorVehicleValuation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehicleValuation/OpenMotorVehicleValuation';
  deleteMotorVehicleValuation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehicleValuation/DeleteMotorVehicleValuation';
  checkPermitNumber: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehicleValuation/CheckPermitNumber';
  deleteExchangeRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/DeleteExchangeRate';
  newExchangeRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/NewExchangeRate';
  submitExchangeRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/SubmitExchangeRate';
  listExchangeRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/ListExchangeRate';
  openExchangeRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/OpenExchangeRate';
  openExchangeRateDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/OpenExchangeRateDetails';
  tarValExchangeGetExchangeRate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/GetExchangeRate';
  newExchangeRateDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/NewExchangeRateDetails';
  submitExchangeRateDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/SubmitExchangeRateDetails';
  listExchangeRateDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/ListExchangeRateDetails';
  tarValExchangeExceluploadErrorList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/ExceluploadErrorList';
  getTariffValuationRemarksHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/GetTariffValuationRemarksHistoryData';
  getTariffValuationHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExchangeRates/GetExchangeRateHistoryDetails?id=';
  getMotorVehicleValuationsApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/MotorVehicleValuation/GetMotorVehicleValuations?filterValue=';

  // Tariff Exception
  getActivatedExemptionCategory: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/ListAcivatedExemptionCategory';
  ftaWorkFlowStates: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagement/GetWorkFlowStates';
  // Service
  listExemptionLawRegulation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/LawsandRegulations/ListExemptionLawRegulation';
  associateLawRegulation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/LawsandRegulations/AssociateLawRegulation';
  associateLawRegulationsAndSectionItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/LawsandRegulations/AssociateLawRegulationsAndSectionItems';
  viewAssoLawRegulation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/LawsandRegulations/ViewAssoLawRegulation';
  newLawsandRegulation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/LawsandRegulations/NewLawsandRegulation';
  openLawsandRegulation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/LawsandRegulations/OpenLawsandRegulation';
  submitLawsandRegulation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/LawsandRegulations/SubmitLawsandRegulation';
  deleteLawsandRegulation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/LawsandRegulations/DeleteLawsandRegulation';
  listApprovalConditions: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ApprovalCondition/ListApprovalConditions';
  newApprovalCondition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ApprovalCondition/NewApprovalCondition';
  openApprovalCondition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ApprovalCondition/OpenApprovalCondition';
  submitApprovalCondition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ApprovalCondition/SubmitApprovalCondition';
  deleteApprovalCondition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ApprovalCondition/DeleteApprovalCondition';
  getOrganisationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetailsOrganization/GetOrganisationDetails';
  listTariffItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/LawsandRegulations/ListTariffItemDetails';
  listDocumentDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/LawsandRegulations/ListDocumentDetails';
  newExemptionReqApprovalCondition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/ExemptionApprovalConditions/NewExemptionReqApprovalCondition';
  submitExemptionReqApprovalCondition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/ExemptionApprovalConditions/SubmitExemptionReqApprovalCondition';
  exemptionReqApprovalList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExemptionApprovalConditions/ExemptionReqApprovalList';
  listExemptionReqApprovalCondition: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/ExemptionApprovalConditions/ListExemptionReqApprovalCondition';
  newExemptionCategory: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExemptionCategory/NewExemptionCategory';
  submitExemptionCategory: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExemptionCategory/SubmitExemptionCategory';
  submitAssoLawsRegulation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExemptionCategory/SubmitAssoLawsRegulation';
  listExemptionCategory: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExemptionCategory/ListExemptionCategory';
  openExemptionCategory: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExemptionCategory/OpenExemptionCategory';
  deleteCategory: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExemptionCategory/DeleteCategory';
  newExemptionReqCustomStation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExemptionReqCustomStation/NewExemptionReqCustomStation';
  submitExemptionReqCustomStation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/ExemptionReqCustomStation/SubmitExemptionReqCustomStation';
  listCustomStationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExemptionReqCustomStation/ListCustomStationDetails';
  associatedCustomStationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ExemptionReqCustomStation/AssociatedCustomStationList';
  newExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/NewExemption';
  newTariffSecExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/NewTariffSecExemption';
  submitExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/SubmitExemption';
  submitTariffSecExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/SubmitTariffSecExemption';
  submitExemptionReqTariffSecItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/SubmitExemptionReqTariffSecItemDetails';
  tariffExemptionListExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/ListExemption';
  openExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/OpenExemption';
  getBRNNo: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/GetBRNNo';
  tariffDetailsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/TariffDetailsList';
  deleteExemption: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/DeleteExemption';
  submitExcelFileData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/SubmitExcelFileData';
  tariffExemptiondownloadTemplate: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/DownloadTemplate';
  associateCountry: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagementCountry/AssociateCountry';
  submitCountryDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagementCountry/SubmitCountryDetails';
  viewAssoCountryDateils: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagementCountry/ViewAssoCountryDateils';
  listFtaManagement: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagement/ListFtaManagement';
  newFtaManagement: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagement/NewFtaManagement';
  openFtaManagement: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagement/OpenFtaManagement';
  submitFtaManagement: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagement/SubmitFtaManagement';
  deleteFtaManagement: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagement/DeleteFtaManagement';
  filterFta: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagement/FilterFta';
  submitEXCountryDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaMgmtExcludedTariffLine/SubmitEXCountryDetails';
  getFTAManagementCountry: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaMgmtExcludedTariffLine/GetFTAManagementCountry';
  viewExAssoCountryDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaMgmtExcludedTariffLine/ViewExAssoCountryDetails';
  associateDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaMgmtSupportingDocument/AssociateDocument';
  submitDocumentDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaMgmtSupportingDocument/SubmitDocumentDetails';
  viewAssoDocDateils: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaMgmtSupportingDocument/ViewAssoDocDateils';
  newFtaManagementTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagementTariffLine/NewFtaManagementTariffLine';
  submitFtaManagementTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagementTariffLine/SubmitFtaManagementTariffLine';
  listFtaManagementTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagementTariffLine/ListFtaManagementTariffLine';
  openFtaManagementTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagementTariffLine/OpenFtaManagementTariffLine';
  deleteFtaManagementTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagementTariffLine/DeleteFtaManagementTariffLine';
  associatedInspectionInstructionList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/InspectionInstructions/AssociatedInspectionInstructionList';
  newInspectionInstructions: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InspectionInstructions/NewInspectionInstructions';
  submitInspectionInstructions: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InspectionInstructions/SubmitInspectionInstructions';
  listIspectionInstructions: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/InspectionInstructions/ListIspectionInstructions';
  associateSectionItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/AssociateSectionItem';
  getSectionItemByExemptionId: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/GetSectionItemByExemptionId';
  newSectionItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/NewSectionItemDetails';
  openSectionItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/OpenSectionItemDetails';
  getSectionItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/GetSectionItemDetails';
  submitSectionItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/SubmitSectionItemDetails';
  associatedTariffLineList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/AssociatedTariffLineList';
  associatedSupportingDocumentList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/AssociatedSupportingDocumentList';
  associatedOrganizationList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/AssociatedOrganizationList';
  deleteSectionItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/DeleteSectionItem';
  sectionItemDetailsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetails/SectionItemDetailsList';
  newSectionItemDetailsSupportingDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/SectionItemDetailsSupportingDocument/NewSectionItemDetailsSupportingDocument';
  submitSectionItemDetailsSupportingDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/SectionItemDetailsSupportingDocument/SubmitSectionItemDetailsSupportingDocument';
  sectionItemlistDocumentDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetailsSupportingDocument/ListDocumentDetails';
  newSectionItemDetailsOrganization: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/SectionItemDetailsOrganization/NewSectionItemDetailsOrganization';
  submitSectionItemDetailsOrganization: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/SectionItemDetailsOrganization/SubmitSectionItemDetailsOrganization';
  newSectionItemDetailsTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/SectionItemDetailsTariffLine/NewSectionItemDetailsTariffLine';
  submitSectionItemDetailsTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/SectionItemDetailsTariffLine/SubmitSectionItemDetailsTariffLine';
  SectionItemlistTariffItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SectionItemDetailsTariffLine/ListTariffItemDetails';
  associateSpecification: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SpecificationModel/AssociateSpecification';
  submitSpecificationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SpecificationModel/SubmitSpecificationDetails';
  viewAssoSpecificationDateils: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/SpecificationModel/ViewAssoSpecificationDateils';
  listTariffLines: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffLines/ListTariffLines';
  newTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffLines/NewTariffLine';
  openTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffLines/OpenTariffLine';
  submitTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffLines/SubmitTariffLine';
  deleteTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffLines/DeleteTariffLine';
  uploadDocumentsDetailsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/UploadDocuments/UploadDocumentsDetailsList';
  exemptionNewUploadDocumentsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/UploadDocuments/NewUploadDocumentsDetails';
  exemptionSubmitUploadDocumentsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/UploadDocuments/SubmitUploadDocumentsDetails';
  exemptionOpenUploadDocumentsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/UploadDocuments/OpenUploadDocumentsDetails';
  getExemptionRemarksHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Exemption/GetExemptionRemarksHistoryData';


  // Tariff Quota
  TariffRateQuotaApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AdditionalTariffRateQuota/GetTariffRateQuotaNumber?trquotaFilter=';
  FtaTariffRateQuotaApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/FtaAdditionalTrQuota/GetFtaTariffRateQuotaNumber?ftaTrquotaFilter=';
  FtaTQuotaItemsApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaAdditionalTrQuota/GetFtaQuotaTariffItems?ftaquotaItemFilter=';
  tariffRateQuotaWorkFlowStates: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/GetTariffRateQuotaWorkFlowStates';
  ftaScheme: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffRateQuota/GetFtaSchema/';
  additionalQuotaWFStatesApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/AdditionalTariffRateQuota/GetAdditionalTariffRateQuotaWorkFlowStates';
  ftaAdditionalQuotaWFStatesApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/FtaAdditionalTrQuota/GetFtaAdditionalTrQuotaWorkFlowStates';
  absQuotaTransactionsList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/ListAbsoluteQuotaTransactions';
  listTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/ListTariffRateQuota';
  newTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/NewTariffRateQuota';
  deleteTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/DeleteTariffRateQuota';
  openTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/OpenTariffRateQuota';
  submitTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/SubmitTariffRateQuota';
  quotaGetOrganisationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/GetOrganisationDetails';
  newRateQuotaOrganization: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/NewRateQuotaOrganization';
  submitRateQuotaOrganization: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/SubmitRateQuotaOrganization';
  getAssociatedOrgs: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/GetAssociatedOrgs';
  listTariffRateQuotaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/ListTariffRateQuotaDetails';
  newTrqTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/NewTrqTariffLine';
  newTariffRateQuotaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/NewTariffRateQuotaDetails';
  openTariffRateQuotaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/OpenTariffRateQuotaDetails';
  submitTariffRateQuotaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/SubmitTariffRateQuotaDetails';
  deleteTariffRateQuotaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/DeleteTariffRateQuotaDetails';
  submitTariffRateQuotaTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/SubmitTariffRateQuotaTariffLine';
  quotaListTariffItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/ListTariffItemDetails';
  getRegions: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/GetRegions';
  listFtaTrQuotaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTRQuotaDetails/ListFtaTrQuotaDetails';
  openFtaTrQuotaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTRQuotaDetails/OpenFtaTrQuotaDetails';
  newFtaTrQuotaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTRQuotaDetails/NewFtaTrQuotaDetails';
  submitFtaTrQuotaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTRQuotaDetails/SubmitFtaTrQuotaDetails';
  deleteFtaTrQuotaDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTRQuotaDetails/DeleteFtaTrQuotaDetails';
  newFtaRateQuotaTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTRQuotaDetails/NewFtaRateQuotaTariffLine';
  getTariffLineDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTRQuotaDetails/GetTariffLineDetails';
  submitFtaRateQuotaTariffLine: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTRQuotaDetails/SubmitFtaRateQuotaTariffLine';
  getAssociatedTariffLines: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTRQuotaDetails/GetAssociatedTariffLines';
  listFtaTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffRateQuota/ListFtaTariffRateQuota';
  openFtaTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffRateQuota/OpenFtaTariffRateQuota';
  newFtaTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffRateQuota/NewFtaTariffRateQuota';
  submitFtaTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffRateQuota/SubmitFtaTariffRateQuota';
  deleteFtaTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffRateQuota/DeleteFtaTariffRateQuota';
  checkConditions: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffRateQuota/CheckConditions';
  newFtaQuotaTariffLineCountry: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffLineCountry/NewFtaQuotaTariffLineCountry';
  getTariffLineCountryDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffLineCountry/GetTariffLineCountryDetails';
  submitFtaQuotaTariffLineCountry: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffLineCountry/SubmitFtaQuotaTariffLineCountry';
  getAssociatedCountries: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffLineCountry/GetAssociatedCountries';
  newFtaQuotaTariffLineCountryOrganization: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/FtaTariffLineCountry/NewFtaQuotaTariffLineCountryOrganization';
  getTariffLineCountryOrganizationDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/FtaTariffLineCountry/GetTariffLineCountryOrganizationDetails';
  submitFtaQuotaTariffLineCountryOrganization: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/FtaTariffLineCountry/SubmitFtaQuotaTariffLineCountryOrganization';
  getAssociatedCountryOrganizations: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/FtaTariffLineCountry/GetAssociatedCountryOrganizations';
  listFtaAdditionalTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaAdditionalTrQuota/ListFtaAdditionalTariffRateQuota';
  newFtaAdditionalTrQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaAdditionalTrQuota/NewFtaAdditionalTrQuota';
  deleteFtaAdditionalTrQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaAdditionalTrQuota/DeleteFtaAdditionalTrQuota';
  openFtaAdditionalTrQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaAdditionalTrQuota/OpenFtaAdditionalTrQuota';
  submitFtaAdditionalTrQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaAdditionalTrQuota/SubmitFtaAdditionalTrQuota';
  openFtaTariffRateQuotaById: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaAdditionalTrQuota/OpenFtaTariffRateQuotaById';
  openFtaQuotaItemsById: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaAdditionalTrQuota/OpenFtaQuotaItemsById';
  listAdditionalTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AdditionalTariffRateQuota/ListAdditionalTariffRateQuota';
  newAdditionalTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AdditionalTariffRateQuota/NewAdditionalTariffRateQuota';
  deleteAdditionalTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/AdditionalTariffRateQuota/DeleteAdditionalTariffRateQuota';
  openAdditionalTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AdditionalTariffRateQuota/OpenAdditionalTariffRateQuota';
  submitAdditionalTariffRateQuota: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/AdditionalTariffRateQuota/SubmitAdditionalTariffRateQuota';
  openTariffRateQuotaById: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AdditionalTariffRateQuota/OpenTariffRateQuotaById';
  submitFtaTrquotaUploadDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffRateQuota/SubmitFtaTrquotaUploadDocument';
  openFtaTrquotaUploadDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaTariffRateQuota/OpenFtaTrquotaUploadDocument';
  submitTRQuotaUploadDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/SubmitTRQuotaUploadDocument';
  openTRQuotaUploadDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TRQuotaDetails/OpenTRQuotaUploadDocument';
  getQuotaRemarksHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/TariffRateQuota/GetQuotaRemarksHistoryData';
  getFtaManagementApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/FtaManagement/GetFTAManagementCoo?filterValue=';

  // Inbox Api Urls
  getAllModulesApiUrl: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/GetAllModules';
  getModuleProfilesApiUrl: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/GetModuleProfiles';
  getModuleClaimsApiUrl: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/GetModuleClaims';
  getModuleRolesApiUrl: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/GetModuleRoles';
  getModuleStateApiUrl: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/GetStates';
  // Service
  getMessageDatesList: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/InboxDashboard/GetMessageDatesList';
  getMessageList: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/InboxDashboard/GetMessageList';
  updateBookMarkMessage: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/InboxDashboard/UpdateBookMarkMessage';
  updateUnReadMessage: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/InboxDashboard/UpdateUnReadMessage';
  listMessageConfiguration: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/ListMessageConfiguration';
  newMessageConfiguration: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/NewMessageConfiguration';
  openMessageConfiguration: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/OpenMessageConfiguration';
  checkClaim: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/CheckClaim';
  submitMessageConfiguration: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/SubmitMessageConfiguration';
  deleteMessage: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/';

  listNotificationConfig: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/ListNotificationConfig';
  openNotificationConfig: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/OpenNotificationConfig';
  submitNotificationConfig: any = environment.appSettings.apiEndpoints.inboxApiBaseUrl + '/api/MessageConfiguration/SubmitNotificationConfig';

  // Tariff Rulings
  getDeclarationNumberUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/GetDeclarationNumbers/?declarationNumberFilter=';
  getValuationCodesUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/GetValuationCodes/?valuationCodeFilter=';
  getSpecificLawsUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/GetRulingsLaws/?regulationLawFilter=';
  appealValuationWFStatesApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/GetValuationAppealWFStates';
  getReferenceDocumentApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingClassificationAppeal/GetClassificationAppealCategory?refNofilter=';
  appealClassificationWFStatesApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingClassificationAppeal/GetClassificationAppealWFStates';
  rulingWFStatesApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/Ruling/GetRulingWorkflowStates';
  getRefNumberForValuationApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingValuationAppeal/GetValuationAppealCategory?refNofilter=';
  // Service
  getApplicantProfile: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/GetApplicantPersonalData';
  listRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/ListRuling';
  newRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/NewRuling';
  openRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/OpenRuling';
  submitRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/SubmitRuling';
  listRulingItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/ListRulingItem';
  newRulingItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/NewRulingItem';
  openRulingItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/OpenRulingItem';
  submitRulingItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/SubmitRulingItem';
  deleteRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/DeleteRuling';
  getDeskOfficersList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/GetDeskOfficersList';
  newDeskOfficerData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/NewDeskOfficerData';
  submitOfficersAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/SubmitOfficersAssociation';
  newRulingDraftDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/NewRulingDraftDetails';
  openRulingDraftDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/OpenRulingDraftDetails';
  submitRulingDraftDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/SubmitRulingDraftDetails';
  newRulingExpertAnalysis: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/NewRulingExpertAnalysis';
  openRulingExpertAnalysis: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/OpenRulingExpertAnalysis';
  submitRulingExpertAnalysis: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/SubmitRulingExpertAnalysis';
  newUploadDocumentsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/NewUploadDocumentsDetails';
  openUploadDocumentsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/OpenUploadDocumentsDetails';
  submitUploadDocumentsDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/SubmitUploadDocumentsDetails';
  getUploadedDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/GetUploadedDocument';
  listSampleCheckList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/ListSampleCheckList';
  listSampleCheckListDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/ListSampleCheckListDetails';
  getHistoryDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/GetHistoryDetails';
  checkBillCollected: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/CheckBillCollected';
  deleteUploadedDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/DeleteUploadedDocument';
  listRulingValuationAppeal: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/ListRulingValuationAppeal';
  newRulingValuationAppeal: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/NewRulingValuationAppeal';
  openRulingValuationAppeal: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/OpenRulingValuationAppeal';
  deleteRulingValuationAppeal: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/DeleteRulingValuationAppeal';
  submitRulingValuationAppeal: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/SubmitRulingValuationAppeal';
  newVehicleAppealItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/NewVehicleAppealItem';
  submitVehicleAppealItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/SubmitVehicleAppealItem';
  tarRulValDeleteUploadedDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/DeleteUploadedDocument';
  tarRulValNewDeskOfficerData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/NewDeskOfficerData';
  tarRulValSubmitOfficersAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/SubmitOfficersAssociation';
  getAdvancedRulingData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/GetAdvancedRulingData';
  getDeclaredAssessedValue: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/GetDeclaredAssessedValue';
  getInvoiceItemDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/GetInvoiceItemDetails';
  tarRulValSubmitInvoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/SubmitInvoiceItem';
  newInoiceItem: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/NewInoiceItem';
  associatedInvoiceList: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/AssociatedInvoiceList';
  submitAssesedValue: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingValuationAppeal/SubmitAssesedValue';
  listRulingClassificationAppeal: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingClassificationAppeal/ListRulingClassificationAppeal';
  newRulingClassificationAppeal: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingClassificationAppeal/NewRulingClassificationAppeal';

  declarationReferenceData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingClassificationAppeal/DeclarationReferenceData';

  openRulingClassificationAppeal: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingClassificationAppeal/OpenRulingClassificationAppeal';
  deleteRulingClassificationAppeal: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingClassificationAppeal/DeleteRulingClassificationAppeal';
  submitRulingClassificationAppeal: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingClassificationAppeal/SubmitRulingClassificationAppeal';
  tarRulClasDeleteUploadedDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingClassificationAppeal/DeleteUploadedDocument';
  newClassificationAppealDraftReport: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingClassificationAppeal/NewClassificationAppealDraftReport';
  openClassificationAppealDraftReport: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingClassificationAppeal/OpenClassificationAppealDraftReport';
  submitClassificationAppealDraftReport: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingClassificationAppeal/SubmitClassificationAppealDraftReport';
  tarRulClasCheckBillCollected: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingClassificationAppeal/CheckBillCollected';
  getClassificationAppealCategory: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/RulingClassificationAppeal/GetClassificationAppealCategory';
  tarRulClasGetAdvancedRulingData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/RulingClassificationAppeal/GetAdvancedRulingData';
  SubmitUploadDocumentsTariffRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/SubmitUploadDocumentsTariffRuling';
  OpendUploadDocumentsTariffRuling: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/OpendUploadDocumentsTariffRuling';
  getTariffRulingRemarksHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Ruling/GetTariffRulingRemarksHistoryData';

  // Dashboard
  trackingApiUrl = environment.appSettings.apiEndpoints.dashboardApiBaseUrl + '/api/Dashboard/GetTrackingData?id=';
  getLocationTrackingLsit: any = environment.appSettings.apiEndpoints.dashboardApiBaseUrl + '/api/Dashboard/GetLocationTrackingLsit';
  getStats: any = environment.appSettings.apiEndpoints.dashboardApiBaseUrl + '/api/Dashboard/GetStats';
  getLocationsForMB: any = environment.appSettings.apiEndpoints.dashboardApiBaseUrl + '/api/Dashboard/ListLocationsForManifestBalancing';
  viewMB: any = environment.appSettings.apiEndpoints.dashboardApiBaseUrl + '/api/Dashboard/ViewManifestBalancing';
  openCargoCompositionChart: any = environment.appSettings.apiEndpoints.dashboardApiBaseUrl + '/api/Dashboard/GetDataForCargoCompositionAndBLChart';

  // Resource Planning
  resourcePlanningFlowStates: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/GetWorkFlowStates';
  getOfficerProfilesApiUrl: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/GetUnavailableOfficerProfiles';
  getDepartmentsApiUrl: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/DepartmentProcess/GetDepartmentDetails';
  getEmployeeDetailsApiUrl: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/GetEmployeeDetails?filterValue=';
  newShiftConfiguration: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/NewShiftConfiguration';
  submitShiftConfiguration: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/SubmitShiftConfiguration';
  getDepartmentProcess: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/GetDepartmentProcess';
  openShiftConfiguration: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/OpenShiftConfiguration';
  deleteShiftConfiguration: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/DeleteShiftConfiguration';
  listShiftConfiguration: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/ListShiftConfiguration';
  getUserCustomStation: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/GetUserCustomStation?filterValue=';

  openShift: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/OpenShift';
  submitShift: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/SubmitShift';
  listShiftUserGroups: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/ListShiftUserGroups';
  openShiftUserGroup: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/OpenShiftUserGroup';
  submitShiftUserGroup: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/SubmitShiftUserGroup';
  submitAssociationReportingOfficer: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl +
    '/api/ShiftConfiguration/SubmitAssociationReportingOfficer';
  associateReportingOfficers: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/AssociateReportingOfficers';
  checkUserGroupName: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/CheckUserGroupName';
  checkUserGroupOrder: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/CheckUserGroupOrder';
  associateOfficersList: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/AssociateOfficersList';
  submitAssociateOfficers: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/SubmitAssociateOfficers';
  activateShiftConfiguration: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/ActivateShiftConfiguration';
  newDutyRoster: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/NewDutyRoster';
  openDutyRoster: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/OpenDutyRoster';
  getShiftsByShiftConfiguration: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/GetShiftsByShiftConfiguration';
  submitDutyRoster: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/SubmitDutyRoster';
  getShiftUserGroupDetails: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/GetShiftUserGroupDetails';
  associateShiftSupervisors: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/AssociateShiftSupervisors';
  submitDutyRosterSupervisors: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/SubmitDutyRosterSupervisors';
  groupAssociatedUserList: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/GroupAssociatedUserList';
  submitDutyRosterUsers: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/SubmitDutyRosterUsers';
  getShiftUsers: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/GetShiftUsers';
  listShiftDutyRoster: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ShiftConfiguration/ListShiftDutyRoster';
  getDepartmentDetails: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/GetDepartmentDetails';
  newResourceUnavailability: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/NewResourceUnavailability';
  submitResourceUnavailability: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/SubmitResourceUnavailability';
  validateActivation: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/ValidateActivation';
  openResourceUnavailability: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/OpenResourceUnavailability';
  getOfficerProfile: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/GetOfficerProfile';
  getEmployeeDetails: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/GetEmployeeDetails';
  listResourceUnavailability: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/ListResourceUnavailability';
  deleteResourceUnavailability: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/DeleteResourceUnavailability';
  updateState: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/UpdateState';
  listDeparmentProcess: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/DepartmentProcess/ListDeparmentProcess';
  newDepartmentProcess: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/DepartmentProcess/NewDepartmentProcess';
  submitDepartmentProcess: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/DepartmentProcess/SubmitDepartmentProcess';
  submitDeptProcessOfficerProfile: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/DepartmentProcess/SubmitDeptProcessOfficerProfile';
  deleteDepartmentProcess: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/DepartmentProcess/DeleteDepartmentProcess';
  getDeptProfiles: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/DepartmentProcess/GetDeptProfiles';
  openDepartmentProcess: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/DepartmentProcess/OpenDepartmentProcess';
  getFilteredDeptAssociatedProfiles: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl +
    '/api/DepartmentProcess/GetFilteredDeptAssociatedProfiles';
  deleteAssociateUserProfile: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/DepartmentProcess/DeleteAssociateUserProfile';
  checkDepartmentProcessName: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/DepartmentProcess/CheckDepartmentProcessName';
  getRPRemarksHistoryData: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/ResourceUnavailability/GetRPRemarksHistoryData';
  listAppointmentRequest: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/AppointmentRequest/ListAppointmentRequest';
  newAppointmentRequest: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/AppointmentRequest/NewAppointmentRequest';
  openAppointmentRequest: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/AppointmentRequest/OpenAppointmentRequest';
  submitAppointmentRequest: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/AppointmentRequest/SubmitAppointmentRequest';
  deleteAppointmentRequest: any = environment.appSettings.apiEndpoints.resourcePlanningApiBaseUrl + '/api/AppointmentRequest/DeleteAppointmentRequest';


  // Refunds
  getCancelledPaymentDeclarationAPiUrl: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/GetCancelledPaymentDeclarations';
  getUserAccountDetailsAPiUrl: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/GetUserAccountDetails';
  getOrganizationBankAccountDetailsAPiUrl: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl +
    '/api/RefundsProcess/GetOrganizationBankAccountDetails?accountNoFilter=';
  getOrgAgentsUrl: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/GetClaimantsData?organizationNameFilter=';
  getCancelledDeclarationsAPiUrl: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/GetCancelledDeclarations?filterValue=';
  refundWorkFlowStates = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/GetRefundWorkFlowStates';
  // Service
  listRefunds: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/ListRefunds';
  newRefunds: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/NewRefunds';
  submitRefunds: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/SubmitRefunds';
  openRefunds: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/OpenRefunds';
  getRefundInvoiceItemDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsAssociateProcess/GetRefundInvoiceItemDetails';
  getRefundDutyDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsAssociateProcess/GetRefundDutyDetails';
  getDeclarationInvoiceItemDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsAssociateProcess/GetDeclarationInvoiceItemDetails';
  submitRefundInvoiceItemDetails: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsAssociateProcess/SubmitRefundInvoiceItemDetails';
  getAssignedOfficerData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsAssociateProcess/GetAssignedOfficerData';
  getAvailableOfficersData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsAssociateProcess/GetAvailableOfficersData';
  updateRefunds: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/UpdateRefunds';
  deleteRefundProcess: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/DeleteRefundProcess';
  getDeclarationSource: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsAssociateProcess/GetDeclarationSource';
  submitRefundsUploadDocument: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/SubmitRefundsUploadDocument';
  openRefundsUploadDocument: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/OpenRefundsUploadDocument';
  getRefundsRemarksHistoryData: any = environment.appSettings.apiEndpoints.paymentsApiBaseUrl + '/api/RefundsProcess/GetRefundsRemarksHistoryData';


  // Drawback
  getFaclilityRequestNumberApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/DrawbackClaimRightTransfer/GetDrawbackFacilityRequests?filterValue=';
  getClaimRightToDetailsApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl +
    '/api/DrawbackClaimRightTransfer/ClaimRightToDetails?filterValue=';
  drawbackFlowStates: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/GetWorkFlowStates';
  getDrawbackPccListApiUrl = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/GetDrawbackPccList?pccNumber=';
  // Service
  listDecExtensionRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackDecalarionExtensionRequest/ListDecExtensionRequest';
  newDecExtensionRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackDecalarionExtensionRequest/NewDecExtensionRequest';
  submitDecExtensionRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackDecalarionExtensionRequest/SubmitDecExtensionRequest';
  openDecExtensionRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackDecalarionExtensionRequest/OpenDecExtensionRequest';
  deleteDecExtensionRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackDecalarionExtensionRequest/DeleteDecExtensionRequest';
  listDrawbackClaimRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRequest/ListDrawbackClaimRequest';
  newDrawbackClaimRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRequest/NewDrawbackClaimRequest';
  submitDrawbackClaimRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRequest/SubmitDrawbackClaimRequest';
  openDrawbackClaimRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRequest/OpenDrawbackClaimRequest';
  getAssociatedDrawbackClaimRights: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRequest/GetAssociatedDrawbackClaimRights';
  claimRightsForAssociation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRequest/ClaimRightsForAssociation';
  submitDrawbackClaimRights: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRequest/SubmitDrawbackClaimRights';
  deleteDrawbackClaimRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRequest/DeleteDrawbackClaimRequest';
  newClaimRight: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/NewClaimRight';
  openClaimRight: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/OpenClaimRight';
  listOfClaimRights: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/ListOfClaimRights';
  submitClaimRightApproval: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/SubmitClaimRightApproval';
  listClaimRightDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/ListClaimRightDeclaration';
  importDeclartionsSearch: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/ImportDeclartionsSearch';
  submitAssociatedDeclarations: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/SubmitAssociatedDeclarations';
  deleteClaimRightTransfer: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/DeleteClaimRightTransfer';
  associateDeclarationItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/AssociateDeclarationItems';
  submitAssociatedItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/SubmitAssociatedItems';
  listAssociatedItems: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/ListAssociatedItems';
  newDrawbackFacilityApproval: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/NewDrawbackFacilityApproval';
  submitDrawbackFacilityApproval: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/SubmitDrawbackFacilityApproval';
  openDrawbackFacilityApproval: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/OpenDrawbackFacilityApproval';
  listDrawbackFacility: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/ListDrawbackFacility';
  newDrawbackPcc: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/NewDrawbackPcc';
  listDrawbackPcc: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/ListDrawbackPcc';
  openDrawbackPcc: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/OpenDrawbackPcc';
  submitDrawbackPcc: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/SubmitDrawbackPcc';
  deleteDrawbackPcc: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/DeleteDrawbackPcc';
  checkDrawbackPcc: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/CheckDrawbackPcc';
  newDrawbackRawMaterials: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/NewDrawbackRawMaterials';
  openDrawbackRawMaterial: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/OpenDrawbackRawMaterial';
  listDrawbackRawMaterial: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/ListDrawbackRawMaterial';
  submitDrawbackRawMaterials: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/SubmitDrawbackRawMaterials';
  deleteDrawbackFacility: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/DeleteDrawbackFacility';
  deleteRawMaterial: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/DeleteRawMaterial';
  getDrawbackFacilityRequests: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/GetDrawbackFacilityRequests';
  claimRightToDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRightTransfer/ClaimRightToDetails';
  submitUploadDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/SubmitUploadDocument';
  openUploadDocument: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/OpenUploadDocument';
  getDrawbackFacilityApprovalHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackFacilityApproval/GetDrawbackFacilityApprovalHistoryData';
  getDrawbackClaimAmount: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRequest/GetDrawbackClaimAmount';
  getDrawbackDutyDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/DrawbackClaimRequest/GetDrawbackDutyDetails';
  // Permit Remarks History Data
  getPermitRemarksHistoryData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitCommon/GetPermitRemarksHistoryData';
  permitWorkFlowStates: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitCommon/GetPermitWfStates';

  // License Remarks History Data
  getLicenseRemarksHistoryData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/SectionsRegulations/GetLicenseRemarksHistoryData';
  licenseWorkFlowStates: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/SectionsRegulations/GetLicneseWorkFlowStates';

  // PCA Start

  // AnalysisReport
  newAnalysisReports: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AnalysisReport/NewAnalysisReport';
  listAnalysisReports: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AnalysisReport/ListAnalysisReport';
  openAnalysisReports: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AnalysisReport/OpenAnalysisReport';
  deleteAnalysisReports: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AnalysisReport/DeleteAnalysisReport';
  submitAnalysisReports: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AnalysisReport/SubmitAnalysisReport';
  pcaTypes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PcaTypes/GetPcaModuleType';
  // Answer
  newAnswer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Answer/NewAnswer';
  openAnswer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Answer/OpenAnswer';
  deleteAnswer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Answer/DeleteAnswer';
  submitAnswer: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Answer/SubmitAnswer';

  // AuditInstruction
  newAuditInstruction: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditInstruction/NewAuditInstruction';
  listAuditInstruction: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditInstruction/ListAuditInstruction';
  openAuditInstruction: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditInstruction/OpenAuditInstruction';
  deleteAuditInstruction: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditInstruction/DeleteAuditInstruction';
  submitAuditInstruction: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditInstruction/SubmitAuditInstruction';

  // AuditProgram
  newAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/NewAuditProgram';
  listAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/ListAuditProgram';
  openAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/OpenAuditProgram';
  deleteAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/DeleteAuditProgram';
  submitAuditProgram: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/SubmitAuditProgram';

  // ORGANIZATION LIST
  getAuditOrganizationList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditProgram/AuditOrganizationList';

  // AuditReport
  newAuditReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/NewAuditReport';
  listAuditReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/ListAuditReport';
  openAuditReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/OpenAuditReport';
  deleteAuditReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/DeleteAuditReport';
  submitAuditReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/SubmitAuditReport';

  // ORGANIZATION DETAILS
  getOrganizationDetailList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/OrganizationDetailList';

  // DECLARATION DISCREPANCIES
  getDiscrepancyList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/DiscrepancyList';

  // AUDIT NOTES LIST
  getAuditReportNoteList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/AuditReportNoteList';

  // QUESTION AND ANSWER LIST
  getAnswerList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/AnswerList';

  // SEIZED ITEM LIST
  getSeizedItemList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/SeizedItemList';

  // SEIZED DOCUMENTS LIST
  getSeizedDocumentList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReport/SeizedDocumentList';

  // AuditReportNotes
  newAuditReportNotes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportNotes/NewAuditReportNotes';
  openAuditReportNotes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportNotes/OpenAuditReportNotes';
  deleteAuditReportNotes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportNotes/DeleteOpenAuditReportNotes';
  submitAuditReportNotes: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/AuditReportNotes/SubmitAuditReportNotes';

  // Discrepancy
  newDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/NewDiscrepancy';
  listDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/ListDiscrepancy';
  openDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/OpenDiscrepancy';
  deleteDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/DeleteDiscrepancy';
  submitDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Discrepancy/SubmitDiscrepancy';

  // FormsDiscrepancy
  newFormsDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/FormsDiscrepancy/NewFormsDiscrepancy';
  listFormsDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/FormsDiscrepancy/ListFormsDiscrepancy';
  openFormsDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/FormsDiscrepancy/OpenFormsDiscrepancy';
  deleteFormsDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/FormsDiscrepancy/DeleteFormsDiscrepancy';
  submitFormsDiscrepancy: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/FormsDiscrepancy/SubmitFormsDiscrepancy';

  // LegalProvision
  newLegalProvision: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/NewLegalProvision';
  listLegalProvision: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/ListLegalProvision';
  openLegalProvision: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/OpenLegalProvision';
  deleteLegalProvision: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/DeleteLegalProvision';
  submitLegalProvision: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/LegalProvision/SubmitLegalProvision';

  // OrganizationAuditPlan
  newOrganizationAuditPlan: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/NewOrganizationAuditPlan';
  listOrganizationAuditPlan: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/ListOrganizationAuditPlan';
  openOrganizationAuditPlan: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/OpenOrganizationAuditPlan';
  deleteOrganizationAuditPlan: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/DeleteOrganizationAuditPlan';
  submitOrganizationAuditPlan: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/SubmitOrganizationAuditPlan';
  getAuditTypesViewList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/GetAuditTypesViewList';
  addPCAVisit: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/AddPCAVisit';

  // AUDITORS LIST
  getAuditPlanAuditorList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/AuditPlanAuditorList';

  // VISIT SCHEDULE
  getVisitList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/VisitList';

  // AUDIT SUMMARY LIST
  getAuditSummaryList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/OrganizationAuditPlan/AuditSummaryList';

  // PcaForm
  newPcaForm: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/NewPcaForm';
  listPcaForm: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/ListPcaForm';
  openPcaForm: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/OpenPcaForm';
  deletePcaForm: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/DeletePcaForm';
  submitPcaForm: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/SubmitPcaForm';

  // SEIZED DOCUMENTS LIST
  getDocumentList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/PCAForm/FormDocumentList';

  // Question
  newQuestion: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Question/NewQuestion';
  listQuestion: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Question/ListQuestion';
  openQuestion: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Question/OpenQuestion';
  deleteQuestion: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Question/DeleteQuestion';
  submitQuestion: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Question/SubmitQuestion';

  // RiskReport
  newRiskReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/RiskReport/NewRiskReport';
  listRiskReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/RiskReport/ListRiskReport';
  openRiskReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/RiskReport/OpenRiskReport';
  deleteRiskReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/RiskReport/DeleteRiskReport';
  submitRiskReport: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/RiskReport/SubmitRiskReport';

  // SeizedDocument
  newSeizedDocument: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedDocument/NewSeizedDocument';
  openSeizedDocument: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedDocument/OpenSeizedDocument';
  deleteSeizedDocument: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedDocument/DeleteSeizedDocument';
  submitSeizedDocument: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedDocument/SubmitSeizedDocument';

  // SeizedItem
  newSeizedItem: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedItem/NewSeizedItem';
  openSeizedItem: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedItem/OpenSeizedItem';
  deleteSeizedItem: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedItem/DeleteSeizedItem';
  submitSeizedItem: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SeizedItem/SubmitSeizedItem';

  // SelectivityCriteria
  newSelectivityCriteria: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/NewSelectivityCriteria';
  listSelectivityCriteria: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/ListSelectivityCriteria';
  openSelectivityCriteria: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/OpenSelectivityCriteria';
  deleteSelectivityCriteria: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/DeleteSelectivityCriteria';
  submitSelectivityCriteria: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/SubmitSelectivityCriteria';

  // Country Of Origin
  getCountryOfOrigin: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/CountryOfOriginList';

  // Country Of Destination
  getCountryOfDestination: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/CountryOfDestinationList';

  // Tariff Line
  getTariffLine: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/ItemCodeList';

  // Organization List
  getOrganizationList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/PcaOrganizationList';

  // Directors List
  getDirectorsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/DirectorList';

  // Duties List
  getDutiesList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/PcaDutyList';

  // Risk Target List
  getRiskTargetList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/RiskTargetList';

  // Business Entities
  getBusinessEntitiesList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/BusinessEntitiesList';

  // Pca Exemptions
  getPcaExemptionsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/PcaExemptionsList';

  // FTA Management
  getFTAManagementsList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/FtaManagementList';

  // Transaction Type
  getTransactionTypeList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/TransactionTypeList';

  // Location List
  getLocationList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/SelectivityCriteria/LocationList';

  // Store
  newStore: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/NewStore';
  listStore: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/ListStore';
  openStore: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/OpenStore';
  deleteStore: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/DeleteStore';
  submitStore: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/SubmitStore';

  // STORE OPERATORS LIST
  getStoreOperatorList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Store/StoreOperatorList';

  // Template
  newTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/NewTemplate';
  listTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/ListTemplate';
  openTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/OpenTemplate';
  deleteTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/DeleteTemplate';
  submitTemplate: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/SubmitTemplate';

  // Template Question
  getTemplateQuestionList: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/Template/TemplateQuestionList';

  // TransactionAudit
  listTransactionAudit: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TransactionAudit/ListTransactionAudit';
  openTransactionAudit: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TransactionAudit/OpenTransactionAudit';
  submitTransactionAudit: any = environment.appSettings.apiEndpoints.pcaApiBaseUrl + '/api/TransactionAudit/SubmitTransactionAudit';

  // PCA END

  // AI Outlier Analysis
  outlierAnalysisApiUrl = 'http://10.138.108.55:11133/outliers/3926.9099/2.4480/4.000/2006-01-01/2006-01-30';
  AIMagnitudeOutlierApiUrl = 'http://10.138.108.55:11133/outliers/';
  AILOFOutlierApiUrl = 'http://10.138.108.55:11132/outliers/IM00033966/';
   MirrorAnalysisApiUrl = 'http://10.138.108.33:5023/comtrade-data';
   WebScraping_GetproductApiUrl = 'http://10.138.108.33:5024/get-product';

   // Anonymous service
  anonymousTariffItemApiUrl: any = environment.appSettings.apiEndpoints.anonymousServiceApiBaseUrl + '/api/TariffItemAnonymousService/GetTariffItem?tariffItemfilter=';
  anonymousCountryListApiUrl: any = environment.appSettings.apiEndpoints.anonymousServiceApiBaseUrl + '/api/MdmAnonymousService/GetCountries?countryName=';
  anonymousTariffItemDetailsApiUrl: any = environment.appSettings.apiEndpoints.anonymousServiceApiBaseUrl + '/api/TariffItemAnonymousService/GetTariffDetails?tariffItemfilter=';
  anonymousTariffItemDutyDetailsApiUrl: any = environment.appSettings.apiEndpoints.anonymousServiceApiBaseUrl + '/api/TariffItemAnonymousService/GetTariffDutyDetails?tariffItemfilter=';
  anonymousUOMListApiUrl: any = environment.appSettings.apiEndpoints.anonymousServiceApiBaseUrl + '/api/MdmAnonymousService/GetUOM?unitName=';
  anonymousViewDutyRateApiUrl: any = environment.appSettings.apiEndpoints.anonymousServiceApiBaseUrl + '/api/TariffItemAnonymousService/ViewDutyRate?id=';
  anonymousDownloadTariffItemDutyDetailsApiUrl: any = environment.appSettings.apiEndpoints.anonymousServiceApiBaseUrl + '/api/TariffItemAnonymousService/DownloadTariffDutyDetails';
  anonymousDownloadPdfTariffItemDutyDetailsApiUrl: any = environment.appSettings.apiEndpoints.anonymousServiceApiBaseUrl + '/api/TariffItemAnonymousService/DownloadTariffDutyDetailsPdf';


  // Absolute Quota
  newAbsQuotaTariffLineCountry: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AbsoluteTariffLineCountry/NewAbsQuotaTariffLineCountry';
  getAbsTariffLineCountryDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AbsoluteTariffLineCountry/GetAbsTariffLineCountryDetails';
  submitAbsQuotaTariffLineCountry: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AbsoluteTariffLineCountry/SubmitAbsQuotaTariffLineCountry';
  getAbsAssociatedCountries: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AbsoluteTariffLineCountry/GetAbsFilterCountries';
  OpendUploadDocumentsAssessmentDeclaration: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/AssessmentRequest/OpenAssessmentDeclarationDocuments';

  // Correlation
  listCorrelation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Correlation/ListCorrelation';
  newCorrelation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Correlation/NewCorrelation';
  openCorrelation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Correlation/OpenCorrelation';
  deleteCorrelation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Correlation/DeleteCorrelation';
  submitCorrelation: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Correlation/SubmitCorrelation';
  listCorrelationDetail: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Correlation/ListCorrelationDetails';
  listCorrelationHSCode: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Correlation/ListCorrelationHSCode';
  submitCorrelationDetail: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Correlation/SubmitCorrelationDetails';

  constructor(
    private http: HttpClient,
    private storageService: StorageService) { }

  ngOnInit() {
  }

  public getLanguageBasedJson() {
    this.getJson().subscribe((response) => {
      this.appData.localeJsonFile = response;
    });
  }

  public getCascadingTypes(moduleName: any, value: any): Observable<any> {
    const api = this.getMcnTypes.split('?');
    return this.http.get(api[0] + '?moduleName=' + moduleName + '&typeName=' + value);
  }

  public getRegistrationCascadingTypes(moduleName: any, value: any): Observable<any> {
    const api = this.getRegistrationMcnTypes.split('?');
    return this.http.get(api[0] + '?moduleName=' + moduleName + '&typeName=' + value);
  }

  public getCascadingjourneyLocationTypes(journeyMode: any, locationNameFilter: any = '',
    filterCount: any = 10, pageIndex: 1, isAutoComplete: boolean = false, portOfOrigin: any = ''): Observable<any> {
    const api = this.journeyLocationApiUrl.split('?');
    return this.http.get(api[0] + '?locationNameFilter=' + locationNameFilter + '&journeyMode=' +
      journeyMode + '&filterCount=' + filterCount + '&pageIndex=' + pageIndex +
      '&isAutoComplete=' + isAutoComplete + '&portOfOrigin=' + portOfOrigin);
  }

  public getCascadingLocationPorts(value: any, journeyMode: any): Observable<any> {
    const api = this.cascadingPortLocationApiUrl.split('?');
    return this.http.get(api[0] + '?originId=' + value + '&journeyMode=' + journeyMode);
  }

  public getCustomsAdUserData(): Observable<any> {
    return this.http.get('../assets/i18n/customsADUsersData.json');
  }

  public getHardCodeMSg(key: any) {
    if (!isNullOrUndefined(this.storageService.get('localeJsonFile'))) {
      return this.setLanguageJsonValue(key);
    } else {
      this.getJson().subscribe((response) => {
        this.storageService.set('localeJsonFile', response);
        return this.setLanguageJsonValue(key);
      });
    }
  }

  private setLanguageJsonValue(key: any) {
    const localeJsonFile = this.storageService.get('localeJsonFile');
    const value = localeJsonFile[key];
    if (isNullOrUndefined(value)) {
      return key;
    } else {
      return value;
    }
  }


  public convertJsonString(key: any, value: any) {
    const obj = {};
    obj[key] = value;
    return JSON.stringify(obj);
  }

  public convertArrayJsonString(keys: any[], values: any[]) {
    const arrayObj = {};
    for (let i = 0; i < keys.length; i++) {
      arrayObj[keys[i]] = values[i];
    }
    return JSON.stringify(arrayObj);
  }

  public replaceString(actualString: string, replaceString: string, replaceValue: string) {
    return actualString.replace(replaceString, replaceValue);
  }

  public replaceStringArry(actualString: string, replaceStringArray: string[], replaceValueArray: string[]) {
    for (let i = 0; i < replaceStringArray.length; i++) {
      actualString = actualString.replace(replaceStringArray[i], replaceValueArray[i]);
    }
    return actualString;
  }

  public sortList(array: any, isAscDesc: boolean) {
    if (isAscDesc) {
      array.sort((a, b) => 0 - (a > b ? -1 : 1));
    } else {
      array.sort((a, b) => 0 - (a > b ? 1 : -1));
    }
    return array;
  }

  public getJson(): Observable<any> {
    return this.http.get('../assets/i18n/' + this.storageService.get('lang_code') + '.json').pipe(
      map((response: any) => response));
  }

  public userDefinedFormatDate(date: string = null, seperator: string = '-') {
    let d = ((date === null || date === '') ? new Date() : new Date(date)),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [day, month, year].join(seperator);
  }
}

export let CONFIGURATION = {
  baseUrls: {
    server: '/'
  },

  Routes: {
    Notification: 'Notification'
  }
};
