import { Injectable } from '@angular/core';

export interface MyData {
  dutyFreeShopFormId: number;
  dutyFreeShopItemId: number;
  passengerDetailId: number;
  isEdit: boolean;
  dfsFormStatus: string;
  ownerCSId: number;
  formTypeId: number;
  formType: string;
  dfsformNumber: string;
  dfsFormGenerationType: string;
  discrepancyAdjReqId: number;
  discrepancyItemId: number;
  transferRequestId: number;
  passengerItemId: number;
  isFromInventory: boolean;
  isFromDiscrepancy: boolean;
  RemittanceRequestId: number;
  destructionRequestId: number;
  destructionReqItemId: number;
}

@Injectable()
export class DfsSharedService {

  constructor() {
  }

  sharingData: MyData = {
    dutyFreeShopFormId: 0,
    dutyFreeShopItemId: 0,
    passengerDetailId: 0,
    isEdit: false,
    dfsFormStatus: null,
    ownerCSId: 0,
    formTypeId: 0,
    dfsformNumber: null,
    formType: null,
    dfsFormGenerationType: null,
    discrepancyAdjReqId: 0,
    discrepancyItemId: 0,
    transferRequestId: 0,
    passengerItemId: 0,
    isFromInventory: false,
    isFromDiscrepancy: false,
    RemittanceRequestId: 0,
    destructionRequestId: 0,
    destructionReqItemId: 0
  };

}
