/* tslint:disable: max-line-length */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { StorageService } from '../storageService/storageService';

@Injectable()
export class PermitConfig implements OnInit {

  // Permit
  listPermitTerms = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TermsandConditions/ListTermsandCondtion';
  openPermitTerms = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TermsandConditions/OpenTermsandCondtion';
  newPermitTerms = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TermsandConditions/NewTermsandCondtion';
  submitPermitTerms = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TermsandConditions/SubmitTermsandCondtion';
  remarksHistoryData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TermsandConditions/GetTermsRemarksHistoryData';
  deletePermitTermsData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TermsandConditions/DeleteTermsandCondtion';

  // Treatment Methods
  listTreatmentMethod = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TreatmentMethod/ListTreatmentMethod';
  openTreatmentMethod = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TreatmentMethod/OpenTreatmentMethod';
  newTreatmentMethod = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TreatmentMethod/NewTreatmentMethod';
  submitTreatmentMethod = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TreatmentMethod/SubmitTreatmentMethod';
  deleteTreatmentMethod = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TreatmentMethod/DeleteTreatmentMethod';
  checkDuplicateTreatmentMethodCode: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/TreatmentMethod/CheckTreatmentMethodExists';

  // Supporting Doc
  listSupportingDoc = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitSupportingDocument/ListSupportingDocument';
  openSupportingDoc = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitSupportingDocument/OpenSupportingDocument?pmTMDDocumentId';
  newSupportingDoc = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitSupportingDocument/NewSupportingDocument';
  submitSupportingDoc = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitSupportingDocument/SubmitSupportingDocument';
  deleteSupportingDoc = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitSupportingDocument/DeleteSupportingDocument';
  checkSupportingDoc = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitSupportingDocument/CheckSupportingDocExists';

  // Permit Product Code End
  listPermitProductCodeMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ProductCode/ListProductCodes';
  openPermitProductCodeMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ProductCode/OpenProductCode';
  submitPermitProductCodeMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ProductCode/SubmitProductCode';
  newPermitProductCodeMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ProductCode/NewProductCode';
  deletePermitProductCodeMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ProductCode/DeleteProductCode';
  checkDuplicateProductCode: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ProductCode/CheckProductCodeExists';

  // Common Names
  listCommonNames = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CommonNames/ListCommonName';
  openCommonNames = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CommonNames/OpenCommonName';
  newCommonNames = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CommonNames/NewCommonName';
  submitCommonNames = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CommonNames/SubmitCommonName';
  commonNamesRemarksHistoryData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CommonNames/GetCommonNameHistoryData';
  deleteCommonNames: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CommonNames/DeleteCommonName';
  checkCommonNames: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CommonNames/CheckCommonName';

  // Scientific Names
  listScientificNameMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ScientificName/ListScientificName';
  openScientificNameMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ScientificName/OpenScientificName';
  submitScientificNameMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ScientificName/SubmitScientificName';
  newScientificNameMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ScientificName/NewScientificName';
  deleteScientificNameMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ScientificName/DeleteScientificName';
  checkDuplicateScientificName: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ScientificName/CheckScientificNameExists';
  getScientificNameRemarksHistoryData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ScientificName/GetScientificNameRemarksHistoryData';


  // Permit Check Lists End
  listPermitCheckListCodeMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CheckList/ListCheckListCodes';
  openPermitCheckListCodeMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CheckList/OpenCheckListCode';
  submitPermitCheckListCodeMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CheckList/SubmitCheckListCode';
  newPermitCheckListCodeMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CheckList/NewCheckListCode';
  deletePermitCheckListCodeMasterData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CheckList/DeleteCheckListCode';
  checkDuplicateCheckListCode: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CheckList/CheckListDuplicateCodeExists';

  // Branchuser
  listBranchusers = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/BranchUser/ListBranchUser';
  openBranchuser = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/BranchUser/OpenBranchUser';
  newBranchuser = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/BranchUser/NewBranchUser';
  submitBranchuser = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/BranchUser/SubmitBranchUser';
  BbanchuserRemarksHistoryData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/BranchUser/GetBranchUserRemarksHistoryData';
  deleteBranchuser: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/BranchUser/DeleteBranchUser';
  checkBranchname: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/BranchUser/CheckBankBranch';
  newBranchuserAssociate: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/BranchUser/NewBranchUserAssociate';
  listBranchUserAssociate: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/BranchUser/ListBranchUserAssociate';
  submitBranchuserAssociate = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/BranchUser/SubmitBranchUsers';



  // MDM
  countryApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Country/GetCountry?filterValue=';
  stateApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/State/GetAllState?filterValue=';
  cityApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/City/GetCity?filterValue=';
  postalApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/PostalCode/GetPostalCode?filterValue=';
  volumeUnitApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/VolumeUnit/GetVolumeUnit?filterValue=';
  weightUnitApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/WeightUnit/GetWeightUnit?filterValue=';

  // Permit Config Urls

  listPermitConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitConfiguration/ListPermitConfigurations';
  openPermitConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitConfiguration/OpenPermitConfiguration';
  submitPermitConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitConfiguration/SubmitPermitConfiguration';
  newPermitConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitConfiguration/NewPermitConfiguration';
  deletePermitConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitConfiguration/DeletePermitConfiguration';
  getPermitLpcoTypeData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitConfiguration/GetPermitLPCOTypeData?filterValue=';

  // Notification Content
  submitNotificationContent: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitConfiguration/SubmitNotificationContent';
  getNotificationContent: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitConfiguration/GetNotificationContent';
  openNotificationContent: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitConfiguration/OpenNotificationContent';

  // Permit Item Config Urls

  listPermitItemConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemConfiguration/ListPermitItemConfigurations';
  openPermitItemConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemConfiguration/OpenPermitItemConfiguration';
  submitPermitItemConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemConfiguration/SubmitPermitItemConfiguration';
  newPermitItemConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemConfiguration/NewPermitItemConfiguration';
  deletePermitItemConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemConfiguration/DeletePermitItemConfiguration';
  getPermitTariffItems: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemConfiguration/GetPermitTariffItems?filterValue=';
  getPermitConfigTariffItems: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemConfiguration/GetPermitConfigTariffItems?filterValue=';
  // To Get MCNTypeS Data
  getMcnTypes: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/McnTypes/GetModuleType';
  getPermitLpcoType: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/Configuration/GetPermitLPCOTypeData?filterValue=';
  quantityUnitApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/QuantityUnit/GetQuentityUnit?filterValue=';
  journeyLocationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Location/GetModeLocation?locationNameFilter=';
  // Permit Item Methods
  listItemCategory = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategory/ListItemCategory';
  openItemCategory = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategory/OpenItemCategory';
  newItemCategory = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategory/NewItemCategory';
  submitItemCategory = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategory/SubmitItemCategory';
  deleteItemCategory = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategory/DeleteItemCategory';
  getProductCode: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategory/GetProductCode?filterValue=';

  // Permit Application
  listApplicablePermitConfigData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitApplication/ListApplicablePermitConfigs';

  // Permit Item Methods
  listItemSubCategory = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategory/ListItemSubCategory';
  openItemSubCategory = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategory/OpenItemSubCategory';
  newItemSubCategory = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategory/NewItemSubCategory';
  submitItemSubCategory = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategory/SubmitItemSubCategory';
  deleteItemSubCategory = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategory/DeleteItemSubCategory';

  // ItemConfig Associations Related Url's
  getAssociatedCommonNames = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AssociationCommonName/GetAssociatedCommonNames';
  getCommonNameList = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AssociationCommonName/GetCommonNameList';
  submitAssociationCommonNames = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AssociationCommonName/SubmitAssociationCommonNames';

  getAssociatedScientificNames = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AscScientificName/GetAssociatedScientificNames';
  getScientificNameList = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AscScientificName/GetScientificNameList';
  submitAscScientificNames = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AscScientificName/SubmitAscScientificNames';

  getAscTermsConditions = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AscTermsCondition/GetAscTermsConditions';
  getAscTermsConditionList = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AscTermsCondition/GetAscTermsConditionList';
  submitAscTermsConditions = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AscTermsCondition/SubmitAscTermsConditions';

  getAscTreatMentMethods = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AscTreatmentMethod/GetAscTreatMentMethods';
  getTreatMentMethodsList = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AscTreatmentMethod/GetTreatMentMethodsList';
  submitAscTreatMentMethods = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AscTreatmentMethod/SubmitAscTreatMentMethods';

  // Stake Holder Association
  newStakeholder: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitStackHolders/NewStakeholder';
  listStakeholder: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitStackHolders/ListStakeholder';
  submitStakeholder = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitStackHolders/SubmitStakeholder';

  // CFG Branch Association
  newCfgBranch: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ConfigBranch/NewAssociateBranch';
  listCfgBranch: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ConfigBranch/AssociateBranch';
  submitCfgBranch: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ConfigBranch/SubmitAssociateBranch';
  getAssociateBranches: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ConfigBranch/GetAssociatedBranch';

  // CFG Other agency Association
  newCfgItemsOthAgency: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CfgItemsOthAgency/NewCfgItemsOthAgency';
  listCfgItemsOthAgency: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CfgItemsOthAgency/ListCfgItemsOthAgency';
  submitCfgItemsOthAgency: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CfgItemsOthAgency/SubmitCfgItemsOthAgency';

  // Supporting Doc's
  getPermitMasterSupportingData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ConfigSupportingDocument/GetPermitMasterDataDocuments';
  submitConfigSupportDocumentData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ConfigSupportingDocument/SubmitConfigSupportDocumentData';
  GetAssociatedConfigSupportDocs = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ConfigSupportingDocument/GetAssociatedConfigSupportDocs';


  // Authorization Item Details Urls

  listItemDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemDetails/ListItemDetails';
  openItemDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemDetails/OpenItemDetails';
  submitItemDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemDetails/SubmitItemDetails';
  newItemDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemDetails/NewItemDetails';
  deleteItemDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemDetails/DeleteItemDetails';
  getItemDetailsTariffItems: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemDetails/GetPermitTariffItems?filterValue=';
  getConfigCommonNamesData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemDetails/GetConfigCommonNamesData?filterValue=';
  getConfigScientificNamesData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemDetails/GetConfigScientificNamesData?filterValue=';
  getConfigItemTreatmentMtdsData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemDetails/GetConfigItemTreatmentMtdsData';
  getConfigItemTermsAndCondsData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemDetails/GetConfigItemTermsAndCondsData';

  // Authorization Request
  newAuthRequest = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAuthorization/NewAuthRequest';
  listAuthRequest = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAuthorization/ListAuthRequest';
  submitAuthRequest = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAuthorization/SubmitAuthRequest';
  openAuthRequest = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAuthorization/OpenAuthRequest';
  deleteAuthRequest = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAuthorization/DeleteAuthRequest';
  checkApprovedItemsForAuthReq = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAuthorization/CheckApprovedItems';
  getRegOrganization: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAuthorization/GetRegOrganization?filterValue=';
  getPermitConfigData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAuthorization/GetPermitConfigData?filterValue=';
  getAssocitedBranches: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAuthorization/GetAssocitedBranches';
  getAuthRequestAssignedOfficerData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AssociationProcess/GetAuthRequestAssignedOfficersData';
  getAuthReqOfficersData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AssociationProcess/GetAuthReqAvailableOfficersData';
  updateAuthRequestAssignedUserId: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AssociationProcess/UpdateAuthRequestAssignedUserId';

  listItemCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategoryDetails/ListItemCategDetails';
  openItemCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategoryDetails/OpenItemCategDetails';
  submitItemCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategoryDetails/SubmitItemCategDetails';
  newItemCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategoryDetails/NewItemCategDetails';
  deleteItemCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategoryDetails/DeleteItemCategDetails';
  getItemCategProductCode: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemCategoryDetails/GetProductCode?filterValue=';


  // Authorization Supporting Documents Upload
  openPermitAuthUploadDocument: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AuthSupportingDocument/OpenPermitAuthUploadDocument';
  submitPermitAuthUploadDocument: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AuthSupportingDocument/SubmitPermitAuthUploadDocument';
  deletePermitAuthUploadDocument: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AuthSupportingDocument/DeletePermitAuthUploadDocument';
  downloadPermitAuthUploadDocument: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AuthSupportingDocument/DownloadPermitAuthUploadDocument';
  getPermitConfigSuppDocsData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl
    + '/api/AuthSupportingDocument/GetPermitAuthConfigSuppDocsData?filterValue=';

  // Org Details
  viewOrgProfile: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/RegistrationProcess/EditProfile';

  // Permit Auth Item Sub Category
  newItemSubCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategoryDetails/NewItemSubCategDetails';
  listItemSubCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategoryDetails/ListItemSubCategDetails';
  openItemSubCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategoryDetails/OpenItemSubCategDetails';
  submitItemSubCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategoryDetails/SubmitItemSubCategDetails';
  deleteItemSubCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategoryDetails/DeleteItemSubCategDetails';
  getSubCategoryCode: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubCategoryDetails/GetSubCategoryCode?filterValue=';


  // Permit Auth Req Recommend Details
  newRecommendDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/RecommendDetails/NewAuthReqRecommendDetails';
  openRecommendDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/RecommendDetails/OpenAuthReqRecommendDetails';
  submitRecommendDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/RecommendDetails/SubmitAuthReqRecommendDetails';

  // Permit Auth Request Reviewer Details
  newAuthReqReviewerDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/Reviewer/NewAuthReqReviewerDetails';
  listAuthReqReviewerDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/Reviewer/ListAuthReqReviewerDetails';
  submitAuthReqReviewerDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/Reviewer/SubmitAuthReqReviewerDetails';
  openAuthReqReviewerDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/Reviewer/OpenAuthReqReviewerDetails';
  deleteAuthReqReviewerDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/Reviewer/DeleteAuthReqReviewerDetails';
  // Permit Auth Request Approval Details
  submitAuthReqApprovalDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ApprovalDetails/SubmitAuthReqApprovalDetails';
  openAuthReqApprovalDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ApprovalDetails/OpenAuthReqApprovalDetails';

  // tslint:disable-next-line: comment-format
  //Permit Application Item Details

  listAppItemDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemDetails/ListAppItemDetails';
  openAppItemDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemDetails/OpenAppItemDetails';
  submitAppItemDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemDetails/SubmitAppItemDetails';
  newAppItemDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemDetails/NewAppItemDetails';
  deleteAppItemDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemDetails/DeleteAppItemDetails';
  getPermitAppTariffItems: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemDetails/GetPermitTariffItems?filterValue=';

  // Permit End User Details
  listEndUserDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/EndUserDetails/ListEndUserDetails';
  openEndUserDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/EndUserDetails/OpenEndUserDetails';
  submitEndUserDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/EndUserDetails/SubmitEndUserDetails';
  newEndUserDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/EndUserDetails/NewEndUser';
  deleteEndUserDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/EndUserDetails/DeleteEndUserDetails';

  // Permit Item Other Details
  listItemOtherDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemOtherDetails/ListItemOtherDetails';
  openItemOtherDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemOtherDetails/OpenItemOtherDetails';
  submitItemOtherDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemOtherDetails/SubmitItemOtherDetails';
  newItemOtherDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemOtherDetails/NewItemOtherDetail';
  deleteItemOtherDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemOtherDetails/DeleteItemOtherDetails';

  // Permit App  Item Sub Category
  newAppItemSubCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAppItemSubcategory/NewItemSubCategDetails';
  listAppItemSubCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAppItemSubcategory/ListItemSubCategDetails';
  openAppItemSubCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAppItemSubcategory/OpenItemSubCategDetails';
  submitAppItemSubCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAppItemSubcategory/SubmitItemSubCategDetails';
  deleteAppItemSubCategDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitAppItemSubcategory/DeleteItemSubCategDetails';
  // getSubCategoryCode: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/ItemSubcategory/GetSubCategoryCode?filterValue=';


  // Permit Application Urls
  newPermitApplication = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitApplication/NewPermitApplication';
  listPermitApplication = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitApplication/ListPermitApplications';
  submitPermitApplication = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitApplication/SubmitPermitApplication';
  openPermitApplication = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitApplication/OpenPermitApplication';
  deletePermitApplication = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitApplication/DeletePermitApplication';
  getPermitApplicableFees = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitApplication/GetPermitApplicableFees';
  listAllowedPermitConfigItem = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitApplication/ListAllowedPermitConfigItem';
  getPermitBillsData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitApplication/GetPermitPaymentBillsData';
  // Permit Application Item category Details
  listAppItemCategory: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemCategory/ListAppItemCategory';
  newAppItemCategory: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemCategory/NewAppItemCategory';
  openAppItemCategory: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemCategory/OpenAppItemCategory';
  submitAppItemCategory: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemCategory/SubmitAppItemCategory';
  deleteAppItemCategory: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemCategory/DeleteAppItemCategory';
  getAppItemCategProductCode: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppItemCategory/GetProductCode?filterValue=';

  // Application Supporting Documents Upload
  openPermitAppUploadDocument: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppSupportingDocument/OpenPermitAppUploadDocument';
  submitPermitAppUploadDocument: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppSupportingDocument/SubmitPermitAppUploadDocument';
  deletePermitAppUploadDocument: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppSupportingDocument/DeletePermitAppUploadDocument';
  downloadPermitAppUploadDocument: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppSupportingDocument/DownloadPermitAppUploadDocument';
  getPermitConfigAppSuppDocsData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl +
    '/api/AppSupportingDocument/GetPermitConfigSuppDocsData?filterValue=';

  // Assign Officer
  getPermitRequestAssignedOfficerData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AssignOfficerDetails/GetPermitRequestAssignedOfficersData';
  getPermitRequestOfficersList: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AssignOfficerDetails/GetPermitRequestAvailableOfficersData';
  updatePermitRequestData: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AssignOfficerDetails/UpdatePermitRequestAssignedUserId';

  // Permit Request Approval Details
  openPermitRequestApprovalDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitRequestApprovalDetails/OpenApprovalDetails';
  submitPermitRequestApprovalDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitRequestApprovalDetails/SubmitApprovalDetails';


  // Permit Application Consignor Details
  listAppConsignorDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppConsignorDetails/ListAppConsignorDetails';
  openAppConsignorDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppConsignorDetails/OpenAppConsignorDetails';
  submitAppConsignorDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppConsignorDetails/SubmitAppConsignorDetails';
  newAppConsignorDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppConsignorDetails/NewAppConsignorDetails';
  deleteAppConsignorDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppConsignorDetails/DeleteAppConsignorDetails';


  // Permit Application Consignee Details
  listAppConsigneeDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppConsigneeDetails/ListAppConsigneeDetails';
  openAppConsigneeDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppConsigneeDetails/OpenAppConsigneeDetails';
  submitAppConsigneeDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppConsigneeDetails/SubmitAppConsigneeDetails';
  newAppConsigneeDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppConsigneeDetails/NewAppConsigneeDetails';
  deleteAppConsigneeDetails: any = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/AppConsigneeDetails/DeleteAppConsigneeDetails';


  // Permit Request Reviewer Details
  openPermitRequestReviewerDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitRequestReviewerDetails/OpenReviewerDetails';
  submitPermitRequestReviewerDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitRequestReviewerDetails/SubmitReviewerDetails';

  // Permit Request Recommendation Details
  openPermitRequestRecommendationDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl +
    '/api/PermitRequesRecommendationDetails/OpenRecommendationDetails';
  submitPermitRequestRecommendationDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl +
    '/api/PermitRequesRecommendationDetails/SubmitRecommendationDetails';

  // Permit Request Other Agency Details
  openPermitRequestOthAgencyDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/OtherAgencyApprovalDetails/OpenOtherAgencyRequestDetails';
  submitPermitRequestOthAgencyDetails = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/OtherAgencyApprovalDetails/SubmitOtherAgencyRequestDetails';
  getGovtStakeHolderOrgData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CfgItemsOthAgency/GetGovtStakeHolderOrgData';
  getCfgItemsOthAgencyData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/CfgItemsOthAgency/GetCfgItemsOthAgencyData';

  getUserProfileId: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationSharedData/GetUserProfileId';
  checkPermitAuthorizationCompletedStatus: any = environment.appSettings.apiEndpoints.permitApiBaseUrl
    + '/api/PermitApplication/CheckPermitAuthorizationCompletedStatus';

  getPermitAbsoluteRateQuotaData = environment.appSettings.apiEndpoints.permitApiBaseUrl + '/api/PermitApplication/PermitAbsoluteRateQuotaData?filterValue=';
  constructor(
    private http: HttpClient,
    private storageService: StorageService) { }
  ngOnInit() {
  }


}
