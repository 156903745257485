import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ToasterService } from '../../toastrService/toasterService';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { isNullOrUndefined } from 'util';
@Injectable()
export class TrackingDashboradService {
  constructor(private http: HttpClient, private toastrService: ToasterService,
    private appConfig: McnConfig
  ) { }

  private handleError() {
    return this.toastrService.Error('Internal Server Error.Please Try Again Later.');
  }

  list(countryId: any, mode: any, selectall: boolean = true): Observable<any> {
    mode = (isNullOrUndefined(mode) || mode === '') ? 0 : mode;
    return this.http.post(this.appConfig.getLocationTrackingLsit,
      new HttpParams().set('countryId', countryId).set('mode', mode).set('selectAll', selectall.toString()))
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }
}
