import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonTypes, ModuleNames } from '../../../common/Constants';
import { SafeUnsubscriber } from '../../../common/SafeUnsubscriber';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { StorageService } from '../../../storageService/storageService';
import { RegistrationService } from '../commonRegistrationComponents/RegistrationService';
import { FormDataService } from '../commonRegistrationComponents/wizardData/wizardDataService';

@Component({
  selector: 'app-profile-details',
  templateUrl: './ProfileDetailsComponent.html',
})
export class ProfileDetailsComponent extends SafeUnsubscriber implements OnInit {
  public accountInfo: any;
  public personalInfo: any;
  public addressInfo: any;
  public otherInfo: any;
  public organizationInfo: any;
  public businessStackHolderCategory: any[] = [];
  public profileDetails: any[] = [];
  public portDetails: any[] = [];
  public customStationDetails: any[] = [];
  public bankDetails: any;
  public departmentDetails: any;
  public department: any;
  public modifiedDate: Date;
  public userDetails: any;
  public mobileCountryCode: any;
  public businessCountryCode: any;
  public alternativeNoCountryCode: any;
  public faxNo: any;
  public IDType = CommonTypes.REGISTRATIONTYPE;
  public moduleNameReg = ModuleNames.Registration;
  public IDName: any;
  public errorMessage: any;
  public telePhoneCountryCodeList: any[] = [];
  public Idtypes: any[] = [];

  constructor(
    private router: Router,
    private mcnConfig: McnConfig,
    private registrationService: RegistrationService,
    private formDataService: FormDataService,
    public webStorageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    const userProfile = JSON.parse(this.webStorageService.get('userProfile'));
    const res = this.webStorageService.get('MyProfile');
    if (res) {
      this.webStorageService.set('User_Profile', this.formDataService.setMyProfile(res));
      this.accountInfo = res.accountInfo;
      this.personalInfo = res.personalInfo;
      this.addressInfo = res.addressInfo;
      this.otherInfo = res.otherInfo;
      this.organizationInfo = res.organizationInfo;
      this.businessStackHolderCategory = res.businessStackHolderCategory;
      this.profileDetails = res.profileDetails;
      this.portDetails = res.portDetails;
      this.customStationDetails = res.customStationDetails;
      this.bankDetails = res.bankDetails;
      this.departmentDetails = res.departmentDetails;
      this.department = res.department;
      this.modifiedDate = res.modifiedDate;
      if (res.personalInfo != null) {
        this.userDetails = {
          userId: userProfile.userId,
          personalId: res.personalInfo.personalId,
          contactId: res.personalInfo.contactId,
        };
      }
          this.getTelePhoneCountryCodeProfileDetails();
          this.getIdtypeProfileDetails();
    }
  }

  public getTelePhoneCountryCodeProfileDetails() {
    if (this.addressInfo != null) {
      this.registrationService.getTelePhoneCountryCode().subscribe
        ((response) => {
          this.telePhoneCountryCodeList = response;
          this.mobileCountryCode = this.telePhoneCountryCodeList.find(x => x.telePhoneCountryCodeId === Number(
            this.addressInfo.mobileCountryCode));
          this.businessCountryCode = this.telePhoneCountryCodeList.find(
            x => x.telePhoneCountryCodeId === Number(
              this.addressInfo.businessCountryCode == null ? 0 : this.addressInfo.businessCountryCode));
          this.alternativeNoCountryCode = this.telePhoneCountryCodeList.find(
            x => x.telePhoneCountryCodeId === Number(
              this.addressInfo.alternativeNoCountryCode == null ? 0 : this.addressInfo.alternativeNoCountryCode));
          this.faxNo = this.telePhoneCountryCodeList.find(x => x.telePhoneCountryCodeId === Number(
            this.addressInfo.faxNo == null ? 0 : this.addressInfo.faxNo));
        }, (error) => this.errorMessage = error);
    }
  }

  public getIdtypeProfileDetails() {
    if (this.personalInfo != null) {
      this.mcnConfig.getRegistrationCascadingTypes(this.moduleNameReg, this.IDType).subscribe
        ((response) => {
          this.Idtypes = response['mcnTypesModel'];
          this.IDName = this.Idtypes.find(x => x.typeId === this.personalInfo.idType);
        }, (error) => this.errorMessage = error);
    }
  }

  public profileEditProfileDetails($event) {
    if (this.personalInfo != null) {

      // this.webStorageService.set('User_Profile', this.formDataService.setMyProfileToSubmitModel(this.personalInfo.referenceTypeId));
      this.webStorageService.set('userDetails', this.userDetails);
      if (this.personalInfo.referenceTypeId === 'O') {
        this.router.navigate(['/user-registration/organiazation-wizard',
         { registrationType: 'organization', isLogin: true, isEdit: true }]);
      } else if (this.personalInfo.referenceTypeId === 'I') {
        this.router.navigate(['/user-registration/individual-wizard', { registrationType: 'individual', isLogin: true, isEdit: true }]);
      } else if (this.personalInfo.referenceTypeId === 'G') {
        this.router.navigate(['/user-registration/govt-wizard', { isLogin: false, isEdit: true }]);
      }
    }
  }
  public cancelProfileDetails(event: any) {
    this.router.navigate(['/Dashboard/Home']);
  }
}
