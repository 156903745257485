import { Component, OnInit, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MarketResearchCommodityUploadComponent } from '../marketResearchCommodityUpload/MarketResearchCommodityUploadComponent';
import { NVDBMarketCommodityService } from '../../services/nvdbMarketCommodity/NVDBMarketCommodityService';
import { RiskIdentificationAndAnalysisSharedService } from '../../services/sharedServices/RiskIdentificationAndAnalysisSharedService';
import { NVDBMarketCommodityModel } from '../../models/NVDBMarketCommodityModel';
import { ServiceDocument } from '@agility/frameworkcore';
import { SafeUnsubscriber } from '../../../../../app/common/SafeUnsubscriber';
import {
  ModuleName, TypesName, ProfileName, Status, Message, UserNames, Constant, SuccessErrMsgs, AlertMessages, ConstantNumber, HttpStatusCode
} from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';
import { RmsConfig } from '../../../../../app/mcnConfig/RmsConfig';
import { CommonSharedService } from '../../../../../app/common/CommonSharedService';
import { CountryModel } from '../../../../../app/mdm/models/CountryModel';
import { TariffitemModel } from '../../../../../app/mdm/models/TariffitemModel';
import { QuantityUnitModel } from '../../../../../app/mdm/models/QuantityUnitModel';
import { ValidationService } from '../../../../../app/validations/ValidationService';
import { ToasterService } from '../../../../../app/toastrService/toasterService';
import { McnConfig } from '../../../../../app/mcnConfig/McnConfig';
import { NvdbCommodityPopUpComponent } from '../nvdbCommodityPopUp/NvdbCommodityPopUpComponent';
import { NVDBMarketSpecificationsModel } from '../../models/NVDBMarketSpecificationsModel';
import { AlertModalService } from '../../../../../app/services/AlertModalService';
import { StorageService } from '../../../../../app/storageService/storageService';

@Component({
  selector: 'app-market-details-commodity',
  templateUrl: './NVDBCommodityFormComponent.html',

})

export class NVDBCommodityFormComponent extends SafeUnsubscriber implements OnInit, AfterContentInit {

  public nvdbCommodityServiceDocument: ServiceDocument<NVDBMarketCommodityModel>;
  public nvdbSpecServiceDocument: ServiceDocument<NVDBMarketSpecificationsModel>;
  public nvdbCommodityDetails: any;
  public nvdbSpecificationList: any;
  public specificationTotalList: ServiceDocument<NVDBMarketCommodityModel>;
  public moduleNameRms: string;
  public priceingDataSource: string;
  public valuationMethod: string;
  public showHideSpecificationSublist: boolean;
  public stateName: string;
  public countryData: CountryModel[] = [];
  public tariffDataData: TariffitemModel[] = [];
  public tariffItemDescription: string;
  public nvdbCommodityFormControls: any;
  public quantityUOMReferenceData: any[] = [];
  public disableEnableToDate: boolean;
  public newFrequecy: boolean;
  public modified: boolean;
  public disableControl = false;
  public showHideControl = false;
  public disableRemarksControl = false;
  public disablePriceDataSource = true;
  public disableValuationMethod = true;
  public disableToDate = true;
  public disableFromDate = true;
  public mandatorySymbol = false;
  public showHideRemarksControl = false;


  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private nvdbCommodityService: NVDBMarketCommodityService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
    private router: Router,
    public rmsConfig: RmsConfig,
    private commonSharedService: CommonSharedService,
    private ref: ChangeDetectorRef,
    private validationService: ValidationService,
    private toasterService: ToasterService,
    private mcnConfig: McnConfig,
    public webStorage: StorageService,
    private alertModalService: AlertModalService) {
    super();
    this.moduleNameRms = ModuleName.Rms;
    this.priceingDataSource = TypesName.PricingSouceTypes;
    this.valuationMethod = TypesName.ValuationMethodTypes;
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(() => {
      this.nvdbCommodityServiceDocument = this.nvdbCommodityService.nvdbCommodityServiceDocument;
      this.nvdbCommodityDetails = this.nvdbCommodityServiceDocument.dataProfile.dataModel;
      this.riskIdentificationAndAnalysisSharedService.sharingData.nvdbMarketComId = this.nvdbCommodityDetails.nvdbMarketComId;
      this.nvdbSpecificationList = this.nvdbCommodityServiceDocument.dataProfile.dataModel.specificationList;
    });

    this.stateName = this.nvdbCommodityServiceDocument.dataProfile.dataModel.workflowInstance.stateName;
    this.nvdbCommodityFormControls = this.nvdbCommodityServiceDocument.dataProfile.profileForm.controls;


    if (this.stateName === Status.Start) {
      this.showHideSpecificationSublist = false;
      this.tariffItemDescription = null;
      this.nvdbCommodityFormControls.nvdbMarketComDate.setValue(new Date());
    } else {
      this.showHideSpecificationSublist = true;
    }

    // Setting Mandatory Symbol
    if (this.nvdbCommodityServiceDocument.userProfile.name === UserNames.DeputyDirectorValuation) {
      this.mandatorySymbol = true;
    } else {
      this.mandatorySymbol = false;
    }

    // If State Name is Submitted,Activated,Deactiavated Disable All Controls
    if (this.stateName === Status.Submitted
      || this.stateName === Status.Activated
      || this.stateName === Status.deactivated
      || (this.stateName === Status.ReturnedForCorrection
        && this.nvdbCommodityServiceDocument.userProfile.name === UserNames.DeputyDirectorValuation)) {
      this.disableControl = true;
      this.showHideControl = false;
      this.disablePriceDataSource = true;
      this.disableValuationMethod = true;
      this.disableToDate = true;
      this.disableFromDate = true;
    } else {
      this.showHideControl = true;
      this.disablePriceDataSource = false;
      this.disableValuationMethod = false;
      this.disableToDate = false;
      this.disableFromDate = false;
    }

    if (this.nvdbCommodityServiceDocument.userProfile.name === UserNames.ValuationAdmin &&
      (this.stateName === Status.Start ||
        this.stateName === Status.Created ||
        this.stateName === Status.Modified ||
        this.stateName === Status.Submitted)) {
      this.showHideRemarksControl = false;
    } else {
      this.showHideRemarksControl = true;
    }

    // Setting Validating and UnValidating
    if (this.stateName === Status.Submitted && this.nvdbCommodityServiceDocument.userProfile.name === UserNames.DeputyDirectorValuation) {
      this.nvdbCommodityFormControls.remarks.setValidators([Validators.required]);
      this.disableRemarksControl = false;
    } else {
      this.nvdbCommodityFormControls.remarks.clearValidators();
      this.disableRemarksControl = true;
    }

    // Check Infinite Date check box validation
    this.checkInfinite();

    // Set Validations
    this.nvdbCommodityFormControls.typeId.setValidators([Validators.required]);
    this.nvdbCommodityFormControls.tariffItemId.setValidators([Validators.required]);
    this.nvdbCommodityFormControls.countryId.setValidators([Validators.required]);
    this.nvdbCommodityFormControls.quantityUnitId.setValidators([Validators.required]);
    this.nvdbCommodityFormControls.unitPrice.setValidators([Validators.required, this.validationService.NumericAndDecimalValidator()]);
    this.nvdbCommodityFormControls.valuationMethodId.setValidators([Validators.required]);
    this.nvdbCommodityFormControls.fromDate
      .setValidators([Validators.required, this.validationService.DateGreateThanCurrentDateValidator()]);
    this.nvdbCommodityFormControls.toDate.setValidators([this.validationService.AllowNullAndDateGreateThanCurrentDate()]);
    this.nvdbCommodityFormControls.quantity.setValidators([Validators.required, this.validationService.NumericAndDecimalValidator()]);
    this.nvdbCommodityFormControls.unitPrice.setValidators([Validators.required, this.validationService.NumericAndDecimalValidator()]);
    this.nvdbCommodityFormControls.minValue.setValidators(this.validationService.AllowNullAndNumeric());
    this.nvdbCommodityFormControls.maxValue.setValidators(this.validationService.AllowNullAndNumeric());

  }


  ngAfterContentInit() {
    // Auto Complete Code
    if (this.stateName !== Status.Start) {
      if (this.nvdbCommodityServiceDocument.dataProfile.dataModel.countryId != null) {
        const responseCountry: CountryModel = new CountryModel();
        responseCountry.countryId = this.nvdbCommodityServiceDocument.dataProfile.dataModel.countryId;
        responseCountry.countryName = this.nvdbCommodityServiceDocument.dataProfile.dataModel.countryDetails.countryName;
        this.countryData.push(responseCountry);
      }

      if (this.nvdbCommodityServiceDocument.dataProfile.dataModel.tariffItemId != null) {
        const responsetariffItem: TariffitemModel = new TariffitemModel();
        responsetariffItem.tariffItemId = this.nvdbCommodityServiceDocument.dataProfile.dataModel.tariffItemId;
        responsetariffItem.tariffCode = this.nvdbCommodityServiceDocument.dataProfile.dataModel.tariffItemDetails.tariffCode;
        this.tariffDataData.push(responsetariffItem);
        this.tariffItemDescription = this.nvdbCommodityServiceDocument.dataProfile.dataModel.tariffItemDetails.description;
      }

      if (this.nvdbCommodityServiceDocument.dataProfile.dataModel.quantityUnitId != null) {
        const quantity = new QuantityUnitModel();
        quantity.quantityUnitId = this.nvdbCommodityServiceDocument.dataProfile.dataModel.quantityUnitId;
        quantity.unitName = this.nvdbCommodityServiceDocument.dataProfile.dataModel.quantityUnitDetails.unitName;
        this.quantityUOMReferenceData.push(quantity);
      }
    }
  }

  /**
   * Checks Min Values and Max Value Validation
   * @param event
   */
  minMaxCheck(event: any) {
    const minValue = this.nvdbCommodityFormControls.minValue.value;
    const maxValue = this.nvdbCommodityFormControls.maxValue.value;
    if (this.commonSharedService.isNotNullOrUndefined(minValue) && this.commonSharedService.isNotNullOrUndefined(maxValue)) {
      if (parseInt(minValue) < parseInt(maxValue)) {
        return true;
      } else {
        if (event.currentTarget.id === Constant.TxtMinValue) {
          this.nvdbCommodityFormControls.minValue.setValue(null);
          this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.MinValueLessThanMaxValue));
        } else {
          this.nvdbCommodityFormControls.maxValue.setValue(null);
          this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.MaxValueGreaterThanMinValue));
        }
        return false;
      }
    }
  }

  /**
   * Checks Date Validation
   * @param event
   */
  checkDateConditions(event: any) {
    if ((this.nvdbCommodityFormControls.fromDate.value) &&
      (this.nvdbCommodityFormControls.toDate.value)) {
      const startdateC = new Date(this.nvdbCommodityFormControls.fromDate.value);
      const endDateC = new Date(this.nvdbCommodityFormControls.toDate.value);
      if (startdateC <= endDateC) {
        return true;
      } else {
        if (event.targetElement.id === Constant.FromDate) {
          this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.FromDateLessthanToDate));
          this.nvdbCommodityFormControls.fromDate.setValue(null);
        } else {
          this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.ToDateGraterthanFromDate));
          this.nvdbCommodityFormControls.toDate.setValue(null);
        }
        return false;
      }
    }
  }

  /**
   * Infinte Check box Validation
   */
  checkInfinite() {
    if (this.nvdbCommodityFormControls.infiniteDate.value === true) {
      this.nvdbCommodityFormControls.toDate.setValue(null);
      this.disableEnableToDate = true;
      this.nvdbCommodityFormControls.toDate.disable();
    } else {
      this.disableEnableToDate = false;
      this.nvdbCommodityFormControls.toDate.enable();
    }
  }

  /**
   * Get Tariff HS Code Details
   * @param event Get Tariff Code Description
   */
  getTariffDescription(event: any) {
    this.tariffItemDescription = event.options.description;
  }

  // On Leave of HS Code ,Description Data should be Cleared
  OnLeave(event: any) {
    if (!this.commonSharedService.isNotNullOrUndefined(event)) {
      this.tariffItemDescription = null;
    }
  }

  /**
   * On Click of View Upload Documents Link
   */
  ViewUpLoadDocs() {
    this.dialog.open(MarketResearchCommodityUploadComponent, {
      panelClass: 'custom-modal-container',
      width: '900px'
    });
  }

  /**
   * On Click Of Cancel button
   */
  cancel() {
    this.router.navigate(['risk-identification-Analysis/market-research-data-commodity']);
  }


  /**
 *  Form Submit Method
 * @param event
 */
  SubmitNVDBCommodity(event: any) {
    if (this.nvdbCommodityServiceDocument.dataProfile.profileForm.valid) {
      this.nvdbCommodityService.submitNVDBMarketCommodity().subscribe((res) => {
        if (this.commonSharedService.isNotNullOrUndefined(res) && res.result === HttpStatusCode.OK) {
          const actionName = this.nvdbCommodityServiceDocument.dataProfile.profileForm.controls['actionName'].value;
          this.riskIdentificationAndAnalysisSharedService.sharingData.nvdbMarketComId = res.mcnProfileResponse;
          if (this.router.url === '/risk-identification-Analysis/market-research-commodity-details') {
            this.router.navigate(['/risk-identification-Analysis/market-research-commodity-details-open']);
          } else if (this.router.url === '/risk-identification-Analysis/market-research-commodity-details-open') {
            this.router.navigate(['/risk-identification-Analysis/market-research-commodity-form-open']);
          } else if (this.router.url === '/risk-identification-Analysis/market-research-commodity-form-open') {
            this.router.navigate(['/risk-identification-Analysis/market-research-commodity-details-open']);
          }
          this.commonSharedService.displayAlertMessages(actionName, ProfileName.NVDBCommodity);
        }
      });
    } else {
      Object.keys(this.nvdbCommodityServiceDocument.dataProfile.profileForm.controls).map(
        (controlName) => this.nvdbCommodityServiceDocument.dataProfile.profileForm.controls[controlName]).forEach(
          (control) => { control.markAsTouched(); },
        );
    }
  }

  /**
   * Add New Specification link
   */
  newCommoditySpecificationPopup() {
    this.nvdbCommodityService.newNVDBMarketCommoditySpecs().subscribe(() => {
      const dialogRef = this.dialog.open(NvdbCommodityPopUpComponent, {
        panelClass: 'custom-modal-container',
        width: '900px'
      });
      dialogRef.componentInstance.parentForm = Constant.IsNVDBMarketCommodity;
      this.editNVDBSpecificationSplit(dialogRef);
    });
  }


  /**
   * On Click of Edit Button Of Specification Record
   * @param id tariffItemSpecId
   */
  editNVDBSpecification(id: number): void {
    this.riskIdentificationAndAnalysisSharedService.sharingData.tariffItemSpecId = id;
    this.nvdbCommodityService.openNVDBMarketCommoditySpecs().subscribe(() => {
      this.modified = true;
      this.newFrequecy = false;
      const dialogRef = this.dialog.open(NvdbCommodityPopUpComponent, {
        panelClass: 'custom-modal-container',
        width: '900px'
      });
      this.editNVDBSpecificationSplit(dialogRef);
    });
  }

  editNVDBSpecificationSplit(dialogRef: any) {
    dialogRef.afterClosed().subscribe(result => {
      this.nvdbCommodityService.openNVDBMarketCommodity().subscribe(() => {
        this.nvdbCommodityServiceDocument = this.nvdbCommodityService.nvdbCommodityServiceDocument;
        this.nvdbSpecificationList = this.nvdbCommodityServiceDocument.dataProfile.dataModel.specificationList;
      });
    });
  }


  /**
   * Delete Specification Record
   * @param nvdbSpecSD NVDBMarketSpecificationsModel
   */
  public deleteNVDBSpecification(nvdbSpecSD: NVDBMarketSpecificationsModel) {
    const confirmationMsg = this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.ConfirmDelete)
      + ' "' + nvdbSpecSD.specificationDetails.code
      + '" ' + this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.NVDBSpecification)
      + ' ?';

    this.alertModalService
      .confirm(AlertMessages.delete, AlertMessages.WarnIcon, Message.DeleteNVDBCommoditySpecification, confirmationMsg, '')
      .subscribe((response) => {
        if (response === true) {
          this.nvdbCommodityService.deleteNVDBMarketCommoditySpecs(nvdbSpecSD.tariffItemSpecId)
            .subscribe((itemresponse) => {
              if (itemresponse) {
                if (this.nvdbSpecificationList.length > ConstantNumber.IsZero) {
                  const index = this.nvdbSpecificationList.findIndex(x => x.tariffItemSpecId ===
                    nvdbSpecSD.tariffItemSpecId);

                  if (index !== ConstantNumber.IsMinuOne) {
                    this.nvdbSpecificationList.splice(index, ConstantNumber.IsOne);
                  }
                  this.ref.markForCheck();
                }
                this.toasterService.Success(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.RecordDeletedSuccessfully), null);
                this.router.navigate(['/risk-identification-Analysis/market-research-commodity-details'],
                  { relativeTo: this.activatedRoute });
                this.ref.markForCheck();
              }
            },
              Error => {
                this.toasterService.Error(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.InternalServerErrorPleaseTryAgainLater), null);
              });
        }
      });
  }


}
