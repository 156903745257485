export enum ServiceReceiptsConstant {
  Activated = 'Activated'
}
export enum ServiceTypes {
  NORMAL = 10062,
  PASSENGER = 10063
}
export enum ServiceGroups {
  DOISSUANCE = 'SERVICEGROUP_DOISSUANCE',
  NOCISSUANCE = 'SERVICEGROUP_NOCISSUANCE',
  GATEPASSISSUANCE = 'SERVICEGROUP_GATEPASSISSUANCE'
}
