import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { RmsConfig } from '../../../../../app/mcnConfig/RmsConfig';
import { Constant } from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';
import { StorageService } from '../../../../../app/storageService/storageService';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { NVDBHistoricalDataService } from '../../services/nvdbHistoricalData/NVDBHistoricalDataService';
import { NVDBOutliersSharedService } from '../../services/sharedServices/nvdbOutlierSharedService';
import { NVDBOutlierScatterChartComponent } from '../nvdbOutlierScatterChart/nvdbOutlierScatterChartComponent';

@Component({
  selector: 'nvdb-outlier',
  templateUrl: './NvdbOutlierComponent.html',
})

export class NvdbOutlierComponent implements OnInit {
  public riskFrequencyCreateFrom: FormGroup;
  public modified: boolean;
  public newFrequecy: boolean;
  public riskRegisterInfoFrom: FormGroup;
  public nvdbViewOutlierInfo: any;
  public nvdbHistoricalDataServiceDocument: any = [];
  public queryString: string;
  public getdata: any;
  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    public appConfig: McnConfig,
    private rmsConfig: RmsConfig,
    public webStorage: StorageService,
    private nvdbHistoricalDataService: NVDBHistoricalDataService,
    private httpClient: HttpClient,
    private nvdbSharedService: NVDBOutliersSharedService
  ) {
  }

  ngOnInit() {
    this.nvdbHistoricalDataServiceDocument = this.nvdbHistoricalDataService.nvdbHistoricalDataServiceDocument;
    this.nvdbViewOutlierInfo = this.nvdbHistoricalDataServiceDocument.dataProfile.dataModel;
  }

  viewOutlierMagnitude(getdata: string) {
    if (getdata === Constant.IsMagnitudeOutlier) {
      this.queryString = this.rmsConfig.isMagnitudeOutlierApiUrl;
      this.viewOutlierMagnitudeSplit(this.queryString);
    } else if (getdata === Constant.IsLOFOutlier) {
      this.queryString = this.rmsConfig.isLOFOutlierApiUrl;
      this.viewOutlierMagnitudeSplit(this.queryString);
    }
  }

  viewOutlierMagnitudeSplit(queryString: any) {
    this.httpClient.get(queryString).subscribe(res => {
      this.nvdbSharedService.sharingData.outliers = res;
      this.dialog.open(NVDBOutlierScatterChartComponent, {
        panelClass: 'custom-modal-container',
      });
    });
  }
}
