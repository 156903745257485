import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { ShipClearanceConfig } from '../../mcnConfig/ShipClearanceConfig';
import { ShipCargoModel } from '../models/ShipCargoModel';
import { ToasterService } from '../../toastrService/toasterService';
import { map, catchError } from 'rxjs/operators';
import { ShipClrSharedService } from '../services/sharedServices/ShipClrSharedServices';
import { ShipCargoDetailsModel } from '../models/ShipCargoDetailsModel';
import { ShipCargoDetailsDataModel } from '../models/ShipCargoDetailsDataModel';
import { CommonSharedService } from '../../common/CommonSharedService';
import { Router } from '@angular/router';
import { ViewCargoHouseBillsModel } from '../models/ViewCargoHouseBillsModel';

@Injectable()
export class ShipCargoService {
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public serviceDocument: ServiceDocument<ShipCargoModel> = new ServiceDocument<ShipCargoModel>();
  public mcserviceDocument: ServiceDocument<ShipCargoDetailsDataModel> = new ServiceDocument<ShipCargoDetailsDataModel>();
  public mcviewserviceDocument: ServiceDocument<ViewCargoHouseBillsModel> = new ServiceDocument<ViewCargoHouseBillsModel>();
  public filterServiceDocument: ServiceDocument<ShipCargoModel> = new ServiceDocument<ShipCargoModel>();
  public modeOfSourceData: any;
  public isSort = false;
  public searchObj: any;
  public orderBy: string;
  private refreshDynamicData = new Subject<string>();

  // Observable string streams
  public callRefreshDynamicData$ = this.refreshDynamicData.asObservable();

  // Service message commands
  public hitRefreshDynamicData() {
    this.refreshDynamicData.next();
  }

  constructor(
    private appConfig: ShipClearanceConfig,
    private toastrService: ToasterService,
    private shipClrSharedService: ShipClrSharedService,
    private http: HttpClient,
    private commonSharedService: CommonSharedService,
    private router: Router
  ) { }

  private handleError() {
    return this.toastrService.Error('Internal Server Error.Please Try Again Later.');
  }

  public list(expression: string = '', aliasName: string = '', pageIndex: any = 1,
    recordPerPage: any = this.appConfig.appData.pageCount, orderIndex: any = 1, isDescending: any = true):
    Observable<ServiceDocument<ShipCargoModel>> {
    const listShipCargo = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending,
    };
    return this.serviceDocument.list(this.appConfig.listShipCargo, listShipCargo)
      .pipe(map((response) => response), catchError(
        (res) => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }


  public new(): Observable<ServiceDocument<ShipCargoModel>> {
    return this.serviceDocument.new(this.appConfig.newShipCargo)
      .pipe(map((response) => response), catchError(() =>
        throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public open(): Observable<ServiceDocument<ShipCargoModel>> {
    if (this.commonSharedService.isNotNullOrUndefined(this.shipClrSharedService.sharingData.shipDeclarationId)
      && this.shipClrSharedService.sharingData.shipDeclarationId > 0) {
      return this.serviceDocument.open(this.appConfig.openShipCargo, new HttpParams().set('ShipDeclarationId',
        this.shipClrSharedService.sharingData.shipDeclarationId.toString()))
        .pipe(map((response) => response), catchError(() => throwError(
          { msg: this.handleError(), ngNavigationCancelingError: true })));
    } else {
      this.router.navigate(['/shipClrCreateData/cargo-decl']);
    }
  }

  public submit(): Observable<any> {
    return this.serviceDocument.submit(this.appConfig.submitShipCargo)
      .pipe(map((response) => response), catchError(() =>
        throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public deleteShipCargo(shipCargoDetailsDataId: any): Observable<any> {
    return this.http.post(this.appConfig.deleteShipCargoDetails, new HttpParams()
      .set('shipCargoDetailsDataId', shipCargoDetailsDataId.toString()));
  }

  public newShipCargoDetails(): Observable<ServiceDocument<ShipCargoDetailsDataModel>> {

    return this.mcserviceDocument.new(this.appConfig.newShipCargoDetails)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public submitShipCargoDetails(): Observable<any> {

    return this.mcserviceDocument.submit(this.appConfig.submitShipCargoDetails)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public openShipCargoDetails(): Observable<ServiceDocument<ShipCargoDetailsDataModel>> {

    return this.mcserviceDocument.open(this.appConfig.openShipCargoDetails, new HttpParams()
      .set('id', this.shipClrSharedService.sharingData.shipCargoDetailsDataId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public listShipCargoDetails(expression: string = '', aliasName: string = '', pageIndex: any = 1,
    recordPerPage: any = this.appConfig.appData.associatePageCount, orderIndex: any = 1, isDescending: any = true):
    Observable<ServiceDocument<ShipCargoDetailsDataModel>> {
    const listShipCargoDetails = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending,
    };
    return this.mcserviceDocument.list(this.appConfig.listShipCargoDetails, listShipCargoDetails)
      .pipe(map((response) => response), catchError(
        (res) => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public submitExeclforShipCargo(f: ShipCargoDetailsModel): Observable<any> {
    return this.http.post(this.appConfig.submitExcelForShipCargo, f, { headers: this.headers })
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public OpenShipCargoHouseBills(): Observable<ServiceDocument<ShipCargoModel>> {
    if (this.commonSharedService.isNotNullOrUndefined(this.shipClrSharedService.sharingData.shipDeclarationId)
      && this.shipClrSharedService.sharingData.shipDeclarationId > 0) {
      return this.serviceDocument.open(this.appConfig.openShipCargoHouseBills, new HttpParams().set('shipDeclarationId',
        this.shipClrSharedService.sharingData.shipDeclarationId.toString()))
        .pipe(map((response) => response), catchError(() => throwError(
          { msg: this.handleError(), ngNavigationCancelingError: true })));
    }
  }

  public view(): Observable<ServiceDocument<ViewCargoHouseBillsModel>> {
    return this.mcviewserviceDocument.view(this.appConfig.viewCargoHouseBills, new HttpParams().set('hbItemId',
      this.shipClrSharedService.sharingData.hbItemId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }
}
