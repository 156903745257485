import {
  Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef,
  ViewChild
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ChatBotDataService, ESendBy, Message } from '../services/ChatBotDataService';
import { scan } from 'rxjs/operators';


@Component({
  selector: 'Chat-bot',
  templateUrl: './ChatWindowComponent.html',
})
export class ChatWindowComponent implements OnInit {

  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @Input() msgTemplate: TemplateRef<any>;
  @Input() inputTemplate: TemplateRef<any>;
  @Input() msg: Subject<any>;
  @Input() token: string;
  @Output() onMsgReceive = new EventEmitter();
  @ViewChild('msgArea') msgArea: ElementRef;
  @ViewChild('defaultMsgTemplate') defaultMsgTemplate: TemplateRef<any>;
  @ViewChild('defaultInputTemplate') defaultInputTemplate: TemplateRef<any>;

  allMessages: Observable<Message[]>;
  speech_message;
  text_message;
  lang_eng = true;
  user_input_required = false;
  constructor(
    public dataService: ChatBotDataService,
  ) { }

  ngOnInit() {
    this.msgTemplate = this.msgTemplate ? this.msgTemplate : this.defaultMsgTemplate;
    this.inputTemplate = this.inputTemplate ? this.inputTemplate : this.defaultInputTemplate;
    this.dataService.init(this.token);
    this.allMessages = this.dataService.conversation.asObservable()
      .pipe(
        scan((acc, val) => {
          setTimeout(() => {
            this.msgArea.nativeElement.scrollTop = this.msgArea.nativeElement.scrollHeight;
          });
          if (ESendBy.bot === val[0].sendBy) {
            this.onMsgReceive.emit(val[0].content);
          }
          return acc.concat(val);
        })
      );
    this.msg.subscribe((msg) => {
      this.dataService.converseOptions(msg);
    });
    this.dataService.showLangOption();
  }

  public onChange(target: any) {
    this.msg.next(target.value);
    target.value = '';
  }

  change_language_arabic() {
    this.dataService.lang_selected = true;
    this.dataService.change_language_arabic();
  }
  change_language_english() {
    this.dataService.lang_selected = true;
    this.dataService.change_language_english();
  }

  public handleData(target: any) {
    this.dataService.converseOptions(target[0]);
  }
}
