import { Component, OnInit, Renderer2, Input} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-hbar-chart',
    templateUrl: './horizontalBarChartComponent.html',
})
export class horizontalBarChartComponent implements OnInit {
    @Input() public title: any;
    @Input() public subtitle: any;
    @Input() public optionsData: any;

    ngOnInit() {
    }

    constructor(private renderer: Renderer2, private router: Router, private activatedRoute: ActivatedRoute) {

    }

    public bar_ChartOptions = {
        title: this.title,
        chartArea: { width: '80%' },
        hAxis: {
            title: this.subtitle,
            minValue: 0,
            textStyle: {
                bold: true,
                fontSize: 12,
                color: '#4d4d4d'
            },
            titleTextStyle: {
                bold: true,
                fontSize: 18,
                color: '#4d4d4d'
            }
        },
        vAxis: {
            title: 'Country',
            textStyle: {
                fontSize: 14,
                bold: true,
                color: '#848484'
            },
            titleTextStyle: {
                fontSize: 14,
                bold: true,
                color: '#848484'
            }
        }
    };

}
