import { DepartmentModel } from './departmentModel';
import { IbankDetails, IcustomStationDetails, IfileData, IportDetails, IprofileDetails, IstakeHolderDetails } from './wizardDataModel';

export class SubmitModel {
  public stakeHoldertype: string;
  public emailID: string;
  public password: string;
  public confirmPassword: string;
  public altEmailId: string;
  public firstName: string;
  public lastName: string;
  public isMaster: boolean;
  public gender: number;
  public nationality: string;
  public IdNumber: string;
  public IdType: string;
  public country: any;
  public state: any;
  public city: any;
  public pin: any;
  public addressLine1: string;
  public addressLine2: string;
  public mobCode: number;
  public mobNumber: string;
  public primaryTelCode: number;
  public primaryTelNumber: string;
  public altCode: number;
  public altNumber: string;
  public idocTypeVal: string;
  public faxCode: number;
  public faxNumber: string;
  public customStationId: string[];
  public registeredCSId: string;
  public odocTypeVal: string;
  public businessEntityId: string;
  public regAuthority: string;
  public businessEntity: string;
  public organizationName: string;
  public organizationId: number;
  public brn: string;
  public gstNumber: string;
  public registeredCSName: string;
  public countryname: string;
  public statename: string;
  public cityname: string;
  public pincode: string;
  public regnStationCode: string;
  public regnAgencyName: string;
  public deptRequired: boolean;
  public regnAgencyCode: string;
  public description: string;
  public regAuthorityName: string;
  public mobTeleCodeValue: string;
  public primaryTeleCodeValue: string;
  public altrTeleCodeValue: string;
  public faxTeleCodeValue: string;
  public departmentName: string;
  public indvidualFiles: IfileData[] = [];
  public orgFiles: IfileData[] = [];
  public banks: IbankDetails[] = [];
  public associatedports: IportDetails[] = [];
  public associatedCustomStations: IcustomStationDetails[] = [];
  public associatedStakeHolders: IstakeHolderDetails[] = [];
  public associatedProfiles: IprofileDetails[] = [];
  public departments: DepartmentModel[] = [];
  public department: DepartmentModel;
  public modifiedDate: Date;
  public designationTypeId: any;
  public departmentId: any;
  public registrationAuthority: any;
  public businessAuthority: any;
  public userDetails: any;
  public isCustomCounter: boolean;
}

export class AddressData {
  public country: any;
  public state: any;
  public city: any;
  public pin: any;
}
