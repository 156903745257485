/* tslint:disable: max-line-length */
import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Injectable()
export class LicenseConfig implements OnInit {

  countryApiUrl: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MasterData/GetCountry?filterValue=';
  stateApiUrl: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MasterData/GetState?filterValue=';
  cityApiUrl: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MasterData/GetCity?filterValue=';
  postalCodeApiUrl: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MasterData/GetPostalCode?filterValue=';

  // License List
  newLicenseListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/NewLicenseListDetails';
  listLicenseListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/ListLicenseListDetails';
  openLicenseListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/OpenLicenseListDetails';
  submitLicenseListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/SubmitLicenseListDetails';
  deleteLicenseListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/DeleteLicenseListDetails';
  checkDuplicatelicenselistname: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/CheckDuplicateLicenseName';

  // License Name
  newLicenseNameDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/NewLicenseNameDetails';
  listLicenseNameDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/ListLicenseNameDetails';
  openLicenseNameDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/OpenLicenseNameDetails';
  submitLicenseNameDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/SubmitLicenseNameDetails';
  deleteLicenseNameDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/DeleteLicenseNameDetails';

  getLicenseListDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/GetLicenseListDetails';
  getLicenseListDetailsForFilter = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/GetLicenseListDetailsForFilter';
  getLicenseNameDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/GetLicenseNames';
  getLicenseNamesForFilter = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseListDetails/GetLicenseNamesForFilter';

  getModuleType: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MasterData/GetModuleType';
  getCustomStations: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MasterData/GetCustomStations?filterValue=';

  // Common
  getRemarksHistoryData: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseCommon/GetRemarksHistoryData';
  getLicensingWfStates: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseCommon/GetLicensingWfStates';

  newComplianceCheckList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceCheckList/NewComplianceCheckList';
  openComplianceCheckList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceCheckList/OpenComplianceCheckList';
  submitComplianceCheckList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceCheckList/SubmitComplianceCheckList';
  listComplianceCheckList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceCheckList/ListComplianceCheckList';
  deleteComplianceCheckList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceCheckList/DeleteComplianceCheckList';

  // Inspection Details
  newInspectionDetail: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/InspectionDetails/NewInspectionDetails';
  listInspectionDetail: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/InspectionDetails/ListInspectionDetails';
  openInspectionDetail: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/InspectionDetails/OpenInspectionDetails';
  submitInspectionDetail: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/InspectionDetails/SubmitInspectionDetails';
  deleteInspectionDetail: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/InspectionDetails/DeleteInspectionDetails';
  checkDuplicateInspection: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/InspectionDetails/CheckInpectionsExists';

  // Acts,Sections and regulations
  newActsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/NewActsDetails';
  listActsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/ListActsDetails';
  openActsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/OpenActsDetails';
  submitActsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/SubmitActsDetails';
  deleteActsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/DeleteActsDetails';
  getActsAssociatedData: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/GetActsAssociatedData';
  newSectionsRegulations: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/NewSectionsRegulations';
  listSectionsRegulations: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/ListSectionsRegulations';
  openSectionsRegulations: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/OpenSectionsRegulations';
  submitSectionsRegulations: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/SubmitSectionsRegulations';
  deleteSectionsRegulations: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/DeleteSectionsRegulations';
  duplicateActsname: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/CheckDuplicateActsName';

  getActsDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/GetActsDetails';
  getSectionsRegulations = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SectionsRegulations/GetSectionsRegulations';

  // License Reason
  newLicenseReasons: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseReasons/NewLicenseReasons';
  listLicenseReasons: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseReasons/ListLicenseReasons';
  openLicenseReasons: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseReasons/OpenLicenseReasons';
  submitLicenseReasons: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseReasons/SubmitLicenseReasons';
  deleteLicenseReasons: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseReasons/DeleteLicenseReasons';
  checkDuplicateLicenseReasons: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseReasons/CheckLicenseReasonsExists';

  // Additional Facility Detailss
  newAdditionalFacilityDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalFacility/NewAdditionalFacility';
  listAdditionalFacilityDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalFacility/ListAdditionalFacility';
  openAdditionalFacilityDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalFacility/OpenAdditionalFacility';
  submitAdditionalFacilityDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalFacility/SubmitAdditionalFacility';
  deleteAdditionalFacilityDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalFacility/DeleteAdditionalFacility';
  checkDuplicateAdfCode = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalFacility/AdditionalFacilityDuplicateNameExists';

  // Recommendation List
  newRecommendationListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConditionsAndRecommendations/NewConditionsAndRecommendations';
  listRecommendationListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConditionsAndRecommendations/ListConditionsAndRecommendations';
  openRecommendationListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConditionsAndRecommendations/OpenConditionsAndRecommendations';
  submitRecommendationListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl +
    '/api/ConditionsAndRecommendations/SubmitConditionsAndRecommendations';
  deleteRecommendationListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl +
    '/api/ConditionsAndRecommendations/DeleteConditionsAndRecommendations';
  checkDuplicateRecommendationListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl +
    '/api/ConditionsAndRecommendations/CheckConditionsAndRecommendations';

  // MachineryDetails
  newMachineryDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MachineryDetails/NewMachineryDetails';
  listMachineryDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MachineryDetails/ListMachineryDetails';
  openMachineryDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MachineryDetails/OpenMachineryDetails';
  submitMachineryDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MachineryDetails/SubmitMachineryDetails';
  deleteMachineryDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/MachineryDetails/DeleteMachineryDetails';

  // License Configuration
  newLicenseConfigurationsDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseConfigurations/NewLicenseConfigurations';
  listLicenseConfigurationsDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseConfigurations/ListLicenseConfigurations';
  openLicenseConfigurationsDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseConfigurations/OpenLicenseConfigurations';
  submitLicenseConfigurationsDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseConfigurations/SubmitLicenseConfigurations';
  deleteLicenseConfigurationsDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseConfigurations/DeleteLicenseConfigurations';

  // SupportDocuments
  newLicenseSupportingDocuments: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSupportingDocument/NewSupportingDocument';
  listLicenseSupportingDocuments: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSupportingDocument/ListSupportingDocument';
  openLicenseSupportingDocuments: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSupportingDocument/OpenSupportingDocument';
  submitLicenseSupportingDocuments: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSupportingDocument/SubmitSupportingDocument';
  deleteLicenseSupportingDocumentsData: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSupportingDocument/DeleteSupportingDocument';
  checkDuplicateSupportingDocument: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSupportingDocument/CheckSupportingDocExists';

  // License AEO Sales Value
  newSalesValue: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SalesValue/NewAEOSalesValue';
  listSalesValue: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SalesValue/ListAEOSalesValue';
  openSalesValue: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SalesValue/OpenAEOSalesValue';
  submitSalesValue: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SalesValue/SubmitAEOSalesValue';
  deleteSalesValue: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SalesValue/DeleteAEOSalesValue';
  // getTariffItems: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SalesValue/GetTariffItems?filterValue=';
  getTariffItems: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseCommon/GetTariffItems?filterValue=';

  // License AEO compliance Check List
  newComplianceCheckListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceChecklist/NewAEOComplianceCheckList';
  listComplianceCheckListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceChecklist/ListAEOComplianceCheckList';
  openComplianceCheckListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceChecklist/OpenAEOComplianceCheckList';
  submitComplianceCheckListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceChecklist/SubmitAEOComplianceCheckList';
  deleteComplianceCheckListDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceChecklist/DeleteAEOComplianceCheckList';
  getQuestionCode: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ComplianceCheckList/GetQuestionName';

  // License AEO Goods Details
  newGoodsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/GoodsDetails/NewAEOGoodsDetails';
  listGoodsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/GoodsDetails/ListAEOGoodsDetails';
  openGoodsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/GoodsDetails/OpenAEOGoodsDetails';
  submitGoodsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/GoodsDetails/SubmitAEOGoodsDetails';
  deleteGoodsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/GoodsDetails/DeleteAEOGoodsDetails';

  // Assign Officer
  // getPermitRequestAssignedOfficerData = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssignOfficerDetails/GetRequestAssignedOfficersData';
  // getPermitRequestOfficersList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssignOfficerDetails/GetRequestAvailableOfficersData';
  // updatePermitRequestData: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssignOfficerDetails/UpdateRequestAssignedUserId';

  // License Aeo Associate Tenants
  getAssocaitedTenants: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateTenants/GetAssocaitedTenants';
  submitAssociatedTenants: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateTenants/SubmitAssociatedTenants';
  getAvaiableTenants: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateTenants/ListIndiAndOrganizations';
  getAssociateIndOrganizationsData: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateTenants/ListIndiOrganizationByIds';
  getUserProfileId: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationSharedData/GetUserProfileId';

  // License Surety Transaction
  openSuretyDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SuretyTransactions/OpenSuretyTransaction';
  submitSuretyDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SuretyTransactions/SubmitSuretyTransaction';
  getAssociatedSureties: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SuretyTransactions/GetAssociatedSureties';
  submitAssociatedSureties: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SuretyTransactions/SubmitAssociatedSureties';
  getAvailableSureties: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SuretyTransactions/GetAvailableSureties';

  // Assosite Allowed Goods
  getAssociatedTariffItem: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AllowedGoods/GetAssociatedTariffItem';
  listTariffItems: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseCommon/ListTariffItems';
  submitAllowedGoodsTariffItems: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AllowedGoods/SubmitAllowedGoodsTariffItems';

  // Associate Organizations Exempted
  getAssociatedExemptedOrganization: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ExemptedOrganizations/GetAssociatedExemptedOrganization';
  ListOrganization: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ExemptedOrganizations/ListOrganization';
  submitExeptedOrganizations: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ExemptedOrganizations/SubmitExeptedOrganizations';

  // Associate Agency List
  getAssociatedAgency: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateAgencyList/GetAssociatedAgency';
  submitAssociateAgency: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateAgencyList/SubmitAssociateAgency';

  // Associate StakeHolderCategory
  submitStakeholder: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/StakeholderCategory/SubmitStakeholderCategory';
  listAssociatedStakeholder: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/StakeholderCategory/GetAssociatedStakeHolders';
  ListPosting: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/StakeholderCategory/ListPostings';

  // Associate Ccompliance Check List
  associateSubmitComplianceCheckList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateComplianceCheckList/SubmitComplianceCheckList';
  getAssociatedCompliance: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateComplianceCheckList/GetAssociatedCompliance';
  associateListComplianceCheckList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateComplianceCheckList/ListComplianceCheckList';

  // Associate Aeo Panel Members
  submitAeoPanelMembers: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AeoPanelMember/SubmitAeoPanelMembers';
  getAeoPanelMembers: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AeoPanelMember/GetAeoPanelMembers';
  getAssosiatedAeoPanenMember: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AeoPanelMember/GetAssosiatedAeoPanenMember';


  // Upload Document
  newLicenseUploadDocument: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadDocuments/NewStakeholderCategoryActivation';
  submitLicenseUploadDocument: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadDocuments/SubmitUploadDocuments';
  openLicenseUploadDocument: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadDocuments/OpenUploadDocuments';
  deleteLicenseUploadDocument: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadDocuments/DeleteUploadDocuments';
  downloadUploadDocument: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadDocuments/DownloadUploadDocument';

  // LicenseInformation
  newLicenseInformation: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseInformation/NewLicenseInformation';
  listLicenseInformation: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseInformation/ListLicenseInformation';
  openLicenseInformation: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseInformation/OpenLicenseInformation';
  submitLicenseInformation: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseInformation/SubmitLicenseInformation';
  deleteLicenseInformation: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseInformation/DeleteLicenseInformation';
  getLicenseInformation: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseInformation/GetLicenseInformation';
  getReviewerDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseInformation/GetReviewerDetails';
  submitReviewerDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseInformation/SubmitReviewerDetails';
  GetQuestionsOnConfigForAEO: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseInformation/GetQuestionsOnConfigForAEO';



  // License AEO View Permits
  viewPermits: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ViewPermits/ViewPermits';

  // License Officials Details
  newLicenseOfficialDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseOfficialDetails/NewLicenseOfficialDetails';
  listLicenseOfficialDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseOfficialDetails/ListLicenseOfficialDetails';
  openLicenseOfficialDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseOfficialDetails/OpenLicenseOfficialDetails';
  submitLicenseOfficialDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseOfficialDetails/SubmitLicenseOfficialDetails';
  deleteLicenseOfficialDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseOfficialDetails/DeleteLicenseOfficialDetails';

  // License AEO Additional Info
  newAdditionalInfo: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalInfo/NewAEOAdditionalInfo';
  listAdditionalInfo: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalInfo/ListAEOAdditionalInfo';
  openAdditionalInfo: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalInfo/OpenAEOAdditionalInfo';
  submitAdditionalInfo: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalInfo/SubmitAEOAdditionalInfo';
  deleteAdditionalInfo: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AdditionalInfo/DeleteAEOAdditionalInfo';

  // Associate License Reasons
  newAssociateLicenseReason: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateLicenseReason/NewAssociateLicenseReason';
  listAssociateLicenseReason: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateLicenseReason/ListAssociateLicenseReason';
  submitAssociateLicenseReason: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociateLicenseReason/SubmitAssociateLicenseReason';

  // Associate Custom Stations
  listCustomStations: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociatedCustomStation/ListCustomStationDetails';
  submitCustomStations: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociatedCustomStation/SubmitCustomStation';
  getAssociatedCustomStations: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociatedCustomStation/GetAssociatedCustomStations';
  getCustomStationsbyId: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssociatedCustomStation/ListCustomStationsById';

  // Associate License Supporting Documents
  getLicenseMasterSupportingDocumentData = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConfigSupportingDocuments/GetLicensingMasterDataDocuments';
  submitConfigSupportDocumentData = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConfigSupportingDocuments/SubmitConfigSupportDocumentData';
  getAssociatedConfigSupportDocs = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConfigSupportingDocuments/GetAssociatedConfigSupportDocs';
  viewAssociatedConfigSupportDocs = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConfigSupportingDocuments/ViewAssociatedSupportDocs';
  getSupportDocumentName = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConfigSupportingDocuments/GetLicenseDocumentType';

  // Associate License Inpection Details
  getLicenseMasterInpectionDetailsData = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConfigInspectionDetails/GetLicensingMasterDataInspections';
  submitConfigInpectionDetailsData = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConfigInspectionDetails/SubmitConfigInspectionDetailData';
  getAssociatedConfigInpectionDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConfigInspectionDetails/GetAssociatedConfigInspectionDetails';
  viewAssociatedInpectionDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/ConfigInspectionDetails/ViewAssociatedInspections';

  // Associate condtional and recommondation
  getLicensingMasterDataRecommendations = environment.appSettings.apiEndpoints.licenseApiBaseUrl +
    '/api/ConfigConditionsAndRecommendations/GetLicensingMasterDataRecommendations';
  submitConfigRecommendationDetailData = environment.appSettings.apiEndpoints.licenseApiBaseUrl +
    '/api/ConfigConditionsAndRecommendations/SubmitConfigRecommendationDetailData';
  getAssociatedConfigRecommendationDetails = environment.appSettings.apiEndpoints.licenseApiBaseUrl +
    '/api/ConfigConditionsAndRecommendations/GetAssociatedConfigRecommendationDetails';

  // License AEO Support Documents
  viewApplicableDocuments: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/SupportingDocument/ListSupportingDocument';

  // AEO Required Upload Documents
  openUploadDocument: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadRequiredDocs/OpenUploadDocuments';
  submitUploadDocument: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadRequiredDocs/SubmitUploadDocuments';
  deleteUploadDocument: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadRequiredDocs/DeleteUploadDocuments';
  downloadUploadDoc: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadRequiredDocs/DownloadUploadDocuments';
  getConfigSuppDocsDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadRequiredDocs/GetConfigSuppDocsData?filterValue=';
  getMcnTypes: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/UploadRequiredDocs/GetModuleType';

  // Authorization Request
  newAuthorizationRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/NewLicenseAuthorizationRequest';
  listAuthorizationRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/ListLicenseAuthorizationRequest';
  openAuthorizationRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/OpenLicenseAuthorizationRequest';
  submitAuthorizationRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/SubmitLicenseAuthorizationRequest';
  deleteAuthorizationRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/DeleteLicenseAuthorizationRequest';
  getLicenseCategoriesForAuthorization: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/GetLicenseCategories';
  getLicenseNamesOnCategory: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/GetLicenseNames';
  getLicenseSectionsOnCategory: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/GetLicenseSections?';
  getLicenseApplicationCategories: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/GetLicenseApplicationCategories';
  getLicenseApplicationNames: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/GetLicenseApplicationNames';
  getAuthorizationRequestWFStates: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl
    + '/api/LicenseAuthorizationRequest/GetLicenseAuthorizationRequestWorkflowStates';
  getLicenseRejectionReasons: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAuthorizationRequest/GetLicenseRejectionReasons';
  quantityUnitApiUrl: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/QuantityUnit/GetQuentityUnit?filterValue=';
  checkAuthorizationForLicenseCategoryAndNameUrl: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl +
    '/api/LicenseAuthorizationRequest/CheckAuthorizationForLicenseCategoryAndName';

  // License Generic Goods
  newGoods: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/NewGoods';
  listGoods: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/ListGoods';
  openGoods: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/OpenGoods';
  submitGoods: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/SubmitGoods';
  deleteGoods: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/DeleteGoods';
  getAllowedGoods = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/GetAllowedGoods?filterValue=';

  // License Machinery and Equipments
  newMachinery: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/NewGoods';
  listMachinery: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/ListGoods';
  openMachinery: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/OpenGoods';
  submitMachinery: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/SubmitGoods';
  deleteMachinery: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/DeleteGoods';
  getAllowedMachinery = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Goods/GetAllowedGoods?filterValue=';

  // License Generic Goods
  orgDetailsGetUserProfileId: any = environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/RegistrationSharedData/GetUserProfileId';

  // License Application Urls
  // newLicenseApplication = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Application/NewLicenseApplication';
  // listLicenseApplication = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Application/ListLicenseApplications';
  // submitLicenseApplication = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Application/SubmitLicenseApplication';
  // openLicenseApplication = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Application/OpenLicenseApplication';
  // deleteLicenseApplication = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Application/DeleteLicenseApplication';
  // listAllowedLicenseConfigItem = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Application/ListAllowedLicenseConfigItem';
  getLicenseApplicableFees = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Application/GetLicenseApplicableFees';
  getLicenseBillsData = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/Application/GetLicensePaymentBillsData';

  // License Aeo Panel Notification
  newLicenseAeoPanelNotification: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAeoPanelNotification/NewLicenseAeoPanelNotification';
  openLicenseAeoPanelNotification: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseAeoPanelNotification/OpenLicenseAeoPanelNotification';
  submitLicenseAeoPanelNotification: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl +
    '/api/LicenseAeoPanelNotification/SubmitLicenseAeoPanelNotification';

  // License Aeo Panel Allocation List
  getAssociatedAllocationList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AllocationList/GetAssocaitedAllocationList';
  submitAllocationList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AllocationList/SubmitAssociatedAllocationList';
  listAllocationData: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AllocationList/ListIndOrganizations';
  // License Suspension Request
  newLicenseSuspension = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSuspensionRequest/NewSuspensionRequest';
  listLicenseSuspension = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSuspensionRequest/ListSuspensionRequest';
  openLicenseSuspension = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSuspensionRequest/OpenSuspensionRequest';
  submitLicenseSuspension = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSuspensionRequest/SubmitSuspensionRequest';
  deleteLicenseSuspension = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSuspensionRequest/DeleteSuspensionRequest';
  getAllowedLicenseInformation = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSuspensionRequest/GetLicenseForSuspension?filterValue=';
  getSuspensionReasons = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSuspensionRequest/GetLicenseSuspensionReasons';
  getSuspensionReviewerDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSuspensionRequest/GetReviewerDetails';
  submitSuspensionReviewerDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSuspensionRequest/SubmitReviewerDetails';

  // Assigin Officers
  getSecretariatUsersListAsync: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssignOfficerDetails/GetSecretariatUsersList';
  updateLicenseInformation: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssignOfficerDetails/UpdateLicenseInformation';
  getLicenseSuspensionRequestDocumentData: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/LicenseSuspensionRequest/GetSuspenseLicenseRequestDocument';

  // RenewalRequest
  newRenewalRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/RenewalRequest/NewRenewalRequest';
  listRenewalRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/RenewalRequest/ListRenewalRequest';
  openRenewalRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/RenewalRequest/OpenRenewalRequest';
  submitRenewalRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/RenewalRequest/SubmitRenewalRequest';
  deleteRenewalRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/RenewalRequest/DeleteRenewalRequest';
  getLicenseConfigurationDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/RenewalRequest/GetLicenseConfigurationDetails';
  getLicensesList = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/RenewalRequest/GetLicensesList?filterValue=';
  getRenewalLicenseApplicableDocument: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/RenewalRequest/GetRenewalRequestDocument';
  getRenewalRequestReviewerDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/RenewalRequest/GetRenewalRequestReviewerDetails';
  submitRenewalRequestReviewerDetails: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/RenewalRequest/SubmitRenewalRequestReviewerDetails';
  getLicensingOfficerUsersList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssignOfficerDetails/GetLicensingOfficerUsersList';
  updateRenewalRequest: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssignOfficerDetails/UpdateRenewalRequest';
  getAssignedOfficersList: any = environment.appSettings.apiEndpoints.licenseApiBaseUrl + '/api/AssignOfficerDetails/GetAssignedOfficersList';

  ngOnInit() { }

}
