<div class="rndm-scltn-cnfg-list" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <!-- -------------------------------------------------------------------------Compilance -->
    <div fxLayout="column Wrap" *ngIf="this.showComplianceList">
        <div fxLayout="row Wrap">
            <div fxLayout="column Wrap" fxFlex="100">
                <div fxLayout="row" class="flex-risk-head flex-random-head">
                    <h2 fxFlex="90" class="main-title main-title-rndm-scltn-cnfg-list pb-0">
                        <span>Compliance Reports</span>
                    </h2>
                </div>
            </div>
        </div>
        <div fxFlex="100" class="docs-table mob-table">
            <table fxFlex="100" class="table table-striped custom-table mrg-20" style="width:100%">
                <thead class="docs-table-thead">
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="docs-table-body">
                    <tr *ngFor="let report of opmComplianceReports">
                        <td>{{report.no}}</td>
                        <td>{{report.name}}</td>
                        <td>{{report.description}}</td>
                        <td>
                            <i class="icon-black-edit mr-10" (click)="ViewComplianceReports(report.name)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- ------------------------------------------------------------------------- Declaration-->
    <div fxLayout="column Wrap" *ngIf="this.showDeclarationList">
        <div fxLayout="row Wrap">
            <div fxLayout="column Wrap" fxFlex="100">
                <div fxLayout="row" class="flex-risk-head flex-random-head">
                    <h2 fxFlex="90" class="main-title main-title-rndm-scltn-cnfg-list pb-0">
                        <span>DECLARATION REPORTS</span>
                    </h2>
                </div>
            </div>
        </div>
        <div fxFlex="100" class="docs-table mob-table">
            <table fxFlex="100" class="table table-striped custom-table mrg-20" style="width:100%">
                <thead class="docs-table-thead">
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="docs-table-body">
                    <tr *ngFor="let report of opmDeclarationReports">
                        <td>{{report.no}}</td>
                        <td>{{report.name}}</td>
                        <td>{{report.description}}</td>
                        <td>
                            <i class="icon-black-edit mr-10" (click)="ViewComplianceReports(report.name)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- -------------------------------------------------------------------------Enforcement -->
    <div fxLayout="column Wrap" *ngIf="this.showEnforcementList">
        <div fxLayout="row Wrap">
            <div fxLayout="column Wrap" fxFlex="100">
                <div fxLayout="row" class="flex-risk-head flex-random-head">
                    <h2 fxFlex="90" class="main-title main-title-rndm-scltn-cnfg-list pb-0">
                        <span>ENFORCEMENT REPORT</span>
                    </h2>
                </div>
            </div>
        </div>
        <div fxFlex="100" class="docs-table mob-table">
            <table fxFlex="100" class="table table-striped custom-table mrg-20" style="width:100%">
                <thead class="docs-table-thead">
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="docs-table-body">
                    <tr *ngFor="let report of opmEnforcementReports">
                        <td>{{report.no}}</td>
                        <td>{{report.name}}</td>
                        <td>{{report.description}}</td>
                        <td>
                            <i class="icon-black-edit mr-10" (click)="ViewComplianceReports(report.name)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- -------------------------------------------------------------------------Workload -->
    <div fxLayout="column Wrap" *ngIf="this.showWorkloadList">
        <div fxLayout="row Wrap">
            <div fxLayout="column Wrap" fxFlex="100">
                <div fxLayout="row" class="flex-risk-head flex-random-head">
                    <h2 fxFlex="90" class="main-title main-title-rndm-scltn-cnfg-list pb-0">
                        <span>OVERALL WORKLOAD REPORTS</span>
                    </h2>
                </div>
            </div>
        </div>
        <div fxFlex="100" class="docs-table mob-table">
            <table fxFlex="100" class="table table-striped custom-table mrg-20" style="width:100%">
                <thead class="docs-table-thead">
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="docs-table-body">
                    <tr *ngFor="let report of opmWorkloadReports">
                        <td>{{report.no}}</td>
                        <td>{{report.name}}</td>
                        <td>{{report.description}}</td>
                        <td>
                            <i class="icon-black-edit mr-10" (click)="ViewComplianceReports(report.name)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- ------------------------------------------------------------------------- performance-->
    <div fxLayout="column Wrap" *ngIf="this.showPerformanceList">
        <div fxLayout="row Wrap">
            <div fxLayout="column Wrap" fxFlex="100">
                <div fxLayout="row" class="flex-risk-head flex-random-head">
                    <h2 fxFlex="90" class="main-title main-title-rndm-scltn-cnfg-list pb-0">
                        <span>PERFORMANCE REPORTS</span>
                    </h2>
                </div>
            </div>
        </div>
        <div fxFlex="100" class="docs-table mob-table">
            <table fxFlex="100" class="table table-striped custom-table mrg-20" style="width:100%">
                <thead class="docs-table-thead">
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="docs-table-body">
                    <tr *ngFor="let report of opmPerformanceReports">
                        <td>{{report.no}}</td>
                        <td>{{report.name}}</td>
                        <td>{{report.description}}</td>
                        <td>
                            <i class="icon-black-edit mr-10" (click)="ViewComplianceReports(report.name)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- ------------------------------------------------------------------------- prohibited-->
    <div fxLayout="column Wrap" *ngIf="this.showProhibitedList">
        <div fxLayout="row Wrap">
            <div fxLayout="column Wrap" fxFlex="100">
                <div fxLayout="row" class="flex-risk-head flex-random-head">
                    <h2 fxFlex="90" class="main-title main-title-rndm-scltn-cnfg-list pb-0">
                        <span>PROHIBITED GOODS REPORTS</span>
                    </h2>
                </div>
            </div>
        </div>
        <div fxFlex="100" class="docs-table mob-table">
            <table fxFlex="100" class="table table-striped custom-table mrg-20" style="width:100%">
                <thead class="docs-table-thead">
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="docs-table-body">
                    <tr *ngFor="let report of opmProhibitedReports">
                        <td>{{report.no}}</td>
                        <td>{{report.name}}</td>
                        <td>{{report.description}}</td>
                        <td>
                            <i class="icon-black-edit mr-10" (click)="ViewComplianceReports(report.name)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- -------------------------------------------------------------------------Revenue -->
    <div fxLayout="column Wrap" *ngIf="this.showRevenueList">
        <div fxLayout="row Wrap">
            <div fxLayout="column Wrap" fxFlex="100">
                <div fxLayout="row" class="flex-risk-head flex-random-head">
                    <h2 fxFlex="90" class="main-title main-title-rndm-scltn-cnfg-list pb-0">
                        <span>REVENUE REPORTS</span>
                    </h2>
                </div>
            </div>
        </div>
        <div fxFlex="100" class="docs-table mob-table">
            <table fxFlex="100" class="table table-striped custom-table mrg-20" style="width:100%">
                <thead class="docs-table-thead">
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="docs-table-body">
                    <tr *ngFor="let report of opmRevenueReports">
                        <td>{{report.no}}</td>
                        <td>{{report.name}}</td>
                        <td>{{report.description}}</td>
                        <td>
                            <i class="icon-black-edit mr-10" (click)="ViewComplianceReports(report.name)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>