import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { NVDBHistoricalDataModel } from '../../models/NVDBHistoricalDataModel';
import { NVDBHistoricalDataService } from './NVDBHistoricalDataService';


@Injectable()
export class NVDBHistoricalDataListResolver implements Resolve<ServiceDocument<NVDBHistoricalDataModel>> {
  constructor(private service: NVDBHistoricalDataService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<NVDBHistoricalDataModel>> {
    return this.service.listNVDBHistoricalData('', '');
  }
}

