import {
  CommonService,
  FxContext,
  MessageType,
  ServiceDocument,
  ServiceDocumentResult,
  UserProfile,
} from "@agility/frameworkcore";
import { Component, HostBinding, OnInit, HostListener } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import { StorageService } from "./storageService/storageService";
import { MatDialog } from "@angular/material";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FormGroup } from "@angular/forms";
import { LoginViewModel } from "./account/login/viewmodels/LoginViewModel";
import { McnConfig } from "./mcnConfig/McnConfig";
import { AccountMessages } from "./account/constant/accountConstant";
import { LoginService } from "./account/login/LoginService";
import { OAuthService } from "angular-oauth2-oidc";
import { isNullOrUndefined } from "util";
import { TranslateService } from "ng2-translate";
import { PlatformLocation } from "@angular/common";
import { environment } from "@env/environment";
import { IdleSessionService } from "./idleSessionService/IdleSessionService";
import { PWAService } from "./services/pwaService";
import { AuthService } from "./header/AuthService";

@Component({
  selector: "app-mcn",
  templateUrl: "./AppComponent.html",
})
export class AppComponent implements OnInit {
  public currentRouteUrl: string;
  @HostBinding("class.registration-bg") public registrationBg: boolean;
  @HostBinding("class.common-bg") public hideHeaders: boolean;
  public hideSubHeader = false;
  public noHeader = false;
  public singleHeader = false;
  public loginPage = false;
  public hideSubheaderr = false;
  public loginForm: FormGroup;
  public dataModel: LoginViewModel = new LoginViewModel();
  public serviceDocument: ServiceDocument<UserProfile>;
  public logval: ServiceDocumentResult;
  public arblanguage: boolean;
  public loginData: any;
  public isChatbotActive: boolean =
    environment.appSettings.keys.isChatbotActive;

  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private dialog: MatDialog,
    private fxContext: FxContext,
    private webStorage: StorageService,
    private httpClient: HttpClient,
    private accountService: LoginService,
    private commonService: CommonService,
    private service: LoginService,
    public storageService: StorageService,
    public appConfig: McnConfig,
    private translate: TranslateService,
    public location: PlatformLocation,
    private idleSessionService: IdleSessionService,
    public pwaService: PWAService,
    private authService: AuthService
  ) {
    if (!window.location.pathname.includes("Idp")) {
      location.onPopState(() => {
        this.logOut();
      });
    }
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.currentRouteUrl = this.router.url;
        this.webStorage.set("Showsubheader", false);
        if (event.title) {
          this.titleService.setTitle(event.title);
        }
        this.currentRouteUrl = this.router.url;
        this.registrationBg = this.currentRouteUrl.startsWith("/registration");
        this.hideHeaders =
          this.currentRouteUrl !== "/Account/activation-process" &&
          this.currentRouteUrl !== "/Account/activated-success" &&
          !this.currentRouteUrl.startsWith("/Account/Login") &&
          !this.currentRouteUrl.startsWith("/registration") &&
          !this.currentRouteUrl.startsWith("/Idp/Account/idp-layer");
        if (
          this.currentRouteUrl.startsWith("/Account/activation-process") ||
          this.currentRouteUrl.startsWith("/Account/activated-success") ||
          this.currentRouteUrl.startsWith("/Account/Login") ||
          this.currentRouteUrl.startsWith("/registration")
        ) {
          this.noHeader = true;
        } else {
          this.noHeader = false;
        }
        if (this.currentRouteUrl === "/Account/Home") {
          this.singleHeader = true;
          this.hideSubHeader = true;
        } else {
          this.hideSubHeader = false;
          this.singleHeader = false;
        }
        if (this.currentRouteUrl === "/Account/Login") {
          this.loginPage = true;
          this.idleSessionService.stop();
        } else {
          this.loginPage = false;
        }
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      });
  }
  @HostListener("document:keyup", ["$event"])
  @HostListener("document:mouseup", ["$event"])
  // @HostListener('document:mouseover', ['$event'])
  @HostListener("document:mousedown", ["$event"])
  @HostListener("document:mouseenter", ["$event"])
  @HostListener("document:keydown", ["$event"])
  @HostListener("document:click", ["$event"])
  @HostListener("document:wheel", ["$event"])
  @HostListener("document:scroll", ["$event"])
  resetTimer() {
    this.authService.notifyUserAction();
  }

  ngOnInit(): void {
    this.dialog.closeAll();
    if (window.location.pathname.includes("Idp")) {
      this.oauthService.configure(environment.appSettings.authConfig);
      this.oauthService.setStorage(localStorage);
      this.oauthService.tryLoginImplicitFlow().then(()=>this.oAuthAuthentication());
    } else if (window.location.pathname.includes("/api/Account/ConfirmEmail")) {
      const searchParams = new URLSearchParams(
        window.location.search.replace("?", "")
      );
      if (
        searchParams.get("encryptrUser") === null ||
        searchParams.get("encryptrUser") === undefined
      ) {
        this.router.navigate(["/Account/Login"]);
      } else {
        this.getDecryptData(searchParams.get("encryptrUser")).subscribe(
          (res) => {
            this.webStorage.set("activationUserId", res);
            this.router.navigate(["/Account/activated-success"]);
          }
        );
      }
      // } else if (window.location.search != null && window.location.search !== '') {
      //  const user = window.location.search.replace('?', '');
      //  this.DashboardLog(user);
    } else {
      this.router.navigate(["/Account/Login"]);
    }
    if (this.storageService.get("lang_code")) {
      this.translate.use(this.storageService.get("lang_code").toString());
    } else {
      this.storageService.set("lanPath", "../../../assets/i18n/English.jpg");
      this.storageService.set("lang_code", "en");
      this.storageService.set("language_Id", 1);
      this.translate.use("en");
    }
  }

  private getDecryptData(userId: any): Observable<any> {
    return this.httpClient.get(
      this.appConfig.decryption + "?decryptedValue=" + userId,
      { responseType: "text" }
    );
  }

  public logOut() {
    if (
      !isNullOrUndefined(this.fxContext) &&
      this.fxContext.IsAuthenticated === true
    ) {
      this.accountService.logout().subscribe((response) => {
        const result = response;
        if (result) {
          if (environment.appSettings.keys.isMicroService) {
            this.accountService
              .logoutIdp(this.oauthService.getAccessToken())
              .subscribe((res) => {});
          }
          this.fxContext.IsAuthenticated = false;
          this.fxContext = null;
          this.idleSessionService.stop();

          if (environment.appSettings.keys.isMicroService) {
            this.oauthService.logOut();
            this.accountService
              .logoutIdp(this.oauthService.getAccessToken())
              .subscribe((res) => {});
          }
          this.storageService.removeAll();
          this.router.navigate(["Account/Login"]);
        }
      });
    }
  }

  public oAuthAuthentication() {
    this.fxContext.IsAuthenticated = this.oauthService.hasValidAccessToken();
    if (
      this.fxContext.IsAuthenticated &&
      isNullOrUndefined(this.fxContext.userProfile)
    ) {
      this.fxContext.userProfile = new UserProfile();
      this.fxContext.userProfile.userAccessToken =
        this.oauthService.getAccessToken();
      this.accountService.getUserData().subscribe((response) => {
        this.loginData = response;
        this.fxContext.userProfile = response.userProfile;
        this.storageService.set(
          "IsRandomPassword",
          this.loginData.isRandomPassword
        );
        this.fxContext.IsAuthenticated = true;
        this.fxContext.languageId = 1;
        this.storageService.set("isAuthenticated", "true");
        this.storageService.set(
          "defaultCustomsId",
          this.loginData.defaultCustomsId
        );
        this.storageService.set(
          "defaultCustomsName",
          this.loginData.defaultCustomsName
        );
        this.storageService.set("defaultRole", this.loginData.defaultRole);
        this.storageService.set("defaultPort", this.loginData.defaultPort);
        this.storageService.set(
          "userRoles",
          this.loginData.userProfile.userRoles.map((x) => x.roleId)
        );
        this.storageService.set("defaultUserId", response.userProfile.userId);
        this.fxContext.menus = response.userProfile.menus;
        this.storageService.set(
          "userProfile",
          JSON.stringify(response.userProfile)
        );
        this.storageService.set("name", response.userProfile.name);
        this.hideHeaders = true;
        this.router.navigate(["/Dashboard/Home"]);
        this.idleSessionService.start();
      });
    }
  }
  public DashboardLog(userid: any) {
    this.dataModel.email = userid;
    this.accountService.DashboardLogin(this.dataModel).subscribe(
      (response) => {
        if (
          response.result !== null &&
          response.result.type !== undefined &&
          response.result.type === MessageType.success
        ) {
          if (
            this.service.serviceDocument.result.type === MessageType.success &&
            this.service.serviceDocument.userProfile !== null
          ) {
            this.fxContext.IsAuthenticated = true;
            this.storageService.set("isAuthenticated", "true");
            this.loginData = this.service.serviceDocument;
            this.storageService.set("userRoles", this.loginData.userRoles);
            this.storageService.set(
              "defaultCustomsId",
              this.loginData.defaultCustomsId
            );
            this.storageService.set(
              "defaultCustomsName",
              this.loginData.defaultCustomsName
            );
            const roleIds: any[] = [];
            const userRoles: any[] =
              this.service.serviceDocument.userProfile.userRoles;
            userRoles.forEach((x) => {
              if (
                x.normalizedName !== "SYSCUSTOMS" &&
                x.normalizedName !== "EXPORTER"
              ) {
                roleIds.push(x.roleId);
              }
            });
            this.storageService.set("defaultRole", roleIds);
            this.storageService.set("defaultPort", this.loginData.defaultPort);
            this.fxContext.menus =
              this.service.serviceDocument.userProfile.menus;
            this.fxContext.userProfile =
              this.service.serviceDocument.userProfile;
            this.storageService.set(
              "userProfile",
              JSON.stringify(this.service.serviceDocument.userProfile)
            );
            this.storageService.set(
              "name",
              this.service.serviceDocument.userProfile.name
            );
            this.router.navigate(["Dashboard/Home"]);
          } else {
            this.commonService.showAlert(
              this.service.serviceDocument.result.message,
              this.appConfig.getHardCodeMSg(AccountMessages.PleaseTryAgain),
              5000
            );
          }
        } else if (response["message"] !== null) {
          this.commonService.showAlert(
            response["message"],
            this.appConfig.getHardCodeMSg(AccountMessages.PleaseTryAgain),
            5000
          );
        }
      },
      () => {
        this.commonService.showAlert(
          this.appConfig.getHardCodeMSg(
            AccountMessages.LoginFailedPleaseTryAgain
          )
        );
      }
    );
  }
}
