import { ProfileModel } from '@agility/frameworkcore';
import { PostingModel } from './postingModel';

export class UserPostingModel extends ProfileModel {
    public userPostingId: number;
    public userId: string;
    public postingId: number;
    public isDefault: string;
    public associatedProfile: any[];
}
