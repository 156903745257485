import { ProfileModel } from '@agility/frameworkcore';
import { McnTypesModel } from '../../../../app/mdm/models/McnTypesModel';

export class SpecificationModel extends ProfileModel {
  specificationId: number;
  code: string;
  specificationType?: number;
  caption: string;
  // specificationName: string;
  specificationName: McnTypesModel;

}
