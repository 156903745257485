import { FxContext } from '@agility/frameworkcore';
import { Component, NgModule, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { StorageService } from '../../storageService/storageService';
import { OverlayMenuService } from '../OverlayMenuService';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-menu-modal',
  templateUrl: './MenuModalComponent.html',
})

export class MenuModalComponent implements OnInit {
  public overlayMenusList: any;
  public errorMessage: string;
  public menuFilter: any = { name: '' };


  constructor(private _overlayMenuService: OverlayMenuService,
    public dialogRef: MatDialogRef<MenuModalComponent>,
    private fxContext: FxContext,
    public storageService: StorageService,
    private router: Router,
    private activeroute: ActivatedRoute,
    private spinner: Ng4LoadingSpinnerService) {
  }
  ngOnInit(): void {
    this.activeroute.data.subscribe(() => {
      this.overlayMenusList = Array.from(new Set(this.fxContext.userProfile.menus));
    });
  }


  // Menu Dialog close
  public closeMenuDialog(url: any) {
    this.dialogRef.close();
    if (url != null) {
      this.dialogRef.afterClosed().subscribe(
        res => {
          this.router.navigate([url]);
        }).add((response) => this.spinner.show());
    }
  }

  public Integrationdashboard(){
    this.router.navigate(["/edi/EDIIntegration"]);
  }

  public getOverlayMenusList() {
    this._overlayMenuService.getOverlayMenus()
      .subscribe(
        (overlayMenusList) => this.overlayMenusList = overlayMenusList,
        (error) => this.errorMessage = error,
        () => console.log(),
      );
  }


  public getMenuId(value: any) {
    if (!isNullOrUndefined(value)) {
      return value.replace(/\s/g, '');
    }
  }
  public closeDialogmenu() {
    this.dialogRef.close();
  }
  // ngOnDestroy() {
  //     this.spinner.hide();
  // }
}


