<div fxLayout="column" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
    <div fxLayout="column" fxLayoutAlign="center center">
      <h1 mat-dialog-title class="dialog-title">{{'ForgotPassword' | translate}}</h1>
        <i class="star-heading-ul"></i>
    </div>
    <div fxLayout="column">
        <div mat-dialog-content>
            <p class="please-tpe-pwd mt-4 pt-2">
                {{'Pleasetypeyourregisteredemailinthebelowfieldtorecoveryourpasswordbye-mail' | translate }}
            </p>
            <form [formGroup]="forgotPWDForm" class="forget-pwd-form my-4" novalidate
                  (ngSubmit)="validateForgotPWD(forgotPWDForm.value, forgotPWDForm.valid)">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="100" class="p-2 position-relative head-search">
                        <mcn-input placeholder="{{'EmailID' | translate }}" controlName="emailId" [formGroup]="forgotPWDForm" class="astric-star "></mcn-input>
                        <mcn-error-messages [control]="forgotPWDForm.controls.emailId"></mcn-error-messages>
                    </div>
                </div>
                <div class="mt-4" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center center">
                    <mcn-button type="submit" cssClass="btn-custom-primary mr-0 mr-mat-3" text="{{'SUBMIT' | translate }}"></mcn-button>
                    <mcn-button type="button" cssClass="btn-custom-secondary mt-3 mt-mat-0" (OnClick)="cancel()" text="{{'CANCEL' | translate }}"></mcn-button>
                </div>
            </form>
        </div>
    </div>
</div>
<button mat-dialog-close class="dialog-close">
    <i class="icon-close"></i>
</button>
