import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export abstract class SafeUnsubscriber implements OnDestroy {
  protected componentDestroyed$: Subject<boolean>;
  constructor() {
    this.componentDestroyed$ = new Subject<boolean>();
    const f = this.ngOnDestroy;
    this.ngOnDestroy = function () {
      f.bind(this)();
      this.componentDestroyed$.next(true);
      this.componentDestroyed$.complete();
    };
  }

  ngOnDestroy() { }
}
