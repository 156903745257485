import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ServiceDocument } from '@agility/frameworkcore';
import { DeclarationDocumentService } from './RequiredDocumentsService';
import { DeclarationDocumentModel } from '../../models/requiredDocumentModels/DeclarationDocumentModel';

@Injectable()
export class DeclarationDocumentNewResolver implements Resolve<ServiceDocument<DeclarationDocumentModel>> {
    constructor(private newRequireDocservice: DeclarationDocumentService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<DeclarationDocumentModel>> {
        return this.newRequireDocservice.newDeclDocumentService();
    }
}

@Injectable()
export class DeclarationDocumentListResolver implements Resolve<ServiceDocument<DeclarationDocumentModel>> {
    constructor(private listRequireDocservice: DeclarationDocumentService) {
    }
    resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<DeclarationDocumentModel>> {
        return this.listRequireDocservice.listDeclDocumentService();
    }
}

@Injectable()
export class DeclarationDocumentOpenResolver implements Resolve<ServiceDocument<DeclarationDocumentModel>> {
    constructor(private openRequireDocservice: DeclarationDocumentService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<DeclarationDocumentModel>> {
        return this.openRequireDocservice.openDeclDocumentService(route.params['id']);
    }
}
