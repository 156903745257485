<div fxLayoutAlign="start" fxLayout="column"
     [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
  <div fxFlex.gt-sm="93" fxFlex.lt-sm="100">
    <mat-tab-group class="inbox-tab-group" (selectedTabChange)="tabChanged($event)">
      <mat-tab label="{{'All' | translate}}">
        <div [ngClass]="{'inbox-dates-scroll': (this.dateTotalCount > this.appConfig.appData.associatePageCount) }"
             infinite-scroll [infiniteScrollDistance]="modalScrollDistance"
             [infiniteScrollThrottle]="modalScrollThrottle" [scrollWindow]="false" (scrolled)="onDateScroll($event)">
          <mat-accordion>
            <mat-expansion-panel class="user-individual-card p-1 my-3 border"
                                 *ngFor="let msgdate of this.msgDates;let i=index" [expanded]="i === 0"
                                 (opened)="getMsgData(msgdate.createdDate, i)" (closed)="this.closePanel($event)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ msgdate.createdDate | date}}
                </mat-panel-title>
                <mat-panel-description>
                  <mat-icon class="accordion-arrow"></mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div [ngClass]="{'inbox-msgList-scroll': (this.pageCount > this.appConfig.appData.associatePageCount) }"
                   infinite-scroll [infiniteScrollDistance]="modalScrollDistance"
                   [infiniteScrollThrottle]="modalScrollThrottle" [scrollWindow]="false"
                   (scrolled)="onMsgListScroll($event, msgdate.createdDate)"> 
                  <accordion [showArrows]="true" class="inbox-accordion" [closeOthers]="true">
                    <accordion-group *ngFor="let msg of this.msgList;let i=index;" (onOpen)="updateUnReadMessage($event,msg.msgInboxId)">
                      <accordion-heading>
                        <div fxLayout="row" fxLayoutAlign="start center" class="inbox-accordion-row">
                          <div fxFlex="8" fxFlex.lt-mat="initial">
                            <div class="place-tag task-place-tag" *ngIf="this.getMsgType(msg.msgType) === 'Task'">
                              {{'Task' | translate}}
                            </div>
                            <div class="place-tag draft-place-tag" *ngIf="this.getMsgType(msg.msgType) === 'Info'">
                              {{'Info' | translate}}
                            </div>
                          </div>
                          <div fxLayout="row" fxLayout.lt-mat="column" fxFlex fxLayoutAlign="start center"
                               fxLayoutAlign.lt-mat="start start">
                            <div fxFlex>
                              <p class="order-data mt-2" [ngClass]="{'font-bold': (msg.unread === true),'':(msg.unread === false || msg.unread === null)}">{{msg.msgHeader | replaceToRefNumber :  'k_refNumber,k_refnumber,K_refNumber' : msg.refNumber }}</p>
                            </div>
                            <div fxFlex="initial" fxLayoutAlign="end center" fxLayoutAlign.mat="end start"
                                 fxLayoutAlign.lt-mat="start start" fxLayout="row" fxLayout.mat="column"
                                 fxLayout.lt-mat="column" class="order-details">
                              <div>
                                {{msg.moduleName | translate}} <span class="order-details-divider">|</span>
                                <span *ngIf="!this.isFirst">
                                  {{msg?.createdDate | date:'EEEE, MMMM d, yyyy h:mm:ss a'}}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div fxFlex="initial">
                            <div class="flag-icon" [ngClass]="{'select': (msg.isBookmark === true )}"
                                 (click)="updateboomark($event,msg.msgInboxId)"></div>
                          </div>
                          <div fxFlex="initial">
                            <div class="accordion-arrow" (click)="updateUnReadMessage($event,msg.msgInboxId)"></div>
                          </div>
                        </div>
                      </accordion-heading>
                      <div class="acc-group-content">
                        <div fxLayout="column">
                          <div class="from-tag"><span>From</span> : {{msg.organizationName}}</div>
                          <div class="m-bottom-20">{{ ((msg.msgBody | replaceToRefNumber : 'k_refNumber,k_refnumber,K_refNumber' : msg.refNumber) | replaceToRefNumber : 'K_UserName' :  this.userName) | replaceToRefNumber : 'K_OptionaValue' :  msg.optionalValue }}</div>
                          <div fxFlex="row" class="m-top-20" fxLayout.lt-mat="column">
                            <div fxFlex fxLayoutAlign="end end" fxLayoutAlign.lt-mat="center center"
                                 fxFlex.lt-mat="column" *ngIf="this.getMsgType(msg.msgType) === 'Task'">
                              <a class="ref-btn" (click)="gotoReference(msg.routingUrl,msg.routeParamsAsJson, msg.msgInboxId)">{{'GoToReference' | translate}}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </accordion-group>
                  </accordion>
              </div>
              <mcn-scroll-pagination [totalCount]="this.pageCount" [pageCount]="this.msgList?.length">
              </mcn-scroll-pagination>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <mcn-scroll-pagination [totalCount]="this.dateTotalCount" [pageCount]="this.msgDates?.length">
        </mcn-scroll-pagination>
      </mat-tab>
      <mat-tab *ngFor="let type of this.msgType; let i = index;" label="{{ type.name | translate}}">
        <mat-accordion>
          <div [ngClass]="{'inbox-dates-scroll': (this.dateTotalCount > this.appConfig.appData.associatePageCount) }"
               infinite-scroll [infiniteScrollDistance]="modalScrollDistance"
               [infiniteScrollThrottle]="modalScrollThrottle" [scrollWindow]="false" (scrolled)="onDateScroll($event)">
            <mat-accordion>
              <mat-expansion-panel class="user-individual-card p-1 my-3 border"
                                   *ngFor="let msgdate of this.msgDates;let i=index" [expanded]="i === 0"
                                   (opened)="getMsgData(msgdate.createdDate, i)" (closed)="this.closePanel($event)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ msgdate.createdDate | date}}
                  </mat-panel-title>
                  <mat-panel-description>
                    <mat-icon class="accordion-arrow"></mat-icon>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div [ngClass]="{'inbox-msgList-scroll': (this.pageCount > this.appConfig.appData.associatePageCount) }"
                     infinite-scroll [infiniteScrollDistance]="modalScrollDistance"
                     [infiniteScrollThrottle]="modalScrollThrottle" [scrollWindow]="false"
                     (scrolled)="onMsgListScroll($event, msgdate.createdDate)">   
                    <accordion [showArrows]="true" class="inbox-accordion" [closeOthers]="true">
                      <accordion-group *ngFor="let msg of this.msgList;let i=index;"  (onOpen)="updateUnReadMessage($event,msg.msgInboxId)">
                        <accordion-heading>
                          <div fxLayout="row" fxLayoutAlign="start center" class="inbox-accordion-row">
                            <div fxFlex="8" fxFlex.lt-mat="initial">
                              <div class="place-tag task-place-tag" *ngIf="this.getMsgType(msg.msgType) === 'Task'">
                                {{'Task' | translate}}
                              </div>
                              <div class="place-tag draft-place-tag" *ngIf="this.getMsgType(msg.msgType) === 'Info'">
                                {{'Info' | translate}}
                              </div>
                            </div>
                            <div fxLayout="row" fxLayout.lt-mat="column" fxFlex fxLayoutAlign="start center"
                                 fxLayoutAlign.lt-mat="start start">
                              <div fxFlex>
                                <p class="order-data mt-2" [ngClass]="{'font-bold': (msg.unread === true),'':(msg.unread === false || msg.unread === null)}">{{msg.msgHeader | replaceToRefNumber :  'k_refNumber,k_refnumber,K_refNumber' : msg.refNumber }}</p>
                              </div>
                              <div fxFlex="initial" fxLayoutAlign="end center" fxLayoutAlign.mat="end start"
                                   fxLayoutAlign.lt-mat="start start" fxLayout="row" fxLayout.mat="column"
                                   fxLayout.lt-mat="column" class="order-details">
                                <div>
                                  {{msg.moduleName | translate}} <span class="order-details-divider">|</span>
                                  <span *ngIf="!this.isFirst">
                                    {{msg?.createdDate | date:'EEEE, MMMM d, yyyy h:mm:ss a'}}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div fxFlex="initial">
                              <div class="flag-icon" [ngClass]="{'select': (msg.isBookmark === true )}"
                                   (click)="updateboomark($event,msg.msgInboxId)"></div>
                            </div>
                            <div fxFlex="initial">
                              <div class="accordion-arrow" (click)="updateUnReadMessage($event,msg.msgInboxId)"></div>
                            </div>
                          </div>
                        </accordion-heading>
                        <div class="acc-group-content">
                          <div fxLayout="column">
                            <div class="from-tag"><span> {{'From' | translate}}</span> : {{msg.organizationName}}</div>
                            <div class="m-bottom-20">{{((msg.msgBody | replaceToRefNumber : 'k_refNumber,k_refnumber,K_refNumber' : msg.refNumber) | replaceToRefNumber : 'K_UserName' :  this.userName) | replaceToRefNumber : 'K_OptionaValue' :  msg.optionalValue}}</div>
                            <div fxFlex="row" class="m-top-20" fxLayout.lt-mat="column">
                              <div fxFlex fxLayoutAlign="end end" fxLayoutAlign.lt-mat="center center"
                                   fxFlex.lt-mat="column" *ngIf="this.getMsgType(msg.msgType) === 'Task'">
                                <a class="ref-btn"
                                   (click)="gotoReference(msg.routingUrl,msg.routeParamsAsJson, msg.msgInboxId)">
                                  {{'GoToReference' | translate}}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </accordion-group>
                    </accordion>
            
                </div>
                <mcn-scroll-pagination [totalCount]="this.pageCount" [pageCount]="this.msgList?.length">
                </mcn-scroll-pagination>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <mcn-scroll-pagination [totalCount]="this.dateTotalCount" [pageCount]="this.msgDates?.length">
          </mcn-scroll-pagination>
        </mat-accordion>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
