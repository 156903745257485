import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { FxContext } from '@agility/frameworkcore';
import { StorageService } from '../../../storageService/storageService';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { Subject, throwError } from 'rxjs';
@Injectable()
export class InboxService {

  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  public filterMessageResponse: any;

  public fromDate: Date;
  public toDate: Date;
  public moduleName: any;
  public isActive: boolean;

  public isFilter: boolean;

  private refreshDynamicData = new Subject<string>();

  // Observable string streams
  public callRefreshDynamicData$ = this.refreshDynamicData.asObservable();

  // Service message commands
  public hitRefreshDynamicData() {
    this.refreshDynamicData.next();
  }
  private resetFilteredDynamicData = new Subject<string>();

  // Observable string streams
  public callResetFilteredDynamicData$ = this.resetFilteredDynamicData.asObservable();

  // Service message commands
  public hitResetFilteredDynamicData() {
    this.resetFilteredDynamicData.next();
  }

  constructor(private http: HttpClient, private appConfig: McnConfig,
    private storageService: StorageService, private fxContext: FxContext,) { }

  private handleError() {
    return null;
  }

  getMessageDatesList(msgTypeId: any = 0, isActive: boolean = true, fromDate: any = null,
    toDate: any = null, moduleName: any = null, pageIndex: any = 1,
    recordPerPage: any = this.appConfig.appData.associatePageCount): Observable<any> {
    return this.makePostCall(this.appConfig.getMessageDatesList, msgTypeId, isActive, pageIndex,
      recordPerPage, fromDate, toDate, moduleName);
  }

  getMessageList(msgTypeId: any = 0, isActive: boolean = true, selectedDate: Date = null,
    moduleName: any = null, pageIndex: any = 1, recordPerPage: any = this.appConfig.appData.pageCount): Observable<any> {
    return this.makePostCall(this.appConfig.getMessageList, msgTypeId, isActive, pageIndex, recordPerPage, selectedDate, null, moduleName);
  }

  public getUserDetails(userId: any): Observable<any> {
    return this.http.get(this.appConfig.getUserDetails + '?userId=' + userId, { headers: this.headers })
      .pipe(map((response) => response), catchError((res) => throwError({
        msg: this.handleError(),
        ngNavigationCancelingError: true
      })));
  }

  updateBookMarkMessage(msgInboxId: number, isBookMark: boolean): Observable<any> {
    const inboxModel = {
      msgInboxId: msgInboxId,
      isBookmark: isBookMark
    };
    return this.http.post(this.appConfig.updateBookMarkMessage, inboxModel)
      .pipe(map((response) => this.filterMessageResponse = response), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  updateUnReadMessage(msgInboxId: number, isRead: boolean): Observable<any> {
    const inboxModel = {
      msgInboxId: msgInboxId,
      Unread: isRead
    };
    return this.http.post(this.appConfig.updateUnReadMessage, inboxModel)
      .pipe(map((response) => this.filterMessageResponse = response), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  private makePostCall(url: any, msgTypeId: any, isActive: any, pageIndex: any, recordPerPage: any,
    fromDate: any, toDate: any, moduleName: any): Observable<any> {
    const filterinboxModel = this.setInboxFiltermodel(msgTypeId, isActive, pageIndex, recordPerPage, fromDate, toDate, moduleName);
    return this.http.post(url, filterinboxModel).pipe(map((response) => response));
  }

  private setInboxFiltermodel(msgTypeId: any, isActive: any, pageIndex: any, recordPerPage: any,
    fromDate: any, toDate: any, moduleName: any) {
    const index = this.fxContext.userProfile.userRoles.findIndex(x => x.name === 'SysCustoms');
    const inboxListModel = {
      roleId: this.storageService.get('defaultRole'),
      ownerCSId: index === -1 ? 0 : this.storageService.get('defaultCustomsId'),
      portId: this.storageService.get('defaultPort'),
      userId: this.storageService.get('defaultUserId'),
      msgTypeId: msgTypeId,
      isActive: isActive,
      skipRows: pageIndex,
      recordPerPage: recordPerPage,
      organaizationId: this.fxContext.userProfile.organizationId,
      fromDate: fromDate,
      toDate: toDate,
      moduleName: moduleName
    };
    return inboxListModel;
  }
}
