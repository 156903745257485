import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'mcn-input',
  template: `<div [formGroup]="formGroup"  *ngIf="isFormGroup">
       <mat-form-field class="mat-input-container">
          <input matInput [disabled]="disabled" id="txt_{{optionalId}}{{placeholder | removeSpace}}" type="{{type}}" maxlength="{{maxlength}}" minlength="{{minlength}}" placeholder="{{placeholder}}" (keydown)="handleKeydownEvent($event.target.value)" (keypress)="handleKeyPress($event.target.value)"
                    (keyup)="handleKeyupEvent($event.target.value)" (mouseenter)="onMouseEvent($event)" (mouseleave)="onMouseEvent($event)" (mousedown)="onMouseEvent($event)"
                    (mouseup)="onMouseEvent($event)" (blur)="handleblurEvent($event)" (mouseover)="onMouseEvent($event)" autocomplete="{{autocomplete}}" formControlName="{{controlName}}" [required]="required" [readonly]="readonly" />
       </mat-form-field>
    </div>
<div *ngIf="!isFormGroup">
       <mat-form-field class="mat-input-container">
          <input matInput [disabled]="disabled" [value]="value" id="txt_{{optionalId}}{{placeholder | removeSpace}}" type="{{type}}" placeholder="{{placeholder}}" (keydown)="handleKeydownEvent($event.target.value)" (keypress)="handleKeyPress($event.target.value)"
                    (keyup)="handleKeyupEvent($event.target.value)" (mouseenter)="onMouseEvent($event)" (mouseleave)="onMouseEvent($event)" (mousedown)="onMouseEvent($event)"
                    (mouseup)="onMouseEvent($event)" (blur)="handleblurEvent($event)" (mouseover)="onMouseEvent($event)" autocomplete="{{autocomplete}}" [required]="required" [readonly]="readonly" />
       </mat-form-field>
    </div>`
})

export class McnInputomponent {
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() optionalId = '';
  @Input() isFormGroup = true;
  @Input() placeholder: string;
  @Input() type = 'text';
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() autocomplete = 'new-password';
  @Input() maxlength: number;
  @Input() minlength: number;
  @Output() OnChange = new EventEmitter();
  @Output() OnKeydown = new EventEmitter<any>();
  @Output() OnKeypress = new EventEmitter<any>();
  @Output() OnKeyup = new EventEmitter<any>();
  @Output() OnFocus = new EventEmitter<any>();
  @Output() OnBlur = new EventEmitter<any>();
  @Output() OnMouseenter = new EventEmitter<any>();
  @Output() OnMousedown = new EventEmitter<any>();
  @Output() OnMouseup = new EventEmitter<any>();
  @Output() OnMouseover = new EventEmitter<any>();
  @Output() OnMouseleave = new EventEmitter<any>();
  @Input() value = '';
  private control: AbstractControl;
  constructor() {
  }

  onMouseEvent(event: MouseEvent): void {
    if (event.type === 'mouseleave') {
      this.OnMouseleave.emit(event);
    } else if (event.type === 'mouseenter') {
      this.OnMouseenter.emit(event);
    } else if (event.type === 'mousedown') {
      this.OnMousedown.emit(event);
    } else if (event.type === 'mouseup') {
      this.OnMouseup.emit(event);
    } else if (event.type === 'mouseover') {
      this.OnMouseover.emit(event);
    }
  }

  ngOnInit(): void {
    this.maxlength = this.maxlength > 0 ? this.maxlength : 100;
    if (this.isFormGroup) {
      const self: McnInputomponent = this;
      self.control = self.formGroup.controls[self.controlName];
      if (self.control) {
        if (self.disabled) {
          self.control.disable();
        }
      }
    }
  }

  handleKeydownEvent(event: any) {
    this.OnKeydown.emit(event);
  }

  handleKeyupEvent(event: any) {
    this.OnKeypress.emit(event);
  }

  handleKeyPress(event: any) {
    this.OnChange.emit(event);
  }

  handleblurEvent(event: any) {
    this.OnBlur.emit(event);
  }
  handleFocusEvent(event: any) {
    this.OnFocus.emit(event);
  }

  ngOnChanges(): void {
    const self: McnInputomponent = this;
    if (this.controlName !== undefined) {
      self.control = this.formGroup.controls[this.controlName];
      if (!this.disabled) {
        self.control.enable();
      }
      if (self.disabled) {
        self.control.disable();
      }
    }
  }
}
