import { FxContext } from '@agility/frameworkcore';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { TranslateService } from 'ng2-translate';
import { Subject, Subscription, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LoginService } from '../account/login/LoginService';
import { ChangePasswordComponent } from '../account/login/passwordManagement/changePassword/changePasswordComponent';
import { RegistrationService } from '../account/registration/commonRegistrationComponents/RegistrationService';
import { CommonSharedService } from '../common/CommonSharedService';
import { UserRoles } from '../common/Constants';
import { IdleSessionService } from '../idleSessionService/IdleSessionService';
import { StorageService } from '../storageService/storageService';
import { AuthService } from './AuthService';
import { LanguageSelectionModalComponent } from './languageSelection/LanguageSelectionModalComponent';
import { MenuModalComponent } from './menuModal/MenuModalComponent';
import { QuickLinksModalComponent } from './quickLinksModal/QuickLinksModalComponent';
import { SearchModalComponent } from './searchModal/SearchModalComponent';

@Component({
  providers: [RegistrationService],
  selector: 'app-header',
  templateUrl: './HeaderComponent.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  public modalStyles: any = {
    height: '100%',
    width: '100%',
    panelClass: 'fullscreen-modal',
  };
  public activeClass: boolean;
  public searchForm: FormGroup;
  public searchFormSubmitted: boolean;
  public isMenuModalVissible: boolean;
  public test = true;
  public arblanguage: boolean;
  public userData: any;
  public postingClass: string;
  public portClass: string;
  public userCustomStation: boolean;
  public userPort: boolean;
  public countdown: number;
  public active = false;

  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  minutesDisplay = 0;
  secondsDisplay = 0;
  endTime = 19;

  constructor(
    public fxContext: FxContext,
    private router: Router, public dialog: MatDialog,
    private accountService: LoginService,
    private idleSessionService: IdleSessionService,
    private registrationService: RegistrationService,
    public storageService: StorageService,
    private oauthStorage: OAuthStorage,
    private oauthService: OAuthService,
    private translate: TranslateService,
    private commonSharedService: CommonSharedService,
    private authService: AuthService) {
    if (this.fxContext.IsAuthenticated) {
      // this.idleSessionService.setKeepAliveSessionConfiguration();
    }
  }

  ngOnInit() {
    this.resetTimer();
    this.authService.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetTimer(endTime: number = this.endTime) {
    const interval = 1000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value =>
      this.render((duration - +value) * interval),
      err => { },
      () => {
      }
    );
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  public onClickMenu() {
    this.active = !this.active;
  }

  public getUserDetails(user: any) {
    this.userData = user;
    this.userCustomStation = this.userData.customStationName;
    this.userPort = this.userData.portName;
    switch (this.userData.stakeHolder) {
      case 'I':
      case 'O':
        this.postingClass = 'business-stakeholder-icon';
        break;
      case 'C':
        this.postingClass = 'customs-stkeholder-icon';
        break;
      case 'G':
        this.postingClass = 'govt-stakeholder-icon';
        break;
    }

    switch (this.userData.portType) {
      case 'SEA PORT':
        this.portClass = 'sea-icon';
        break;
      case 'RAIL PORT':
        this.portClass = 'train-icon';
        break;
      case 'LAND PORT':
        this.portClass = 'land-icon';
        break;
      case 'AIR PORT':
        this.portClass = 'air-icon';
        break;
    }
  }

  public logOut() {
    this.activeClass = !this.activeClass;
    this.accountService.logout().subscribe(
      (response) => {
        const result = response;
        if (result) {
          this.fxContext.IsAuthenticated = false;
          this.fxContext = null;
          this.idleSessionService.stop();
        }
        this.storageService.removeAll();
        this.router.navigate(['Account/Login']);
      });
    if (environment.appSettings.keys.isMicroService) {
      this.accountService.logoutIdp(this.oauthService.getAccessToken()).subscribe((res) => {
      });
      this.oauthService.logOut();
      this.oauthStorage.removeItem('access_token');
      this.oauthStorage.removeItem('id_token');
      this.oauthStorage.removeItem('refresh_token');
      localStorage.removeItem('nonce');
      localStorage.removeItem('PKCI_verifier');
      this.oauthStorage.removeItem('nonce');
      this.oauthStorage.removeItem('PKCI_verifier');
      this.oauthStorage.removeItem('expires_at');
      this.oauthStorage.removeItem('id_token_claims_obj');
      this.oauthStorage.removeItem('id_token_expires_at');
      this.oauthStorage.removeItem('id_token_stored_at');
      this.oauthStorage.removeItem('access_token_stored_at');
      this.oauthStorage.removeItem('granted_scopes');
      this.oauthStorage.removeItem('session_state');
    }
  }

  public openChangePasswordModal(event) {
    this.dialog.open(ChangePasswordComponent, {
      panelClass: 'custom-modal-container',
    });
  }

  // Toggle Class
  public onToggleClass() {
    this.activeClass = !this.activeClass;
  }

  public onClickOutside(event: Object) {
    this.activeClass = false;
  }

  // Search Dialog Open
  public openSearchDialog() {
    this.dialog.open(SearchModalComponent, this.modalStyles);
  }

  // Quick links Dailog open
  public openQuickLinksDialog() {
    this.dialog.open(QuickLinksModalComponent, this.modalStyles);
  }

  // Profile Dialog Open
  public openProfileDialog() {
    this.onToggleClass();
    this.registrationService.editProfile(this.fxContext.userProfile.userId).subscribe(
      (res) => {
        this.storageService.set('MyProfile', res);
        this.router.navigate(['/Account/user-profile']);
      });
  }

  // Menu Dialog Open
  public openMenuDialog() {
    this.dialog.open(MenuModalComponent, this.modalStyles);
  }

  // Lang. Drop down selection
  public selection(val: any) {
    this.translate.use(val);
    this.storageService.set('lang_code', val);
  }

  public openLanguageSelectionDialog() {
    const dialogRef = this.dialog.open(LanguageSelectionModalComponent, {
      panelClass: 'language-selection-modal',
    });
    dialogRef.afterClosed().subscribe(
      () => {
        if (dialogRef.componentInstance.lanSelect) {
          this.fxContext.languageId = dialogRef.componentInstance.languageId;
        }
      });
  }
}
