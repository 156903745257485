<!-- Organization Details starts -->
<div class="container" [ngClass]="{'arb': (this.webStorageService.get('lang_code') === 'Arb')}">
  <div class="clear-display-inline">
    <mat-card class="p-3 my-3 mt-4">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h5 class="main-title m-0">
          {{ (personalInfo?.referenceTypeId === "O" ? 'OrganizationDetails': personalInfo?.referenceTypeId === "I" ? 'IndividualDetails' : personalInfo?.referenceTypeId === "G" ? 'GovernmentDetails':'')  | translate }}
          <span class="mr-2 preview-content header-span">{{modifiedDate | date}} </span>
        </h5>
        <h6 class="main-title m-0">{{ (((personalInfo?.referenceTypeId === "O" && personalInfo?.isMaster) || (personalInfo?.referenceTypeId === "G" && personalInfo?.isMaster)) ? 'Master': personalInfo?.referenceTypeId === "I" ? 'Trader' :'') | translate }}</h6>
      </div>

      <expansion-panels-container class="custom-expansion-panel-container">
        <expansion-panel *ngIf='personalInfo?.referenceTypeId === "G"'>
          <expansion-panel-title>
            {{'GovernmentAuthorityInfo' | translate }}
          </expansion-panel-title>
          <expansion-panel-content>
            <div fxLayout="column wrap">
              <div fxLayout="row">
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{otherInfo?.registeredCustomStation}}</span></h6>
                  <p class="preview-label"><span>{{'RegistrationStationName' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{otherInfo?.registeredCustomStationCode}}</span></h6>
                  <p class="preview-label"><span>{{'StationCode' | translate }}</span></p>
                </div>
              </div>
              <div fxLayout="row">
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{organizationInfo?.organizationName}}</span></h6>
                  <p class="preview-label"><span>{{'GovernmentalAgencyName' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{organizationInfo?.code}}</span></h6>
                  <p class="preview-label"><span>{{'GovernmentalAgencyCode' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{organizationInfo?.gstNo}}</span></h6>
                  <p class="preview-label"><span>{{'GSTNo' | translate }}</span></p>
                </div>
              </div>
            </div>
          </expansion-panel-content>
        </expansion-panel>
        <expansion-panel *ngIf='personalInfo?.referenceTypeId === "O"'>
          <expansion-panel-title>
            {{ 'OrganizationInfo' | translate }}
          </expansion-panel-title>
          <expansion-panel-content>
            <div fxLayout="column wrap">
              <div fxLayout="row">
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{organizationInfo?.organizationName}}</span></h6>
                  <p class="preview-label"><span> {{ 'OrganizationName' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{organizationInfo?.registrationAuthority?.name}}</span></h6>
                  <p class="preview-label"><span>{{ 'RegistrationAuthority' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{organizationInfo?.businessAuthority?.name}}</span></h6>
                  <p class="preview-label"><span>{{ 'SelectedBusinessEntity' | translate }}</span></p>
                </div>
              </div>
              <div fxLayout="row">
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{organizationInfo?.brn}}</span></h6>
                  <p class="preview-label"><span>{{ 'BRN' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{organizationInfo?.gstNo}}</span></h6>
                  <p class="preview-label"><span>{{ 'GST' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2" *ngIf="personalInfo?.referenceTypeId === 'O'">
                  <h6 class="preview-content font-bold"><span>{{organizationInfo?.aeoStatus == 'Y'? 'Yes':'No'}}</span></h6>
                  <p class="preview-label"><span>{{ 'IsAEO' | translate }}</span></p>
                </div>
              </div>
            </div>
          </expansion-panel-content>
        </expansion-panel>
        <expansion-panel onOpen>
          <expansion-panel-title>
            {{ 'AccountInfo' | translate }}
          </expansion-panel-title>
          <expansion-panel-content>
            <div fxLayout="row wrap" fxFlex>
              <div fxFlex="33" fxFlex.xs="100" class="view-content pr-5">
                <h6 class="preview-content font-bold">
                  <span>
                    {{accountInfo?.emailId}}
                  </span>
                </h6>
                <p class="preview-label"><span>{{ 'EmailID' | translate }}</span></p>
              </div>
              <!--<div fxFlex="33" fxFlex.xs="100" class="view-content pl-5">
                  <h6 class="preview-content font-bold" [hidden]="!hidePassword">
                      <span>*********</span>
                      <a appCustomTooltip title="Show" (click)="hidePassword = false;">
                          <i class="eye-icon"></i>
                      </a>
                  </h6>
                  <h6 class="preview-content font-bold" [hidden]="hidePassword">
                      <span></span>
                      <a appCustomTooltip title="hide" (click)="hidePassword = true;">
                          <i class="icon-eye"></i>
                      </a>
                  </h6>
                  <p class="preview-label"><span>{{ 'Password' | translate }}</span></p>
              </div>-->
              <div fxFlex="33" fxFlex.xs="100" class="p-2">
                <h6 class="preview-content font-bold">
                  <span>
                    {{accountInfo?.alternateEmailId}}
                  </span>
                </h6>
                <p class="preview-label"><span>{{ 'AlternateEmailId' | translate }}</span></p>
              </div>

              <div fxFlex="33" fxFlex.xs="100" class="p-2" *ngIf="personalInfo?.referenceTypeId === 'I'">
                <h6 class="preview-content font-bold">
                  <span>
                    {{organizationInfo?.aeoStatus == 'Y'? 'Yes':'No'}}
                  </span>
                </h6>
                <p class="preview-label"><span>{{ 'IsAEO' | translate }}</span></p>
              </div>


              <div fxFlex="25" fxFlex.xs="100" class="p-2">
                <h6 class="preview-content font-bold"><span>{{organizationInfo?.registrationReferenceNo}}</span></h6>
                <p class="preview-label"><span>{{ 'RegistrationReferenceNo' | translate }}</span></p>
              </div>
            </div>
          </expansion-panel-content>
        </expansion-panel>
        <expansion-panel>
          <expansion-panel-title>
            {{ 'PesonalInfo' | translate }}
          </expansion-panel-title>
          <expansion-panel-content>
            <div fxLayout="column wrap">
              <div fxLayout="row">
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{personalInfo?.fullName}}</span></h6>
                  <p class="preview-label"><span>{{ 'FullName' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{personalInfo?.gender}}</span></h6>
                  <p class="preview-label"><span>{{ 'Gender' | translate }}</span></p>
                </div>
              </div>
              <div fxLayout="row">
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{this.personalInfo?.idTypeName}}</span></h6>
                  <p class="preview-label"><span>{{ 'IDType' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{personalInfo?.idNumber}}</span></h6>
                  <p class="preview-label"><span>{{ 'IdNumber' | translate }}</span></p>
                </div>
              </div>
            </div>
          </expansion-panel-content>
        </expansion-panel>
        <expansion-panel>
          <expansion-panel-title>
            {{ 'AddressInfo' | translate }}
          </expansion-panel-title>
          <expansion-panel-content>
            <div fxLayout="column wrap">
              <div fxLayout="row">
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{addressInfo?.country?.countryName}}</span></h6>
                  <p class="preview-label"><span>{{ 'Country' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{addressInfo?.state?.stateName}}</span></h6>
                  <p class="preview-label"><span>{{ 'State' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{addressInfo?.city?.cityName}}</span></h6>
                  <p class="preview-label"><span>{{ 'City/Town/Area/Land' | translate }}</span></p>
                </div>
              </div>
              <div fxLayout="row">
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{addressInfo?.pin?.postalCode}}</span></h6>
                  <p class="preview-label"><span>{{ 'PostalCode' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{ this.mobileCountryCode?.countryCode }} {{addressInfo?.mobileNumber}}</span></h6>
                  <p class="preview-label"><span>{{ 'MobileNo' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{ this.businessCountryCode?.countryCode }} {{addressInfo?.businessNumber}}</span></h6>
                  <p class="preview-label"><span>{{ 'PrimaryTelephoneNo' | translate }}</span></p>
                </div>
              </div>
              <div fxLayout="row">
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{ this.alternativeNoCountryCode?.countryCode }} {{addressInfo?.residencyNumber}}</span></h6>
                  <p class="preview-label"><span>{{ 'AlternativeNo' | translate }}</span></p>
                </div>
                <div fxFlex="33" fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{ this.faxNo?.countryCode }} {{addressInfo?.faxNo}}</span></h6>
                  <p class="preview-label"><span>{{ 'FaxNo' | translate }}</span></p>
                </div>
              </div>
              <div fxLayout="row">
                <div fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{addressInfo?.addressLine1}}</span></h6>
                  <p class="preview-label"><span>{{ 'AddressLine1' | translate }}</span></p>
                </div>
              </div>
              <div fxLayout="row">
                <div fxFlex.xs="100" class="p-2">
                  <h6 class="preview-content font-bold"><span>{{addressInfo?.addressLine2}}</span></h6>
                  <p class="preview-label"><span>{{ 'AddressLine2' | translate }}</span></p>
                </div>
              </div>
            </div>
          </expansion-panel-content>
        </expansion-panel>
        <expansion-panel *ngIf='personalInfo?.referenceTypeId !== "G"'>
          <expansion-panel-title>
            {{ 'OtherInfo' | translate }}
          </expansion-panel-title>
          <expansion-panel-content>
            <div fxLayout="column wrap">
              <div fxLayout="row" fxFlex="100">
                <div fxLayout="row" fxFlex>
                  <div fxFlex="50" fxFlex.xs="100" class="p-2">
                    <h6 class="preview-content font-bold"><span>{{otherInfo?.registeredCustomStation}}</span></h6>
                    <p class="preview-label"><span>  {{ 'RegisteredCustomStation' | translate }}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </expansion-panel-content>
        </expansion-panel>
      </expansion-panels-container>

      <div class="my-2" *ngIf='personalInfo?.referenceTypeId === "O" || personalInfo?.referenceTypeId === "G"'>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h5 class="main-title m-0">{{'BusinessStakeholderCategory' | translate }}</h5>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="33.33" class="my-2" *ngFor="let stakeholder of businessStackHolderCategory">
            <span class="p-3 grey-background mx-2 display-block"> {{stakeholder.postingName}}</span>
          </div>
        </div>
      </div>

      <div class="my-3">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h5 class="main-title m-0">{{'ProfilesList' | translate }}</h5>
        </div>
        <div fxLayout="row wrap" class="my-2">
          <div fxFlex="100" *ngFor="let profileItem of profileDetails;let i=index">
            <mat-card fxLayout="row wrap" fxLayoutAlign="space-between center" class="my-2 p-2 grey-background card-border">
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="10" class="p-2">
                <p class="preview-content font-bold">{{i+1}}</p>
                <p class="preview-label">{{'No' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="30" class="p-2">
                <p class="preview-content font-bold">{{profileItem.postingName}}</p>
                <p class="preview-label">{{'ProfileName' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="60" class="p-2">
                <p class="preview-content font-bold" *ngIf="profileItem.isDefault === true ">{{'IsDefault' | translate }}</p>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="my-3">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h5 class="main-title m-0">{{'PortsList' | translate }}</h5>
        </div>
        <div fxLayout="row wrap" class="my-2">
          <div fxFlex="100" *ngFor="let portItem of portDetails; let i=index">
            <mat-card fxLayout="row wrap" fxLayoutAlign="space-between center" class="my-2 p-2 grey-background">
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="10" class="p-2">
                <p class="preview-content font-bold">{{i+1}}</p>
                <p class="preview-label">{{'No' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="30" class="p-2">
                <p class="preview-content font-bold">{{portItem.name}}</p>
                <p class="preview-label" title="{{portItem.name}}">{{'PortName' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="20" class="p-2">
                <p class="preview-content font-bold">{{portItem.locationType?.name}}</p>
                <p class="preview-label">{{'PortType' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="20" class="p-2">
                <p class="preview-content font-bold">{{portItem.unCode}}</p>
                <p class="preview-label">{{'PortCode' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="20" class="p-2">
                <p class="preview-content font-bold" *ngIf="portItem.isDefault === true">{{'IsDefault' | translate }}</p>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="my-3">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h5 class="main-title m-0">{{'CustomsStationsList' | translate }}</h5>
        </div>
        <div fxLayout="row wrap" class="my-2">
          <div fxFlex="100" *ngFor="let customstationItem of customStationDetails;let i= index">
            <mat-card fxLayout="row wrap" fxLayoutAlign="space-between center" class="my-2 p-2 grey-background">
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="10" class="p-2">
                <p class="preview-content font-bold">{{i+1}}</p>
                <p class="preview-label">{{'No' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="30" class="p-2">
                <p class="preview-content font-bold">{{customstationItem.stationName}}</p>
                <p class="preview-label" title="{{customstationItem.stationName}}">{{'StationName' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="20" class="p-2">
                <p class="preview-content font-bold">{{customstationItem.stationCode}}</p>
                <p class="preview-label">{{'StationCode' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="20" class="p-2">
                <p class="preview-content font-bold">{{customstationItem.state?.stateName}}</p>
                <p class="preview-label" title="{{customstationItem.state?.stateName}}">{{'StateName' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="20" class="p-2">
                <p class="preview-content font-bold" *ngIf="customstationItem.isDefault === '1' ">{{'IsDefault' | translate }}</p>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="mt-3" *ngIf='personalInfo?.referenceTypeId === "I" || personalInfo?.referenceTypeId === "O"'>
        <h5 class="main-title m-0">{{'BankDetails' | translate }}</h5>
        <div fxLayout="row wrap">
          <ul fxFlex class="bank-details">
            <li class="my-3 mx-0" *ngFor="let bankItem of bankDetails">
              <div fxFlex class="row no-gutters" fxFlex.xs="100">
                <div fxFlex="20" fxFlex.xs="100">
                  <div class="bank-image" fxFlex.xs="100">
                    <img src="../../../../assets/images/bank-images/bank-3.png" alt="{{bankItem?.bankName}}">
                  </div>
                </div>
                <span fxFlex="5" fxHide.xs="true"></span>
                <div fxFlex="100" class="bank-details" fxLayout="row" fxFlex.xs="100">
                  <div fxFlex="25" class="inner-bank-details" fxFlex.xs="100">
                    <h6 class="preview-content font-bold" fxLayoutAlign="start center">{{bankItem?.bankName}}</h6>
                    <p class="preview-label" fxLayoutAlign="start center">{{ 'BankName' | translate }}</p>
                  </div>
                  <div fxFlex="30" class="inner-bank-details">
                    <h6 class="preview-content font-bold" fxLayoutAlign="start center">{{bankItem?.accountNo}}</h6>
                    <p class="preview-label" fxLayoutAlign="start center">{{ 'AccountNo' | translate }}</p>
                  </div>
                  <div fxFlex="30" class="inner-bank-details">
                    <h6 class="preview-content font-bold" fxLayoutAlign="start center">{{bankItem?.ibanCode}}</h6>
                    <p class="preview-label" fxLayoutAlign="start center">{{ 'IBANCode' | translate }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="additioal-user-types-div" *ngIf='personalInfo?.referenceTypeId === "G" || personalInfo?.referenceTypeId === "C"'>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h5 class="main-title m-0"> {{'DepartmentList' | translate }}</h5>
        </div>
        <div fxLayout="column" class="my-2" *ngFor="let departmentItem of this.departmentDetails;let i = index">
          <mat-card fxLayout="row wrap" class="p-2 light-grey-background">
            <div fxFlex="90" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between center">
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="20" class="p-2">
                <h6 class="preview-content font-bold">{{departmentItem?.name}}</h6>
                <p class="preview-label">{{'DepartmentName' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="15" class="p-2">
                <h6 class="preview-content font-bold">{{departmentItem?.code}}</h6>
                <p class="preview-label">{{'DepartmentCode' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="25" class="p-2">
                <h6 class="preview-content font-bold">{{departmentItem?.description}}</h6>
                <p class="preview-label">{{'DepartmentDescription' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="15" class="p-2" *ngIf="personalInfo?.referenceTypeId === 'C' ">
                <h6 class="preview-content font-bold">{{ departmentItem?.usersCount ? departmentItem?.usersCount: 0 }}</h6>
                <p class="preview-label">{{'TotalUsers' | translate }}</p>
              </div>
              <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="15" class="p-2" *ngIf="personalInfo?.referenceTypeId === 'C' ">
                <h6 class="preview-content font-bold">{{departmentItem.activeUsers ? departmentItem.activeUsers : 0 }}</h6>
                <p class="preview-label">{{'ActiveUsers' | translate }}</p>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="form-footer button-container" fxLayout="row" fxLayoutAlign="end end" *ngIf='personalInfo?.referenceTypeId != "C"'>
        <mcn-button type="button" cssClass="btn-custom-primary" (OnClick)="profileEditProfileDetails($event)" text=" {{'Edit' | translate }}"></mcn-button>
        <mcn-button type="button" cssClass="btn-custom-secondary" (OnClick)="cancelProfileDetails($event)" text=" {{'Cancel' | translate }}"></mcn-button>
      </div>
    </mat-card>
  </div>
</div>
<!-- Organization Details Ends -->
