import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FrameworkCoreModule } from '@agility/frameworkcore';

import { InterceptedHttp } from '@agility/frameworkcore/interceptor/httpInterceptor';
import { NgxMasonryModule } from 'ngx-masonry';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';

import { LoginModule } from './account/login/LoginModule';
import { DashboardModule } from './dashboard/DashboardModule';
import { AppRoutingModule } from './AppRoutingModule';
import { McnControlsModule } from './mcnControls/McnControlsModule';
import { LanguageModule } from './common/LanguageModule';
import { SharedStaticModule } from './common/SharedStaticModule';
import { OAuthModule } from 'angular-oauth2-oidc';
import { OAuthService, OAuthStorage, AuthConfig } from 'angular-oauth2-oidc';

import { AppComponent } from './AppComponent';
import { HeaderComponent } from './header/HeaderComponent';
import { MenuModalComponent } from './header/menuModal/MenuModalComponent';
import { OverlayMenuService } from './header/OverlayMenuService';
import { ProfileModalComponent } from './header/profileModal/ProfileModalComponent';
import { QuickLinksModalComponent } from './header/quickLinksModal/QuickLinksModalComponent';
import { SearchModalComponent } from './header/searchModal/SearchModalComponent';
import { LanguageSelectionModalComponent } from './header/languageSelection/LanguageSelectionModalComponent';
import { ChangePasswordComponent } from './account/login/passwordManagement/changePassword/changePasswordComponent';
import { SelectivePreloadingStrategy } from './SelectivePreloadingStrategy';
import { AlertModalService } from './services/AlertModalService';
import { SignalRService } from '././signalR/signalR.service';
import { ToasterService } from './toastrService/toasterService';
import { McnConfig } from './mcnConfig/McnConfig';
import { PermitConfig } from './mcnConfig/PermitConfig';

import { IdleSessionService } from './idleSessionService/IdleSessionService';
import { IdleSessionModalComponent } from './idleSessionService/IdleSessionModal';

import { FooterComponent } from './footer/FooterComponent';
import { McnRoutingConfig } from './mcnRoutingConfig/McnRoutingConfig';

import { ChartModule } from 'angular2-highcharts';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { JourneyService } from './cargo/JourneyService';
import { WizardService } from './common/WizardService';

import { DeclarationSharedService } from './declaration/services/sharedServices/DeclarationSharedService';
import { CarrierShareService } from './carrierRegistration/CarrierSharedService';
import { TariffSharedService } from './tariffManagement/services/sharedServices/TariffSharedServices';
import { ExemptionSharedService } from './tariffExemption/services/sharedServices/ExemptionSharedServices';
import { ShipClrSharedService } from './shipClr/services/sharedServices/ShipClrSharedServices';
import { DrawbackSharedService } from './drawback/services/sharedService/DrawbackSharedService';
import { AssessmentSharedService } from './assessment/sharedservice/AssessmentSharedService';
import { CommonRemarksHistoryService } from './commonComponents/remarksHistory/CommonRemarksHistoryService';
import { CommonSharedService } from './common/CommonSharedService';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RmsConfig } from './mcnConfig/RmsConfig';
import { BwhConfig } from './mcnConfig/BwhConfig';
import { LicenseConfig } from './mcnConfig/LicenseConfig';
import { PcaConfig } from './mcnConfig/PcaConfig';
import { ServiceReceiptConfig } from './mcnConfig/ServiceReceiptConfig';
import {
  RiskSelectivityProfilingSharedService
} from './rms/riskSelectivityProfiling/services/sharedServices/RiskSelectivityProfilingSharedService';
import { RMSPreferencesSharedService } from './rms/rmsPreferences/services/sharedServices/RMSPreferencesSharedService';
import { DashboardRoutingModule } from './dashboard/DashboardRoutingModule';
import { RiskIdentificationAnalysisRoutingModule } from './rms/riskIdentificationAnalysis/RiskIdentificationAnalysisRoutingModule';
import { PWAService } from './services/pwaService';

import { UpldCargoDocsComponent } from './shipClr/createShipFrm/cargoDecl/upldCargoDocs/UpldCargoDocsComponent';
import { UpldShipStoresComponent } from './shipClr/createShipFrm/shipStores/upldShipStores/UpldShipStoresComponent';
import { UpldCrewEffectsComponent } from './shipClr/createShipFrm/crewEffects/upldCrewEffects/UpldCrewEffectsComponent';
import { UpldCrewListComponent } from './shipClr/createShipFrm/crewList/upldCrewList/UpldCrewListComponent';
import { DfsConfig } from './mcnConfig/DfsConfig';
import { DfsSharedService } from './dfs/sharedService/DfsSharedService';
import { FreeZoneConfig } from './mcnConfig/FreeZoneConfig';
import { chartBotModule } from './account/chatBot/chartBotModule';
import { environment } from '@env/environment';
import { AppSettingsService } from './app.settings';
import { SezConfig } from './mcnConfig/SezConfig';

export function highchartFactory() {
  const highcharts = require('highcharts');
  const highchartsMore = require('highcharts/highcharts-more');
  const highcharts3D = require('highcharts/highcharts-3d');
  highcharts3D(highcharts);
  highchartsMore(highcharts);
  return highcharts;
}

export const highCharts = [
  { provide: HighchartsStatic, useFactory: highchartFactory }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProfileModalComponent,
    MenuModalComponent,
    SearchModalComponent,
    QuickLinksModalComponent,
    LanguageSelectionModalComponent,
    ChangePasswordComponent,
    IdleSessionModalComponent,
    FooterComponent,
    UpldCargoDocsComponent,
    UpldShipStoresComponent,
    UpldCrewEffectsComponent,
    UpldCrewListComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgxMasonryModule,
    BrowserAnimationsModule,
    LanguageModule.forRoot(),
    SharedStaticModule.forRoot(),
    FrameworkCoreModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    NgIdleModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB6Mih99RBMDuxJIdXLx8kpcbz-nl4me20'
    }),
    ChartModule,
    AgmDirectionModule,
    InfiniteScrollModule,
    OAuthModule.forRoot(),

    // Third Party Modules
    FilterPipeModule,
    Ng4LoadingSpinnerModule.forRoot(),
    ToastrModule.forRoot(),
    McnControlsModule,
    // Client Modules
    LoginModule,
    DashboardModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false,registrationStrategy: 'registerImmediately' }),  

    DashboardRoutingModule,
    RiskIdentificationAnalysisRoutingModule,
    chartBotModule
  ],
  entryComponents: [
    ChangePasswordComponent,
    ProfileModalComponent,
    MenuModalComponent,
    SearchModalComponent,
    QuickLinksModalComponent,
    LanguageSelectionModalComponent,
    IdleSessionModalComponent,
    UpldCargoDocsComponent,
    UpldShipStoresComponent,
    UpldCrewEffectsComponent,
    UpldCrewListComponent,
  ],
  providers: [
    // Common
    CommonRemarksHistoryService,
    CommonSharedService,

    // Shared Services
    JourneyService,
    DeclarationSharedService,
    CarrierShareService,
    TariffSharedService,
    ExemptionSharedService,
    DrawbackSharedService,
    AssessmentSharedService,
    ShipClrSharedService,
    RiskSelectivityProfilingSharedService,
    RMSPreferencesSharedService,
    DfsSharedService,
    OverlayMenuService,
    ToasterService,
    AlertModalService,
   PWAService,
    McnConfig,
    BwhConfig,
    DfsConfig,
    LicenseConfig,
    RmsConfig,
    PcaConfig,
    PermitConfig,
    McnRoutingConfig,
    SignalRService,
    IdleSessionService,
    WizardService,
    SelectivePreloadingStrategy,
    FreeZoneConfig,
    ServiceReceiptConfig,
    SezConfig,
    highCharts,
    { provide: AppSettingsService, useFactory: appSettingsServiceFactory },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptedHttp, multi: true },
    { provide: OAuthStorage, useFactory: storageFactory }
  ],
  exports: [McnControlsModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}


export function appSettingsServiceFactory() {
  return new AppSettingsService(environment.appSettings);
}

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory(): OAuthStorage {
  return localStorage
}


