import { ServiceDocument } from '@agility/frameworkcore';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { ToasterService } from '../../../../toastrService/toasterService';
import { InspectionSharedService } from '../../../sharedService/InspectionSharedService';
import { InspectionSeizeDetailsModel } from '../../createInspectionRequest/invoiceItemEdit/InspectionSeizeDetailsModel';
import { DeclarationModel } from './DeclarationModel';
import { InvoiceItemModel } from './invoiceItemDetails/InvoiceItemModel';
import { InvoiceModel } from './InvoiceModel';
import { InspectionMessages } from '../../../common/Constant';

@Injectable()
export class InvoiceRefService {
  public declarationServiceDocument: ServiceDocument<DeclarationModel> = new ServiceDocument<DeclarationModel>();
  public invoiceServiceDocument: ServiceDocument<InvoiceModel> = new ServiceDocument<InvoiceModel>();
  public invoiceItemServiceDocument: ServiceDocument<InvoiceItemModel> = new ServiceDocument<InvoiceItemModel>();
  public invoiceRefHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private toastrService: ToasterService,
    public appConfig: McnConfig,
    private sharedService: InspectionSharedService,
  ) { }

  public listInvoiceItems(): Observable<ServiceDocument<InvoiceItemModel>> {
    return this.invoiceItemServiceDocument.list(this.appConfig.invoiceItemsdata,
      new HttpParams().set('id', this.sharedService.sharingData.declarationId.toString()))
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.invoiceRefHandleError(), ngNavigationCancelingError: true
      })));
  }

  public openInvoiceReferenceDetails(): Observable<ServiceDocument<InvoiceModel>> {
    return this.invoiceServiceDocument.open(this.appConfig.openInvoiceReferenceDetails,
      new HttpParams().set('id', this.sharedService.sharingData.invoiceId.toString()))
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.invoiceRefHandleError(), ngNavigationCancelingError: true
      })));
  }

  public fiterInvoiceItemList(expression: string, aliasName: string): Observable<ServiceDocument<InvoiceItemModel>> {
    return this.invoiceItemServiceDocument.list(this.appConfig.inspInvoiceItemFilterList,
      new HttpParams().set('expression', expression).set('aliasName', aliasName).set('pageIndex', '0')
        .set('recordPerPage', this.appConfig.appData.pageCount.toString())).pipe(map((response) => response),
          catchError(() => throwError({ msg: this.invoiceRefHandleError(), ngNavigationCancelingError: true })));
  }

  public openInvoiceItem(): Observable<ServiceDocument<InvoiceItemModel>> {
    return this.invoiceItemServiceDocument.open(this.appConfig.openInvoiceItems,
      new HttpParams().set('id', this.sharedService.sharingData.invoiceItemId.toString()))
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.invoiceRefHandleError(), ngNavigationCancelingError: true
      })));
  }

  public openRefDetails(): Observable<any> {
    return this.http.get(this.appConfig.openReferenceDetails + '?id=' +
      this.sharedService.sharingData.declarationId, { headers: this.invoiceRefHeaders })
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.invoiceRefHandleError(), ngNavigationCancelingError: true
      })));
  }

  public listJourneyDetails(): Observable<any> {
    return this.http.get(this.appConfig.getBillOfLadingData + '?id=' + this.sharedService.sharingData.billOfLadingId)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.invoiceRefHandleError(), ngNavigationCancelingError: true
      })));

  }

  public getBillOfLadingReferenceDetails(): Observable<any> {
    return this.http.get(this.appConfig.openBillOfLadingReferenceDetails + '?id=' + this.sharedService.sharingData.billOfLadingId)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.invoiceRefHandleError(), ngNavigationCancelingError: true
      })));
  }

  public submitSeizeDetails(seizedModel: InspectionSeizeDetailsModel): Observable<any> {
    return this.http.post(this.appConfig.submitSeizeDetails, seizedModel, { headers: this.invoiceRefHeaders })
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.invoiceRefHandleError(), ngNavigationCancelingError: true
      })));
  }

  public getTariffData(): Observable<any> {
    return this.http.get(this.appConfig.getTariffItem + '?filterCount=10', { headers: this.invoiceRefHeaders })
      .pipe(map((response) => this.extractInvoiceRefDomainData(response)), catchError(() => throwError({
        msg: this.invoiceRefHandleError(), ngNavigationCancelingError: true
      })));
  }

  private invoiceRefHandleError() {
    return this.toastrService.Error(this.appConfig.getHardCodeMSg(InspectionMessages.InternalServerError));
  }

  private extractInvoiceRefDomainData(response: any) {
    const i = 0;
    let keys: string[];
    keys = Object.keys(response);
    const body = response[keys[i]];
    return body || [];
  }
}
