import { ServiceDocument, UserProfile } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import { LoginViewModel } from './viewmodels/LoginViewModel';
import { CaptchaModel } from './viewmodels/CaptchaViewModel';
import { OrganizationViewModel } from './viewmodels/OrganizationViewModel';
import { RegisterViewModel } from './viewmodels/RegisterViewModel';
import { map } from 'rxjs/operators';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class LoginService {
  public serviceDocument: ServiceDocument<UserProfile> = new ServiceDocument<UserProfile>();
  public organizationModel: OrganizationViewModel[];
  constructor(
    // tslint:disable-next-line: deprecation
    private http: HttpClient,
    private appConfig: McnConfig,
  ) { }

  public login(loginModel: LoginViewModel): Observable<ServiceDocument<UserProfile>> {
    return this.serviceDocument.login(this.appConfig.login, loginModel);
  }

  public DashboardLogin(loginModel: LoginViewModel): Observable<ServiceDocument<UserProfile>> {
    return this.serviceDocument.login(this.appConfig.dashboardLogin, loginModel);
  }

  public register(registerModel: RegisterViewModel): Observable<ServiceDocument<UserProfile>> {
    return this.http.post(this.appConfig.register, registerModel).pipe(
      // tslint:disable-next-line: deprecation
      map((response: Response) => response.json()));
  }
  public getOrganizations(): Observable<OrganizationViewModel[]> {
    return this.http.get(this.appConfig.getOrganizations)
      // tslint:disable-next-line: deprecation
      .pipe(map((response: Response) => response.json()));
  }

  public logout(): Observable<boolean> {
    return this.serviceDocument.logout(this.appConfig.logout);
  }

  public getUserData(): Observable<ServiceDocument<UserProfile>> {
    return this.http.get<ServiceDocument<UserProfile>>(this.appConfig.registerGetUserData);
  }
  public validateCaptcha(captchaModel: CaptchaModel): Observable<boolean> {
    return this.http.post<boolean>(this.appConfig.validateCaptcha, captchaModel);
  }

  public logoutIdp(logoutId: any): Observable<any> {
    return this.http.get<any>(environment.appSettings.authConfig.issuer + '/Account/Logout?logoutId=' + logoutId);
  }
}
