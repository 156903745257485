/* tslint:disable: max-line-length */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { StorageService } from '../storageService/storageService';


@Injectable()
export class BwhConfig implements OnInit {

  // Destruction Request
  listDestructionRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DestructionRequest/ListDestructionRequest';
  newDestructionRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DestructionRequest/NewDestructionRequest';
  openDestructionRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DestructionRequest/OpenDestructionRequest';
  submitDestructionRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DestructionRequest/SubmitDestructionRequest';
  deleteDestructionRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DestructionRequest/DeleteDestructionRequest';
  listDestructionItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DestructionRequest/ListDestructionItems';

  // RemitDuties Request
  listRemitDuties: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/RemitDuties/ListRemitDuties';
  newRemitDuties: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/RemitDuties/NewRemitDuties';
  openRemitDuties: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/RemitDuties/OpenRemitDuties';
  submitRemitDuties: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/RemitDuties/SubmitRemitDuties';
  deleteRemitDuties: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/RemitDuties/DeleteRemitDuties';
  filterRemitDutiesTariffItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/RemitDuties/ListWarehouseFormItems';
  submitRemitDutiesRequestItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/RemitDuties/SubmitRemittanceReqItems';
  listAssociatedRemitDutiesRequestItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/RemitDuties/ListAssociatedRemitDutiesRequestItems';
  submitRemitDutiesInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/RemitDuties/SubmitRemitInspectionItem';
  openRemitDutiesInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/RemitDuties/OpenRemitInspectionItem';

  // StorageExtension Request
  listStorageExtension: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/StorageExtension/ListStorageExtension';
  newStorageExtension: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/StorageExtension/NewStorageExtension';
  openStorageExtension: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/StorageExtension/OpenStorageExtension';
  submitStorageExtension: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/StorageExtension/SubmitStorageExtension';
  deleteStorageExtension: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/StorageExtension/DeleteStorageExtension';
  listStorageExtensionWHItems = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/StorageExtension/ListStorageExtensionWHItems';

  // TransferRequest
  listTransferRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/ListTransferRequest';
  newTransferRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/NewTransferRequest';
  openTransferRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/OpenTransferRequest';
  submitTransferRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/SubmitTransferRequest';
  deleteTransferRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/DeleteTransferRequest';
  organizationApiUrl: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/GetOrganizations?filterValue=';
  filterTransferGoodsRequestTariffItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/ListWarehouseFormItems';
  submitTransferGoodsRequestItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/SubmitTransferReqItems';
  listAssociatedTransferGoodsRequestItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/ListAssociatedTransferRequestItems';
  submitTransferGoodsRequestInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/SubmitWHFormItem';
  openTransferGoodsRequestInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/OpenWHFormItem';
  getOrganizationDetailsApiUrl: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/TransferRequest/GetOrganizationDetails';

  // WareHouse Quantity Balance
  listWareHouseBalance: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/WareHouseBalance/ListWareHouseBalance';
  openWareHouseBalance: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/WareHouseBalance/openWareHouseBalance';
  deleteWareHouseBalance: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/WareHouseBalance/deleteWareHouseBalance';
  newWareHouseBalance: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/WareHouseBalance/newWareHouseBalance';
  submitWareHouseBalance: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/WareHouseBalance/submitWareHouseBalance';
  listWareHouseBalanceFormItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/WareHouseBalance/ListWarehouseItems';
  openWareHouseBalanceTariffItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/WareHouseBalance/OpenTariffItem';
  listWareHouseQuantityBalanceItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/WareHouseBalance/ListWarehouseBalanceItems';

  // Warehouse Inbound/Outbound form
  listWareHouse = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/ListWarehouse';
  openWareHouse = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/OpenWarehouse';
  newWareHouse: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/NewWarehouse';
  submitWareHouse: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/SubmitWarehouse';
  deleteWareHouse: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/DeleteWarehouse';
  filterTariffItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/associateTariffItem';
  submitWarehouseTariffItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/submitTariffItems';
  getWHRemarksHistoryData: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/GetWHRemarksHistoryData';
  listAssociatedWarehouseTariffItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/listAssociatedWarehouseTariffItems';
  licenseInfoApiUrl: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/GetWarehouseLicenseNumbers?filterValue=';
  consigneeInfoApiUrl: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/GetConsigneeInfo?filterValue=';
  listWarehouseItems = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/ListWarehouseItems';
  newInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/NewInvoiceItem';
  getTariffItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/GetTariffItems?filterValue=';
  submitInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/SubmitInvoiceItem';
  openInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/OpenInvoiceItem';
  deleteInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/DeleteInvoiceItem';
  getWarehouseWfStates: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/GetWarehouseWfStates';
  getWhFormItemsWfStates: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/GetWHFormItemsWfStates';
  openWHFormItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/OpenWHFormItem';
  listWarehouseDecRefData: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/ListWarehouseDecRefData';

  // Vehicle Movement Request
  listVehicleMovement: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/ListVehicleMovement';
  newVehicleMovement: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/NewVehicleMovement';
  openVehicleMovement: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/OpenVehicleMovement';
  submitVehicleMovement: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/SubmitVehicleMovement';
  deleteVehicleMovement: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/DeleteVehicleMovement';
  filterWarehouseVehicles: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/associateWarehouseVehicles';
  listAssociatedWarehouseVehicles: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/ListAssociatedWarehouseVehicles';
  submitAssocWarehouseVehicles: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/SubmitAssocWarehouseVehicles';
  listVMExtensionHistory: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/ListVMExtensionHistory';
  newVMExtensionHistory: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/NewVMExtensionHistory';
  submitVMExtensionHistory: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/VehicleMovement/SubmitVMExtensionHistory';

  listAssociatedRemittanceRequestItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/listAssociatedWarehouseTariffItems';

  // Discrepancy Request
  listDiscrepancyItems = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DiscrepancyRequest/ListDiscrepancyItems';
  listDiscrepancyRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DiscrepancyRequest/ListDiscrepancyRequest';
  newDiscrepancyRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DiscrepancyRequest/NewDiscrepancyRequest';
  openDiscrepancyRequest = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DiscrepancyRequest/OpenDiscrepancyRequest';
  submitDiscrepancyRequest = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DiscrepancyRequest/SubmitDiscrepancyRequest';
  deleteDiscrepancyRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DiscrepancyRequest/DeleteDiscrepancyRequest';
  openDiscrepancyItem = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DiscrepancyRequest/OpenDiscrepancyItem';
  submitDiscrepancyItem = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/DiscrepancyRequest/SubmitDiscrepancyItem';

  // MDM
  getMcnTypes: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/McnTypes/GetModuleType';
  countryApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Country/GetCountry?filterValue=';
  volumeUnitApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/VolumeUnit/GetVolumeUnit?filterValue=';
  currencyApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Currency/GetCurrency?filterValue=';
  customStationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/CustomStation/GetCustomStation?filterValue=';
  stateApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/State/GetAllState?filterValue=';
  cityApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/City/GetCity?filterValue=';
  postalApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/PostalCode/GetPostalCode?filterValue=';


  // Documents Upload
  openWarehouseUploadDocument: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/OpenWarehouseUploadDocument';
  submitWarehouseUploadDocument: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/SubmitWarehouseUploadDocument';
  deleteWarehouseUploadDocuments: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/DeleteWarehouseUploadDocuments';
  downloadWarehouseUploadDocuments: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/Warehouse/DownloadWarehouseUploadDocuments';
  
  constructor(
    private http: HttpClient,
    private storageService: StorageService) { }
  ngOnInit() {
  }

  public convertJsonString(key: any, value: any) {
    const obj = {};
    obj[key] = value;
    return JSON.stringify(obj);
  }

  public replaceString(actualString: string, replaceString: string, replaceValue: string) {
    return actualString.replace(replaceString, replaceValue);
  }

}
