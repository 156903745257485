/* tslint:disable: max-line-length */
import { OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { StorageService } from '../storageService/storageService';
import { isNullOrUndefined } from 'util';


@Injectable()
export class DfsConfig implements OnInit {

  // Transaction History
  // Dfs Inbound Outbound Forms
  getDfsRemarksHistoryData: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/GetDfsRemarksHistoryData';

  // Dfs Inbound/Outbound form
  listDfs = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/ListDutyFreeShop';
  openDfs = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/OpenDutyFreeShop';
  newDfs: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/NewDutyFreeShop';
  submitDfs: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/SubmitDutyFreeShop';
  deleteDfs: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/DeleteDutyFreeShop';
  getDfsWfStates: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/GetDutyFreeShopWfStates';

  // Dfs From Items
  newInvoiceItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/NewDutyFreeShopInvoiceItem';
  openInvoiceItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/OpenInvoiceItem';
  submitInvoiceItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/SubmitDutyFreeShopInvoiceItem';
  deleteInvoiceItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/DeleteInvoiceItem';
  getTariffItems: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/GetTariffItems?filterValue=';
  getInventoryTariffItems: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/GetTariffItems?filterValue=A';

  // Dfs Form Invoice Items
  listDfsItems = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/ListDutyFreeShopItems';
  filterTariffItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/AssociateTariffItem';
  submitDfsTariffItems: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/SubmitTariffItems';
  openDfsFormItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/OpenDfsFormItem';
  listAssociatedDfsTariffItems: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/Warehouse/ListAssociatedDutyFreeShopTariffItems';

  // Discrepancy Request
  listDiscrepancyRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDiscrepancyRequest/ListDfsDiscrepancyRequest';
  newDiscrepancyRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDiscrepancyRequest/NewDfsDiscrepancyRequest';
  openDiscrepancyRequest = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDiscrepancyRequest/OpenDfsDiscrepancyRequest';
  submitDiscrepancyRequest = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDiscrepancyRequest/SubmitDfsDiscrepancyRequest';
  deleteDiscrepancyRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDiscrepancyRequest/DeleteDfsDiscrepancyRequest';
  getDiscrepancyWfStates: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDiscrepancyRequest/GetDfsDiscrepancyWfStates';

  // Discrepancy Items
  listDiscrepancyItems = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDiscrepancyRequest/ListDfsDiscrepancyItems';
  openDiscrepancyItem = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDiscrepancyRequest/NewDiscrepancyItemDetail';
  submitDiscrepancyItem = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDiscrepancyRequest/SubmitDfsDiscrepancyItem';

  // Dfs Outbound Passenger Details
  listDfsPassenger = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsPassenger/ListDfsPassenger';
  openDfsPassenger = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsPassenger/OpenDfsPassenger';
  newDfsPassenger: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsPassenger/NewDfsPassenger';
  submitDfsPassenger: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsPassenger/SubmitDfsPassenger';
  deleteDfsPassenger: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsPassenger/DeleteDfsPassenger';

  // Dfs Passenger Items
  listPassengerItems = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsPassenger/ListPassengerItem';
  newPassengerItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsPassenger/NewPassengerItem';
  openPassengerItems: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsPassenger/OpenPassengerItem';
  submitPassengerItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsPassenger/SubmitPassengerItem';
  deletePassengerItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsPassenger/DeletePassengerItem';

  // MDM
  getMcnTypes: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/McnTypes/GetModuleType';
  countryApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Country/GetCountry?filterValue=';
  volumeUnitApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/VolumeUnit/GetVolumeUnit?filterValue=';
  currencyApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Currency/GetCurrency?filterValue=';
  quantityUnitApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/QuantityUnit/GetQuentityUnit?filterValue=';
  customStationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/CustomStation/GetCustomStation?filterValue=';
  // stateApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/State/GetAllState?filterValue=';
  // cityApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/City/GetCity?filterValue=';
  // postalApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/PostalCode/GetPostalCode?filterValue=';


  // Documents Upload
  openDfsUploadDocument: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/OpenDutyFreeShopUploadDocument';
  submitDfsUploadDocument: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/SubmitDutyFreeShopUploadDocument';
  deleteDfsUploadDocuments: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/DeleteDutyFreeShopUploadDocuments';
  downloadDfsUploadDocuments: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DutyFreeShop/DownloadDutyFreeShopUploadDocuments';

  // DFS Transfer Request
  listDfsTransferRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/ListDfsTransferRequest';
  newDfsTransferRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/NewDfsTransferRequest';
  submitDfsTransferRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/SubmitDfsTransferRequest';
  organizationApiUrl: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/GetOrganizations?filterValue=';
  openDfsTransferRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/OpenDfsTransferRequest';
  deleteDfsTransferRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/DeleteDfsTransferRequest';
  filterTransferRequestTariffItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/ListWarehouseFormItems';
  submitTransferRequestItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/SubmitTransferReqItems';
  listAssociatedTransferRequestItems: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/ListAssociatedTransferRequestItems';
  getOrganizationDetailsApiUrl: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/GetOrganizationDetails';

  // DFS Transfer Request Documents Upload
  openDfsTransReqUploadDocument: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/OpenDfsTransferReqUploadDocument';
  submitDfsTransReqUploadDocument: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/SubmitDfsTransferReqUploadDocument';
  deleteDfsTransReqUploadDocuments: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/DeleteDfsTransferReqUploadDocuments';
  downloadDfsTransReqUploadDocuments: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsTransferRequest/DownloadDfsTransferReqUploadDocuments';

  // Dfs Quantity Balance
  listDfsBalance: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsQtyBalance/ListDfsBalance';
  listDfsInventoryItems: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsQtyBalance/ListDfsInventoryItem';
  listDfsQuantityBalanceItems: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsQtyBalance/ListDfsBalanceItems';
  openDfsBalance: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsQtyBalance/OpenDfsQtyBalance';
  openDfsBalanceTariffItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsQtyBalance/OpenTariffItem';

  // Dfs Remit Duties
  listRemittanceRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/ListDfsRemitDuties';
  newRemittanceRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/NewDfsRemitDuties';
  openRemittanceRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/OpenDfsRemitDuties';
  deleteRemittanceRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/DeleteDfsRemitDuties';
  submitRemittanceRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/SubmitDfsRemitDuties';
  getAssociatedRemitItemsList: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/AssociatedRemitItemsList';
  // Dfs Remit Items
  filterRemitDutiesTariffItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/ListDfsFormItems';
  submitRemitDutiesRequestItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/SubmitRemittanceReqItems';
  listAssociatedRemitDutiesRequestItems: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/ListAssociatedRemitDutiesRequestItems';
  submitRemitDutiesInvoiceItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/SubmitRemitInspectionItem';
  openRemitDutiesInvoiceItem: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/OpenRemitInspectionItem';
  // Remit Documents Upload
  // openRemitUploadDocument: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/OpenDutyFreeShopUploadDocument';
  // submitRemitUploadDocument: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/SubmitDutyFreeShopUploadDocument';
  // deleteRemitUploadDocuments: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/DeleteDutyFreeShopUploadDocuments';
  // downloadRemitUploadDocuments: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsRemitDuties/DownloadDutyFreeShopUploadDocuments';


  // Destruction Request
  listDfsDestructionRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDestructionRequest/ListDfsDestructionRequest';
  openDfsDestructionRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDestructionRequest/OpenDfsDestructionRequest';
  viewDfsDestructionRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDestructionRequest/ViewDestructionRequestItems';
  submitDestructionRequest: any = environment.appSettings.apiEndpoints.dfsApiBaseUrl + '/api/DfsDestructionRequest/SubmitDestructionRequest';

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {

  }

  ngOnInit() {
  }

}
