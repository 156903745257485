export enum DrawbackMessages {
  CommonFilterMessage = 'CommonFilterMessage',
  ConfirmDelete = 'ConfirmDelete',
  InternalServerErrorPleaseTryAgainLater = 'InternalServerErrorPleaseTryAgainLater',
  PccDeleteSuccessMessage = 'PccDeleteSuccessMessage',
  FilterMessage = 'CommonFilterMessage',
  SubmitReportMessage = 'SubmitReportMessasge',
  Error = 'Error',
  FacilityDeleteSucessMessage = 'FacilityDeleteSucessMessage',
  RawMaterialDeleteSuccessMessage = 'RawMaterialDeleteSuccessMessage',
  ClaimDeleteSucessMessage = 'ClaimDeleteSucessMessage',
  DrawbackAmountShouldBeGreaterThanFifty = 'DrawbackAmountShouldBeGreaterThanFifty',
  PlsUploadDocuments = 'UploadDocs',
  ClaimRightDeleteSucessMessage = 'ClaimRightDeleteSucessMessage',
  DeleteRequestForExtension = 'DeleteRequestForExtension',
  ReferenceNumber = 'ReferenceNumber',
  PleaseUploadDocumentWhiletheSubmissionofDecExtension = 'PleaseUploadDocumentWhiletheSubmissionofDecExtension',
  DeleteDrawbackClaim = 'DeleteDrawbackClaim',
  DeleteClaimRight = 'DeleteClaimRight',
  DeleteDrawbackPcc = 'DeleteDrawbackPcc',
  DeleteRawMaterial = 'DeleteRawMaterial',
  DeleteDrawbackFacility = 'DeleteDrawbackFacility'
}
export enum DrawbackModel {
  DrawbackFacilityApprovalListModel = 'DrawbackFacilityApprovalListModel',
  DrawbackClaimRequestListModel = 'DrawbackClaimRequestListModel',
  DrawbackPccListModel = 'DrawbackPccListModel',
  DrawbackRawMaterialModel = 'DrawbackRawMaterialModel',
  ListClaimRightModel = 'ListClaimRightModel',
  InvoiceItemViewModel = 'InvoiceItemViewModel',
  DecExtensionRequestListModel = 'DecExtensionRequestListModel'
}
export enum Constant {
  documentType = 'DOCUMENTTYPES',
  Typecode = 'Declaration_DocumentTypes'
}
export enum ProfileNames {
  DrawbackFacilityApproval = 'DrawbackFacilityApproval'
}
export enum ActionNames {
  Submit = 'Submit'
}
export enum SubmitErrors {
  None = 0,
  UploadDocs = 1,
  RawMaterials = 2,
  DrawbackPccError = 3,
  InternalServerError = 99
}
export enum Roles {
  CustomsStateDirector = 'CUSTOMS STATE DIRECTOR',
  Laboratory = 'LABORATORY'
}
export enum CurrentActions {
  SubmittedToReturnForCorrection = 'SubmittedToReturnForCorrectionDrawbackFacility',
  CreatedToSubmittedDrawbackFacility = 'CreatedToSubmittedDrawbackFacility',
  ModifiedToSubmittedDrawbackFacility = 'ModifiedToSubmittedDrawbackFacility',
  SubmittedToRejectedDrawbackFacility = 'SubmittedToRejectedDrawbackFacility',
  SubmittedToSentToOGADrawbackFacility = 'SubmittedToSentToOGADrawbackFacility',
  SentToOGAToReportSubmitDrawbackFacility = 'SentToOGAToReportSubmitDrawbackFacility',
  SubmittedToReturnForCorrectionDrawbackClaim = 'SubmittedToReturnForCorrectionDrawbackClaim',
  SubmittedToRejectedDrawbackClaim = 'SubmittedToRejectedDrawbackClaim',
  SubmittedToRejectedClaimRight = 'SubmittedToRejectedClaimRight',
  ReturnSubmittedDecExtenisonRequest = 'ReturnSubmittedDecExtenisonRequest',
  RejectDecExtensionRequest = 'RejectDecExtensionRequest',
  StartToCreatedDrawbackClaim = 'StartToCreatedDrawbackClaim',
  CreatedToModifiedDrawbackClaim = 'CreatedToModifiedDrawbackClaim',
  CreatedToSubmittedDrawbackClaim = 'CreatedToSubmittedDrawbackClaim',
  ModifiedToSubmittedDrawbackClaim = 'ModifiedToSubmittedDrawbackClaim',
  ReturnedForCorrectionToSubmittedDrawbackClaim = 'ReturnedForCorrectionToSubmittedDrawbackClaim',
  ReturnForCorrectionToSubmittedToDrawbackFacility = 'ReturnForCorrectionToSubmittedToDrawbackFacility',
  DraftCreatedToSubmittedClaimRight = 'DraftCreatedToSubmittedClaimRight',
  ModifiedToSubmittedClaimRight = 'ModifiedToSubmittedClaimRight',
  SaveModifiedDrawbackClaim = 'SaveModifiedDrawbackClaim',
  SubmitCreatedDecExtensionRequest = 'SubmitCreatedDecExtensionRequest',
  SubmitModifiedDecExtensionRequest = 'SubmitModifiedDecExtensionRequest'
}

export enum AlertMessages {
  delete = 'delete',
  WarnIcon = 'warn-icon',
}
