import { ChangeDetectorRef, Component, OnInit, Renderer } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ModuleNames, CommonTypes } from '../../../common/Constants';
import { StorageService } from '../../../storageService/storageService';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { McnTypesModel } from '../../../mdm/models/McnTypesModel';
import { MirrorAnalysisOpenModel } from '../models/MirrorAnalysisOpenModel';
import { ServiceDocument } from '@agility/frameworkcore';
import { RmsConfig } from '../../../../app/mcnConfig/RmsConfig';
import { WizardService } from '../../../../app/common/WizardService';
import { SafeUnsubscriber } from '../../../../app/common/SafeUnsubscriber';
import { ValidationService } from '../../../validations/ValidationService';
import { MirrorAnalysisChartComponent } from './MirrorAnalysisChart/MirrorAnalysisChartComponent';
import { ToasterService } from '../../../toastrService/toasterService';
@Component({
  selector: 'app-mirror-analysis',
  templateUrl: './MirrorAnalysisComponent.html',

})
export class MirrorAnalysisComponent extends SafeUnsubscriber implements OnInit {
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public serviceDocumentMirrorAnalysis: ServiceDocument<MirrorAnalysisOpenModel>;
  public mirrorAnalysisForm: FormGroup;
  public modified: boolean;
  public newFrequecy: boolean;
  public declarationModuleName: any;
  public modeOfTransportType: string;
  public tariffData: any;
  public mirrorAnalysisParameter: any[];
  public mirrorAnalysisYear: any[] = [];
  public mirrorAnalysisMonth: any[] = [];
  public moduleNameRms: string;
  public operaterType: string;
  public mirrorAnalysisDetails: any;
   public countryData: any[] = [];
  CountriesData =
  [
    { name: 'India-Import-81', value: 1012107 },
    { name: 'Australia-Export-81', value : 1011100 },
    { name: 'India-Import-87', value : 163096 },
    { name: 'Australia-Export-87', value: 160000 },
 ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public appConfig: McnConfig,
    public webStorage: StorageService,
    public rmsConfig: RmsConfig,
    private registrationWizardService: WizardService,
    private validationService: ValidationService,
    private http: HttpClient,
    private ref: ChangeDetectorRef,
    private toastrService: ToasterService,
  ) {
    super();
    this.declarationModuleName = ModuleNames.Declaration;
    this.modeOfTransportType = CommonTypes.MODEOFTRANSPORTDECLTYPES;
  }

  ngOnInit() {

    this.mirrorAnalysisForm = this.fb.group({
      tariffItemId: [null, [Validators.required as any]],
      reporterCountry: [null, [Validators.required as any]],
      partnerCountry: [null, [Validators.required as any]],
      year: [null, [Validators.required as any]],
      reportType: ['A'],
      month: [null]
      // modeofTransportId: [null, [Validators.required as any]],
      // parameter: [null, [Validators.required as any]],
    });

    // this.getParameter();
    // this.getYear();
    this.getYearData();
  }

  private getParameter() {
    this.operaterType = 'MirrorAnalysisParameterTypes';
    this.moduleNameRms = 'Rms';
    this.registrationWizardService.emitStepCompletionStatus(this.activatedRoute.snapshot.data['stepCount']);
    this.rmsConfig.getCascadingRMSTypes(this.moduleNameRms, this.operaterType).subscribe(response => {
      this.mirrorAnalysisParameter = response['rmsTypesModel'];
    });
  }
  private getYear() {
    this.operaterType = 'MirrorAnalysisYearTypes';
    this.moduleNameRms = 'Rms';
    this.registrationWizardService.emitStepCompletionStatus(this.activatedRoute.snapshot.data['stepCount']);
    this.rmsConfig.getCascadingRMSTypes(this.moduleNameRms, this.operaterType).subscribe(response => {
      this.mirrorAnalysisYear = response['rmsTypesModel'];
    });
  }

  private getYearData() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    for (let i = year; i >= year - 15; i--) {
      this.mirrorAnalysisYear.push({ item: i });
    }
    for (let i = 1; i <= 12; i++) {
      if (i < 10) { this.mirrorAnalysisMonth.push({ item: '0' + i });
    } else { this.mirrorAnalysisMonth.push({ item: i }); }
    }
  }

  public reportTypeChange(value: any) {
    if (value === 'M') {
      this.mirrorAnalysisForm.controls.month.setValidators([Validators.required]);
    } else {
      this.mirrorAnalysisForm.controls.month.setValue(null);
      this.mirrorAnalysisForm.controls.month.clearValidators();
    }
    this.mirrorAnalysisForm.controls.month.updateValueAndValidity();
    this.ref.markForCheck();
  }

  public cancel() {
    this.router.navigate(['/Dashboard/Home']);
  }

  private mirrorReport(): Observable<any> {
    const dic = {
      report_country: this.mirrorAnalysisForm.controls.reporterCountry.value,
      partner_country: [this.mirrorAnalysisForm.controls.partnerCountry.value],
      hs_list: [this.mirrorAnalysisForm.controls.tariffItemId.value],
      period: this.mirrorAnalysisForm.controls.reportType.value === 'A' ?
       this.mirrorAnalysisForm.controls.year.value :
       this.mirrorAnalysisForm.controls.year.value + this.mirrorAnalysisForm.controls.month.value ,
      freq: this.mirrorAnalysisForm.controls.reportType.value
   };
   const body = JSON.stringify(dic);
   const resp = this.http.post(this.appConfig.MirrorAnalysisApiUrl, body, { headers: this.headers });
    return resp;
}

public generateReport() {
 // this.reportTypeChange(this.mirrorAnalysisForm.controls.reportType.value);
 if (this.mirrorAnalysisForm.valid) {
  this.mirrorReport().subscribe((data) => {
    this.dialog.open(MirrorAnalysisChartComponent, {
        panelClass: 'custom-modal-container',
        data: {
          barChartData: data
        },
        width: '1050px',
      });
  });
 } else {
    Object.keys(this.mirrorAnalysisForm.controls).forEach(field => {
    const control = this.mirrorAnalysisForm.get(field);
    control.markAsTouched({ onlySelf: true });
  });
 }
}

}
