import { ProfileModel } from '@agility/frameworkcore';
export class ShipEffectsDetailsModel extends ProfileModel {
  shipEffectsDetailId: number;
  shipDeclarationId: number;
  familyName: string;
  rankorRating: string;
  goodsDescription: number;
  packageTypeId: number;
  quantity: number;
  uoM: number;
  ownerCSId: number;
  others: string;
  shipEffectId: number;
  excelDocuments?: any;
  packageType?: any;
  goodsDescript?: any;
  travelDocType?: any;
}
