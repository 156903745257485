import { ProfileModel } from '@agility/frameworkcore';
export class ShipCargoDetailsModel extends ProfileModel {
  shipCargoDetailId: number;
  shipDeclarationId: number;
  shipCargoId: number;
  houseBillNumber: string;
  hsCode: string;
  description: string;
  containerId: string;
  marks: string;
  pkgNumber: number;
  pkgType: number;
  grossweight: number;
  grossWeightUOM: number;
  shipmentOriginPortId: number;
  portOfDischargeId: number;
  remainOnBoard: number;
  isSplitHouseBill: string;
  submittedDate: Date;
  ownerCSId: number;
  startTime: Date;
  endTime: Date;
  excelDocuments?: any;
}
