import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FilterMenuService } from '../common/filter-menu.service';
import { McnConfig } from '../mcnConfig/McnConfig';
import { InboxService } from './inbox-tabs/services/InboxService';
import { ToasterService } from '../toastrService/toasterService';
import { HeaderComponent } from '../header/HeaderComponent';
import { FxContext } from '@agility/frameworkcore';
import { StorageService } from '../storageService/storageService';
import { ChangePasswordComponent } from '../account/login/passwordManagement/changePassword/changePasswordComponent';
import { MatDialog } from '@angular/material';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'dashboard',
  templateUrl: './DashboardComponent.html',
})

export class DashboardComponent implements OnInit {
  public inboxFilterForm: FormGroup;
  public filterMenuOpened: boolean;

  constructor(
    private _fb: FormBuilder,
    private appConfig: McnConfig,
    private filterMenuService: FilterMenuService,
    private inboxService: InboxService,
    private toasterService: ToasterService,
    private fxContext: FxContext,
    private dialog: MatDialog,
    public webStorage: StorageService

  ) { }

  ngOnInit() {
    this.filterMenuService.isFilterMenuOpen().subscribe((isFilterMenuOpened: boolean) => {
      this.filterMenuOpened = isFilterMenuOpened;
    });
    const isRandomPassowd = this.webStorage.get('IsRandomPassword');
    if (isRandomPassowd === true) {
      this.openChangePasswordModal();
    }
    this.webStorage.remove('IsRandomPassword');
    this.inboxFilterForm = this._fb.group({
      moduleName: [''],
      fromDate: [''],
      toDate: [''],
      isActive: [true]
    });
    this.inboxService.getUserDetails(this.fxContext.userProfile.userId).subscribe(
      (res) => {
        if (res.length > 0) {
          HeaderComponent.prototype.getUserDetails(res[0]);
          this.webStorage.set('stakeholderType', res[0].stakeHolder);
        }
      });
  }

  public filterInbox(event: any) {
    if (isNullOrUndefined(this.inboxFilterForm.controls.fromDate.value) || this.inboxFilterForm.controls.fromDate.value === '') {
      this.toasterService.Warning(this.appConfig.getHardCodeMSg('FromDateSelect'));
      return false;
    }
    if (isNullOrUndefined(this.inboxFilterForm.controls.toDate.value) || this.inboxFilterForm.controls.toDate.value === '') {
      this.toasterService.Warning(this.appConfig.getHardCodeMSg('ToDateSelect'));
      return false;
    }

    const timeDiff =
      Math.abs(this.inboxFilterForm.controls.fromDate.value.getTime() - this.inboxFilterForm.controls.toDate.value.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (diffDays > this.appConfig.historicalDataDateLimit) {
      this.toasterService.Warning(this.appConfig.getHardCodeMSg('BetwDateSelect') + ' ' +
        this.appConfig.historicalDataDateLimit + ' ' + this.appConfig.getHardCodeMSg('Days'));
      return false;
    }
    this.inboxService.isFilter = true;
    this.inboxService.fromDate = this.inboxFilterForm.controls.fromDate.value;
    this.inboxService.toDate = this.inboxFilterForm.controls.toDate.value;
    this.inboxService.moduleName = this.inboxFilterForm.controls.moduleName.value;
    this.inboxService.isActive = this.inboxFilterForm.controls.isActive.value;

    this.inboxService.getMessageList(0, this.inboxFilterForm.controls.isActive.value, this.inboxFilterForm.controls.fromDate.value,
      this.inboxFilterForm.controls.toDate.value, this.inboxFilterForm.controls.moduleName.value)
      .subscribe(() => {
        this.inboxService.hitRefreshDynamicData();
      });
  }

  public resetFilter(event: any) {
    this.inboxService.isFilter = false;
    this.inboxService.fromDate = null;
    this.inboxService.toDate = null;
    this.inboxService.moduleName = null;
    this.inboxService.isActive = true;

    this.inboxFilterForm.reset();
    this.inboxService.hitResetFilteredDynamicData();
  }

  public openChangePasswordModal() {
    this.dialog.open(ChangePasswordComponent, {
      panelClass: 'custom-modal-container',
    });
  }
}

