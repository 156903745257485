import { ProfileModel } from '@agility/frameworkcore';

export class CountryModel extends ProfileModel {

  public countryId: number;

  public countryName: string;

  public countryCode: string;

  public countryDescription: string;

  public UNCode: string;

  public status: boolean;
}
