import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { StorageService } from '../../storageService/storageService';

@Component({
  selector: 'mcn-multiSelect',
  template: `<div [formGroup]="formGroup" class="ps-rel {{cssClass}}" (click)="refresh($event)">
           <p class="preview-content {{cssClass}}">{{placeholder}}</p>
           <angular2-multiselect [data]="this.optionsData" [(ngModel)]="this.selectedItems"
                                  [settings]="dropdownSettings"
                                  (onSelect)="onItemSelect($event)"
                                  (onDeSelect)="OnItemDeSelect($event)"
                                  (onSelectAll)="onSelectAll($event)"
                                  (onDeSelectAll)="onDeSelectAll($event)" formControlName="{{controlName}}">
            </angular2-multiselect>
        </div>`
})

export class McnMultiSelectComponent {

  @Input() public formGroup: FormGroup;
  @Input() public controlName: string;
  @Input() public required: boolean;
  @Input() public placeholder: string;

  // @Input() isCascading: boolean;
  @Input() public optionsData: any[] = [];
  @Input() public optionKey: any;
  @Input() public isList = false;
  @Input() public isInbox = false;
  @Input() public isCascading = false;
  @Input() public optionValue: any;
  @Input() public optionParameterKey: any = null;
  @Input() public optionParameterValue: any = null;
  @Input() public containKeys: any[] = [];
  @Input() public iconClass: any;
  @Input() public iconList = false;
  @Input() public cssClass: string;
  @Input() public apiUrl: string;
  @Input() public disabled: boolean;
  @Input() public moduleName = '';
  @Output() public OnChange = new EventEmitter();
  @Input() public selectedValue: any[] = [];

  public userData: string[];
  public selectedItems: any[] = [];
  public dropdownSettings = {};
  public isDataFromApiOnValueChange: boolean;
  public inputData: any;

  constructor(private ref: ChangeDetectorRef, private http: HttpClient, private storageService: StorageService) {
  }

  ngOnInit(): void {
    const self: McnMultiSelectComponent = this;
    if (self.apiUrl) {
      self.isDataFromApiOnValueChange = true;
    }
    if (self.isDataFromApiOnValueChange) {
      self.setApiToSelect();
    } else {
      self.optionsData = this.optionsData;
      this.setSelectedItems();
    }
    this.setDropdownSettings();
  }

  ngOnChanges(): void {
    const self: McnMultiSelectComponent = this;
    if (self.apiUrl !== undefined && self.apiUrl != null && self.apiUrl !== '') {
      this.selectedItems = [];
      self.setApiToSelect();
    }
  }


  private setDropdownSettings() {
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select ' + this.placeholder,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      primaryKey: this.optionKey,
      labelKey: this.optionValue,
      classes: 'myclass custom-class ' + this.cssClass,
      disabled: this.disabled
    };
  }

  private setSelectedItems() {
    if (this.optionsData.length > 0) {
      if (this.formGroup.controls[this.controlName].value) {
        const selectedvalue: any[] = this.selectedValue;
        this.optionsData.forEach(x => {
          selectedvalue.forEach(opt => {
            if (x[this.optionKey] === opt) {
              this.selectedItems.push(x);
              selectedvalue.splice(selectedvalue.findIndex(z => z === opt), 1);
            }
          });
        });
        if (this.selectedItems.length > 0) {
          const result: any = this.selectedItems;
          this.formGroup.controls[this.controlName].patchValue((result));
        }
        this.setvaluetoFromcontrol();
      }
      // if (this.selectedValue) {
      //  const response = this.optionsData.find((x) => x[this.optionValue] === this.selectedValue);
      //  this.selectedItems.push(response);
      //  this.setvaluetoFromcontrol();
      // }
    }
  }

  onItemSelect(item: any) {
    this.setvaluetoFromcontrol();
  }
  OnItemDeSelect(item: any) {
    this.setvaluetoFromcontrol();
  }
  onSelectAll(items: any) {
    this.setvaluetoFromcontrol();
  }
  onDeSelectAll(items: any) {
    this.setvaluetoFromcontrol();
  }

  private setvaluetoFromcontrol() {
    const self: McnMultiSelectComponent = this;
    if (this.selectedItems.length > 0) {
      self.OnChange.emit({ event, options: this.selectedItems });
    }
  }

  private setApiToSelect(): void {
    this.getDataFromAPI().subscribe((res) => {
      const i = 0;
      if (!this.isList) {
        this.userData = Object.keys(res);
        this.optionsData = res[this.userData[i]];
      } else {
        this.optionsData = res;
      }
      this.setSelectedItems();
      this.ref.markForCheck();
      this.isCascading = false;
    });
  }


  private getDataFromAPI(): Observable<any> {
    let url = null;
    const api = this.apiUrl.split('?');
    if (this.moduleName !== '' && this.moduleName !== null && this.moduleName !== undefined) {
      url = api[0] + '?moduleName=' + this.moduleName;
      if (this.optionParameterKey !== null && this.optionParameterValue !== null) {
        url += '&&' + this.optionParameterKey + '=' + this.optionParameterValue;
      }
    } else if (this.optionParameterKey !== null && this.optionParameterValue !== null) {
      url = api[0] + '?' + this.optionParameterKey + '=' + this.optionParameterValue;
    } else {
      url = api[0];
    }
    return this.http.get(url);
  }


  public refresh(event: any): any {
    event.preventDefault();
    event.stopPropagation();
    if (this.isCascading) {
      this.cascading();
    }
  }

  private cascading(): void {
    const self: McnMultiSelectComponent = this;
    this.setDropdownSettings();
    if (this.optionParameterKey !== null
      && this.optionParameterValue !== null
      && self.apiUrl !== undefined
      && self.apiUrl != null
      && self.apiUrl !== '') {
      self.setApiToSelect();
      this.setSelectedItems();
    }
  }

}
