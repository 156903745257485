import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-ma-chart-popup',
  templateUrl: './MirrorAnalysisChartComponent.html',
  styleUrls: ['./MirrorAnalysisChart.css']
})

export class MirrorAnalysisChartComponent implements OnInit {
  public lblXAxis: String = 'Countries';
  public lblYAxis: String = 'Trade Values($)';
  public headerBarChartLabel = 'Mirror Analysis';
  public isHeaderFlagChart: Boolean = false;
  public vesselName: string;
  public imo: string;
  public berth: string;
  public departingIn: string;
  public totalManifests: number;
  public totalBLs: number;
  public label: String = 'Export';
  public label1: String = 'Import';
  // options for bar chart
  public optionsBarChartData: any;
  view: any[] = [800, 650];
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = this.lblXAxis;
  showYAxisLabel = true;
  yAxisLabel = this.lblYAxis;
  animations = true;
  colorScheme = {
    domain: ['#5AA454', '#A10A28']
  };

  customColors = (name) => {
    if (name.includes('Import')) {
    return '#5AA454';
   } else {
     return '#A10A28'; }
  }

  constructor(@Inject(MAT_DIALOG_DATA) chartData: any) {
    this.optionsBarChartData = chartData.barChartData.data;
    this.isHeaderFlagChart = true;
  }

  ngOnInit() {
    this.yAxisLabel = this.lblYAxis;
  }

  public onSelect(value: any) { }
}
