import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { McnConfig } from '../../mcnConfig/McnConfig';

export interface MyData {
  serviceId: number;
  servicePriceId: number;
  serviceReceiptId: number;
  serviceReceiptDetailId: number;
  consigneeId: number;
}

@Injectable()
export class ServiceReceiptSharedService {

  constructor(private http: HttpClient,
    private appConfig: McnConfig,) {
  }

  sharingData: MyData = {
    serviceId: 0,
    servicePriceId: 0,
    serviceReceiptId: 0,
    serviceReceiptDetailId: 0,
    consigneeId: 0
  };
}

