import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';

@Component(
  {
    selector: 'mcn-checkbox',
    template: `<div class="fx-card {{ cssClass }}" *ngIf="!IsformGroup">
                <mat-checkbox id="chk_{{optionalId}}{{(idValue == ''?(text==''?'checkbox1':text):idValue) | removeSpace}}" [disabled]="this.disabled"  (change)="_onChange($event)" [checked]="checked" value="value" [required]="required">{{text}}</mat-checkbox>
             </div>
            <div class="fx-card {{ cssClass }}" [formGroup]="formGroup" *ngIf="IsformGroup">
                <mat-checkbox [disabled]="this.disabled" id="chk_{{optionalId}}{{(idValue == ''?(text==''?'checkbox1':text):idValue) | removeSpace}}"  formControlName="{{controlName}}" (change)="_onChange($event)" [checked]="checked" value="value" [required]="required">{{text}}</mat-checkbox>
            </div>`
  }
)
export class McnCheckBoxComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() IsformGroup = true;
  @Input() optionalId = '';
  @Input() align: string;
  @Input() checked: boolean;
  @Input() disabled = false;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() value: any = '';
  @Input() idValue: any = '';
  @Input() text: any = '';
  @Input() cssClass: string;
  @Output() OnCheck = new EventEmitter();
  private _control: AbstractControl;
  private _self: McnCheckBoxComponent = this;

  ngOnInit(): void {
    if (this.IsformGroup) {
      const _self = this;
      if (_self.align === undefined || _self.align === '') {
        _self.align = 'start';
      }
      _self._control = this.formGroup.controls[_self.controlName];
      _self._control.enable();
      if (this.disabled) {
        _self._control.disable();
      }
    }
  }

  _onChange(event: any): void {
    if (this.OnCheck != null) {
      this.OnCheck.emit({ event: event, selectedValue: this.value });
    }
  }
  ngOnChanges(): void {
    if (this.IsformGroup) {
      const _self = this;
      _self._control = this.formGroup.controls[_self.controlName];
      if (this.disabled) {
        _self._control.disable();
      } else {
        _self._control.enable();
      }
    }
  }
}
